import React, { Component } from "react";
import { fetchContestRequests } from "../../store/actions/contestActions";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import { USER_TYPE } from "../../utils/constants";
import ReactHtmlParser, { } from 'react-html-parser';
import dateFormat from "dateformat";
import { formatDate } from "../../utils/utils";

class AdminEventRequestList extends Component {

  state = {
    isLoaded: false,
    events: [],
    accountType: null
  };


  /**
   * get all contests annd toggle componet loaded state when all data is loaded
   */
  componentDidMount() {
    const user = JSON.parse(Cookie.get("user"));
    this.setState({ "accountType": user.accountType });
    this.props.getAllEventRequest(user._id)
      .then(() => this.setState({
        events: this.props.eventRequests,
        isLoaded: true
      }))
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">

              <div className="row">
                {this.state.events.length > 0 ?
                  this.state.events.map(eventRequest => (
                    <div key={eventRequest._id} className="col-md-4 col-xl-4">
                      {/* <!-- Simple card --> */}
                      <div
                        onClick={() =>
                          this.props.history.push(`/${this.state.accountType !== null && this.state.accountType === USER_TYPE.SPONSOR ? "sponsor" : "admin"}/hackathon/requests/` + eventRequest._id)
                        }
                        className="card"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        <div style={{
                          height: "150px",
                          width: "100%"
                          , backgroundImage: "url(" + eventRequest.eventBanner.data + ")",
                          backgroundOrigin: "center",
                          backgroundSize: "cover"
                        }}>

                        </div>
                        {eventRequest.isApproved ?
                          <span
                            style={{ position: "absolute", right: "-20px", padding: "5px 10px", background: "#1bbe1b" }}
                            href="#"
                            className="badge badge-primary font-size-11 m-1"
                          >
                            Approved
                          </span>

                          :
                          <span
                            style={{ position: "absolute", right: "-20px", padding: "5px 10px", background: "red" }}
                            href="#"
                            className="badge badge-primary font-size-11 m-1"
                          >
                            Not Approved
                          </span>
                        }

                        <div className="card-body" style={{ textAlign: "left" }}>

                          <h4 className="card-title mt-0 text-dark" style={{ fontSize: '20px' }}>{eventRequest.title}</h4>
                          <p className="card-text text-muted" style={{ fontSize: "12px" }}>
                            {formatDate(new Date(eventRequest.startDate),)}
                            &nbsp;-&nbsp;
                            {formatDate(new Date(eventRequest.endDate),)}
                          </p>
                          <p className="text-dark">{ReactHtmlParser(eventRequest.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 40).trim().padEnd(3, "."))}</p>
                          <button className="btn btn-primary ml-auto">VIEW</button>
                          <div style={{ textAlign: "left" }}>
                            {eventRequest.tags ? eventRequest.tags.map((tag, index) => (
                              <span key={{ index }}
                                href="#"
                                className="badge badge-primary font-size-11 m-1"
                              >
                                {tag}
                              </span>
                            )) : ""}
                          </div>
                        </div>
                      </div>
                    </div>



                  ))

                  :
                  <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                    <div className="card" style={{ cursor: "pointer", border: "1px gray dashed", backgroundColor: "transparent", minHeight: "120px" }}>
                      <div className="card-body">
                        <p style={{ fontSize: "1.5vw" }}>NO EVENT REQUESTS</p>
                      </div>
                    </div>
                  </div>
                }


              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    eventRequests: state.contestData.contestRequests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllEventRequest: (userId) => dispatch(fetchContestRequests(userId)),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEventRequestList);
