import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchContest,
  getContestleaderboard,
  checkParticipation,
  createSubmission,
} from "../../store/actions/contestActions";
import {
  fetchAllEventTeams,
  getEventUserTeam,
  fetchTeam,
} from "../../store/actions/teamActions";
import {
  checkSession,
  judgeSubmission,
} from "../../store/actions/judgeActions";
import ReactHtmlParser from "react-html-parser";
import { BASE_URL } from "../../api/client";
import Cookie from "js-cookie";
import bih from "../../../src/bih.jpg";
import globe from "../../../src/globe.svg";
import dateFormat from "dateformat";
import { ResponsiveRadar } from "@nivo/radar";
import TeamListSkeleton from "../../components/skeletons/TeamListSkeleton";
import ContentSkeleton from "../../components/skeletons/ContentSkeleton";
import loader from "../../../src/Infinity.svg";
import moment from "moment";
import { formatDate, timeZoneHelper } from "../../utils/utils";

class JudgePage extends Component {
  data = [];
  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  state = {
    focusSubmission: {},
    event: {},
    team: {},
    leaderboard: [],
    isRegistered: false,
    submissionFile: null,
    teams: [],
    isLoaded: false,
    isTeamsLoaded: false,
    isLeaderBoardLoaded: false,
    focus: "teams",
    judge: {},
    rating: 0,
    eval: [],
    isSessionValid: true,
    isSubAnimOn: false,
    isTeamLoaded: false,
    focusRound: { evalCriteria: [] },
    focusSubmission: {},
    judgeEntries: []
  };

  makeSubmission() {
    this.props
      .pushFile(
        this.state.team._id,
        this.state.submissionFile.file,
        JSON.parse(Cookie.get("user"))._id,
        this.state.submissionFile.fileName
      )
      .then(() => this.setState({ team: this.props.team }));
  }

  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  setJudgeEntries(focusRound, focusSubmission) {

    let judgeEntries = focusRound.evalCriterias.map(criteria => {
      return {
        criteria: criteria,
        submission: focusSubmission._id,
        round: focusRound._id,
        event: this.state.event._id,
        team: this.state.team._id,
        score: 0,
        comment: ""
      }
    })

    this.setState({ judgeEntries: judgeEntries, focusRound: focusRound, focusSubmission: focusSubmission })
  }

  updateJudgeEntry(value, idx) {
    let entry = this.state.judgeEntries[idx]
    entry.score = value
    let entries = this.state.judgeEntries
    entries[idx] = entry

    this.setState({ judgeEntries: entries })
  }
  handleSkills(users) {
    // console.log(users);
    var data_temp = [];
    users.forEach((user) => {
      data_temp.push(...user.programmingLanguages);
      data_temp.push(...user.technologies);
    });

    var data = [];

    data_temp.forEach((item) => {
      data.push(item.title);
    });

    // console.log(data);
    var compressed = [];
    // make a copy of the input array
    var copy = data.slice(0);

    // first loop goes over every element
    for (var i = 0; i < data.length; i++) {
      var myCount = 0;
      // loop over every element in the copy and see if it's the same
      for (var w = 0; w < copy.length; w++) {
        // console.log(data[w]);
        if (data[i] === copy[w]) {
          // increase amount of times duplicate is found
          myCount++;
          // sets item to undefined
          delete copy[w];
        }
      }

      if (myCount > 0) {
        var a = {};
        a.value = data[i];
        a.count = myCount;
        compressed.push({ id: data[i], label: data[i], value: myCount });
      }
    }
    return compressed;
  }

  data = [];
  componentDidMount() {
    window.$(".modal-backdrop").css("display", " !important");

    const { history } = this.props;
    let splitPath = history.location.pathname.split("/");
    let cid = splitPath[2];
    let sessionId = splitPath[3];

    if (cid === undefined || sessionId === undefined)
      this.props.history.push("/");

    // let user = Cookies.get("user") ? JSON.parse(Cookies.get("user"))._id : null
    this.props
      .validateSession(sessionId)
      .then(() => {
        //check if session is valid
        let today = new Date();
        let sessionEnds = new Date(this.props.judge.expiresOn);
        if (today > sessionEnds) {
          //session invalid
          // display message or redirect to home page
          // window.location.href = "/"
          this.setState({ isSessionValid: false });
          //console.log(true);
        } else {
          this.setState({ judge: this.props.judge, isSessionValid: true });
          this.props
            .getContest(cid)
            .then(() => {
              let evalList =
                this.props.contest.evaluationCriteria !== ""
                  ? this.props.contest.evaluationCriteria.map((x) => {
                    return { comment: "", score: "", title: x.title };
                  })
                  : [];
              this.setState({
                event: this.props.contest,
                isLoaded: true,
                caughtErr: false,
                eval: evalList,
              });
            })
            .catch((err) => this.props.dispatchNotification(err));

          this.props
            .getEventTeams(cid)
            .then(() =>
              this.setState({
                teams: this.props.eventTeams,
                isTeamsLoaded: true,
              })
            )
            .catch((err) => console.log(err));

          this.props
            .getLeaderboard(cid)
            .then(() =>
              this.setState({
                leaderboard: this.props.leaderboard,
                isLeaderBoardLoaded: true,
              })
            )
            .catch((err) => console.log(err));
        }
      })
      .catch((error) => {
        // this.props.history.push("/")
        this.setState({ isSessionValid: false });
      });
  }

  getTeam(team) {
    this.setState({ isTeamLoaded: false, focus: "judge" });
    this.props
      .getTeam(team)
      .then(() =>
        this.setState({
          team: this.props.team,
          focus: "judge",
          isTeamLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  getFileType(str) {
    return str.split(";")[0].split("/")[1];
  }

  uploadFile(event, ctx) {
    let file = event.target.files[0];

    ctx.getBase64(file, (result) => {
      ctx.setState({
        submissionFile: {
          file: result,
          fileName: file.name,
        },
      });
    });
  }

  getEventTeam(tid) {
    this.props
      .getTeam(tid)
      .then(() => this.setState({ team: this.props.team }));
  }

  updateLeaderBoard() {
    this.props
      .getLeaderboard(this.state.event._id)
      .then(() =>
        this.setState({
          leaderboard: this.props.leaderboard,
          isLeaderBoardLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }

  updateJudgeHistory() {
    // this.props.getJudgeEntryHistory(this.state.judge._id, this.state.team._id)
    // .then(() =>{
    //     this.setState({judgeHistory:this.props.judgeHistory})
    // })
  }

  rateSubmission() {
    this.setState({ isSubAnimOn: true });
    this.props.rateSubmission(this.state.judgeEntries, this.state.judge._id, this.state.team._id, this.state.focusSubmission._id)
      .then(() => {
        this.setState({ isSubAnimOn: false });
        this.updateLeaderBoard();
        this.updateJudgeHistory()
      });
  }

  changeScore(score, idx) {
    let evalList = this.state.eval;
    evalList[idx].score = score;
    this.setState({ eval: evalList });
  }

  changeComment(comment, idx) {
    let evalList = this.state.eval;
    evalList[idx].comment = comment;
    this.setState({ eval: evalList });
  }

  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />

        <div style={{ position: "fixed", width: "100%", height: "100%" }}>
          <div
            style={{
              backgroundImage: "url(" + globe + ")",
              transform: "translate(-50%, -50%) scale(1)",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform",
            }}
          ></div>
        </div>
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      <a href="#">
                        <img
                          src="/assets/landing-page/images/bih_logo_white.png"
                          alt="logo"
                          style={{ width: "40%", opacity: "100%", padding: "1rem", }}
                        />
                      </a>
                      <a href="#">
                        <img src="/assets/landing-page/images/logo_white.png" alt="logo" style={{ width: "40%", opacity: "100%", padding: "1rem" }} />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div className="responsive" onClick={() => this.toggleMenu()} >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                      <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub">
                            <a href="/#">Home </a>
                          </li>
                          <li className="has-sub font-weight-bolder">
                            <a href="/#about">About</a>
                          </li>
                          <li className="has-sub font-weight-bolder">
                            <a href="/#how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/#footer">Contacts</a>
                          </li>
                          <li>
                            <a href="/events">Hackathons</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />{" "}
                        {this.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
          <section
            id="parallax"
            className="parallax slider-area second-slider3-bg d-flex align-items-center justify-content-left fix"
            style={{
              background: "#00000000",
              minHeight: "45vh",
              position: "relative",
            }}
          >
            {typeof this.state.event.eventBanner !== "undefined" ? (
              <div
                style={{
                  backgroundImage:
                    "url(" + this.state.event.eventBanner.data + ")",
                  position: "absolute",
                  width: "100vw",
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            ) : (
              <div
                style={{
                  backgroundImage: "url(" + bih + ")",
                  position: "absolute",
                  width: "100vw",
                  height: "100%",
                  backgroundSize: "cover",
                }}
              ></div>
            )}
            <div
              style={{
                background: "#00000063",
                position: "absolute",
                width: "100vw",
                height: "100%",
              }}
            ></div>
          </section>

          <section
            style={{ alignItems: "center", minHeight: "100vh" }}
            id="how-it-works"
            className=" p-relative"
          >
            {this.state.isSessionValid ? (
              <>
                <div className="row" style={{ background: "#8080802e" }}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-2 text-center">
                        {this.state.judge.image ? (
                          <img
                            src={this.state.judge.image.data}
                            alt=""
                            className="rounded-circle avatar-lg mb-1"
                            style={{
                              background: "rgb(158 158 158 / 77%)",
                              marginTop: ".7rem",
                              marginBottom: "1rem",
                              height: "7rem",
                              width: "7rem",
                            }}
                          ></img>
                        ) : (
                          <img
                            src="/assets/images/users/avatar.png"
                            alt=""
                            className="rounded-circle avatar-lg mb-1"
                            style={{
                              background: "rgb(158 158 158 / 77%)",
                              marginTop: ".7rem",
                              marginBottom: "1rem",
                              height: "7rem",
                              width: "7rem",
                            }}
                          ></img>
                        )}

                        {/* <p className="mb-0">{this.state.leaderboard[0].team.teamName}</p>
                                                                                            <p>{this.state.leaderboard[0].totalScore} points</p> */}
                      </div>
                      <div className="col-10 " style={{ padding: "2rem 0rem" }}>
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-10 col-md-3 col-lg-3 ">
                              <h3
                                style={{
                                  color: "#495057",
                                  marginBottom: "0px",
                                }}
                              >
                                {this.state.judge.name}
                              </h3>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                {this.state.judge.title}
                              </p>
                            </div>
                            <div className="col-sm-12 col-md-3 mt-3">
                              <p
                                className="mb-0 text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                STARTS ON:
                              </p>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  lineHeight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state.event.startDate
                                  ? formatDate(
                                    new Date(this.state.event.startDate),

                                  )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-12 col-md-3 mt-3">
                              <p
                                className="mb-0 text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                ENDS ON:
                              </p>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  lineHeight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state.event.endDate
                                  ? formatDate(
                                    new Date(this.state.event.endDate),

                                  )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-12 col-md-3 mt-3">
                              <p
                                className="mb-0 text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                TYPE:
                              </p>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  lineHeight: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                {this.state.event.eventType}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isSubAnimOn ? (
                  <div
                    style={{
                      width: "100vw",
                      height: "100vh",
                      position: "absolute",
                      backgroundColor: "#07070757",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ transform: "translateY(50%)" }}>
                      <img src={loader} />
                      <p style={{ color: "white", margin: "0px" }}>
                        Submitting ......
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="container-fluid">
                    <div className="row">
                      <div className="">
                        <div className="card-body">
                          {/* <h4 className="card-title">Vertical Nav Tabs</h4>
                        <p className="card-title-desc">Example of Vertical nav tabs</p> */}

                          <div className="row">
                            <div className="col-md-2">
                              <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                <a
                                  className="nav-link mb-2 active"
                                  style={{
                                    borderBottom: "solid 1px rgb(172 175 177)",
                                    borderRadius: "0px",
                                  }}
                                  id="v-pills-description-tab"
                                  data-toggle="pill"
                                  href="#v-pills-description"
                                  role="tab"
                                  aria-controls="v-pills-description"
                                  aria-selected="true"
                                >
                                  Description
                                </a>
                                <a
                                  className="nav-link mb-2"
                                  style={{
                                    borderBottom: "solid 1px rgb(172 175 177)",
                                    borderRadius: "0px",
                                  }}
                                  id="v-pills-rules-tab"
                                  data-toggle="pill"
                                  href="#v-pills-rules"
                                  role="tab"
                                  aria-controls="v-pills-rules"
                                  aria-selected="false"
                                >
                                  Rules and legal
                                </a>
                                <a
                                  className="nav-link mb-2"
                                  style={{
                                    borderBottom: "solid 1px rgb(172 175 177)",
                                    borderRadius: "0px",
                                  }}
                                  id="v-pills-teams-tab"
                                  data-toggle="pill"
                                  href="#v-pills-teams"
                                  role="tab"
                                  aria-controls="v-pills-teams"
                                  aria-selected="false"
                                  onClick={() =>
                                    this.setState({ focus: "teams" })
                                  }
                                >
                                  Teams
                                </a>
                                <a
                                  className="nav-link mb-2"
                                  style={{
                                    borderBottom: "solid 1px rgb(172 175 177)",
                                    borderRadius: "0px",
                                  }}
                                  id="v-pills-prizes-tab"
                                  data-toggle="pill"
                                  href="#v-pills-prizes"
                                  role="tab"
                                  aria-controls="v-pills-prizes"
                                  aria-selected="false"
                                >
                                  Prizes
                                </a>
                                <a
                                  className="nav-link mb-2"
                                  style={{
                                    borderBottom: "solid 1px rgb(172 175 177)",
                                    borderRadius: "0px",
                                  }}
                                  id="v-pills-leaderboard-tab"
                                  data-toggle="pill"
                                  href="#v-pills-leaderboard"
                                  role="tab"
                                  aria-controls="v-pills-settings"
                                  aria-selected="false"
                                >
                                  Leader Board
                                </a>
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div
                                className="tab-content text-muted  mt-md-0"
                                id="v-pills-tabContent"
                                style={{
                                  borderLeft: "solid 1px rgb(172 175 177)",
                                  padding: "0rem 1rem 1rem 1rem",
                                  minWidth: "70vw",
                                }}
                              >
                                <div
                                  className="tab-pane active show fade"
                                  id="v-pills-description"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-description-tab"
                                >
                                  <div className="pl-4">
                                    <div className="row mb-4">
                                      <h2
                                        style={{
                                          fontSize: "29px",
                                          fontWeight: "bolder",
                                          color: "#000",
                                          textTransform: "uppercase",
                                        }}
                                        className="regular dark weight-600 caps inline-block float-left mr-3"
                                      >
                                        {this.state.event.title}
                                      </h2>
                                    </div>
                                    {this.state.isLoaded ? (
                                      <div
                                        className="row"
                                        style={{
                                          fontFamily:
                                            "Ubuntu,sans-serif !important",
                                          display: "block",
                                        }}
                                      >
                                        {ReactHtmlParser(
                                          this.state.event.description
                                        )}
                                      </div>
                                    ) : (
                                      <ContentSkeleton />
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-rules"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-rules-tab"
                                >
                                  <div className="pl-4">
                                    <div className="row mb-4">
                                      <h2
                                        style={{
                                          fontSize: "29px",
                                          textTransform: "uppercase",
                                        }}
                                        className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                      >
                                        Event rules and legal
                                      </h2>
                                    </div>
                                    {this.state.isLoaded ? (
                                      <div
                                        className="row"
                                        style={{
                                          fontFamily:
                                            "Ubuntu,sans-serif !important",
                                          display: "block",
                                        }}
                                      >
                                        {ReactHtmlParser(
                                          this.state.event.rules
                                        )}
                                      </div>
                                    ) : (
                                      <ContentSkeleton />
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-teams"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-teams-tab"
                                >
                                  {this.state.isTeamsLoaded ? (
                                    this.state.focus === "teams" ? (
                                      <div className="pl-4">
                                        <div className="row mb-4">
                                          <h2
                                            style={{
                                              fontSize: "29px",
                                              textTransform: "uppercase",
                                            }}
                                            className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                          >
                                            Teams
                                          </h2>
                                        </div>
                                        <p className="row mb-4">
                                          This section shows all registered
                                          teams, select a team to begin judging.
                                        </p>
                                        <div className="row">
                                          {this.state.teams.length > 0 ? (
                                            this.state.teams.map((team) =>
                                              team.isEliminated ? (
                                                <div
                                                  className="col-md-6"
                                                  onClick={() => {
                                                    this.getTeam(team._id);
                                                  }}
                                                >
                                                  <div
                                                    className="card"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <div className="table-responsive">
                                                      <table className="table table-nowrap table-centered table-hover mb-0">
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                width: "45px",
                                                                height: "45px",
                                                              }}
                                                            >
                                                              {team.members[0].githubData ? (
                                                                <img className="rounded-circle avatar-sm"
                                                                  src={team.members[0].githubData.avatar_url}
                                                                  style={{ width: "45px", height: "45px" }}
                                                                  alt=""></img>
                                                              ) : (
                                                                <span className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                  style={{
                                                                    backgroundColor: "#0a0b0e9e",
                                                                    color: "#ffffffa3!important",
                                                                    width: "45px",
                                                                    height: "45px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {team.members[0].name.firstName[0] + " " + team.members[0].name.lastName[0]}
                                                                </span>
                                                              )}
                                                            </td>
                                                            <td>
                                                              <small>
                                                                {team.teamName}
                                                              </small>
                                                              <h5 className="text-dark font-size-14 mb-1">
                                                                ELIMINATED
                                                              </h5>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className="col-md-6"
                                                  onClick={() =>
                                                    this.getTeam(team._id)
                                                  }
                                                >
                                                  <div className="card"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <div className="table-responsive">
                                                      <table className="table table-nowrap table-centered table-hover mb-0">
                                                        <tbody>
                                                          {team.members.length >
                                                            0 ? (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  width: "45px",
                                                                  height:
                                                                    "45px",
                                                                }}
                                                              >
                                                                {team.members[0].githubData ? (
                                                                  <img
                                                                    className="rounded-circle avatar-sm"
                                                                    src={team.members[0].githubData.avatar_url}
                                                                    style={{ width: "45px", height: "45px" }}
                                                                    alt=""
                                                                  ></img>
                                                                ) : (
                                                                  <span className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#0a0b0e9e",
                                                                      color:
                                                                        "#ffffffa3!important",
                                                                      width:
                                                                        "45px",
                                                                      height:
                                                                        "45px",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {team.members[0].name.firstName[0] + " " + team.members[0].name.lastName[0]}
                                                                  </span>
                                                                )}
                                                              </td>
                                                              <td>
                                                                <h5 className="text-dark font-size-14 mb-1">
                                                                  {team.teamName}
                                                                </h5>
                                                                <small>
                                                                  {team.members.length} team members
                                                                </small>
                                                              </td>
                                                              <td>
                                                                {/* <h5 className="text-dark font-size-14 mb-1">{team.teamName}</h5> */}
                                                                <small>
                                                                  Submissions:{team.submissions.length}
                                                                </small>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <div
                                              className="card text-center"
                                              style={{
                                                backgroundColor: "#cec9c985",
                                                width: "50%",
                                                margin: "0 auto",
                                              }}
                                            >
                                              <span
                                                style={{ lineHeight: "5rem" }}
                                              >
                                                NO REGISTERED TEAMS
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {this.state.isTeamLoaded ? (
                                          <div className="tab-pane active show fade"
                                            id="v-pills-teams"
                                            role="tabpanel"
                                            aria-labelledby="v-pills-teams-tab"
                                          >
                                            <div className="row mb-4">
                                              <h2
                                                style={{
                                                  fontSize: "29px",
                                                  textTransform: "uppercase",
                                                }}
                                                className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                              >
                                                {this.state.team.teamName}
                                              </h2>
                                            </div>
                                            <div
                                              className="card-body pl-0 pr-0"
                                              style={{
                                                border: "1px dashed #7a7a7a",
                                              }}
                                            >
                                              {/* <!-- Nav tabs --> */}
                                              <ul
                                                className="pl-0 nav nav-tabs nav-tabs-custom nav-justified"
                                                role="tablist"
                                              >
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#teammembers"
                                                    role="tab"
                                                  >
                                                    <span className="d-block d-sm-none">
                                                      <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">
                                                      Team Members
                                                    </span>
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#teamsubmissions"
                                                    role="tab"
                                                  >
                                                    <span className="d-block d-sm-none">
                                                      <i className="far fa-envelope"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">
                                                      Submissions
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>

                                              {/* <!-- Tab panes --> */}
                                              <div className="tab-content p-3 text-muted">
                                                <div
                                                  className="tab-pane active"
                                                  id="teammembers"
                                                  role="tabpanel"
                                                >
                                                  <div className="row">
                                                    <div className="col-7 pl-0">
                                                      {/* memebers table */}
                                                      <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap table-hover">
                                                          <thead className="thead-light">
                                                            <tr>
                                                              <th scope="col" style={{ width: "70px" }}
                                                              >
                                                                #
                                                              </th>
                                                              <th scope="col">
                                                                Name
                                                              </th>
                                                              <th scope="col">
                                                                Email
                                                              </th>
                                                              {/* <th scope="col">Points</th> */}
                                                              <th scope="col">
                                                                Action
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {this.state.team
                                                              .members ? (
                                                              this.state.team.members.map((member) =>
                                                                this.state.team.type === "business" ? (
                                                                  <tr
                                                                    key={member._id}
                                                                  >
                                                                    <td>
                                                                      <div className="avatar-xs">
                                                                        {member.githubData ? (
                                                                          <img
                                                                            className="rounded-circle avatar-sm ml-1"
                                                                            src={member.githubData.avatar_url}
                                                                            alt="profile picture"
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title={
                                                                              member.githubData ? member.githubData.public_repos + " public projects on github" : "0 public projects"
                                                                            }
                                                                            onClick={() =>
                                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                            }
                                                                            style={{ height: "40px", width: "40px", cursor: "pointer" }}
                                                                          ></img>
                                                                        ) : (
                                                                          <span className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                            style={{
                                                                              backgroundColor: "#0a0b0e9e",
                                                                              color: "#ffffffa3!important",
                                                                              height: "40px",
                                                                              width: "40px",
                                                                              objectFit: "cover",
                                                                              cursor: "pointer",
                                                                            }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title={member.githubData ? member.githubData.public_repos + " public projects on github" : "0 public projects"}
                                                                            onClick={() =>
                                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                            }
                                                                          >
                                                                            {" "}
                                                                            {member.name.firstName[0] + " " + member.name.lastName[0]}{" "}
                                                                          </span>
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <h5 className="font-size-14 mb-1">
                                                                        <a
                                                                          href="#"
                                                                          className="text-dark"
                                                                        >
                                                                          {member.name.firstName + " " + member.name.lastName}
                                                                        </a>
                                                                      </h5>
                                                                      {/* <p className="text-muted mb-0">Full Stack Developer</p> */}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        member.email
                                                                      }
                                                                    </td>

                                                                    {/* <td>
                                                                                                                                    125
                                                                                                                                </td> */}
                                                                    <td>
                                                                      <ul className="list-inline font-size-20 contact-links mb-0 pl-0">
                                                                        {member._id ==
                                                                          this
                                                                            .state
                                                                            .user ? (
                                                                          <li
                                                                            className="list-inline-item px-2"
                                                                            style={{
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                              this.leaveTeam(member._id)
                                                                            }
                                                                          >
                                                                            <span
                                                                              data-toggle="tooltip"
                                                                              data-placement="top"
                                                                              title="Remove"
                                                                            >
                                                                              <i className="bx bx-user-minus"></i>
                                                                            </span>
                                                                          </li>
                                                                        ) : (
                                                                          <>

                                                                          </>
                                                                        )}
                                                                      </ul>
                                                                    </td>
                                                                  </tr>
                                                                ) : (
                                                                  <tr>
                                                                    <td>
                                                                      <div className="avatar-xs">
                                                                        {member.githubData ? (
                                                                          <img
                                                                            className="rounded-circle avatar-sm ml-1"
                                                                            src={member.githubData.avatar_url}
                                                                            alt="profile picture"
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title={member.githubData ? member.githubData.public_repos + " public projects on github" : "0 public projects"}
                                                                            onClick={() =>
                                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                            }
                                                                            style={{
                                                                              height: "40px",
                                                                              width: "40px",
                                                                              cursor: "pointer",
                                                                            }}
                                                                          ></img>
                                                                        ) : (
                                                                          <span
                                                                            className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                            style={{
                                                                              backgroundColor: "#0a0b0e9e",
                                                                              color: "#ffffffa3!important",
                                                                              height: "40px",
                                                                              width: "40px",
                                                                              objectFit: "cover",
                                                                              cursor: "pointer",
                                                                            }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="top"
                                                                            title={
                                                                              member.githubData ? member.githubData.public_repos + " public projects on github" : "0 public projects"
                                                                            }
                                                                            onClick={() =>
                                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                            }
                                                                          >

                                                                            {member.name.firstName[0] + " " + member.name.lastName[0]}
                                                                          </span>
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td>
                                                                      <h5 className="font-size-14 mb-1">
                                                                        <a
                                                                          href="#"
                                                                          className="text-dark"
                                                                        >
                                                                          {member.name.firstName + " " + member.name.lastName}
                                                                        </a>
                                                                      </h5>
                                                                      {/* <p className="text-muted mb-0">Full Stack Developer</p> */}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        member.email
                                                                      }
                                                                    </td>

                                                                    {/* <td>
                                                                                                                                    125
                                                                                                                                </td> */}
                                                                    <td>
                                                                      <ul className="list-inline font-size-20 contact-links mb-0 pl-0">
                                                                        {member._id ==
                                                                          this
                                                                            .state.team.owner ? (
                                                                          <>
                                                                            <li
                                                                              className="list-inline-item px-2"
                                                                              style={{ cursor: "pointer" }}
                                                                              onClick={() => this.removeTeamMember(member._id)}
                                                                            >
                                                                              <span
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title="Remove"
                                                                              >
                                                                                <i className="bx bx-user-minus"></i>
                                                                              </span>
                                                                            </li>
                                                                            <li
                                                                              className="list-inline-item px-2"
                                                                              style={{
                                                                                cursor:
                                                                                  "pointer",
                                                                              }}
                                                                              onClick={() => this.makeTeamLeader(member._id)}
                                                                            >
                                                                              <span
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title="Make team leader"
                                                                              >
                                                                                <i className="bx bx-rocket"></i>
                                                                              </span>
                                                                            </li>
                                                                          </>
                                                                        ) : member._id == this.state.user ? (
                                                                          <li
                                                                            className="list-inline-item px-2"
                                                                            style={{
                                                                              cursor: "pointer",
                                                                            }}
                                                                            onClick={() => this.leaveTeam(member._id)}
                                                                          >
                                                                            <span
                                                                              data-toggle="tooltip"
                                                                              data-placement="top"
                                                                              title="Remove"
                                                                            >
                                                                              <i className="bx bx-user-minus"></i>
                                                                            </span>
                                                                          </li>
                                                                        ) : (
                                                                          <>

                                                                          </>
                                                                        )}
                                                                      </ul>
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                    <div className="col-5 pl-0 text-center">
                                                      <h6>
                                                        Combined Platform Points
                                                      </h6>
                                                      <p
                                                        style={{
                                                          fontSize: "4vw",
                                                          letterSpacing: "5px",
                                                          marginBottom: "3rem",
                                                          marginTop: "3rem",
                                                          fontWeight: "bolder",
                                                        }}
                                                      >
                                                        {
                                                          this.state.team.totalRank
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {/* team stats */}
                                                  <div className="row">
                                                    <div className="col-12">
                                                      <h6>
                                                        Skills Distribution
                                                      </h6>
                                                      <div>
                                                        {this.state.team.members !== undefined ? (
                                                          <div
                                                            style={{
                                                              width: "400px",
                                                              height: "400px",
                                                            }}
                                                          >
                                                            {/* {console.log(this.handleSkills(this.state.team.members))}; */}
                                                            <ResponsiveRadar
                                                              data={this.handleSkills(this.state.team.members)}
                                                              keys={["value"]}
                                                              indexBy="label"
                                                              maxValue={
                                                                this.state.team.members.length
                                                              }
                                                              margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                                                              curve="linearClosed"
                                                              borderWidth={2}
                                                              borderColor={{ from: "color" }}
                                                              gridLevels={5}
                                                              gridShape="circular"
                                                              gridLabelOffset={36}
                                                              enableDots={true}
                                                              dotSize={10}
                                                              dotColor={{ theme: "background" }}
                                                              dotBorderWidth={2}
                                                              dotBorderColor={{ from: "color" }}
                                                              enableDotLabel={true}
                                                              dotLabel="value"
                                                              dotLabelYOffset={-12}
                                                              colors={{ scheme: "nivo" }}
                                                              fillOpacity={0.25}
                                                              blendMode="multiply"
                                                              animate={true}
                                                              motionConfig="wobbly"
                                                              isInteractive={true}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="tab-pane"
                                                  id="teamsubmissions"
                                                  role="tabpanel"
                                                >
                                                  <div className="row">
                                                    {this.state.event.rounds.map((round) => (
                                                      <div className="col-6">
                                                        <div
                                                          className="table-responsive"
                                                          style={{ border: "1px solid #80808087" }}
                                                        >
                                                          <table
                                                            className="table card table-nowrap table-centered mb-0 box-shadow"
                                                            style={{ backgroundColor: "transparent" }}
                                                          >
                                                            <thead
                                                              className="font-weight-bold"
                                                              style={{ backgroundColor: "#cec9c985" }}
                                                            >
                                                              <tr>
                                                                <th
                                                                  className="p-3"
                                                                  style={{ borderBottom: "0px", width: "10%" }}
                                                                >
                                                                  {round.title}
                                                                </th>
                                                                <th
                                                                  className="p-3"
                                                                  style={{ borderBottom: "0px", width: "70%" }}
                                                                >
                                                                </th>
                                                                <th
                                                                  className="p-3"
                                                                  style={{ borderBottom: "0px", width: "10%" }}
                                                                >
                                                                </th>
                                                                <th
                                                                  className="p-3"
                                                                  style={{ borderBottom: "0px", width: "10%" }}
                                                                >
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.state.team.submissions ? (
                                                                this.state.team.submissions.reverse()
                                                                  .map((sub, index) =>
                                                                    sub.type === "fcs" || sub.type === "file" || sub.type == "s3" ? (
                                                                      <tr key={index} style={{ backgroundColor: "transparent" }}>
                                                                        <td style={{ width: "10%", height: "fit-content" }}>
                                                                          <i
                                                                            style={{
                                                                              verticalAlign: "middle",
                                                                              fontSize: "20px",
                                                                            }}
                                                                            className="bx bxs-file-blank"
                                                                          ></i>
                                                                        </td>
                                                                        <td
                                                                          key={index}
                                                                          style={{ cursor: "pointer", width: "75%" }}>
                                                                          {/* <small className="font-size-14 mb-1"  >{sub.file.fileName}</small> */}
                                                                          <small>
                                                                            {sub.file.fileName}
                                                                            {ReactHtmlParser("&bullet;")}
                                                                            {this.bytesToSize(sub.file.fileSize)}
                                                                            <br />
                                                                          </small>
                                                                          <small>
                                                                            Submission Time:{formatDate(new Date(sub.createdAt))}
                                                                          </small>
                                                                        </td>
                                                                        <td style={{ width: "10%" }}>
                                                                          <div className="text-center">
                                                                            <a
                                                                              href={`${BASE_URL}events/submission?fileId=${sub.file._id}`}
                                                                              target="_blank" rel="noreferrer"
                                                                            >
                                                                              <i className="bx bx-download h3 m-0"></i>
                                                                            </a>
                                                                          </div>
                                                                        </td>
                                                                        <td style={{ width: "10%", cursor: "pointer" }} onClick={() => this.setJudgeEntries(round, sub)} data-toggle="modal" data-target="#judgeModal">
                                                                          <div className="text-center">

                                                                            <i className="bx bx-badge-check h3 m-0"></i>

                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr style={{ backgroundColor: "transparent" }} >
                                                                        <td style={{ width: "10%", height: "fit-content" }}>
                                                                          <i style={{ verticalAlign: "middle", fontSize: "20px" }} className="bx bxs-file-blank"></i>
                                                                        </td>
                                                                        <td key={index} style={{ cursor: "pointer", width: "75%" }}>
                                                                          {/* <small className="font-size-14 mb-1"  >{sub.file.fileName}</small> */}
                                                                          {/* <small>{sub.file.fileName} {ReactHtmlParser("&bullet;")} {this.bytesToSize(sub.file.fileSize)}<br /></small> */}
                                                                          <small>
                                                                            Submission Time:{timeZoneHelper(new Date(sub.createdAt)).format()}
                                                                          </small>
                                                                        </td>
                                                                        <td style={{ width: "10%" }}>
                                                                          <div className="text-center">
                                                                            <a
                                                                              href={sub.link}
                                                                              target="_blank" rel="noreferrer"
                                                                            >
                                                                              <i className="bx bx-link-alt h3 m-0"></i>
                                                                            </a>
                                                                          </div>
                                                                        </td>
                                                                        <td style={{ width: "10%", cursor: "pointer" }} onClick={() => this.setJudgeEntries(round, sub)} data-toggle="modal" data-target="#judgeModal">
                                                                          <div className="text-center">

                                                                            <i className="bx bx-badge-check h3 m-0"></i>

                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    )
                                                                  )
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    )
                                                    )}
                                                  </div>
                                                  <div className="modal fade" id="judgeModal" tabindex="-1" role="dialog" aria-labelledby="judgeModal" aria-hidden="true" style={{ backgroundColor: "#0000007d" }}>
                                                    <div className="modal-dialog modal-dialog-scrollable" style={{ maxWidth: "85%" }}>
                                                      <div className="modal-content">
                                                        <div className="modal-header">
                                                          <h5 className="modal-title mt-0" id="exampleModalScrollableTitle">Scores</h5>
                                                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                          </button>
                                                        </div>
                                                        <div className="modal-body">
                                                          <div className="row">
                                                            {this.state.focusRound.evalCriterias ?
                                                              this.state.focusRound.evalCriterias.map((criteria, idx) => (
                                                                <div className="col-3 text-center p-4 m-2" style={{ border: "gray 1px dashed" }}>

                                                                  <h5>{criteria.title}</h5>
                                                                  <input type="number" className="text-muted text-center" onChange={e => this.updateJudgeEntry(e.target.value, idx)} value={this.state.judgeEntries[idx].score} placeholder={0} max={criteria.maxScore} min={0} style={{ height: "100px", width: "175px", border: "gray", fontSize: "5vw", overflow: "hidden" }} />

                                                                </div>
                                                              ))
                                                              :
                                                              <>
                                                              </>
                                                            }
                                                          </div>
                                                          <div className="row pt-4">
                                                            <div className="col-12" style={{ borderBottom: "gray 1px dashed" }}>
                                                              <h4>Round description</h4>
                                                              <p>{this.state.focusRound.description}</p>
                                                            </div>
                                                          </div>
                                                          <div className="row">
                                                            <h4 className="mt-3 ml-2">Evaluation Criteria</h4>
                                                            {
                                                              this.state.focusRound.evalCriterias ?
                                                                this.state.focusRound.evalCriterias.map(criteria => (
                                                                  <div className="col-12 mb-1" >
                                                                    <div id="accordion" style={{ borderBottom: "gray 1px dashed" }}>
                                                                      <div className="card mb-1 shadow-none">
                                                                        <div className="card-header" id="headingOne">

                                                                          <h6 className="m-0" >

                                                                            <strong>Title: {criteria.title}</strong>

                                                                          </h6>
                                                                        </div>
                                                                      </div>
                                                                      <div className="show" aria-labelledby="headingOne" data-parent="#accordion">
                                                                        <div className="card-body">
                                                                          <p>
                                                                            <strong> Max Score:</strong> {criteria.maxScore}
                                                                          </p>
                                                                          <p>
                                                                            <strong>Description:</strong><br />{criteria.description}
                                                                          </p>

                                                                        </div>
                                                                      </div>


                                                                    </div>
                                                                  </div>

                                                                ))
                                                                :
                                                                <>
                                                                </>
                                                            }

                                                          </div>

                                                        </div>
                                                        <div className="modal-footer">
                                                          <button type="button" onClick={() => this.rateSubmission()} className="btn btn-primary">Submit</button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <ContentSkeleton />
                                        )}
                                      </>
                                    )
                                  ) : (
                                    <TeamListSkeleton />
                                  )}
                                </div>

                                <div
                                  className="tab-pane fade"
                                  id="v-pills-prizes"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-prizes-tab"
                                >
                                  <div className="pl-4">
                                    <div className="row mb-4">
                                      <h2
                                        style={{ fontSize: "29px", textTransform: "uppercase" }}
                                        className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                      >
                                        Prizes
                                      </h2>
                                    </div>
                                    <div
                                      className="row"
                                      style={{ fontFamily: "Ubuntu,sans-serif !important", display: "block" }}
                                    >
                                      {this.state.isLoaded ? (
                                        <>
                                          {ReactHtmlParser(this.state.event.prizes)}

                                          <div className="prize-container mt-3">
                                            <div className="prize-image align-center inline-block v-align-top">
                                              {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/c45fe55Code_Ed_MainPrizesIcon.jpg?Signature=yHL8b5dm02uaJPadIzLi6Z2frio%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}
                                            </div>

                                            <div className="prize-desc medium-margin-left inline-block v-align-top">
                                              {/* <div className="large font-weight-bold dark">Winner </div>
                                                                                        <div className="regular dark">
                                                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>100 USD</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit <strong>Certificate of Achievement</strong> for each member.</p>

                                                                                        </div> */}
                                            </div>
                                            <div className="clear"></div>
                                          </div>
                                        </>
                                      ) : (
                                        <ContentSkeleton />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="tab-pane fade"
                                  id="v-pills-leaderboard"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-leaderboard-tab"
                                >
                                  <div className="pl-4">
                                    <div className="row">
                                      <h2
                                        style={{
                                          fontSize: "29px",
                                          textTransform: "uppercase",
                                        }}
                                        className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                      >
                                        leaderboard
                                      </h2>
                                    </div>

                                    {this.state.leaderboard.length > 0 ? (
                                      <>
                                        {/* TOP 3 TEAMS */}
                                        <div className="row">
                                          {this.state.leaderboard.length === 1 ? (
                                            <div className="col-sm-12 col-md-3 col-lg-3 text-center offset-md-4 offset-lg-4 mt-4">
                                              {this.state.leaderboard[0].team.members[0].githubData ? (
                                                <img
                                                  src={this.state.leaderboard[0].team.members[0].githubData.avatar_url}
                                                  alt=""
                                                  className="rounded-circle avatar-lg mb-1"
                                                  style={{
                                                    background: "rgb(158 158 158 / 77%)",
                                                    width: "10rem",
                                                    height: "10rem",
                                                  }}
                                                ></img>
                                              ) : (
                                                <img
                                                  src="/assets/images/users/avatar.png"
                                                  alt=""
                                                  className="rounded-circle avatar-lg mb-1"
                                                  style={{
                                                    background: "rgb(158 158 158 / 77%)",
                                                    width: "10rem",
                                                    height: "10rem",
                                                  }}
                                                ></img>
                                              )}
                                              <p className="mb-0">RANK #1</p>
                                              <p className="mb-0">
                                                {
                                                  this.state.leaderboard[0].team.teamName
                                                }
                                              </p>
                                              <p>
                                                {
                                                  this.state.leaderboard[0].totalScore
                                                }{" "}
                                                points
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="col-sm-12 col-md-3 col-lg-3 text-center order-md-2 order-lg-2 mt-4">
                                              {this.state.leaderboard[0].team.members[0].githubData ? (
                                                <img
                                                  src={this.state.leaderboard[0].team.members[0].githubData.avatar_url}
                                                  alt=""
                                                  className="rounded-circle avatar-lg mb-1"
                                                  style={{
                                                    background: "rgb(158 158 158 / 77%)",
                                                    width: "10rem",
                                                    height: "10rem",
                                                  }}
                                                ></img>
                                              ) : (
                                                <img
                                                  src="/assets/images/users/avatar.png"
                                                  alt=""
                                                  className="rounded-circle avatar-lg mb-1"
                                                  style={{
                                                    background: "rgb(158 158 158 / 77%)",
                                                    width: "10rem",
                                                    height: "10rem",
                                                  }}
                                                ></img>
                                              )}
                                              <p className="mb-0">RANK #1</p>
                                              <p className="mb-0">
                                                {
                                                  this.state.leaderboard[0].team.teamName
                                                }
                                              </p>
                                              <p>
                                                {
                                                  this.state.leaderboard[0].totalScore
                                                }{" "}
                                                points
                                              </p>
                                            </div>
                                          )}

                                          {this.state.leaderboard.length > 1 ? (
                                            <div className="col-sm-12 col-md-3 col-lg-3 text-center offset-lg-1 order-md-1 order-lg-1">
                                              <img
                                                src="/assets/images/users/avatar.png"
                                                alt=""
                                                className="rounded-circle avatar-lg mb-1"
                                                style={{
                                                  background: "rgb(158 158 158 / 77%)",
                                                  width: "8rem",
                                                  height: "8rem",
                                                }}
                                              ></img>
                                              <p className="mb-0">RANK #2</p>
                                              <p className="mb-0">
                                                {
                                                  this.state.leaderboard[1].team.teamName
                                                }
                                              </p>
                                              <p>
                                                {
                                                  this.state.leaderboard[1].totalScore
                                                }{" "}
                                                points
                                              </p>
                                            </div>
                                          ) : null}
                                          {this.state.leaderboard.length > 2 ? (
                                            <div className="col-sm-12 col-md-3 col-lg-3 text-center order-md-3 order-lg-3">
                                              <img
                                                src="/assets/images/users/avatar.png"
                                                alt=""
                                                className="rounded-circle avatar-lg mb-1"
                                                style={{
                                                  background:
                                                    "rgb(158 158 158 / 77%)",
                                                  width: "8rem",
                                                  height: "8rem",
                                                }}
                                              ></img>
                                              <p className="mb-0">RANK #3</p>
                                              <p className="mb-0">
                                                {
                                                  this.state.leaderboard[2].team.teamName
                                                }
                                              </p>
                                              <p>
                                                {
                                                  this.state.leaderboard[2].totalScore
                                                }{" "}
                                                points
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                                            <div
                                              className="table-responsive text-center"
                                              style={{
                                                border: "dashed 1px #80808061",
                                              }}
                                            >
                                              <table className="table table-centered table-nowrap table-hover">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      style={{ width: "70px" }}
                                                    >
                                                      #
                                                    </th>
                                                    <th scope="col">Team</th>
                                                    <th scope="col">Points</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.leaderboard.map((item, idx) => (
                                                    <tr>
                                                      <td>{idx + 1}</td>
                                                      <td>
                                                        <h5 className="font-size-14 mb-1">
                                                          <a
                                                            href="#"
                                                            className="text-dark"
                                                          >
                                                            {item.team ? item.team.teamName : ""}
                                                          </a>
                                                        </h5>
                                                        {/* <small className="text-muted mb-0">Combined Rank Points: 1234</small> */}
                                                      </td>
                                                      <td>
                                                        {item.totalScore}
                                                      </td>
                                                    </tr>
                                                  )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div
                                        className="card text-center"
                                        style={{
                                          backgroundColor: "#cec9c985",
                                          width: "50%",
                                          margin: "5rem auto",
                                        }}
                                      >
                                        <span style={{ lineHeight: "5rem" }}>
                                          NO REGISTERED TEAMS
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="container-fluid text-center">
                <h3 className="mt-4">SESSION COULD NOT BE VALIDATED</h3>
                <p>Your session has probably expired or been revoked.</p>
                <p className="text-muted">Contact support for help</p>
              </div>
            )}
          </section>
        </main>

        <Footer />

        {/* <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contest: state.contestData.contest,
    eventTeams: state.teamsData.teams,
    team: state.teamsData.team,
    leaderboard: state.contestData.leaderboard,
    judge: state.judgeData.judge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContest: (cid) => dispatch(fetchContest(cid)),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getEventTeams: (eventId) => dispatch(fetchAllEventTeams(eventId)),
    getLeaderboard: (eventId) => dispatch(getContestleaderboard(eventId)),
    getUserTeam: (userId, eventId) =>
      dispatch(getEventUserTeam(userId, eventId)),
    getTeam: (tid) => dispatch(fetchTeam(tid)),
    validateSession: (sessionId) => dispatch(checkSession(sessionId)),
    checkEventParticipation: (eventId, userId) =>
      dispatch(checkParticipation(eventId, userId)),
    pushFile: (teamId, file, uploadedBy, fileName) =>
      dispatch(createSubmission(teamId, file, uploadedBy, fileName)),
    rateSubmission: (entries, session, teamId, submission) =>
      dispatch(
        judgeSubmission({
          entries: entries,
          judgeSession: session,
          teamId: teamId,
          submission: submission,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JudgePage));
