import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import { Overlay } from "react-bootstrap";
import { Alignment } from "react-data-table-component";




class LandingPage extends Component {
  

  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />

   
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      {/* <a href="#">
                        <img
                          src="/assets/landing-page/images/bih_logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a> */}
                      <a href="/#">
                        <img
                          src="/assets/landing-page/images/logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div
                      className="responsive"
                      onClick={() => this.toggleMenu()}
                    >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                      
                      
                      
                    <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub ml-sm-4">
                            <a href="/#">Home </a>
                          </li>
                          {/* <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="#about">About</a>
                          </li> */}
                          <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="/#how-it-works">Developer</a>
                          </li>
                          <li className=" ml-sm-4">
                            <a href="/#how-it-works-business">Business</a>
                          </li>
                         

                          <li className="ml-sm-4 has-sub">
                            <a href="/events">Hackathons</a>
                            <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/plannedHackathons">Planned Hackathons</a></li>
                            </ul>
                          </li>


                          {/* <li className=" ml-sm-4">
                            <a href="/events">Hackathons</a>
                          </li> */}
                            {/* <li className=" ml-sm-4">
                            <a href="/awsRegistration"></a>
                           </li> */}


                          {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                          <ul className="dropdown">
                            <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                          </ul>
                          </li> */}

                          <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                           <ul className="dropdown">
                            <li className="ml-sm-4 has-sub"><a href="#">AWS-re/Start</a>
                              <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                              <li className="ml-sm-4"><a href="/awsRequirements">Requirements</a></li>
                              <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                              </ul>
                            </li>
                           </ul>
                        </li>

                          <li className=" ml-sm-4">
                            <a href="#footer">Contacts</a>
                          </li>

                          </ul>                        
                      </nav>




                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />{" "}
                        {this.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* backgroundColor: "rgb(49,49,50)"  */}
        <main>


          <section
            id="parallax"
            className=""
            style={{
              height:"75%",
              background: "url(/assets/landing-page/images/plannedHackCover.jpg)",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover"}}
          >
            <div className="slider-shape ss-one layer" data-depth="0.10">
              {/*  <img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
              {/*<img
                src="/assets/landing-page/images/shape/slider_shape02.png"
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
              {/*<img
                src="/assets/landing-page/images/shape/html.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
              {/*<img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-eight layer" data-depth="">
              </div>
            <div className="slider-active"
            >
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 style={{marginTop:"100px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          <span>Planned</span>
                        </h2>

                        <h2 style={{marginTop:"-20px", marginBottom:"100px", fontSize:"45px"}} data-animation="fadeInUp animated" data-delay=".4s">
                        Hackathons
                        </h2>
                    
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{ alignItems: "center" }}
            id="how-it-works"
            className="about-area about-p p-relative d-flex">
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"></div>

                        <br/>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "rgb(140,142,94)", fontSize:"19px", visibility:"visible", padding:"0px", marginTop:"-30px", marginBottom:"50px"}}
                      >
                        <br/>WE ARE EXCITED TO SHARE WITH YOU THAT WE HAVE UPCOMING HACKATHONS TO LOOK FOWARD TO.
                      </span>
                     
                    </div>


                    <div className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s" style={{visibility:"visible"}}>
                        <br/>



                        <div className="accordion" id="accordionExample" style={{width:"100%"}}>






<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingOne">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
1. Payment Only Services
</button>
</h2>
</div>

<div id="collapseOne" className="collapse hide" aria-labelledby="headingOne" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Payment for tenders</p>
<p style={{padding:"0px", margin:"0px"}}>- Payment of fines</p>
<p style={{padding:"0px", margin:"0px"}}>- Payment for Council Rates</p>
<p style={{padding:"0px", margin:"0px"}}>- Payment of Levies</p>
<p style={{padding:"0px", margin:"0px"}}>- Payment of Garnishee orders</p>
<p style={{padding:"0px", margin:"0px"}}>- Police Fines &amp; Charges</p>
<p style={{padding:"0px", margin:"0px"}}>- Court Fines &amp; Charges</p>
<p style={{padding:"0px", margin:"0px"}}>- Payment of etc</p>
</div>

</div>
</div>



<div className="mb-1 card" style={{padding:"0px", marginTop:"0px"}}>
<div className="card-header p-0" id="headingTwo">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)" }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
2. Work & Resident Permit Services
</button>
</h2>
</div>

<div id="collapseTwo" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordionExample">
{/* style="border-radius: unset; background: linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%) */}
<div class="card-body" style={{marginTop:"0px"}}>
<p style={{padding:"0px", margin:"0px"}}>- Emergency</p>
<p style={{padding:"0px", margin:"0px"}}>- Long Term</p>
<p style={{padding:"0px", margin:"0px"}}>- Exemption</p>
<p style={{padding:"0px", margin:"0px"}}>- Appeals</p>                
</div>

</div>
</div>




<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingThree">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
3. Elderly & Social Benefits
</button>
</h2>
</div>

<div id="collapseThree" className="collapse hide" aria-labelledby="headingThree" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Old Age Pensions (Universal Benefit)</p>
<p style={{padding:"0px", margin:"0px"}}>- World war II Veterans Allowance</p>
<p style={{padding:"0px", margin:"0px"}}>- Destitute Food Basket and Allowance</p>
<p style={{padding:"0px", margin:"0px"}}>- Community Home Based Care</p>
<p style={{padding:"0px", margin:"0px"}}>- Orphans & Vulnerable Children Care</p>
<p style={{padding:"0px", margin:"0px"}}>- Disability Cash Transfer</p>
<p style={{padding:"0px", margin:"0px"}}>- Economic Empowerment Services</p>
<p style={{padding:"0px", margin:"0px"}}>- Rural Area Development Programme</p>
</div>

</div>
</div>




<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingFour">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
4. Registration of Workers Compensation Claims
</button>
</h2>
</div>

<div id="collapseFour" className="collapse hide" aria-labelledby="headingFour" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- To Be Updated</p>

</div>

</div>
</div>

<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingFive">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
5. Minerals & Energy
</button>
</h2>
</div>

<div id="collapseFive" className="collapse hide" aria-labelledby="headingFive" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Diamond Hub Information Services</p>

</div>

</div>
</div>







<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingSix">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
6. Office of the reciever services
</button>
</h2>
</div>

<div id="collapseSix" className="collapse hide" aria-labelledby="headingFive" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Asset Management</p>

</div>

</div>
</div>








<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingSeven">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
7. Labour Services
</button>
</h2>
</div>

<div id="collapseSeven" className="collapse hide" aria-labelledby="headingSeven" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Registration of Factories</p>
<p style={{padding:"0px", margin:"0px"}}>- Application for Inspection</p>
<p style={{padding:"0px", margin:"0px"}}>- Examination of Plant & Machinery</p>

</div>

</div>
</div>





<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingEight">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
8. BPS Services
</button>
</h2>
</div>

<div id="collapseEight" className="collapse hide" aria-labelledby="headingEight" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Small Claims Court</p>
<p style={{padding:"0px", margin:"0px"}}>- Incident Reporting</p>
<p style={{padding:"0px", margin:"0px"}}>- Crime Update</p>
<p style={{padding:"0px", margin:"0px"}}>- Application for Political Rallies & Demonstrations</p>
<p style={{padding:"0px", margin:"0px"}}>- Application for Firearms</p>

</div>

</div>
</div>



<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingNine">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
9. Ministry of Communications, Knowledge and Technology
</button>
</h2>
</div>

<div id="collapseNine" className="collapse hide" aria-labelledby="headingNine" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Radiation Protection Services</p>
<p style={{padding:"0px", margin:"0px"}}>- Research Services</p>
<p style={{padding:"0px", margin:"0px"}}>- Knowledge Management Services</p>
<p style={{padding:"0px", margin:"0px"}}>- ICT Services</p>


</div>

</div>
</div>





<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingTen">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
10. DPSM - Government to Employee Services
</button>
</h2>
</div>

<div id="collapseTen" className="collapse hide" aria-labelledby="headingTen" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Leave Management</p>
<p style={{padding:"0px", margin:"0px"}}>- Directives, Policies Repository</p>



</div>

</div>
</div>





<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingEleven">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
11. Language Translator Services
</button>
</h2>
</div>

<div id="collapseEleven" className="collapse hide" aria-labelledby="headingEleven" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- To Be Updated</p>



</div>

</div>
</div>






<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingTwelve">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
12. eEducation
</button>
</h2>
</div>

<div id="collapseTwelve" className="collapse hide" aria-labelledby="headingTwelve" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Student & Staff Management Services</p>
<p style={{padding:"0px", margin:"0px"}}>- TVET Online Student Admission</p>
<p style={{padding:"0px", margin:"0px"}}>- Trainee admission Process</p>



</div>

</div>
</div>





<div className="mb-1 card" style={{padding:"0px", margin:"0px"}}>
<div className="card-header p-0" id="headingThirteen">
<h2 className="mb-0">
<button style={{ borderRadius: "unset", background:"linear-gradient(to right, rgb(246, 211, 101) 0%, rgb(253, 160, 133) 51%, rgb(246, 211, 101) 100%)"  }} className="btn btn-link btn-block text-left btnCustom collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
13. eEducation
</button>
</h2>
</div>

<div id="collapseThirteen" className="collapse hide" aria-labelledby="headingThirteen" data-parent="#accordionExample">
{/* style="font-size: medium; padding: 0px; margin: 0px;" */}
<div class="card-body">
<p style={{padding:"0px", margin:"0px"}}>- Curriculum Management</p>




</div>

</div>
</div>





  
</div>

                    </div>



















                  </div>
                </div>

            

                
  


{/* style="padding: 0px; margin: 0px; color: red; font-weight: bold;" */}

<p style={{padding:"0px", margin:"0px", color:"red", fontWeight:"bold"}}> Disclaimer: 
<span>The Procuring Entity and BDIH reserves the right to amend the plan as it may deem necessary.
  </span></p>


              </div>
            </div>
          </section>


        </main>

        <Footer />
        

        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
