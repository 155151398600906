import {
    getAllContests,
    getContest,
    newContest,
    updateEvent,
    removeContest,
    getEventLeaderboard,
    getEventAdminLeaderboard,
    getContestRequests,
    approveContestRequest,
    updateEventRequest,
    newContestRequest,
    getEventRequest,
    isRegistered,
    submitFile,
    getContestParticipants,
    createContestRound,
    updateContestRound,
    removeContestRound,
    updateContestRoundSubmissions,
    fetchEventEntries,
    fetchJudgeEventEntries,
    updateEventCompliance
} from "../../api/contests"

import { fireNotification } from "./notificationsActions";

export const checkParticipation = (eventId, userId, isBusiness) => {

    return dispatch => {
        return isRegistered({ eventId: eventId, userId: userId, isBusiness: isBusiness })
            .then(response => {
                dispatch(fireNotification(response))
                const event = response.data;
                return dispatch({
                    type: "IS_REGISTERED",
                    event
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const publishContest = (data) => {
    return dispatch => {
        return newContest(data)
            .then(response => {
                dispatch(fireNotification(response))
                const reg_status = response.data;
                dispatch({
                    type: "PUBLISH_CONTEST",
                    reg_status
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const addEvent = (data) => {
    return dispatch => {
        return newContest(data)
            .then(response => {
                dispatch(fireNotification(response))
                const event = response.data;
                dispatch({
                    type: "CREATE_EVENT",
                    event
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const fetchAllContests = (userId) => {
    return dispatch => {
        return getAllContests(userId)
            .then(response => {
                const events = response.data
                // console.log(events);
                dispatch({
                    type: "GET_ALL_CONTESTS",
                    events
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};


export const fetchAllParticipants = (teamId) => {
    return dispatch => {
        return getContestParticipants(teamId)
            .then(response => {
                const participants = response.data
                // console.log(participants);
                dispatch({
                    type: "GET_CONTEST_PARTICIPANTS",
                    participants
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const fetchContest = (cid) => {
    return dispatch => {
        return getContest(cid)
            .then(response => {
                const event = response.data;
                dispatch({
                    type: "GET_CONTEST",
                    event
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const updateContest = (contestId, data) => {
    return dispatch => {
        return updateEvent({ eventId: contestId, updates: data })
            .then(response => {
                dispatch(fireNotification(response))
                const event = response.data;
                dispatch({
                    type: "UPDATE_CONTEST",
                    event
                });
            })
            .catch(err => (
                dispatch(fireNotification(err)), Promise.reject(err))
            );
    };
};
export const addContestRound = (data) => {
    return dispatch => {
        return createContestRound(data)
            .then(response => {
                dispatch(fireNotification(response))
                const round = response.data;
                dispatch({
                    type: "ADD_CONTEST_ROUND",
                    round
                });
            })
            .catch(err => {
                dispatch(fireNotification(err));
                return Promise.reject(err);
            });
    };
};

export const patchContestRound = (data) => {
    return dispatch => {
        return updateContestRound(data)
            .then(response => {
                dispatch(fireNotification(response))
                const round = response.data;
                dispatch({
                    type: "UPDATE_CONTEST_ROUND",
                    round
                });
            })
            .catch(err => (
                dispatch(fireNotification(err)), Promise.reject(err))
            );
    };
};

export const patchContestRoundSubs = (data) => {
    return dispatch => {
        return updateContestRoundSubmissions(data)
            .then(response => {
                dispatch(fireNotification(response))
                const round = response.data;
                dispatch({
                    type: "UPDATE_CONTEST_ROUND",
                    round
                });
            })
            .catch(err => (
                dispatch(fireNotification(err)), Promise.reject(err))
            );
    };
};

export const deleteContestRound = (data) => {
    return dispatch => {
        return removeContestRound(data)
            .then(response => {
                dispatch(fireNotification(response))
                const round = response.data;
                dispatch({
                    type: "REMOVE_CONTEST_ROUND",
                    round
                });
            })
            .catch(err => (
                dispatch(fireNotification(err)), Promise.reject(err))
            );
    };
};

export const getContestleaderboard = (contestId, data) => {
    return dispatch => {
        return getEventLeaderboard(contestId, data)
            .then(response => {
                const leaderboard = response.data;
                dispatch({
                    type: "PULL_CONTEST_LEADERBOARD",
                    leaderboard
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const getContestAdminleaderboard = (contestId, data) => {
    return dispatch => {
        return getEventAdminLeaderboard(contestId, data)
            .then(response => {
                const leaderboard = response.data;
                dispatch({
                    type: "PULL_CONTEST_LEADERBOARD",
                    leaderboard
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const deleteContest = (cid) => {
    return dispatch => {
        return removeContest(cid)
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "REMOVE_CONTEST",
                    data: response.data,
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};



export const publishContestRequests = (data) => {
    return dispatch => {

        return newContestRequest(data)
            .then(response => {
                dispatch(fireNotification(response))
                const contestRequest = response.data;
                dispatch({
                    type: "PUBLISH_CONTEST_REQUEST",
                    contestRequest
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};


export const fetchContestRequests = (userId) => {
    return dispatch => {
        return getContestRequests(userId)
            .then(response => {
                dispatch(fireNotification(response))
                const contestRequests = response.data;
                // console.log(contestRequests);
                dispatch({
                    type: "PULL_CONTEST_REQUESTS",
                    contestRequests
                });
            })
            .catch(err => { dispatch(fireNotification(err)); Promise.reject(err); });
    };
};

export const fetchContestRequest = (request_ID) => {
    return dispatch => {
        return getEventRequest(request_ID)
            .then(response => {
                const contest_request = response.data;
                dispatch({
                    type: "PULL_CONTEST_REQUEST",
                    contest_request
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const approveContestRequests = (request_ID, data) => {
    return dispatch => {
        return approveContestRequest(request_ID, data)
            .then(response => {
                dispatch(fireNotification(response))
                const contest_request = response.data;
                dispatch({
                    type: "UPDATE_CONTEST_REQUEST",
                    contest_request
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};




export const updateContestRequest = (request_ID, data) => {
    return dispatch => {
        return updateEventRequest(request_ID, data)
            .then(response => {
                dispatch(fireNotification(response))
                const contest_request = response.data;
                dispatch({
                    type: "UPDATE_CONTEST_REQUEST",
                    contest_request
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const createSubmission = (body) => {
    return dispatch => {
        return submitFile(body)
            .then(response => {
                const submissions = response.data;
                dispatch({
                    type: "EVENT_SUBMIT",
                    submissions

                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const getEventEntries = (body) => {
    return dispatch => {
        return fetchEventEntries(body)
            .then(response => {
                const entries = response.data;
                dispatch({
                    type: "EVENT_ENTRIES",
                    entries

                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const getJudgeEventEntries = (body) => {
    return dispatch => {
        return fetchJudgeEventEntries(body)
            .then(response => {
                const entries = response.data;
                dispatch({
                    type: "EVENT_ENTRIES",
                    entries

                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const updateEventComplianceList = (eventId, complianceList) => {
    return dispatch => {
        return updateEventCompliance(eventId, complianceList)
            .then(response => {
                dispatch(fireNotification(response))
                const contest = response.data;
                // dispatch({
                //     type: "UPDATE_CONTEST",
                //     contest

                // });
            })
            .catch(err => {
                dispatch(fireNotification(err))
                return Promise.reject(err)
            });
    };
};
