// NEEDS SERIOUS CODE CLEANUP

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "react-select";
import BadgeHorizontal from "./BadgeHorizontal";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import { client } from "../api/client";
import {
  fetchUserTechnologies,
  fetchAllTechnologies,
  updateTechnologies,
} from "../store/actions/technologiesActions";
import { getTechnologyIcon } from "../utils/utils";

class TechnologiesList extends Component {

  constructor(...props) {
    super(...props);
  }
  
  state = {
    loaded: false,
    updating: false,
    isEdited: false,
    selectedTechnologies: [],
    userTechnologies: [],
    allTechnologies:[],
  };



  componentWillMount() {
    this.props.getTechnologies().then(() => {
      const technologies = JSON.parse(Cookies.get("user")).technologies;

      if(technologies.length > 0 ){
        this.props.getUserTechnologies().then(() => {
          this.setState({
            selectedTechnologies: this.props.userTechnologies.map((i) => ({
              id: i._id, 
              value: i.title,
              label: i.title,
              img: i.img,
            })),
            userTechnologies: this.props.userTechnologies,
            allTechnologies: this.props.allTechnologies,
          });
        });
      }

      this.setState({
        userTechnologies: this.props.userTechnologies,
        allTechnologies: this.props.allTechnologies,
      });

    });
  }

  /**
   * returns state to render when no information is available,
   * with option to populate new data to state.
   * 
   */
  blank() {
    return (
      <div
        className="ml-4 mr-5"
        onClick={this.toggleEditing}
        style={{
          cursor: "pointer",
          display: "table",
          textAlign: "center",
          width: "100%",
          minHeight: "8rem",
          border: "dashed 1px gray",
        }}
      >
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          No technology info yet, click to add data
        </div>
      </div>
    );
  }

  /**
   * push profile technologies updates to backend
   * 
   * @param  {} technologies updated technologies
   */
  updateProfile(technologies) {
    this.setState({ isLoading: true });
    client
      .put("/profile", {
        technologies,
        userId: JSON.parse(Cookie.get("user"))._id,
      })
      .then((user) => {
        
        
        if (user.data) {
          this.toggleEditing();
          Cookie.set("user", user.data);
          
          if(user.data.technologies.length > 0 ){
            
            this.props.getUserTechnologies().then(() => {
              this.setState({
                selectedTechnologies: this.props.userTechnologies.map((i) => ({
                  id: i._id,
                  value: i.title,
                  label: i.title,
                  img: i.img,
                })),
                userTechnologies: this.props.userTechnologies,
                allTechnologies: this.props.allTechnologies,
              });
            });
          }else{
            
            this.setState({
              userTechnologies: [],
              selectedTechnologies:[],
            })
          }

        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  /**
 * all users to update their technology selections
 * update component state with new selections
 * 
 * @param  {} selectedTechnologies new technology selections
 */
  handleChange = (selectedTechnologies) => {
    this.setState({
      selectedTechnologies,
    });
  };


  /**
   * inidicates if a user is editing their technology selections
   * and displays a form with all other technology options to choose from
   * 
   */
  toggleEditing = (event) => {
    this.setState({ isEdited: !this.state.isEdited });
  };


  handleSubmit = (event) => {
    event.preventDefault();
    if (
      Array.isArray(this.state.selectedTechnologies) &&
      this.state.selectedTechnologies.length
    ) {
      this.setState({ updating: true });
      this.updateProfile(this.state.selectedTechnologies);
    } else {
      this.updateProfile([]);
    }
  };

  render() {
    return (
      <div className="card pl-4 pr-2">
        {/* <!-- Technologies --> */}
        <div className="card-body">
          {!this.state.isEdited ? (
            <div className="float-right md-4 mr-3">
              <button
                onClick={this.toggleEditing}
                className="btn btn-outline-secondary waves-effect waves-light"
              >
                <i className="mdi mdi-lead-pencil"></i> &nbsp; Edit
              </button>
            </div>
          ) : (
            ""
          )}
          <h4 className="card-title text-uppercase" style={{ color: "#000" }}>
            Technologies
          </h4>
        </div>

        {this.state.isEdited ? (
          <div className="pr-4 pl-4 pt-2 pb-2">
            {this.state.isLoading ? (
              <p>Submitting profile update...</p>
            ) : (
              <div className="d-flex mb-3">
                <div className="vw-100 mr-5">
                  <Select
                    options={this.props.allTechnologies}
                    value={this.state.selectedTechnologies}
                    onChange={this.handleChange}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={{ width: "-webkit-fill-available" }}
                    placeholder="Select multiple technologies"
                  />
                </div>
                <div style={{ display: "contents" }} className="ml-5">
                  <button
                    onClick={this.handleSubmit}
                    // disabled={!this.state.isEdited}
                    className="btn btn-primary ml-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={this.toggleEditing}
                    className="btn btn-secondary ml-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="row mb-4">
          {this.state.userTechnologies.length > 0
            ? this.state.userTechnologies.map((item, index) => (
                <BadgeHorizontal
                  icon={getTechnologyIcon(item.img)}
                  data={item.title}
                  key={item._id}
                />
              ))
            : this.blank()}
        </div>

        <div
          className="modal fade technologies-modal bs-example-modal-center"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        ></div>
      </div>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    userTechnologies: state.technologiesData.technologiesList,
    allTechnologies: state.technologiesData.allTechnologiesList.map((i) => ({
      value: i._id,
      label: i.title,
    })),
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    getUserTechnologies: () => dispatch(fetchUserTechnologies()),
    getTechnologies: () => dispatch(fetchAllTechnologies()),
    updateTechnologies: (tech) => dispatch(updateTechnologies(tech)),
  };
};

export default connect(
  mapStateToprops,
  mapDispatchToprops
)(withRouter(TechnologiesList));
