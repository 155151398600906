import React, { Component } from "react";
import { withRouter } from "react-router"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ExcelExport extends Component {
    state = {
    } 

    

    componentDidMount(){
        
        
    }

    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';
    
    exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: this.fileType});
        FileSaver.saveAs(data, fileName + this.fileExtension);
    }
   render(){

      
    return (
        
        <a onClick={() => this.exportToCSV(this.props.csvData,this.props.fileName)} style={{color:'white'}} className="btn btn-primary waves-effect waves-light btn-sm">EXCEL</a>
    );
    
    }
}


export default withRouter(ExcelExport);
