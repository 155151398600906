import React, { Component } from "react";
import { connect } from "react-redux";
import AdminUsersList from "../../components/AdminUsersList";
import { pushAdmin, fetchAdminUsers } from "../../store/actions/usersActions";

class AdminUsers extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        isLoaded: false,
        users: []
    }


    /**
     * Get admin users 
     * Populate state with data
     * toggle isLoaded state to true when data is populated
     */
    componentDidMount() {
        this.props.getUsers()
            .then((res) => {
                this.setState({
                    isLoaded: true,
                    users: res.data,
                });
                // console.log(this.state.users);

            })
    }


    /**
     * update state with new input values on change
     * 
     * @param  {} doc key value pairs with updated data
     */
    handleInputChange(doc) {
        this.setState(doc)
    }



    /**
     * submit new user data to backend to create new admi user
     * prevent pasge from refreshing
     * 
     * @param  {} event submit event
     */
    handleSubmit(event) {
        event.preventDefault();
        var data = { ...this.state, accountType: "admin" }

        this.props.createAdmin(data)
    }

    render() {
        return (
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        {/* <!-- start page title --> */}
                        <div className="row">

                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Admin Users</h4>

                                    <div className="page-title-right">

                                        <ol className="breadcrumb m-0">
                                            <li className="mr-5">
                                                <button type="button" style={{ backgroundColor: '#74788d', borderColor: '#74788d', color: 'white' }} className="btn waves-effect waves-light" data-toggle="modal" data-target=".bs-example-modal-center">
                                                    <i className="bx bx-user-plus font-size-16 align-middle mr-2" ></i> Add admin
                                                </button>
                                            </li>

                                        </ol>
                                    </div>
                                    <div className="modal fade bs-example-modal-center" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0">Add new admin</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>

                                                </div>
                                                <div className="modal-body">
                                                    <form onSubmit={e => this.handleSubmit(e)} className=" needs-validation custom-validation" encType="multipart/form-data">
                                                        <div className="mb-4">
                                                            <input onChange={(e) => this.handleInputChange({ firstName: e.target.value })} value={this.state.firstName} required className="form-control" name="firstName" type="text" placeholder="first name" />
                                                        </div>
                                                        <div className="mb-4">
                                                            <input onChange={(e) => this.handleInputChange({ lastName: e.target.value })} value={this.state.lastName} required className="form-control" name="lastName" type="text" placeholder="Last name" />
                                                        </div>
                                                        <div className="mb-4">
                                                            <input onChange={(e) => this.handleInputChange({ email: e.target.value })} value={this.state.email} required data-parsley-type="email" className="form-control" name="email" type="email" placeholder="Email" />
                                                        </div>
                                                        <div className="mb-4">
                                                            <input id="password" onChange={(e) => this.handleInputChange({ password: e.target.value })} value={this.state.password} required minLength="8" maxLength="28" className="form-control" name="password" type="password" placeholder="Password" />
                                                        </div>
                                                        <div className="mb-4">
                                                            <input onChange={(e) => this.handleInputChange({ confirmPassword: e.target.value })} value={this.state.passwordConfirm} required data-parsley-equalto="#password" data-parsley-equalto-message="password fields dont match" className="form-control" name="confirm-password" type="password" placeholder="Confirm password" />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="submit" className="btn btn-primary">Save</button>
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>{/** <!-- /.modal-content --> **/}
                                        </div>{/** <!-- /.modal-dialog --> **/}
                                    </div>{/** <!-- /.modal --> **/}
                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title --> */}



                        <div className="row">
                            <div className="col-xl-12">

                                {this.state.users.length > 0 ? <AdminUsersList
                                    updateUsers={this.updateUsers}
                                    users={this.state.users}
                                /> : <div></div>}


                            </div>
                        </div>

                    </div> {/** <!-- container-fluid --> **/}
                </div>
                {/* <!-- End Page-content --> */}


                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                {new Date().getFullYear()} © Botswana Innovation Hub
                            </div>
                            <div className="col-sm-6">
                                <div className="text-sm-right d-none d-sm-block">
                                    Skills Ranker
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.admin.adminUsersList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (uid) => dispatch(fetchAdminUsers(uid)),
        createAdmin: (data) => dispatch(pushAdmin(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
