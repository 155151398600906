import React, { Component } from "react";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Cookies from "js-cookie";
import { formatDate, getLanguageIcon, timeZoneHelper } from "../../utils/utils";
import globe from "../../../src/globe.svg";
import { fetchPlatformSkills, findJobPosts } from "../../store/actions/businessProfileActions";

class JobPosts extends Component {
  state = {
    isLoaded: false,
    skills: [],
    currentSubject: null,
    searchSkills: "",
    searchJobType: "Temporary Internship Freelance Permanent Full time Part time",
    searchDateRange: "",
    sortOrder: "Newest",
    jobPosts: [],
    page: 1,
    isAvailable: false,
    availableRange: [
      {
        id: 1,
        label: "Last hour",
        value: "last hour",
        isOn: false
      },
      {
        id: 2,
        label: "Last 7 days",
        value: "last 7 days",
        isOn: false
      },
      {
        id: 3,
        label: "Last 30 days",
        value: "last 30 days",
        isOn: false
      },
      {
        id: 4,
        label: "Last 90 days",
        value: "last 90 days",
        isOn: false
      }
    ],
    availableJobTypes: [
      {
        id: 1,
        label: "Temporary",
        value: "Temporary",
        isOn: true
      },
      {
        id: 2,
        label: "Internship",
        value: "Internship",
        isOn: true
      },
      {
        id: 3,
        label: "Freelance",
        value: "Freelance",
        isOn: true
      },
      {
        id: 4,
        label: "Permanent",
        value: "Permanent",
        isOn: true
      },
      {
        id: 5,
        label: "Full time",
        value: "Full time",
        isOn: true
      },
      {
        id: 6,
        label: "Part time",
        value: "Part time",
        isOn: true
      },
    ],
  };


  componentDidMount() {
    this.props.getSkills()
      .then(() => {
        this.setState({ skills: this.props.skills })
      })


    this.props.searchPosts({ searchKeys: ["Permanent Internship Freelance Temporary Full Time Part time"], page: 1, order: -1 })
      .then(() => {
        this.setState({ jobPosts: this.props.jobPosts, isAvailable: true })
      })
      .catch(err => {
        this.setState({ isAvailable: false })
      })
      .finally(() => {
        this.setState({ isLoaded: true })
      })


  }

  searchJobs = (data, target) => {

    let searchKeys = this.state.searchJobType + this.state.searchSkills
    let searchRange = this.state.searchDateRange
    let order = this.state.sortOrder == "Newest" ? -1 : 1
    let page = this.state.page

    if (target == "skills")
      searchKeys = this.state.searchJobType + data
    else if (target == "range")
      searchRange = data
    else if (target == "order")
      order = data == "Newest" ? -1 : 1
    else if (target == "page")
      page = data
    else if (target == "jobType")
      searchKeys = data + this.state.searchSkills
    else if (target == "page")
      page = data


    this.props.searchPosts({ searchKeys: [searchKeys], page: page, order: order, searchRange: searchRange })
      .then(() => {
        this.setState({ jobPosts: this.props.jobPosts, isAvailable: true })
      })
      .catch(err => {
        this.setState({ isAvailable: false })
      })
      .finally(() => {
        this.setState({ isLoaded: true })
      })
  }
  handleSkillChange = (idx) => {
    let skills = this.state.skills
    let searchSkills = this.state.searchSkills
    skills[idx].isOn = !skills[idx].isOn
    if (skills[idx].isOn) {
      searchSkills = searchSkills + " " + skills[idx].label
    }
    else {
      searchSkills = searchSkills.replace(skills[idx].label, "")
    }
    this.setState({ skills: skills, searchSkills: searchSkills })

    this.searchJobs(searchSkills, "skills")
  }

  pagination = (currentPage, totalResults) => {

    let x = []
    totalResults = totalResults < 0 ? 1 : totalResults

    let pages = Math.ceil(totalResults / 25)

    for (let index = 1; index <= pages; index++) {
      if (index == currentPage) {
        x.push(
          <li className="page-item active">
            <a href="#" className="page-link">{index}</a>
          </li>
        )
      } else {
        x.push(
          <li onClick={() => this.searchJobs(index, "page")} className="page-item">
            <a href="#" className="page-link">{index}</a>
          </li>
        )
      }

    }

    return x

  }
  handleRangeChange = (idx) => {
    let range = this.state.availableRange
    let rangeSearch = this.state.searchDateRange
    let previous = range.filter(range => range.isOn)

    let previousIdx = 0
    if (previous.length > 0) {
      range.map((item, idx) => {
        if (item.id == previous[0].id) {
          previousIdx = idx
        }
      })

      range[previousIdx].isOn = false
    }

    range[idx].isOn = true

    rangeSearch = rangeSearch.replace(range[previousIdx].label, "")
    rangeSearch = range[idx].label

    this.setState({ availableRange: range, searchDateRange: rangeSearch })

    this.searchJobs(rangeSearch, "range")
  }

  handleJobTypeChange = (idx) => {
    let jobTypes = this.state.availableJobTypes
    let searchJobType = this.state.searchJobType
    jobTypes[idx].isOn = !jobTypes[idx].isOn
    if (jobTypes[idx].isOn) {
      searchJobType = searchJobType + " " + jobTypes[idx].value
    }
    else {
      searchJobType = searchJobType.replace(jobTypes[idx].value, "")
    }
    this.setState({ availableJobTypes: jobTypes, searchJobType: searchJobType })

    this.searchJobs(searchJobType, "jobType")
  }

  handleOrderChange = (value) => {
    this.setState({ sortOrder: value })
    this.searchJobs(value, "order")
  }


  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div style={{ position: "fixed", width: "100%", height: "100%" }}>
            <div
              style={{
                backgroundImage: "url(" + globe + ")",
                transform: "translate(-50%, -50%) scale(1)",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transition: "transform .15s ease-out 0s",
                transform: "translate(-50%,-50%)",
                transformOrigin: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: " cover",
                willChange: "transform",
              }}
            ></div>
          </div>
          <div className="page-content pt-5">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <link rel="stylesheet" href="/assets/css/jobs.css" />
              <div className="row mt-2">
                <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block col-xl-2 col-lg-2 col-md-2 col-sm-0 offset-xl-2 offset-lg-2 offset-md-2 p-3">
                  <div className="mb-5">
                    <h4 className="mb-3 jobs-h4" style={{ fontWeight: "bold", fontSize: "17px" }}>Order</h4>
                    <div className="form-group row">
                      <div className="col-md-10">
                        <select className="form-control" onChange={(e) => this.handleOrderChange(e.target.value)}>
                          <option>Newest</option>
                          <option>Oldest</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <h4 className="mb-3 jobs-h4" style={{ fontWeight: "bold", fontSize: "17px" }}>Date Posted</h4>
                    <div>
                      {
                        this.state.availableRange.map((range, idx) => (
                          <div key={range.id} className="custom-control custom-radio mb-2">
                            <input type="radio" id={`customRadio${range.id}`} name="customRadio" className="custom-control-input" onChange={() => this.handleRangeChange(idx)} />
                            <label className="custom-control-label" htmlFor={`customRadio${range.id}`}>{range.label}</label>
                          </div>
                        ))
                      }


                    </div>
                  </div>
                  <div className="mb-5">
                    <h4 className="mb-3 jobs-h4" style={{ fontWeight: "bold", fontSize: "17px" }}>Job Type</h4>
                    {this.state.availableJobTypes.map((jobType, idx) => (
                      <div className="custom-control custom-switch mb-2" dir="ltr">
                        <input type="checkbox" className="custom-control-input" id={`customSwitch${jobType.id}`} checked={jobType.isOn} onChange={(e) => this.handleJobTypeChange(idx)} />
                        <label className="custom-control-label" htmlFor={`customSwitch${jobType.id}`}>{jobType.label}</label>
                      </div>
                    ))}


                  </div>
                  <div className="mb-5">
                    <h4 className="mb-3 jobs-h4" style={{ fontWeight: "bold", fontSize: "17px" }}>Skills</h4>
                    <div className="skills-filter" style={{ maxHeight: "200px", overflowY: "auto", overflowX: "none" }}>
                      {/* scrollable */}
                      {this.state.skills.map((skill, idx) => (
                        <div key={skill.id} className="custom-control custom-checkbox mb-3">
                          <input type="checkbox" className="custom-control-input" checked={skill.isOn} id={'a' + skill.id} onChange={(e) => this.handleSkillChange(idx)} />
                          <label className="custom-control-label" htmlFor={'a' + skill.id}>{skill.label}</label>
                        </div>
                      ))}


                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  {/* <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5
                      className="mb-0 font-size-14 font-weight-bolder"
                      style={{ color: "#000" }}
                    >
                      Job Posts
                    </h5>
                  </div> */}
                  <div className="row mb-5">
                    <div className="col-12">
                      <p>{this.props.jobPostMeta} Results found</p>
                      {/* <div style={{float:"right"}}>
                          <div className="form-group" style={{display:"flex"}}>
                            
                                {/* <label>Sort by: </label> *
                                <select className="form-control">
                                    <option>Newest</option>
                                    <option>Oldest</option>
                                </select>
                              
                          </div>
                        </div> */}
                    </div>
                  </div>
                  {this.props.jobPosts.map((item) => (
                    <div className="row">
                      <div key={item._id} className="col-12">
                        <div
                          className="card job-card"
                          onClick={() =>
                            this.props.history.push(
                              "/profile/jobposts/" + item._id
                            )
                          }
                        >
                          <div
                            className="card-body"
                            style={{ paddingBottom: ".5rem" }}
                          >
                            <div className="media">
                              <div className="avatar-md mr-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                  <img
                                    src={getLanguageIcon(item.logo)}
                                    alt=""
                                    height="30"
                                  />
                                </span>
                              </div>
                              <div className="media-body overflow-hidden">
                                <p

                                  className="job-type mb-2"
                                >
                                  {item.jobType}
                                </p>
                                <h5
                                  className="text-truncate font-size-18 font-weight-bolder"
                                  style={{
                                    fontFamily: "monospace",
                                    color: "#000",
                                  }}
                                >
                                  <a href="#" className="text-dark">
                                    {item.title}
                                  </a>
                                </h5>
                                <p
                                  style={{
                                    maxHeight: "3.5rem",
                                    fontFamily: "monospace",
                                    overflow: "hidden",
                                  }}
                                  className="text-muted mb-2"
                                >
                                  Company <span className="job-highlight" style={{ fontSize: "17px" }}>{item.owner[0].businessName}</span>
                                </p>

                                <p
                                  style={{
                                    maxHeight: "3.5rem",
                                    fontFamily: "monospace",
                                    overflow: "hidden",
                                  }}
                                  className="text-muted mb-2"
                                >
                                  {item.description}
                                </p>
                                <br />

                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-3 border-top">
                            <ul className="list-inline mb-0">


                              <li
                                className="list-inline-item mr-3 mb-1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Due Date"
                              >

                                Posted - {formatDate(item.createdAt)}
                                <button className="btn job-details-btn" onClick={() => this.props.history.push(
                                  "/profile/jobposts/" + item._id
                                )}> View Details </button>
                              </li>
                              {/* </div> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="pagination pagination-rounded justify-content-center mt-4">
                        {/* <li className="page-item disabled">
                                <a href="#" className="page-link"><i className="mdi mdi-chevron-left"></i></a>
                            </li> */}
                        {
                          this.pagination(this.state.page, this.props.jobPostMeta).map(page => (page))
                        }
                        {/* <li className="page-item">
                                <a href="#" className="page-link"><i className="mdi mdi-chevron-right"></i></a>
                            </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    skills: state.jobPostData.skills,
    jobPosts: state.jobPostData.posts,
    jobPostMeta: state.jobPostData.postsMeta
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSkills: () => dispatch(fetchPlatformSkills()),
    searchPosts: (body) => dispatch(findJobPosts(body))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPosts);
