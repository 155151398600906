import React, { Component } from "react";
// import { fetchJobApplications } from "../store/actions/businessProfileActions";
import { fireNotification } from "../store/actions/notificationsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createBrowserHistory as history } from "history";
import { getUserProfile } from "../store/actions/usersActions";
import UserLanguagesList from "./UserLanguagesList";
import UserTechnologiesList from "./UserTechnologiesList";
import UserProjectList from "./UserProjectList";
import { getLanguages } from "../api/languages";
import { getTechnologies } from "../api/technologies";

class BusinessApplicantDetails extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    applicant:{name:{}, titles:[]},
    programmingLanguages:[],
    technologies:[]
  };

  componentDidMount() {
    this.props.getApplicant(this.props.applicant)
    .then((response) =>{
      this.setState({applicant:this.props.user})
     getLanguages(response.programmingLanguages)
        .then((resp) => {
          this.setState({ programmingLanguages: resp.data })
        })
      getTechnologies(response.technologies)
        .then((resp) => {
          this.setState({ technologies: resp.data })
        })
    })
  }


  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
        <>
          <link rel="stylesheet" href="/assets/css/app.min2.css" />
         <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">

                        

                        {/* <!-- Nav tabs --> */}
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#applicant" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Applicant</span> 
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#messages" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">Messages</span> 
                                </a>
                            </li>
                        </ul>

                        {/* <!-- Tab panes --> */}
                        <div className="tab-content p-3 text-muted">
                            <div className="tab-pane active" id="applicant" role="tabpanel">
                                <div className="">
                                  {/* <!-- Top header --> */}
                                  <div className="row">
                                    <div className="col-xl-3">
                                      {/* avater */}
                                      <div className="card overflow-hidden">
                                        <div className="bg-soft-secondary">
                                          <div className="row">
                                            <div className="col-10">
                                              <div className="text-primary p-3"> </div>
                                            </div>
                                            {/* <!-- <div className="col-5 align-self-end">
                                                                                        <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                                                                                    </div> --> */}
                                          </div>
                                        </div>
                                        <div className="card-body pt-0 pb-0">
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="avatar-xl mb-4" style={{margin:"auto"}}>
                                                    <img
                                                      src={
                                                        this.state.applicant.githubData
                                                          ? this.state.applicant.githubData
                                                              .avatar_url
                                                          : "/assets/images/users/avatar.png"
                                                      }
                                                      alt=""
                                                      className="img-thumbnail rounded-circle"
                                                    />
                                                  </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                  {this.state.applicant ? (
                                                    <RankBadge
                                                      userId={this.state.applicant._id}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </div> */}
                                              </div>
                                              <h5
                                                className="font-size-15 text-truncate"
                                                style={{ color: "#000" }}
                                              >
                                                {this.state.applicant.name.firstName +
                                                  " " +
                                                  this.state.applicant.name.lastName}
                                              </h5>
                                              <p className="font-size-13 text-muted">
                                                {this.state.applicant.email}
                                              </p>
                                              {this.state.applicant.titles.map(
                                                (title, index) => (
                                                  <p
                                                    key={index}
                                                    className="text-muted mb-0 text-truncate text-primary"
                                                    style={{ textTransform: "capitalize" }}
                                                  >
                                                    {title}
                                                  </p>
                                                )
                                              )}

                                              
                                              
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </div>
                                      {/* PROJECTS */}
                                      {/* pass project data objects as a prop */}
                                      {this.state.applicant ? (
                                          <UserProjectList
                                            user={this.state.applicant}
                                            history={history}
                                          />
                                        ) : (
                                            ""
                                      )}
                                    </div>
                                    <div className="col-xl-9">
                                      <div className="card pl-3 pr-1">
                                        <div className="card-body">
                                          <div className="invoice-title">
                                            <h4
                                              className="card-title"
                                              style={{
                                                color: "#000",
                                              }}
                                            >
                                              SUMMARY
                                            </h4>
                                          </div>
                                          <div className="row pl-3 pr-5 pt-3">
                                            <p
                                              className="card-text pr-2"
                                              style={{
                                                color: "#000",
                                              }}
                                            >
                                              {this.state.applicant.userBio
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <UserLanguagesList
                                        userLanguages={
                                          this.state.programmingLanguages
                                            ? this.state.programmingLanguages
                                            : []
                                        }
                                      />
                                      <UserTechnologiesList
                                        userTechnologies={
                                          this.state.technologies
                                            ? this.state.technologies
                                            : []
                                        }
                                      />
                                      {/* <AssesmentsList /> */}
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="messages" role="tabpanel">
                                <div className="w-100 user-chat">
                                  <div className="card">
                                      <div className="p-4 border-bottom ">
                                          <div className="row">
                                              <div className="col-md-4 col-9">
                                                  <h5 className="font-size-15 mb-1">Steven Franklin</h5>
                                                  <p className="text-muted mb-0"><i className="mdi mdi-circle text-success align-middle me-1"></i> Active now</p>
                                              </div>
                                              <div className="col-md-8 col-3">
                                                  
                                              </div>
                                          </div>
                                      </div>
      
      
                                      <div>
                                          <div className="chat-conversation p-3">
                                              <ul className="list-unstyled mb-0" data-simplebar="init" style={{maxHeight: "486px"}}>
                                                <div className="simplebar-wrapper" style={{margin: "0px"}}>
                                                  <div className="simplebar-height-auto-observer-wrapper">
                                                    <div className="simplebar-height-auto-observer"></div>
                                                  </div>
                                                  <div className="simplebar-mask">
                                                    <div className="simplebar-offset" style={{right: "-17px", bottom: "0px"}}>
                                                      <div className="simplebar-content-wrapper" style={{height: "auto", overflow:"hidden scroll"}}>
                                                        <div className="simplebar-content" style={{padding: "0px"}}>
                                                          <li> 
                                                              <div className="chat-day-title">
                                                                  <span className="title">Today</span>
                                                              </div>
                                                          </li>
                                                          
              
                                                          

                                                          
              
                                                          <li>
                                                              <div className="conversation-list">
                                                                  
                                                                  <div className="ctext-wrap">
                                                                      <div className="conversation-name">Steven Franklin</div>
                                                                      <p className="text-muted">&amp; Next meeting tomorrow 10.00AM</p>
                                                                      <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1" style={{color:"#f1b44c"}}></i> 10:06</p>
                                                                  </div>
                                                                  
                                                              </div>
                                                          </li>
              
                                                          <li className=" right">
                                                              <div className="conversation-list">
                                                                  
                                                                  <div className="ctext-wrap">
                                                                      <div className="conversation-name">Henry Wells</div>
                                                                      <p className="text-muted">
                                                                          Wow that's great
                                                                      </p>
              
                                                                      <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1" style={{color:"#f1b44c"}}></i> 10:07</p>
                                                                  </div>
                                                              </div>
                                                          </li>
                                                        </div> 
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="simplebar-placeholder" style={{width: "auto", height: "645px"}}>
                                                  </div>
                                                </div>
                                                <div className="simplebar-track simplebar-horizontal" style={{visibility: "hidden"}}>
                                                  <div className="simplebar-scrollbar" style={{transform: "translate3d(0px, 0px, 0px)", display: "none"}}>
                                                  </div>
                                                </div>
                                                <div className="simplebar-track simplebar-vertical" style={{visibility: "visible"}}>
                                                  <div className="simplebar-scrollbar" style={{height: "366px", transform: "translate3d(0px, 67px, 0px)", display: "block"}}>
                                                  </div>
                                                </div>
                                              </ul>
                                          </div>
                                          <div className="p-3 chat-input-section">
                                              <div className="row">
                                                  <div className="col">
                                                      <div className="position-relative">
                                                          <input type="text" className="form-control chat-input" placeholder="Enter Message..."/>
                                                          {/* <div className="chat-input-links" id="tooltip-container">
                                                              <ul className="list-inline mb-0">
                                                                  <li className="list-inline-item"><a href="#" title="Emoji"><i className="mdi mdi-emoticon-happy-outline"></i></a></li>
                                                                  <li className="list-inline-item"><a href="#" title="Images"><i className="mdi mdi-file-image-outline"></i></a></li>
                                                                  <li className="list-inline-item"><a href="#" title="Add Files"><i className="mdi mdi-file-document-outline"></i></a></li>
                                                              </ul>
                                                          </div> */}
                                                      </div>
                                                  </div>
                                                  <div className="col-auto">
                                                      <button type="submit" className="btn btn-warning btn-rounded chat-send w-md waves-effect waves-light"><span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send"></i></button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
         </div>
        </>     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user:state.admin.userProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getApplicant: (id) => dispatch(getUserProfile(id))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessApplicantDetails));
