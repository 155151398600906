import React, { Component } from "react";

class GithubOAuth extends Component {
  onComponentDidMount() {}
  render() {
    return <div></div>;
  }
}

export default GithubOAuth;
