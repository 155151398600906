import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fireNotification } from "../store/actions/notificationsActions";

import store from "../index";
import Select from "react-select";
import { fetchPlatformSkills, pushNewJobPost, updateJobPostData } from "../store/actions/businessProfileActions";


class BusinessCreateJobPost extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    title: "",
    description: "",
    skills: [],
    deadline: "",
    experience: "",
    intent: "",
    jobType: "",
    availableSkills: [],
    availableExperience: [
      {
        id: 1,
        label: "Entry level",
        value: "Entry level"
      },
      {
        id: 2,
        label: "3 years",
        value: "3 years"
      },
      {
        id: 3,
        label: "4 years",
        value: "4 years"
      },
      {
        id: 4,
        label: "5 years",
        value: "5 years"
      },
      {
        id: 5,
        label: "> 5 years",
        value: "> 5 years"
      }
    ],
    availableJobTypes: [
      {
        id: 1,
        label: "Temporary",
        value: "Temporary"
      },
      {
        id: 2,
        label: "Internship",
        value: "Internship"
      },
      {
        id: 3,
        label: "Freelance",
        value: "Freelance"
      },
      {
        id: 4,
        label: "Permanent",
        value: "Permanent"
      },
      {
        id: 5,
        label: "Full time",
        value: "Full time"
      },
      {
        id: 6,
        label: "Part time",
        value: "Part time"
      },
    ],
    isCreatingJob: false,
  };

  componentDidMount() {
    if (this.props.intent == "edit") {
      this.setState({
        title: this.props.job.title,
        description: this.props.job.description,
        skills: this.props.job.skillObjects,
        deadline: this.props.job.dueDate,
        experience: this.props.job.experienceLevel,
        jobType: this.props.job.jobType
      })
    }
    this.props.getSkills()
      .then(() => {
        this.setState({ availableSkills: this.props.skills })
      })
      .catch(err => {

      })

  }

  handleSkillsChange = (selectedSkills) => {
    this.setState({ skills: selectedSkills })
  }
  handleExperienceChange = (selectedExperience) => {
    this.setState({ experience: selectedExperience.value })
  }
  handleJobTypeChange = (selectedJobType) => {
    this.setState({ jobType: selectedJobType.value })
  }
  submitJobPost = (e) => {
    e.preventDefault()
    this.setState({ isCreatingJob: true })

    let post = {
      title: this.state.title,
      description: this.state.description,
      skills: this.state.skills,
      deadline: this.state.deadline,
      business: this.props.business._id,
      experience: this.state.experience,
      jobType: this.state.jobType
    }

    if (this.props.input == "new") {
      this.props.createJobPost(post)
        .then(() => {
          this.props.navigateToJobListings()
        })
        .catch(err => {
          this.props.dispatchNotification(err)
        })
        .finally(() => {
          this.setState({ isCreatingJob: false })
        })
    } else {

      this.props.patchJobPost(this.props.job._id, post)
        .then(() => {
          this.props.navigateToJobListings()
        })
        .catch(err => {
          this.props.dispatchNotification(err)
        })
        .finally(() => {
          this.setState({ isCreatingJob: false })
        })
    }

  }
  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div className="row">
        <div className="col-12 card">
          {this.state.isCreatingJob ?
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: 0,
                top: 0,
                zIndex: 3,
                height: "100%",
                background: "#fffbfb3d",
                textAlign: "center",
                backdropFilter: "blur(3px)",
              }}
            >
              <p style={{ top: "50%", fontWeight: "bold" }}>
                {this.props.intent == "new" ?
                  "Creating your job post ..."
                  :
                  "Updating your job post ..."
                }
              </p>
            </div>
            :
            ""

          }
          <form className="card-body" onSubmit={e => this.submitJobPost(e)}>
            <div className="form-group">
              <div className="mb-4">
                <label>Job Title</label>
                <input className="form-control" required value={this.state.title} type="text" placeholder="Job title" onChange={(e) => this.setState({ title: e.target.value })} />
              </div>
            </div>
            <div className="form-group mb-2">
              <label className="control-label">Select job type</label>
              <br />

              <Select
                options={this.state.availableJobTypes}
                onChange={this.handleJobTypeChange}

                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select job type"
              />
            </div>
            <div className="form-group">
              <div className="mb-4">
                <label>Job Description</label>
                <textarea className="form-control" style={{ height: "400px" }} required type="text" placeholder="Job description" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}></textarea>
              </div>
            </div>
            <div className="form-group mb-0">
              <label className="control-label">
                Application Deadline
              </label>
              <br />
              <input
                type="datetime-local"
                className="form-control"
                id="date"
                required
                placeholder="Application deadline"
                onChange={e => this.setState({ deadline: e.target.value })}
              />
              <div className="valid-feedback">
                Looks good!
              </div>
              <br />
            </div>
            <div className="form-group mb-2">
              <label className="control-label">Select required experience</label>
              <br />

              <Select
                options={this.state.availableExperience}
                onChange={this.handleExperienceChange}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select required experience"
              />
            </div>
            <div className="form-group mb-2">
              <label className="control-label">Select required skills</label>
              <br />

              <Select
                options={this.state.availableSkills}
                onChange={this.handleSkillsChange}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select required skills"
              />
            </div>
            <div className="form-group mb-0">
              <button className="btn btn-info" type="submit">Submit</button>
            </div>
          </form>

        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobPosts: state.jobPostData.posts,
    skills: state.jobPostData.skills,
    jobPost: state.jobPostData.post
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    createJobPost: (body) => dispatch(pushNewJobPost(body)),
    patchJobPost: (post_id, updateData) => dispatch(updateJobPostData(post_id, updateData)),
    getSkills: () => dispatch(fetchPlatformSkills()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessCreateJobPost));
