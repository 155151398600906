import React, { Component } from "react";
import {
  fetchContest,
  fetchContestRequest,
  getContestAdminleaderboard,
  updateContest,
  fetchAllParticipants,
  addContestRound,
  patchContestRoundSubs,
  patchContestRound,
  getEventEntries,
  updateEventComplianceList,
} from "../../store/actions/contestActions";
import Select from "react-select";
import {
  fetchAllEventTeams,
  fetchAllEliminatedEventTeams,
  changeTeamSuspensionStatus,
  updateEliminationStatus,
  changeTeamComplianceStatus,
} from "../../store/actions/teamActions";
import {
  createJudge,
  updateJudge,
  sendJudgeEmail,
  deleteJudge,
} from "../../store/actions/judgeActions";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import ReactHtmlParser from "react-html-parser";
import { USER_TYPE } from "../../utils/constants";
import dateFormat from "dateformat";
import { ResponsiveRadar } from "@nivo/radar";
import { ResponsiveBarCanvas } from "@nivo/bar";
import { BASE_URL } from "../../api/client";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from "moment";
import MDEditor from "@uiw/react-md-editor";
import ReactQuill from "react-quill";
// import { ImageResize } from 'quill-image-resize-module-react';
import "react-quill/dist/quill.snow.css";
import { formatDate, timeZoneHelper } from "../../utils/utils";
import { fireNotification } from "../../store/actions/notificationsActions";
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, VerticalGridLines } from 'react-vis';
import { fetchComplianceElements } from "../../store/actions/complianceActions";

class AdminContest extends Component {
  constructor(props) {
    super(props);
    // this.createButton = this.createButton.bind(this);
    this.state.submitting = [];
  }

  Context = {
    DESCRIPTION: 1,
    RULES: 2,
    DATA: 3,
    PRIZES: 4,
    LEADERBOARD: 5,
    SUBMISSIONS: 6,
    JUDGES: 7,
    REPORTS: 8,
    FAQS: 9,
    CRITERIA: 10,
    DISCUSSIONS: 11,
    SCHEDULE: 12,
    COMPLIANCE: 13,
  };
  RequestContext = {
    DESCRIPTION: 1,
    RULES: 2,
    DATA: 3,
    PRIZES: 4,
    LEADERBOARD: 5,
    SUBMISSIONS: 6,
    JUDGES: 7,
    REPORTS: 8,
    FAQS: 9,
    CRITERIA: 10,
    SCHEDULE: 11,
  };

  state = {
    data: [
      { x: 0, y: 8 },
      { x: 1, y: 5 },
      { x: 2, y: 4 },
      { x: 3, y: 9 },
      { x: 4, y: 1 },
      { x: 5, y: 7 },
      { x: 6, y: 6 },
      { x: 7, y: 3 },
      { x: 8, y: 2 },
      { x: 9, y: 0 }
    ],
    contest: { judges: [], rounds: [], supportingFiles: { files: [] } },
    tempContest: {},
    teams: [],
    region: "",
    isEditing: false,
    isLoaded: false,
    caughtErr: false,
    context: this.Context.DESCRIPTION,
    accountType: null,
    participants: [],
    participantsData: { columns: [], data: [] },
    teamData: { columns: [], data: [] },
    eliminatedTeamData: { columns: [], data: [] },
    faqs: "",
    leaderboard: [],
    eventState: "",
    focusJudge: {
      name: "",
      title: "",
      email: "",
      description: "",
      image: "",
    },
    isJudgeEditing: false,
    judgeEditIndex: 0,
    criteriaEditIndex: 0,
    submitting: [],
    foucsIndex: 0,
    intent: null,
    datatable: null,
    isFocusDiscussionSet: false,
    isLoadingDiscussionComments: true,
    discussionReply: "",
    discussions: [
      {
        _id: 0,
        title: "Discussion title",
        content:
          '**Contrary to popular belief**, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
        author: { _id: 2, name: { firstName: "Elias", lastName: "Bayona" } },
        createdAt: "5 minutes ago",
        likes: 50,
        dislikes: 50,
        isOpen: true,
        comments: [
          {
            author: {
              _id: 2,
              name: { firstName: "Elias", lastName: "Bayona" },
            },
            createdAt: "5 minutes ago",
            likes: 50,
            dislikes: 50,
            content:
              '**Contrary to popular belief**, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
          },
        ],
      },
    ],
    focusDiscussion: {},
    isJudgesDiscussionSet: true,
    judgesDiscussion: {
      _id: 0,
      title: "Discussion title",
      content:
        '**Contrary to popular belief**, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
      author: { _id: 2, name: { firstName: "Elias", lastName: "Bayona" } },
      createdAt: "5 minutes ago",
      likes: 50,
      dislikes: 50,
      isOpen: true,
      comments: [
        {
          author: { _id: 2, name: { firstName: "Elias", lastName: "Bayona" } },
          createdAt: "5 minutes ago",
          likes: 50,
          dislikes: 50,
          content:
            '**Contrary to popular belief**, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
        },
      ],
    },
    focusCriteria: {},
    focusRound: { evalCriterias: [] },
    roundIdx: 0,
    intent: "",
    criteriaIdx: 0,
    isEditingDescription: false,
    isEditingRules: false,
    isEditingPrizes: false,
    isEditingFAQ: false,
    isEditingReg: false,
    isEditingComplianceList: false,
    judgeEntries: [],
    complianceList: [],
    selectedComplianceList: [],
  };

  handleRegEndDateChange = (e) => {
    var contest = this.state.tempContest;
    contest.registrationEndDate = e;

    this.setState({ tempContest: contest });
  };
  handleRegStartDateChange = (e) => {
    var contest = this.state.tempContest;
    contest.registrationStartDate = e;

    this.setState({ tempContest: contest });
  };

  updateRegDateInfo = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        registrationStartDate: this.state.tempContest.registrationStartDate,
        registrationEndDate: this.state.tempContest.registrationEndDate,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingReg: false });
      });
  };

  changeDiscription = (e) => {
    var contest = this.state.tempContest;
    contest.description = e;

    this.setState({ tempContest: contest });
  };
  updateDescription = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        description: this.state.tempContest.description,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingDescription: false });
      });
  };
  changerRules = (e) => {
    var contest = this.state.tempContest;
    contest.rules = e;

    this.setState({ tempContest: contest });
  };
  updateRules = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        rules: this.state.tempContest.rules,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingRules: false });
      });
  };
  updateComplianceList = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        rules: this.state.tempContest.rules,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingRules: false });
      });
  };

  updateEventCompliance = (e) => {
    e.preventDefault()
    this.props
      .updateEventCompliance(this.state.contest._id, {
        complianceList: this.state.contest.complianceList.map(element => element._id),
      })
      // .then(() => {
      //   this.setState({
      //     contest: this.props.contest,
      //     tempContest: this.props.contest,
      //   });
      // })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingComplianceList: false });
      });
  };
  updateSelectedComplianceList = (selections) => {
    let contest = this.state.contest
    contest.complianceList = selections.map(element => { return { _id: element.id, title: element.label } })
    this.setState({
      contest: contest
    });

  };
  changePrizes = (e) => {
    var contest = this.state.tempContest;
    contest.prizes = e;
    this.setState({ tempContest: contest });
  };
  updatePrizes = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        prizes: this.state.tempContest.prizes,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingPrizes: false });
      });
  };
  changeFAQ = (e) => {
    var contest = this.state.tempContest;
    contest.faqs = e;

    this.setState({ tempContest: contest });
  };
  updateFaqs = () => {
    this.props
      .updateEvent(this.state.tempContest._id, {
        faqs: this.state.tempContest.faqs,
      })
      .then(() => {
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
        });
      })
      .catch(() => { })
      .finally(() => {
        this.setState({ isEditingFAQ: false });
      });
  };

  setReply = (e) => {
    this.setState({ discussionReply: e });
  };
  selectDiscussion = (discussion) => {
    let currentDiscussion = this.state.discussions.filter(
      (x) => x._id === discussion
    )[0];
    this.setState({
      focusDiscussion: currentDiscussion,
      isFocusDiscussionSet: true,
    });
    this.setState({ isLoadingDiscussionComments: false });
  };
  resetState = () => {
    this.setState({
      isLoadingDiscussionComments: true,
      isFocusDiscussionSet: false,
      focusDiscussion: {},
    });
  };

  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  addJudge = () => {
    this.props
      .addJudge(this.state.contest._id, this.state.focusJudge)
      .then(() => {
        let contest = this.state.contest;
        contest.judges.push(this.props.judges);
        this.setState({ contest: contest });
      });
  };

  editJudge = () => {
    //TODO :: Push judge to backend, judge info and event id
    this.props
      .updateJudge(this.state.focusJudge._id, this.state.focusJudge)
      .then(() => {
        let temp = this.state.contest.judges;
        temp[this.state.foucsIndex] = this.props.judge;
        let contest = this.state.contest;
        contest.judges = temp;
        this.setState({ contest: contest, intent: null });
      });
  };

  selectJudge = (judge, idx) => {
    this.setState({
      focusJudge: judge,
      judgeEditIndex: idx,
      isJudgeEditing: true,
    });
  };

  sendEmail = (sessionId) => {
    this.props.sendEmail(sessionId);
  };

  removeJudge = (session) => {
    this.props.removeJudge(session).then(() => {
      let contest = this.state.contest;
      contest.judges = this.props.judges;
      this.setState({ contest: contest });
    });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  toggleRoundSubs = (round) => {
    this.props
      .toogleEventRound({
        roundId: round._id,
        submissionStatus: !round.isClosed,
      })
      .then(() => this.setState({ contest: this.props.contest }));
  };

  /**
   * update state with input change
   *
   * @param  {} doc key value pair with state variable to change as key and variable value as value
   */
  handleInputChange(doc) {
    this.setState(doc);
  }

  handleSkills(users) {
    // console.log(users);
    var data_temp = [];
    users.forEach((user) => {
      data_temp.push(...user.programmingLanguages);
      data_temp.push(...user.technologies);
    });

    var data = [];

    data_temp.forEach((item) => {
      data.push(item.title);
    });

    // console.log(data);
    var compressed = [];
    // make a copy of the input array
    var copy = data.slice(0);

    // first loop goes over every element
    for (var i = 0; i < data.length; i++) {
      var myCount = 0;
      // loop over every element in the copy and see if it's the same
      for (var w = 0; w < copy.length; w++) {
        // console.log(data[w]);
        if (data[i] === copy[w]) {
          // increase amount of times duplicate is found
          myCount++;
          // sets item to undefined
          delete copy[w];
        }
      }

      if (myCount > 0) {
        var a = {};
        a.value = data[i];
        a.count = myCount;
        compressed.push({ id: data[i], label: data[i], value: myCount });
      }
    }
    return compressed;
  }

  componentDidMount() {
    //   check if viewing Event or event request
    const { history } = this.props;
    const user = JSON.parse(Cookie.get("user"));
    this.setState({ accountType: user.accountType });
    let splitPath = history.location.pathname.split("/");
    // console.log(splitPath);
    let cid = splitPath[splitPath.length === 4 ? 3 : 4];
    let region = splitPath[2];
    // console.log(region);
    this.props.getComplianceElemets()
      .then(() => {
        this.setState({
          complianceList: this.props.compalianceElements
        })
      })

    if (region === "hackathon") {
      this.setState({ region: region });
      this.props.getContest(cid).then(() =>
        this.setState({
          contest: this.props.contest,
          tempContest: this.props.contest,
          eventState: this.props.contest.status ? "Approved" : "Suspended",
          isLoaded: true,
          caughtErr: false,
        })
      );

      this.props.fetchEventEntries(cid)
        .then(() => {
          let entries = this.props.judgeEntries.map(entry => {
            if (!entry.team) return null;
            return {
              judge: entry.session.title + " " + entry.session.name,
              team: entry.team.teamName,
              round: entry.round.title,
              criteria: entry.criteria.title,
              score: entry.score
            }
          }).filter((e) => e !== null)
          let entriesCols = [
            {
              name: "Judge",
              selector: "judge",
              sortable: true,
            },
            {
              name: "Team",
              selector: "team",
              sortable: true,
            },
            {
              name: "Round",
              selector: "round",
              sortable: true,
            },
            {
              name: "Criteria",
              selector: "criteria",
              sortable: true,
            },
            {
              name: "Score",
              selector: "score",
              sortable: true,
            },
          ];

          this.setState({ judgeEntries: { data: entries, columns: entriesCols } })
        })
      this.props
        .getLeaderBoard(cid)
        .then(() => this.setState({ leaderboard: this.props.leaderboard }));

      this.props.getEventTeams(cid).then(() => {
        let teamCols = [
          {
            name: "Name",
            selector: "teamName",
            sortable: true,
          },
          {
            name: "Type",
            selector: "type",
            sortable: true,
          },
          {
            name: "Email",
            selector: "email",
            sortable: true,
          },
        ];
        this.setState({
          teams: this.props.teams,
          teamData: { data: this.props.teams, columns: teamCols },
        });
      });
      this.props.getEventTeamsEliminated(cid).then(() => {
        let teamCols = [
          {
            name: "Name",
            selector: "teamName",
            sortable: true,
          },
          {
            name: "Type",
            selector: "type",
            sortable: true,
          },
          {
            name: "Email",
            selector: "mail",
            sortable: true,
          },
        ];
        this.setState({
          teams: this.props.teams,
          eliminatedTeamData: {
            data: this.props.eliminatedTeams,
            columns: teamCols,
          },
        });
      });
    }
    if (splitPath[3] != "edit") {
      this.props.pullParticipants(cid).then(() => {
        let columns = [
          {
            name: "Name",
            selector: "names",
            sortable: true,
          },
          {
            name: "Gender",
            selector: "gender",
            sortable: true,
          },
          {
            name: "Email",
            selector: "email",
            sortable: true,
          },
          {
            name: "Contact Number",
            selector: "contactNumber",
            sortable: true,
          },

          {
            name: "Location",
            selector: "location",
            sortable: true,
          },
          {
            name: "DOB",
            selector: "dob",
            sortable: true,
          },
          {
            name: "Titles",
            selector: "titles",
            sortable: true,
          },
        ];

        let data = this.props.participants.map((user) => ({
          ...user,
          names: user.name
            ? user.name.firstName + " " + user.name.lastName
            : "",
          location: user.location ? user.location : "",
          titles: user.titles.join(", "),
        }));

        this.setState({
          participants: this.props.participants,
          participantsData: { columns: columns, data: data },
        });
      });
    }
    const ctx = this;

    const self = this;
  }

  suspendEvent(eventId) {
    this.setState({ eventState: "Suspending" });
    this.props.updateEvent(eventId, { status: false }).then(() =>
      this.setState({
        contest: this.props.contest,
        isLoaded: true,
        caughtErr: false,
        eventState: "Suspended",
      })
    );
  }
  approveRequest2(eventId) {
    this.setState({ eventState: "Approving" });
    this.props.updateEvent(eventId, { status: true }).then(() =>
      this.setState({
        contest: this.props.contest,
        isLoaded: true,
        caughtErr: false,
        eventState: "Approved",
      })
    );
  }

  approveRequest(eventId) {
    this.setState({ eventState: "Approving" });
    this.props.updateEvent(eventId, { isApproved: true }).then(() => {
      this.setState({ eventState: "Approved" });
    });
  }

  updateSubmissionStatus(status) {
    let event = this.state.contest._id;
    this.props
      .updateSubmissionStatus(event, { isSubmissionOpen: status })
      .then(() => {
        let contest = this.state.contest;
        contest.isSubmissionOpen = status;
        this.setState({ contest: contest });
      });
  }
  eliminateTeam(team, status, idx) {
    this.props.changeEliminationStatus(team, status).then(() => {
      let leaderboard = this.state.leaderboard;
      leaderboard[idx].team.isEliminated = status;
      this.setState({ leaderboard: leaderboard });
    });
  }

  reviveTeam(team) {
    this.props.changeEliminationStatus(team, false);
  }
  suspendTeam(team, status, idx) {
    this.props
      .changeTeamSuspensionStatus(team, status)
      .then(() => {
        let leaderboard = this.state.leaderboard;
        leaderboard[idx].team.isSuspended = status;
        this.setState({ leaderboard: leaderboard });
      })
      .catch((err) => { });
  }

  uploadProfile(event, ctx) {
    let file = event.target.files[0];

    ctx.getBase64(file, (result) => {
      ctx.setState({
        focusJudge: {
          ...this.state.focusJudge,
          image: {
            data: result,
            contentType: result.split(";")[0].split("/")[1],
          },
        },
      });
    });
  }

  updateCriteria(e) {
    e.preventDefault();
    let round = this.state.focusRound;

    if (this.state.intent === "edit") {
      round.evalCriterias[this.state.criteriaIdx] = this.state.focusCriteria;

      this.props
        .updateRound({ roundId: this.state.focusRound._id, data: round })
        .then(() => {
          window.$("#criteriaModal").modal("hide");
          this.setState({
            contest: this.props.contest,
            tempContest: this.props.contest,
            focusCriteria: {},
            focusRound: {},
          });
        })
        .catch((err) => { });
    } else {
      round.evalCriterias.push(this.state.focusCriteria);

      this.props
        .updateRound({ roundId: this.state.focusRound._id, data: round })
        .then(() => {
          window.$("#criteriaModal").modal("hide");
          this.setState({
            contest: this.props.contest,
            tempContest: this.props.contest,
            focusCriteria: {},
            focusRound: {},
          });
        })
        .catch((err) => { });
    }
  }
  submitRound(e) {
    e.preventDefault();
    let contestEnd = moment(this.state.contest.endDate);
    let contestStart = moment(this.state.contest.startDate);
    let roundStart = moment(this.state.focusRound.start);
    let roundEnd = moment(this.state.focusRound.end);

    if (roundStart > contestEnd || roundEnd > contestEnd) {
      this.props.dispatchNotification("Round must be between event start and end dates.")
    } else if (roundStart < contestStart || roundEnd < contestStart) {
      this.props.dispatchNotification("Round must be between event start and end dates.")
    } else {
      if (this.state.intent === "edit") {
        this.props
          .updateRound({
            roundId: this.state.focusRound._id,
            data: { ...this.state.focusRound, start: new Date(this.state.focusRound.start).toUTCString(), end: new Date(this.state.focusRound.end).toUTCString() },
          })
          .then(() => {
            this.setState({
              contest: this.props.contest,
              tempContest: this.props.contest,
              focusRound: {},
              intent: "",
            });
            window.$("#addRoundModal").modal("hide");
          })
          .catch((err) => { });
      } else {
        this.props
          .addRound({
            eventId: this.state.contest._id,
            roundData: { ...this.state.focusRound, start: new Date(this.state.focusRound.start).toUTCString(), end: new Date(this.state.focusRound.end).toUTCString() },
          })
          .then(() => {
            this.setState({
              contest: this.props.contest,
              tempContest: this.props.contest,
              roundData: {},
              focusRound: {},
            });
            window.$("#addRoundModal").modal("hide");
          })
          .catch((err) => { });
      }
    }
  }
  /**
   * check if all required data is loaded
   * render componet if true else render loading animations
   */

  toggleCompliance(team, status, idx) {
    this.props.changeComplianceStatus(team, status).then(() => {
      let leaderboard = this.state.leaderboard;
      leaderboard[idx].team.isCompliant = status;
      this.setState({ leaderboard: leaderboard });
    })
      .catch(err => this.props.dispatchNotification(err));
  }


  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    minHeight: "30vh",
                    background: "rgb(42, 48, 66)",
                    backgroundSize: "cover",
                    backgroundOrigin: "center",
                    padding: "2rem",
                  }}
                >
                  {/* <div style={{position:"absolute", width:"100%", height:"100%", background:"#0000008a", top:0, left:0}}></div> */}

                  <div className="row" style={{ paddingBottom: "2rem" }}>
                    {this.state.accountType == "sponsor" ? (
                      this.state.contest.isApproved ? null : (
                        <span
                          style={{
                            position: "absolute",
                            right: "20px",
                            padding: "5px 10px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          href="#"
                          onClick={() =>
                          (window.location.href =
                            `/${this.state.accountType !== null &&
                              this.state.accountType === USER_TYPE.SPONSOR
                              ? "sponsor"
                              : "admin"
                            }/hackathon/edit/` + this.state.contest._id)
                          }
                          className="badge bg-primary font-size-11 m-1 text-light"
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          &nbsp;Edit
                        </span>
                      )
                    ) : (
                      <>
                        <span
                          style={{
                            position: "absolute",
                            right: "20px",
                            padding: "5px 10px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          href="#"
                          onClick={() =>
                          (window.location.href =
                            `/${this.state.accountType !== null &&
                              this.state.accountType === USER_TYPE.SPONSOR
                              ? "sponsor"
                              : "admin"
                            }/hackathon/edit/` + this.state.contest._id)
                          }
                          className="badge bg-primary font-size-11 m-1 text-light"
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          &nbsp;Edit
                        </span>
                        <span
                          style={{
                            position: "absolute",
                            right: "80px",
                            padding: "5px 10px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          href="#"
                          className="badge bg-primary font-size-11 m-1 text-light"
                        >
                          {this.state.contest.isSubmissionOpen ? (
                            <span
                              onClick={() => this.updateSubmissionStatus(false)}
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Close submission{" "}
                            </span>
                          ) : (
                            <span
                              onClick={() => this.updateSubmissionStatus(true)}
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Open submission
                            </span>
                          )}
                        </span>
                      </>
                    )}

                    {this.state.region === "hackathon" ? (
                      this.state.accountType === "admin" ? (
                        this.state.contest.isApproved ? (
                          this.state.contest.status ? (
                            <span
                              style={{
                                position: "absolute",
                                right: "215px",
                                padding: "5px 10px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              href="#"
                              onClick={() =>
                                this.suspendEvent(this.state.contest._id)
                              }
                              className="badge bg-primary text-light font-size-11 m-1"
                            >
                              <i
                                className="fa fa-low-vision "
                                aria-hidden="true"
                              ></i>
                              &nbsp;{this.state.eventState}
                            </span>
                          ) : (
                            <span
                              style={{
                                position: "absolute",
                                right: "215px",
                                padding: "5px 10px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              href="#"
                              onClick={() =>
                                this.approveRequest2(this.state.contest._id)
                              }
                              className="badge bg-primary text-light font-size-11 m-1"
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              &nbsp;{this.state.eventState}
                            </span>
                          )
                        ) : (
                          <span
                            style={{
                              position: "absolute",
                              right: "215px",
                              padding: "5px 10px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            href="#"
                            onClick={() =>
                              this.approveRequest(this.state.contest._id)
                            }
                            className="badge bg-primary text-light font-size-11 m-1"
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                            &nbsp;{this.state.eventState}
                          </span>
                        )
                      ) : null
                    ) : null}

                    <div className="col-11 mt-4">
                      <h3 style={{ color: "white" }}>
                        {this.state.contest.title}
                      </h3>
                      <p style={{ color: "white", fontSize: "12px" }}>
                        <b className="text-light font-weight-bolder">
                          Starts:{" "}
                        </b>
                        {formatDate(
                          new Date(this.state.contest.startDate),

                        )}
                        <br />{" "}
                        <b className="text-light font-weight-bolder">Ends: </b>
                        {formatDate(
                          new Date(this.state.contest.endDate),

                        )}
                      </p>
                      <div
                        className="mb-4"
                        style={{
                          color: "white",
                          fontWeight: "lighter",
                          WebkitLineClamp: 3,
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {ReactHtmlParser(
                          new String(
                            this.state.contest.shortDescription.replace(
                              /<\/?[^>]+(>|$)/g,
                              ""
                            )
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "-4rem" }}>
                <div className="col-xl-3">
                  <div className="card overflow-hidden">
                    <div className="card-body">
                      <div className="">
                        <ul className="verti-timeline list-unstyled">
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.DESCRIPTION,
                                      })
                                    }
                                  >
                                    Description
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.SCHEDULE,
                                      })
                                    }
                                  >
                                    Schedule
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.RULES,
                                      })
                                    }
                                  >
                                    Rules and Legal
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          {this.state.contest.eventTier == "business" ?
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="media">
                                <div className="media-body">
                                  <div>
                                    <h5
                                      className="font-size-15 text-dark"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          context: this.Context.COMPLIANCE,
                                        })
                                      }
                                    >
                                      Event Compliance Items
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </li>
                            :
                            <></>}
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.CRITERIA,
                                      })
                                    }
                                  >
                                    Evaluation Criteria
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.PRIZES,
                                      })
                                    }
                                  >
                                    Prizes
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.LEADERBOARD,
                                      })
                                    }
                                  >
                                    Leaderboard
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.SUBMISSIONS,
                                      })
                                    }
                                  >
                                    Submissions
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.JUDGES,
                                      })
                                    }
                                  >
                                    Judges/Moderators
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.FAQS,
                                      })
                                    }
                                  >
                                    FAQs
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                          {/* <li className="event-list">
                                                        <div className="event-timeline-dot">
                                                            <i className="bx bx-right-arrow-circle"></i>
                                                        </div>
                                                        <div className="media">
                                                            <div className="media-body">
                                                                <div>
                                                                    <h5 className="font-size-15 text-dark" style={{ cursor: "pointer" }} onClick={() => this.setState({ context: this.Context.DISCUSSIONS })}>Discussions and Anouncements</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li> */}
                          <li className="event-list">
                            <div className="event-timeline-dot">
                              <i className="bx bx-right-arrow-circle"></i>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div>
                                  <h5
                                    className="font-size-15 text-dark"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        context: this.Context.REPORTS,
                                      })
                                    }
                                  >
                                    Event Reports
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9">
                  <div className="card">
                    <div className="card-body" style={{ minHeight: "100vh" }}>
                      {/* info container */}
                      {this.state.region == "hackathon" ? (
                        <div>
                          {this.state.context === this.Context.DESCRIPTION ? (
                            <>
                              <div className="row">
                                <div className="col-8">
                                  <h4
                                    style={{ fontWeight: "bold" }}
                                    className="card-title mb-4"
                                  >
                                    Event Description
                                  </h4>
                                </div>
                                <div className="col-4">
                                  {this.state.isEditingDescription ? (
                                    <>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "100px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() => this.updateDescription()}
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Save
                                      </span>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "20px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() =>
                                          this.setState({
                                            isEditingDescription: false,
                                          })
                                        }
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Cancel
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        padding: "5px 10px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      href="#"
                                      onClick={() =>
                                        this.setState({
                                          isEditingDescription: true,
                                        })
                                      }
                                      className="badge bg-primary font-size-11 m-1 text-light"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;Edit
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  {this.state.isEditingDescription ? (
                                    <ReactQuill
                                      modules={{
                                        toolbar: {
                                          container: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false,
                                                ],
                                              },
                                            ],
                                            ["bold", "italic", "underline"],
                                            [
                                              { list: "ordered" },
                                              { list: "bullet" },
                                            ],
                                            [{ align: [] }],
                                            ["link", "image", "video"],
                                            ["clean"],
                                            [{ color: [] }],
                                          ],
                                          //   handlers: {
                                          //     image: this.imageHandler
                                          //   }
                                        },
                                        // imageResize:{
                                        //     modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                                        // }
                                        // table: true
                                      }}
                                      value={this.state.tempContest.description}
                                      onChange={(e) =>
                                        this.changeDiscription(e)
                                      }
                                    />
                                  ) : (
                                    ReactHtmlParser(
                                      this.state.contest.description
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.context === this.Context.SCHEDULE ? (
                            <>
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-4"
                              >
                                Event Schedule
                              </h4>

                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-4"
                              >
                                Registration
                              </h4>
                              <div
                                className="row mb-2"
                                style={{
                                  borderBottom: "1px gray dashed",
                                }}
                              >
                                {this.state.isEditingReg ? (
                                  <>
                                    <div className="col-4">
                                      <div className="form-group mb-2">
                                        <label className="control-label">
                                          Registration Start Date
                                        </label>
                                        <br />
                                        <input
                                          type="datetime-local"
                                          onChange={(e) =>
                                            this.handleRegStartDateChange(
                                              e.target.value
                                            )
                                          }
                                          className="form-control"
                                          id="date"
                                          value={
                                            this.state.tempContest
                                              .registrationStartDate
                                          }
                                          required
                                          placeholder="Event registration start date"
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group mb-2">
                                        <label className="control-label">
                                          Registration End Date
                                        </label>
                                        <br />
                                        <input
                                          type="datetime-local"
                                          onChange={(e) =>
                                            this.handleRegEndDateChange(
                                              e.target.value
                                            )
                                          }
                                          className="form-control"
                                          id="date"
                                          value={
                                            this.state.tempContest
                                              .registrationEndDate
                                          }
                                          required
                                          placeholder="Event registration start date"
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <span
                                        onClick={() => this.updateRegDateInfo()}
                                        style={{
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                          float: "right",
                                        }}
                                        href="#"
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        &nbsp;Save
                                      </span>
                                    </div>
                                    <div className="col-2">
                                      <span
                                        onClick={() =>
                                          this.setState({
                                            isEditingReg: false,
                                          })
                                        }
                                        style={{
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        &nbsp;Cancel
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-4">
                                      <p>
                                        Starts:{" "}
                                        {moment(
                                          this.state.contest
                                            .registrationStartDate
                                        ).format("MMM DD YYYY HH:mm")}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <p>
                                        Ends:{" "}
                                        {moment(
                                          this.state.contest.registrationEndDate
                                        ).format("MMM DD YYYY HH:mm")}
                                      </p>
                                    </div>
                                    <div className="col-2">
                                      <span
                                        onClick={() =>
                                          this.setState({
                                            isEditingReg: true,
                                          })
                                        }
                                        style={{
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-low-vision "
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Edit
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>

                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-0"
                              >
                                Event rounds
                              </h4>
                              <span
                                data-toggle="modal"
                                data-target="#addRoundModal"
                                style={{
                                  padding: "5px 10px",
                                  color: "black",
                                  cursor: "pointer",
                                  float: "right",
                                }}
                                href="#"
                                className="badge bg-primary font-size-11 m-1 text-light"
                              >
                                &nbsp;Add new round
                              </span>
                              <div
                                id="addRoundModal"
                                className="modal fade bs-example-modal-md"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="mySmallModalLabel"
                                aria-hidden="true"
                                style={{ backgroundColor: "#0000007d" }}
                              >
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0">
                                        Add / Edit Event Round
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <form
                                        onSubmit={(e) => {
                                          this.submitRound(e);
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-12">
                                            <div className="form-group mb-0">
                                              <label className="control-label">
                                                Title
                                              </label>
                                              <br />
                                              <input
                                                type="text"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checkValidity()
                                                  ) {
                                                    this.handleInputChange({
                                                      focusRound: {
                                                        ...this.state
                                                          .focusRound,
                                                        title: e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="judgename"
                                                value={
                                                  this.state.focusRound.title
                                                }
                                                required
                                                placeholder="Round title"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group mb-2">
                                              <label className="control-label">
                                                Start time
                                              </label>
                                              <br />
                                              <input
                                                type="datetime-local"
                                                onChange={(e) =>
                                                  this.handleInputChange({
                                                    focusRound: {
                                                      ...this.state.focusRound,
                                                      start: e.target.value,
                                                    },
                                                  })
                                                }
                                                className="form-control"
                                                id="date"
                                                value={
                                                  this.state.focusRound.start
                                                }
                                                required
                                                placeholder="Round start date"
                                              />
                                              <div className="valid-feedback">
                                                Looks good!
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group mb-2">
                                              <label className="control-label">
                                                End time
                                              </label>
                                              <br />
                                              <input
                                                type="datetime-local"
                                                onChange={(e) =>
                                                  this.handleInputChange({
                                                    focusRound: {
                                                      ...this.state.focusRound,
                                                      end: e.target.value,
                                                    },
                                                  })
                                                }
                                                className="form-control"
                                                id="date"
                                                value={
                                                  this.state.focusRound.end
                                                }
                                                required
                                                placeholder="Round end date"
                                              />
                                              <div className="valid-feedback">
                                                Looks good!
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-12">
                                            <div className="form-group mb-0">
                                              <label className="control-label">
                                                Description
                                              </label>
                                              <br />
                                              <textarea
                                                type="text"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checkValidity()
                                                  ) {
                                                    this.handleInputChange({
                                                      focusRound: {
                                                        ...this.state
                                                          .focusRound,
                                                        description:
                                                          e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="judgename"
                                                value={
                                                  this.state.focusRound
                                                    .description
                                                }
                                                required
                                                placeholder="Round Description"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <button
                                              type="submit"
                                              className="btn btn-primary col-12"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.contest.rounds ? (
                                this.state.contest.rounds.map((round, idx) => (
                                  <div
                                    className="mt-4 mb-2"
                                    key={round._id}
                                    style={{
                                      borderBottom: "1px gray dashed",
                                    }}
                                  >
                                    <p>{round.title}</p>
                                    <p>
                                      Submissions:{" "}
                                      {round.isClosed ? "Closed" : "Open"}
                                    </p>
                                    <div className="row">
                                      <div className="col-4">
                                        <p>
                                          Starts:{" "}
                                          {moment(round.start).format(
                                            "MMM DD YYYY HH:mm"
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p>
                                          Ends:{" "}
                                          {moment(round.end).format(
                                            "MMM DD YYYY HH:mm"
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <span
                                          data-toggle="modal"
                                          data-target="#addRoundModal"
                                          onClick={() =>
                                            this.setState({
                                              focusRound: round,
                                              intent: "edit",
                                            })
                                          }
                                          style={{
                                            padding: "5px 10px",
                                            color: "black",
                                            cursor: "pointer",
                                          }}
                                          href="#"
                                          className="badge bg-primary font-size-11 m-1 text-light"
                                        >
                                          <i
                                            className="fa fa-low-vision "
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;Edit
                                        </span>
                                        <span
                                          onClick={() =>
                                            this.toggleRoundSubs(round)
                                          }
                                          style={{
                                            padding: "5px 10px",
                                            color: "black",
                                            cursor: "pointer",
                                          }}
                                          href="#"
                                          className="badge bg-primary font-size-11 m-1 text-light"
                                        >
                                          <i
                                            className="fa fa-low-vision "
                                            aria-hidden="true"
                                          ></i>
                                          &nbsp;Toggle submissions
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.RULES ? (
                            <>
                              <div className="row">
                                <div className="col-8">
                                  <h4
                                    style={{ fontWeight: "bold" }}
                                    className="card-title mb-4"
                                  >
                                    Rules and Legal
                                  </h4>
                                </div>
                                <div className="col-4">
                                  {this.state.isEditingRules ? (
                                    <>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "100px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() => this.updateRules()}
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Save
                                      </span>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "20px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() =>
                                          this.setState({
                                            isEditingRules: false,
                                          })
                                        }
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Cancel
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        padding: "5px 10px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      href="#"
                                      onClick={() =>
                                        this.setState({
                                          isEditingRules: true,
                                        })
                                      }
                                      className="badge bg-primary font-size-11 m-1 text-light"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;Edit
                                    </span>
                                  )}
                                </div>
                                <div className="col-12">
                                  {this.state.isEditingRules ? (
                                    <ReactQuill
                                      modules={{
                                        toolbar: {
                                          container: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false,
                                                ],
                                              },
                                            ],
                                            ["bold", "italic", "underline"],
                                            [
                                              { list: "ordered" },
                                              { list: "bullet" },
                                            ],
                                            [{ align: [] }],
                                            ["link", "image", "video"],
                                            ["clean"],
                                            [{ color: [] }],
                                          ],
                                          //   handlers: {
                                          //     image: this.imageHandler
                                          //   }
                                        },
                                        // imageResize:{
                                        //     modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                                        // }
                                        // table: true
                                      }}
                                      value={this.state.contest.rules}
                                      onChange={(e) => this.changerRules(e)}
                                    />
                                  ) : (
                                    <>
                                      {ReactHtmlParser(
                                        this.state.contest.rules
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.context === this.Context.COMPLIANCE ? (
                            <>
                              <div className="row">
                                <div className="col-8">
                                  <h4
                                    style={{ fontWeight: "bold" }}
                                    className="card-title mb-4"
                                  >
                                    Event Compliance Items
                                  </h4>
                                </div>
                                <div className="col-4">
                                  <span
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-center"
                                    style={{
                                      position: "absolute",
                                      right: "100px",
                                      padding: "5px 10px",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    href="#"
                                    className="badge bg-primary font-size-11 m-1 text-light"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;Edit
                                  </span>
                                </div>
                                <div className="col-12">
                                  <>
                                    <div className="row mb-4">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className="col-lg-6 col-sm-6 pl-3 pr-3 mt-2 mb-2"
                                      >
                                        <div
                                          data-toggle="modal"
                                          data-target=".bs-example-modal-center"

                                          style={{
                                            height: "4rem",
                                            border: "1px dashed gray",
                                            display: "flex",
                                            padding: "1rem",
                                            borderRadius: ".5rem",
                                            width: "100%",
                                          }}
                                        >
                                          <p
                                            className="text-truncate"
                                            style={{
                                              margin: "auto",
                                              fontSize: "16px",
                                              lineHeight: 2,
                                              color: "gray",
                                            }}
                                          >
                                            Add new
                                          </p>
                                        </div>
                                      </div>
                                      {this.state.contest.complianceList.map((item, index) => (

                                        <div
                                          key={item._id}
                                          className="col-lg-6 col-sm-6 pl-3 pr-3 mt-2 mb-2"
                                        >
                                          <div
                                            key={item._id}

                                            style={{
                                              height: "4rem",
                                              backgroundColor: "#f8f8fb",
                                              display: "flex",
                                              padding: "1rem",
                                              borderRadius: ".5rem",
                                              width: "100%",
                                            }}
                                          >
                                            <p
                                              className="ml-2 text-truncate"
                                              style={{
                                                margin: "auto",
                                                fontSize: "16px",
                                                lineHeight: 2,
                                                color: "#000",
                                              }}
                                            >
                                              {item.title}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                </div>
                              </div>
                              <div
                                className="modal fade bs-example-modal-center"
                                tabIndex="-1"
                                id="compliance-modal"
                                role="dialog"
                                aria-labelledby="mySmallModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      {/* <h5 className="modal-title mt-0">Add a technology</h5> */}
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <form
                                        onSubmit={(e) => this.updateEventCompliance(e)}
                                        className=" needs-validation custom-validation"
                                      >
                                        <div className="mb-4">
                                          <label>Compliance Items: </label>
                                          <div className="d-flex mb-3">
                                            <div className="vw-100 mr-5">
                                              <Select
                                                options={this.state.complianceList}
                                                value={this.state.contest.complianceList.map(element => { return { id: element._id, label: element.title, title: element.title, value: element._id } })}
                                                onChange={this.updateSelectedComplianceList}
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                style={{ width: "-webkit-fill-available" }}
                                                placeholder="Select multiple Compliance Items"
                                              />
                                            </div>
                                          </div>
                                        </div>



                                        <div
                                          className="modal-footer"
                                          style={{ borderTop: "none" }}
                                        >
                                          <button type="submit" className="btn btn-primary">
                                            {this.state.update ? "Update" : "Save"}
                                          </button>
                                          {!this.state.update ? (
                                            ""
                                          ) : (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                this.deleteElement(this.state.id)
                                              }
                                              className="btn btn-secondary"
                                            >
                                              Delete
                                            </button>
                                          )}
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  {/** <!-- /.modal-content --> **/}
                                </div>
                                {/** <!-- /.modal-dialog --> **/}
                              </div>
                              {/** <!-- /.modal --> **/}
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.context ===
                            this.RequestContext.CRITERIA ? (
                            <>
                              <h4 className="card-title mb-4">
                                Evaluation Criteria 1
                              </h4>
                              {this.state.contest.evaluationCriteria.length >
                                0 ? (
                                <div id="accordion">
                                  {this.state.contest.rounds.map(
                                    (round, rIdx) => (
                                      <div className="card mb-1 shadow-none">
                                        <div
                                          className="card-header text-dark"
                                          id="headingOne"
                                          aria-expanded="true"
                                        >
                                          <h6 className="m-0">{round.title}</h6>
                                          <h6
                                            className="m-0 text-dark"
                                            onClick={() => {
                                              this.setState({
                                                focusRound: round,
                                              });
                                            }}
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "10px",
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            data-target="#criteriaModal"
                                          >
                                            Add a new criteria
                                          </h6>
                                        </div>

                                        <div
                                          id="collapseOne"
                                          className="collapse show"
                                          aria-labelledby="headingOne"
                                          data-parent="#accordion"
                                        >
                                          <div className="card-body">
                                            {round.evalCriterias.length > 0 ? (
                                              round.evalCriterias.map(
                                                (criteria, cIdx) => (
                                                  <div
                                                    className="mb-1"
                                                    style={{
                                                      borderBottom:
                                                        "gray 1px dashed",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        position: "absolute",
                                                        right: "20px",
                                                        padding: "5px 10px",
                                                        color: "black",
                                                        cursor: "pointer",
                                                      }}
                                                      href="#"
                                                      className="badge bg-primary font-size-11 m-1 text-light"
                                                      data-toggle="modal"
                                                      data-target="#criteriaModal"
                                                      onClick={() =>
                                                        this.setState({
                                                          focusCriteria:
                                                            criteria,
                                                          focusRound: round,
                                                          criteriaIdx: cIdx,
                                                          roundIdx: rIdx,
                                                          intent: "edit",
                                                        })
                                                      }
                                                    >
                                                      <i
                                                        className="fa fa-pencil"
                                                        aria-hidden="true"
                                                      ></i>
                                                      &nbsp;Edit
                                                    </span>
                                                    <p>
                                                      Title: {criteria.title}
                                                    </p>
                                                    <p>
                                                      Max Score:{" "}
                                                      {criteria.maxScore}
                                                    </p>
                                                    <p>
                                                      Description:{" "}
                                                      {criteria.description}
                                                    </p>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <div
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  margin: "auto",
                                                  padding: "2rem",
                                                }}
                                              >
                                                <div>
                                                  <i
                                                    className="bx bx-cloud-snow"
                                                    style={{
                                                      fontSize: "4rem",
                                                      width: "100%",
                                                      textAlign: "center",
                                                    }}
                                                  ></i>
                                                  <p
                                                    className="mb-5"
                                                    style={{
                                                      fontSize: ".8rem",
                                                      width: "100%",
                                                      textAlign: "center",
                                                      marginTop: ".8rem",
                                                    }}
                                                  >
                                                    no data
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div
                                    id="criteriaModal"
                                    className="modal fade bs-example-modal-center"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="mySmallModalLabel"
                                    aria-hidden="true"
                                    style={{ background: "#00000063" }}
                                  >
                                    <div className="modal-dialog modal-dialog-centered">
                                      <div className="modal-content">
                                        <div className="modal-header">
                                          <h5 className="modal-title mt-0">
                                            Center modal
                                          </h5>
                                          <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">
                                              &times;
                                            </span>
                                          </button>
                                        </div>
                                        <div className="modal-body">
                                          <form
                                            onSubmit={(e) =>
                                              this.updateCriteria(e)
                                            }
                                          >
                                            <div className="form-group mb-0 ">
                                              <label className="control-label">
                                                Title
                                              </label>
                                              <br />
                                              <input
                                                type="text"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checkValidity()
                                                  ) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state
                                                          .focusCriteria,
                                                        title: e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                // id="judgename"
                                                value={
                                                  this.state.focusCriteria.title
                                                }
                                                required
                                                placeholder="Evaluation criteria title"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                            <div className="form-group mb-0">
                                              <label className="control-label">
                                                Max score
                                              </label>
                                              <br />
                                              <input
                                                type="number"
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checkValidity()
                                                  ) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state
                                                          .focusCriteria,
                                                        maxScore:
                                                          e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="judgeTitle"
                                                value={
                                                  this.state.focusCriteria
                                                    .maxScore
                                                }
                                                required
                                                placeholder="Max allowable score"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>

                                            <div className="form-group mb-0">
                                              <label>Description</label>
                                              <br />
                                              <textarea
                                                onChange={(e) => {
                                                  if (
                                                    e.target.checkValidity()
                                                  ) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state
                                                          .focusCriteria,
                                                        description:
                                                          e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="description"
                                                value={
                                                  this.state.focusCriteria
                                                    .description
                                                }
                                                required
                                                placeholder="Some information about the evaluation criteria"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                            <div className="form-group mb-0">
                                              <button
                                                className="btn btn-primary"
                                                type="submit"
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.context === this.Context.DATA ? (
                            <>
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-4"
                              >
                                Supporting Files
                              </h4>
                              {this.state.contest.supportingFiles ? (
                                <>
                                  <p>{this.state.contest.supportingFiles}</p>
                                  {this.state.contest.supportingFiles.files
                                    .length > 0 ? (
                                    <ul>
                                      {this.state.contest.supportingFiles.files.map(
                                        (file) => (
                                          <li>
                                            <a href={file.url}>
                                              {file.filename}
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        margin: "auto",
                                        padding: "10rem",
                                      }}
                                    >
                                      <div>
                                        <i
                                          className="bx bx-cloud-snow"
                                          style={{
                                            fontSize: "4rem",
                                            width: "100%",
                                            textAlign: "center",
                                          }}
                                        ></i>
                                        <p
                                          className="mb-5"
                                          style={{
                                            fontSize: ".8rem",
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: ".8rem",
                                          }}
                                        >
                                          no data
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.PRIZES ? (
                            <>
                              <div className="row">
                                <div className="col-8">
                                  <h4
                                    style={{ fontWeight: "bold" }}
                                    className="card-title mb-4"
                                  >
                                    Prizes
                                  </h4>
                                </div>
                                <div className="col-4">
                                  {this.state.isEditingPrizes ? (
                                    <>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "100px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() => this.updatePrizes()}
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Save
                                      </span>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "20px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() =>
                                          this.setState({
                                            isEditingPrizes: false,
                                          })
                                        }
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Cancel
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        padding: "5px 10px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      href="#"
                                      onClick={() =>
                                        this.setState({
                                          isEditingPrizes: true,
                                        })
                                      }
                                      className="badge bg-primary font-size-11 m-1 text-light"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;Edit
                                    </span>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    {this.state.isEditingPrizes ? (
                                      <ReactQuill
                                        modules={{
                                          toolbar: {
                                            container: [
                                              [
                                                {
                                                  header: [
                                                    1,
                                                    2,
                                                    3,
                                                    4,
                                                    5,
                                                    6,
                                                    false,
                                                  ],
                                                },
                                              ],
                                              ["bold", "italic", "underline"],
                                              [
                                                { list: "ordered" },
                                                { list: "bullet" },
                                              ],
                                              [{ align: [] }],
                                              ["link", "image", "video"],
                                              ["clean"],
                                              [{ color: [] }],
                                            ],
                                            //   handlers: {
                                            //     image: this.imageHandler
                                            //   }
                                          },
                                          // imageResize:{
                                          //     modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                                          // }
                                          // table: true
                                        }}
                                        value={this.state.contest.prizes}
                                        onChange={(e) => this.changePrizes(e)}
                                      />
                                    ) : (
                                      <>
                                        {ReactHtmlParser(
                                          this.state.contest.prizes
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.LEADERBOARD ? (
                            <>
                              <h5
                                style={{ fontWeight: "bold" }}
                                className="font-size-15"
                              >
                                Leaderboard
                              </h5>
                              <div className="table-responsive">
                                {this.state.leaderboard.length > 0 ? (
                                  <table className="table table-centered table-nowrap table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th
                                          scope="col"
                                          style={{ width: "70px" }}
                                        >
                                          #
                                        </th>
                                        <th>Compliance</th>
                                        <th scope="col">Team</th>
                                        <th scope="col">Points</th>
                                        <th scope="col">#Members</th>
                                        <th scope="col">#Requests</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.leaderboard.map(
                                        (team, idx) => (
                                          <tr>
                                            <td>{idx + 1}</td>
                                            <td>{team.isCompliant ? "Compliant" : "Not Compliant"}</td>
                                            <td>
                                              <h5 className="font-size-14 mb-1">
                                                <a
                                                  href="#"
                                                  className="text-dark"
                                                >
                                                  {team.team.teamName}
                                                </a>
                                              </h5>
                                            </td>
                                            {/* <td>{team.members[0]}</td> */}
                                            {/* <td>
                                                                                skote
                                                                    </td> */}

                                            <td>{team.totalScore}</td>
                                            <td>{team.team.members.length}</td>
                                            <td>{team.team.requests.length}</td>
                                            <td style={{ display: "flex" }}>
                                              {team.team.isEliminated ? (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  className="badge bg-primary font-size-11 m-1 text-light"
                                                  onClick={() => {
                                                    this.eliminateTeam(
                                                      team.team._id,
                                                      false,
                                                      idx
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Eliminated
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    this.eliminateTeam(
                                                      team.team._id,
                                                      true,
                                                      idx
                                                    );
                                                  }}
                                                  className="badge bg-primary font-size-11 m-1 text-light"
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Eliminate
                                                </span>
                                              )}
                                              {team.team.isSuspended ? (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  href="#"
                                                  onClick={() =>
                                                    this.suspendTeam(
                                                      team.team._id,
                                                      false,
                                                      idx
                                                    )
                                                  }
                                                  className="badge bg-primary font-size-11 m-1 text-light"
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Revive
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  href="#"
                                                  onClick={() =>
                                                    this.suspendTeam(
                                                      team.team._id,
                                                      true,
                                                      idx
                                                    )
                                                  }
                                                  className="badge bg-danger font-size-11 m-1 text-light"
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Suspend
                                                </span>
                                              )}
                                              {team.team.isCompliant ? (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  href="#"
                                                  onClick={() =>
                                                    this.toggleCompliance(
                                                      team.team._id,
                                                      false,
                                                      idx
                                                    )
                                                  }
                                                  className="badge bg-warning font-size-11 m-1 text-light"
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Compliance
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    padding: "5px 10px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                  }}
                                                  href="#"
                                                  onClick={() =>
                                                    this.toggleCompliance(
                                                      team.team._id,
                                                      true,
                                                      idx
                                                    )
                                                  }
                                                  className="badge bg-primary font-size-11 m-1 text-light"
                                                >
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                  &nbsp;Compliance
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto",
                                      padding: "10rem",
                                    }}
                                  >
                                    <div>
                                      <i
                                        className="bx bx-cloud-snow"
                                        style={{
                                          fontSize: "4rem",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      ></i>
                                      <p
                                        className="mb-5"
                                        style={{
                                          fontSize: ".8rem",
                                          width: "100%",
                                          textAlign: "center",
                                          marginTop: ".8rem",
                                        }}
                                      >
                                        no data
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.SUBMISSIONS ? (
                            <>
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-4"
                              >
                                Submissions
                              </h4>
                              <div className="row">
                                {this.state.contest.teams.length > 0 ? (
                                  this.state.teams.map((team) => (
                                    <div className="col-6">
                                      <div id="accordion">
                                        <div className="card mb-1 shadow-none">
                                          <div
                                            className="card-header"
                                            id="headingOne"
                                            style={
                                              team.submissions.length > 0
                                                ? {
                                                  backgroundColor:
                                                    "#008000ab",
                                                }
                                                : {}
                                            }
                                          >
                                            <a
                                              href={"#a" + team._id}
                                              className="text-dark"
                                              data-toggle="collapse"
                                              aria-expanded="true"
                                              aria-controls={"#" + team._id}
                                            >
                                              <h6
                                                className="m-0"
                                                style={
                                                  team.submissions.length > 0
                                                    ? { color: "white" }
                                                    : {}
                                                }
                                              >
                                                <div className="d-flex mr-5 ">
                                                  <span>
                                                    Team: {team.teamName}
                                                  </span>
                                                  <span className="ml-auto">
                                                    Submissions:{" "}
                                                    {team.submissions.length}
                                                  </span>
                                                </div>

                                                <i
                                                  className="bx bx-chevron-down"
                                                  style={{
                                                    float: "right",
                                                  }}
                                                ></i>
                                              </h6>
                                            </a>
                                          </div>

                                          <div
                                            id={"a" + team._id}
                                            className="collapse"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordion"
                                          >
                                            <div className="card-body">
                                              <div className="table-responsive">
                                                <table className="table table-nowrap table-centered table-hover mb-0">
                                                  <tbody>
                                                    {team.submissions ? (
                                                      team.submissions
                                                        .reverse()
                                                        .map((submission) =>
                                                          submission.type ===
                                                            "s3" ||
                                                            submission.type ===
                                                            "file" ||
                                                            submission.type ===
                                                            "fcs" ? (
                                                            <>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "45px",
                                                                  }}
                                                                >
                                                                  <div className="avatar-sm">
                                                                    <span className=" rounded-circle text-secondary font-size-24">
                                                                      <i className="bx bxs-file-blank"></i>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  <h5 className="font-size-14 mb-1">
                                                                    <a
                                                                      href="#"
                                                                      className="text-dark"
                                                                    >
                                                                      {
                                                                        submission
                                                                          .file
                                                                          .fileName
                                                                      }
                                                                    </a>
                                                                  </h5>
                                                                  <small>
                                                                    {this.bytesToSize(
                                                                      submission
                                                                        .file
                                                                        .fileSize
                                                                    )}
                                                                  </small>
                                                                  <small
                                                                    style={{
                                                                      marginLeft:
                                                                        "2rem",
                                                                    }}
                                                                  >
                                                                    Submission
                                                                    Time:{" "}
                                                                    {formatDate(
                                                                      submission.createdAt
                                                                    )}
                                                                  </small>
                                                                </td>
                                                                <td>
                                                                  <div
                                                                    className="text-center"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={`${BASE_URL}events/submission?fileId=${submission.file._id}`}
                                                                      className="text-dark"
                                                                    >
                                                                      <i className="bx bx-download h3 m-0"></i>
                                                                    </a>
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "45px",
                                                                  }}
                                                                >
                                                                  <div className="avatar-sm">
                                                                    <span className=" rounded-circle text-secondary font-size-24">
                                                                      <i className="bx bxs-file-blank"></i>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  {/* <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{submission.link}</a></h5> */}
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      flexDirection:
                                                                        "column",
                                                                    }}
                                                                  >
                                                                    <small>
                                                                      Submission
                                                                      type: Link
                                                                    </small>
                                                                    <small>
                                                                      Submission
                                                                      Time:{" "}
                                                                      {formatDate(
                                                                        submission.createdAt
                                                                      )}
                                                                    </small>
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  <div
                                                                    className="text-center"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <a
                                                                      href={
                                                                        submission.link
                                                                      }
                                                                      target="_blank" rel="noreferrer"
                                                                      className="text-dark"
                                                                    >
                                                                      <i className="bx bx-link-alt h3 m-0"></i>
                                                                    </a>
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            </>
                                                          )
                                                        )
                                                    ) : (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            <div className="text-center">
                                                              No submissions yet
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto",
                                      padding: "10rem",
                                    }}
                                  >
                                    <div>
                                      <i
                                        className="bx bx-cloud-snow"
                                        style={{
                                          fontSize: "4rem",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      ></i>
                                      <p
                                        className="mb-5"
                                        style={{
                                          fontSize: ".8rem",
                                          width: "100%",
                                          textAlign: "center",
                                          marginTop: ".8rem",
                                        }}
                                      >
                                        no data
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.state.context === this.Context.JUDGES ? (
                            <>
                              <div
                                id="addJudgeModal"
                                className="modal fade bs-example-modal-xl"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="mySmallModalLabel"
                                aria-hidden="true"
                                style={{ backgroundColor: "#0000007d" }}
                              >
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0">
                                        Add Judge
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          window
                                            .$("#addJudgeModal")
                                            .modal("hide");
                                          this.state.intent === "edit"
                                            ? this.editJudge()
                                            : this.addJudge();
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div
                                              className="p-2"
                                              style={{
                                                background: "#bdbdbd4d",
                                              }}
                                            >
                                              <div className="form-group mb-0">
                                                <label className="control-label">
                                                  Full name
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checkValidity()
                                                    ) {
                                                      this.handleInputChange({
                                                        focusJudge: {
                                                          ...this.state
                                                            .focusJudge,
                                                          name: e.target.value,
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  className="form-control"
                                                  id="judgename"
                                                  value={
                                                    this.state.focusJudge.name
                                                  }
                                                  required
                                                  placeholder="Judge full name"
                                                />
                                                <div className="invalid-feedback">
                                                  Looks good!
                                                </div>
                                                <br />
                                              </div>
                                              <div className="form-group mb-0">
                                                <label className="control-label">
                                                  Title/Company
                                                </label>
                                                <br />
                                                <input
                                                  type="text"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checkValidity()
                                                    ) {
                                                      this.handleInputChange({
                                                        focusJudge: {
                                                          ...this.state
                                                            .focusJudge,
                                                          title: e.target.value,
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  className="form-control"
                                                  id="judgeTitle"
                                                  value={
                                                    this.state.focusJudge.title
                                                  }
                                                  required
                                                  placeholder="Judge Title"
                                                />
                                                <div className="invalid-feedback">
                                                  Looks good!
                                                </div>
                                                <br />
                                              </div>
                                              <div className="form-group mb-0">
                                                <label>Email</label>
                                                <br />
                                                <input
                                                  type="text"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checkValidity()
                                                    ) {
                                                      this.handleInputChange({
                                                        focusJudge: {
                                                          ...this.state
                                                            .focusJudge,
                                                          email: e.target.value,
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  className="form-control"
                                                  id="judgename"
                                                  value={
                                                    this.state.focusJudge.email
                                                  }
                                                  required
                                                  placeholder="Judge email"
                                                />
                                                <div className="invalid-feedback">
                                                  Looks good!
                                                </div>
                                                <br />
                                              </div>
                                              <div className="form-group mb-0">
                                                <label>Description</label>
                                                <br />
                                                <textarea
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checkValidity()
                                                    ) {
                                                      this.handleInputChange({
                                                        focusJudge: {
                                                          ...this.state
                                                            .focusJudge,
                                                          description:
                                                            e.target.value,
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  className="form-control"
                                                  id="description"
                                                  value={
                                                    this.state.focusJudge
                                                      .description
                                                  }
                                                  required
                                                  placeholder="Some information about the judge"
                                                />
                                                <div className="invalid-feedback">
                                                  Looks good!
                                                </div>
                                                <br />
                                              </div>
                                              <div className="form-group mb-0">
                                                <label className="control-label">
                                                  Photo
                                                </label>
                                                <br />
                                                <div className="fallback">
                                                  <input
                                                    name="file"
                                                    id="banner"
                                                    type="file"
                                                    onChange={(event) =>
                                                      this.uploadProfile(
                                                        event,
                                                        this
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="invalid-feedback">
                                                  Looks good!
                                                </div>
                                                <br />
                                              </div>
                                              <div className="form-group mb-0">
                                                {this.state.isJudgeEditing ? (
                                                  <>
                                                    <button
                                                      className="btn btn-primary mr-2"
                                                      style={{
                                                        borderColor:
                                                          "rgb(90, 25, 6)",
                                                        background:
                                                          "rgb(90, 25, 6)",
                                                      }}
                                                    >
                                                      Submit
                                                    </button>
                                                    <button
                                                      className="btn btn-primary"
                                                      style={{
                                                        borderColor:
                                                          "rgb(90, 25, 6)",
                                                        background:
                                                          "rgb(90, 25, 6)",
                                                      }}
                                                      onClick={() =>
                                                        this.cancelEdit()
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                  </>
                                                ) : (
                                                  <button
                                                    className="btn btn-primary"
                                                    style={{
                                                      borderColor:
                                                        "rgb(90, 25, 6)",
                                                      background:
                                                        "rgb(90, 25, 6)",
                                                    }}
                                                  >
                                                    Submit
                                                  </button>
                                                )}
                                                <br />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div
                                              className="text-center"
                                              style={{
                                                width: "70%",
                                                margin: "0 auto",
                                              }}
                                            >
                                              {/* center profile image */}
                                              <img
                                                src={
                                                  this.state.focusJudge.image
                                                    ? this.state.focusJudge
                                                      .image.data
                                                    : "/assets/images/users/avatar.png"
                                                }
                                                alt=""
                                                className="rounded-circle avatar-lg mb-1"
                                                style={{
                                                  background:
                                                    "rgb(158 158 158 / 77%)",
                                                  width: "8rem",
                                                  height: "8rem",
                                                  objectFit: "cover",
                                                }}
                                              ></img>
                                              {/* full name */}
                                              <p
                                                style={{
                                                  fontWeight: "bold",
                                                  marginTop: "1rem",
                                                }}
                                              >
                                                {this.state.focusJudge.name}
                                              </p>
                                              {/* title */}
                                              <p>
                                                {this.state.focusJudge.title}
                                              </p>
                                              {/* email */}
                                              <p>
                                                {this.state.focusJudge.email}
                                              </p>
                                              <p>
                                                {
                                                  this.state.focusJudge
                                                    .description
                                                }
                                              </p>

                                              {/* add remove buttons */}
                                              {/* <div className="form-group mb-0">
                                                                                                <button className="mr-1 btn" style={{ color: "#2196F3", fontWeight: "bold" }}> Edit </button>
                                                                                                <button className="btn" style={{ color: "#2196F3", fontWeight: "bold" }}> Remove </button>
                                                                                            </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h5
                                style={{ fontWeight: "bold" }}
                                className="font-size-15"
                              >
                                Judges/Moderators
                              </h5>
                              <span
                                data-toggle="modal"
                                data-target=".bs-example-modal-xl"
                                style={{
                                  padding: "10px 10px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                // onClick={() => { this.eliminateTeam(team._id) }}
                                className="badge bg-primary font-size-11 m-1 text-light"
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;Add Judge
                              </span>
                              <div className="table-responsive">
                                {this.state.contest.judges.length > 0 ? (
                                  <table className="table table-centered table-nowrap table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th scope="col">Action</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.contest.judges.map(
                                        (judge, idx) => (
                                          <tr key={judge._id}>
                                            <td>
                                              <span
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-xl"
                                                style={{
                                                  padding: "10px 10px",
                                                  color: "black",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.setState({
                                                    focusJudge: judge,
                                                    foucsIndex: idx,
                                                    intent: "edit",
                                                  });
                                                }}
                                                className="badge bg-primary font-size-11 m-1 text-light"
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;Edit
                                              </span>
                                              <span
                                                style={{
                                                  padding: "10px 10px",
                                                  color: "black",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.removeJudge(judge._id);
                                                }}
                                                className="badge bg-danger font-size-11 m-1 text-light"
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;Remove
                                              </span>
                                              <span
                                                style={{
                                                  padding: "10px 10px",
                                                  color: "black",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.sendEmail(judge._id);
                                                }}
                                                className="badge bg-primary font-size-11 m-1 text-light"
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;Send Email
                                              </span>
                                            </td>
                                            <td>{judge.name}</td>
                                            <td>{judge.email}</td>
                                            <td>{judge.title}</td>
                                            <td>{judge.description}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto",
                                      padding: "10rem",
                                    }}
                                  >
                                    <div>
                                      <i
                                        className="bx bx-cloud-snow"
                                        style={{
                                          fontSize: "4rem",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      ></i>
                                      <p
                                        className="mb-5"
                                        style={{
                                          fontSize: ".8rem",
                                          width: "100%",
                                          textAlign: "center",
                                          marginTop: ".8rem",
                                        }}
                                      >
                                        no data
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.REPORTS ? (
                            <>
                              <h5
                                style={{ fontWeight: "bold" }}
                                className="font-size-15 text-dark"
                              >
                                Event Reports
                              </h5>
                              {this.state.participants.length > 0 ? (
                                <>
                                  <div className="col-lg-12">
                                    <div className="">
                                      <div className="card-body">
                                        {/* <!-- Nav tabs --> */}
                                        <ul
                                          className="nav nav-tabs nav-tabs-custom nav-justified"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <a
                                              className="nav-link active"
                                              data-toggle="tab"
                                              href="#home1"
                                              role="tab"
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Main Reports
                                              </span>
                                            </a>
                                          </li>

                                          <li className="nav-item">
                                            <a
                                              className="nav-link"
                                              data-toggle="tab"
                                              href="#registeredUsers"
                                              role="tab"
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Registered Users
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className="nav-link"
                                              data-toggle="tab"
                                              href="#registeredTeams"
                                              role="tab"
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Registered Teams
                                              </span>
                                            </a>
                                          </li>
                                          {/* <li className="nav-item">
                                                                                        <a className="nav-link" data-toggle="tab" href="#registeredBusinesses" role="tab">
                                                                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                                                            <span className="d-none d-sm-block">Registered Businesses</span>
                                                                                        </a>
                                                                                    </li> */}
                                          <li className="nav-item">
                                            <a
                                              className="nav-link"
                                              data-toggle="tab"
                                              href="#eliminatedteams"
                                              role="tab"
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Eliminated / Suspended teams
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className="nav-link"
                                              data-toggle="tab"
                                              href="#judgescoring"
                                              role="tab"
                                            >
                                              <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                              </span>
                                              <span className="d-none d-sm-block">
                                                Judge scoring
                                              </span>
                                            </a>
                                          </li>
                                        </ul>

                                        {/* <!-- Tab panes --> */}
                                        <div className="tab-content p-3 text-muted">
                                          <div
                                            className="tab-pane active"
                                            id="home1"
                                            role="tabpanel"
                                          >
                                            <h4>Registered Teams</h4>
                                            <h3
                                              style={{
                                                textAlign: "center",
                                                fontWeight: "100",
                                                fontSize: "4vw",
                                                marginBottom: "3rem",
                                              }}
                                            >
                                              {this.state.teams.length}
                                            </h3>
                                            <h4>Registered Users</h4>
                                            <h3
                                              style={{
                                                textAlign: "center",
                                                fontWeight: "100",
                                                fontSize: "4vw",
                                                marginBottom: "3rem",
                                              }}
                                            >
                                              {this.state.participants.length}
                                            </h3>
                                            <div className="row">
                                              <div className="col-6">
                                                <h4>Male</h4>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                >
                                                  <h3
                                                    style={{
                                                      margin: "2rem auto",
                                                      fontWeight: "100",
                                                      fontSize: "4vw",
                                                    }}
                                                  >
                                                    {
                                                      this.state.participants.filter(
                                                        (user) =>
                                                          user.gender === "Male"
                                                      ).length
                                                    }
                                                  </h3>
                                                  <i
                                                    className="fas fa-male"
                                                    style={{
                                                      fontSize: "5vw",
                                                      float: "right",
                                                      marginTop: "2rem",
                                                    }}
                                                  ></i>
                                                </div>

                                                <h4>Age Distribution</h4>
                                                <h4>Skill Distribution</h4>
                                                <div
                                                  style={{
                                                    width: "350px",
                                                    height: "350px",
                                                  }}
                                                >
                                                  <XYPlot height={300} width={300}>
                                                    <VerticalGridLines />
                                                    <HorizontalGridLines />
                                                    <XAxis />
                                                    <YAxis />
                                                    <LineSeries data={this.state.data} />
                                                  </XYPlot>
                                                  {/* <ResponsiveBarCanvas
                                                    data={this.handleSkills(
                                                      this.state.participants.filter(
                                                        (user) =>
                                                          user.gender === "Male"
                                                      )
                                                    )}
                                                    keys={["value"]}
                                                    indexBy="label"
                                                    maxValue={
                                                      this.state.participants
                                                        .length
                                                    }
                                                    pixelRatio={1}
                                                    padding={0.15}
                                                    innerPadding={0}
                                                    minValue="auto"
                                                    // maxValue="auto"
                                                    margin={{
                                                      top: 70,
                                                      right: 80,
                                                      bottom: 40,
                                                      left: 80,
                                                    }}
                                                    layout="horizontal"
                                                    reverse={false}
                                                    valueScale={{
                                                      type: "linear",
                                                    }}
                                                    indexScale={{
                                                      type: "band",
                                                      round: true,
                                                    }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={null}
                                                    axisLeft={{
                                                      tickSize: 5,
                                                      tickPadding: 5,
                                                      tickRotation: 0,
                                                      legend: "Skill",
                                                      legendPosition: "middle",
                                                      legendOffset: -100,
                                                    }}
                                                    colors={{
                                                      scheme: "nivo",
                                                    }}
                                                    colorBy="id"
                                                    borderWidth={0}
                                                    borderColor={{
                                                      from: "color",
                                                    }}
                                                    enableGridX={true}
                                                    enableGridY={false}
                                                    enableLabel={true}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{
                                                      from: "color",
                                                      modifiers: [
                                                        ["darker", 1.6],
                                                      ],
                                                    }}
                                                    isInteractive={true}
                                                    legends={[]}
                                                    fillOpacity={0.25}
                                                    blendMode="multiply"
                                                    animate={true}
                                                    motionConfig="wobbly"
                                                  /> */}
                                                </div>
                                              </div>
                                              <div className="col-6">
                                                <h4>Female</h4>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <i
                                                    className="fas fa-female"
                                                    style={{
                                                      fontSize: "5vw",
                                                      float: "left",
                                                      marginTop: "2rem",
                                                    }}
                                                  ></i>
                                                  <h3
                                                    style={{
                                                      margin: "2rem auto",
                                                      fontWeight: "100",
                                                      fontSize: "4vw",
                                                    }}
                                                  >
                                                    {
                                                      this.state.participants.filter(
                                                        (user) =>
                                                          user.gender ===
                                                          "Female"
                                                      ).length
                                                    }
                                                  </h3>
                                                </div>

                                                <h4>Age Distribution</h4>
                                                <h4>Skill Distribution</h4>
                                                <div
                                                  style={{
                                                    width: "300px",
                                                    height: "300px",
                                                  }}
                                                >
                                                  <ResponsiveBarCanvas
                                                    data={this.handleSkills(
                                                      this.state.participants.filter(
                                                        (user) =>
                                                          user.gender ===
                                                          "female"
                                                      )
                                                    )}
                                                    keys={["value"]}
                                                    indexBy="label"
                                                    maxValue={
                                                      this.state.participants
                                                        .length
                                                    }
                                                    pixelRatio={1}
                                                    padding={0.15}
                                                    innerPadding={0}
                                                    minValue="auto"
                                                    // maxValue="auto"
                                                    margin={{
                                                      top: 70,
                                                      right: 80,
                                                      bottom: 40,
                                                      left: 80,
                                                    }}
                                                    layout="horizontal"
                                                    reverse={false}
                                                    valueScale={{
                                                      type: "linear",
                                                    }}
                                                    indexScale={{
                                                      type: "band",
                                                      round: true,
                                                    }}
                                                    axisTop={null}
                                                    axisRight={null}
                                                    axisBottom={null}
                                                    axisLeft={{
                                                      tickSize: 5,
                                                      tickPadding: 5,
                                                      tickRotation: 0,
                                                      legend: "Skill",
                                                      legendPosition: "middle",
                                                      legendOffset: -100,
                                                    }}
                                                    colors={{
                                                      scheme: "nivo",
                                                    }}
                                                    colorBy="id"
                                                    borderWidth={0}
                                                    borderColor={{
                                                      from: "color",
                                                    }}
                                                    enableGridX={true}
                                                    enableGridY={false}
                                                    enableLabel={true}
                                                    labelSkipWidth={12}
                                                    labelSkipHeight={12}
                                                    labelTextColor={{
                                                      from: "color",
                                                      modifiers: [
                                                        ["darker", 1.6],
                                                      ],
                                                    }}
                                                    isInteractive={true}
                                                    legends={[]}
                                                    fillOpacity={0.25}
                                                    blendMode="multiply"
                                                    animate={true}
                                                    motionConfig="wobbly"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane"
                                            id="registeredTeams"
                                            role="tabpanel"
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="">
                                                  <div className="">
                                                    <DataTableExtensions
                                                      {...this.state.teamData}
                                                    >
                                                      <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={true}
                                                        pagination
                                                        exportHeaders={true}
                                                        highlightOnHover
                                                        expandableRows
                                                        expandableRowsComponent={
                                                          TeamsExpandedComponent
                                                        }
                                                      />
                                                    </DataTableExtensions>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane"
                                            id="registeredUsers"
                                            role="tabpanel"
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="">
                                                  <div className="">
                                                    <DataTableExtensions
                                                      {...this.state
                                                        .participantsData}
                                                    >
                                                      <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                      />
                                                    </DataTableExtensions>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane"
                                            id="registeredBusinesses"
                                            role="tabpanel"
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="">
                                                  <div className="">
                                                    <DataTableExtensions
                                                      {...this.state
                                                        .participantsData}
                                                    >
                                                      <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                      />
                                                    </DataTableExtensions>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane"
                                            id="eliminatedteams"
                                            role="tabpanel"
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="">
                                                  <div className="">
                                                    <DataTableExtensions
                                                      {...this.state
                                                        .eliminatedTeamData}
                                                    >
                                                      <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                        expandableRows
                                                        expandableRowsComponent={
                                                          TeamsExpandedComponent
                                                        }
                                                      />
                                                    </DataTableExtensions>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane"
                                            id="judgescoring"
                                            role="tabpanel"
                                          >
                                            <div className="row">
                                              <div className="col-12">
                                                <div className="">
                                                  <div className="">
                                                    <DataTableExtensions
                                                      {...this.state
                                                        .judgeEntries}
                                                    >
                                                      <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                      />
                                                    </DataTableExtensions>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.FAQS ? (
                            <>
                              <div className="row">
                                <div className="col-8">
                                  <h4
                                    style={{ fontWeight: "bold" }}
                                    className="card-title mb-4"
                                  >
                                    FAQs
                                  </h4>
                                </div>
                                <div className="col-4">
                                  {this.state.isEditingFAQ ? (
                                    <>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "100px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() => this.updateFaqs()}
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Save
                                      </span>
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "20px",
                                          padding: "5px 10px",
                                          color: "black",
                                          cursor: "pointer",
                                        }}
                                        href="#"
                                        onClick={() =>
                                          this.setState({
                                            isEditingFAQ: false,
                                          })
                                        }
                                        className="badge bg-primary font-size-11 m-1 text-light"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Cancel
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        padding: "5px 10px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      href="#"
                                      onClick={() =>
                                        this.setState({
                                          isEditingFAQ: true,
                                        })
                                      }
                                      className="badge bg-primary font-size-11 m-1 text-light"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;Edit
                                    </span>
                                  )}
                                </div>
                                <div className="col-12">
                                  {this.state.isEditingFAQ ? (
                                    <ReactQuill
                                      modules={{
                                        toolbar: {
                                          container: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false,
                                                ],
                                              },
                                            ],
                                            ["bold", "italic", "underline"],
                                            [
                                              { list: "ordered" },
                                              { list: "bullet" },
                                            ],
                                            [{ align: [] }],
                                            ["link", "image", "video"],
                                            ["clean"],
                                            [{ color: [] }],
                                          ],
                                          //   handlers: {
                                          //     image: this.imageHandler
                                          //   }
                                        },
                                        // imageResize:{
                                        //     modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
                                        // }
                                        // table: true
                                      }}
                                      value={this.state.contest.faqs}
                                      onChange={(e) => this.changeFAQ(e)}
                                    />
                                  ) : (
                                    <>
                                      {ReactHtmlParser(this.state.contest.faqs)}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.Context.DISCUSSIONS ? (
                            <div
                              className="card-body pl-0 pr-0"
                              style={{ border: "none" }}
                            >
                              {/* <!-- Nav tabs --> */}
                              <ul
                                className="pl-0 nav nav-tabs nav-tabs-custom nav-justified"
                                role="tablist"
                              >
                                <li
                                  className="nav-item"
                                  onClick={() => this.resetState()}
                                >
                                  <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#eventboards"
                                    role="tab"
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Event
                                    </span>
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#teamdiscussion"
                                    role="tab"
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Teams
                                    </span>
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#judgesdiscussions"
                                    role="tab"
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="far fa-envelope"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Judges
                                    </span>
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#eventanouncements"
                                    role="tab"
                                  >
                                    <span className="d-block d-sm-none">
                                      <i className="fas fa-cog"></i>
                                    </span>
                                    <span className="d-none d-sm-block">
                                      Anouncements
                                    </span>
                                  </a>
                                </li>
                              </ul>

                              {/* <!-- Tab panes --> */}
                              <div className="tab-content p-3 text-muted">
                                <div
                                  className="tab-pane active"
                                  id="eventboards"
                                  role="tabpanel"
                                >
                                  {this.state.isFocusDiscussionSet ? (
                                    <div className="container">
                                      {/* discussion header */}
                                      <div className="row">
                                        <div>
                                          <h4
                                            className="m-0"
                                            style={{
                                              textTransform: "capitalize",
                                              color: "rgb(73, 80, 87)",
                                            }}
                                          >
                                            {this.state.focusDiscussion.title}
                                          </h4>
                                          <div style={{ display: "flex" }}>
                                            <p className="mr-4 mb-0">
                                              {" "}
                                              <a
                                                href="user profile"
                                                style={{
                                                  color: "rgb(73, 80, 87)",
                                                }}
                                              >
                                                {this.state.focusDiscussion
                                                  .author.name.firstName +
                                                  " " +
                                                  this.state.focusDiscussion
                                                    .author.name.lastName}{" "}
                                              </a>
                                            </p>
                                            <p className="mr-4 mb-0">
                                              {formatDate(
                                                this.state.focusDiscussion
                                                  .createdAt
                                              )}
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {this.state.focusDiscussion.likes}{" "}
                                              <i className="bx bx-like"></i>
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {
                                                this.state.focusDiscussion
                                                  .dislikes
                                              }{" "}
                                              <i className="bx bx-dislike"></i>
                                            </p>
                                            <p className="mr-3 mb-0">
                                              {
                                                this.state.focusDiscussion
                                                  .comments.length
                                              }{" "}
                                              <i className="bx bx-comment-dots"></i>
                                            </p>
                                          </div>
                                          <div className="mt-4">
                                            <MDEditor.Markdown
                                              source={
                                                this.state.focusDiscussion
                                                  .content
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* reply box */}
                                      <div className="row mt-5 mb-5">
                                        <div className="col-lg-8 mr-5 ml-5">
                                          <form>
                                            <div className="container">
                                              <MDEditor
                                                value={
                                                  this.state.discussionReply
                                                }
                                                hideToolbar={true}
                                                preview="edit"
                                                onChange={this.setReply}
                                              />
                                              <button
                                                className="mt-3 btn"
                                                style={{ float: "right" }}
                                              >
                                                Reply
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                      {/* Discussion responses */}
                                      {this.state
                                        .isLoadingDiscussionComments ? (
                                        <>loading comments ...</>
                                      ) : (
                                        <ul className="p-0">
                                          {this.state.focusDiscussion.comments.map(
                                            (comment) => (
                                              <li
                                                className="row mt-3 ml-5 mr-5 pb-3"
                                                style={{
                                                  borderBottom:
                                                    "solid 1px #b9b7b7 ",
                                                }}
                                              >
                                                <div className="avatar-sm m-1">
                                                  {comment.author.githubData ? (
                                                    <img
                                                      className="rounded-circle avatar-sm mr-3"
                                                      src={
                                                        comment.author
                                                          .githubData.avatar_url
                                                      }
                                                      alt="profile picture"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title={
                                                        comment.author
                                                          .githubData
                                                          ? comment.author
                                                            .githubData
                                                            .public_repos +
                                                          " public projects on github"
                                                          : "0 public projects"
                                                      }
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                      style={{
                                                        height: "40px",
                                                        width: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></img>
                                                  ) : (
                                                    <span
                                                      className="avatar-title rounded-circle font-size-16 p-1 mr-3"
                                                      style={{
                                                        backgroundColor:
                                                          "#0a0b0e9e",
                                                        color:
                                                          "#ffffffa3!important",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                    >
                                                      {" "}
                                                      {comment.author.name
                                                        .firstName[0] +
                                                        " " +
                                                        comment.author.name
                                                          .lastName[0]}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: "0.5em",
                                                  }}
                                                >
                                                  <a href="">
                                                    <h5
                                                      className="m-0"
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                        color:
                                                          "rgb(73, 80, 87)",
                                                      }}
                                                    >
                                                      {comment.author.name
                                                        .firstName +
                                                        " " +
                                                        comment.author.name
                                                          .lastName}
                                                    </h5>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p className="mr-4 mb-0">
                                                      {formatDate(
                                                        comment.createdAt
                                                      )}
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.likes}{" "}
                                                      <i className="bx bx-like"></i>
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.dislikes}{" "}
                                                      <i className="bx bx-dislike"></i>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="mt-4">
                                                  <MDEditor.Markdown
                                                    source={comment.content}
                                                  />
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="row mb-3">
                                        <div className="col-12">
                                          <button
                                            className="btn btn-secondary"
                                            data-toggle="modal"
                                            data-target="#startNewDiscussionModal"
                                            style={{
                                              float: "right",
                                              borderRadius: "5px",
                                              padding: "0.47rem 0.75rem",
                                            }}
                                          >
                                            Start a new discussion
                                          </button>
                                          <div
                                            className="modal fade"
                                            id="exampleModal"
                                            tabindex="-1"
                                            aria-labelledby="startNewDiscussionModal"
                                            aria-hidden="true"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h5
                                                    className="modal-title"
                                                    id="startNewDiscussionModal"
                                                  >
                                                    Modal title
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                  >
                                                    <span aria-hidden="true">
                                                      &times;
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="modal-body"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {this.state.discussions.length > 0 ? (
                                        <ul className="pl-4">
                                          {this.state.discussions.map(
                                            (discussion) => (
                                              <li
                                                className="row mb-4 pb-2"
                                                style={{
                                                  borderBottom:
                                                    "solid #b9b7b7 1px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.selectDiscussion(
                                                    discussion._id
                                                  )
                                                }
                                              >
                                                <div className="avatar-sm m-1">
                                                  {discussion.author
                                                    .githubData ? (
                                                    <img
                                                      className="rounded-circle avatar-sm mr-1"
                                                      src={
                                                        discussion.author
                                                          .githubData.avatar_url
                                                      }
                                                      alt="profile picture"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title={
                                                        discussion.author
                                                          .githubData
                                                          ? discussion.author
                                                            .githubData
                                                            .public_repos +
                                                          " public projects on github"
                                                          : "0 public projects"
                                                      }
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                      }
                                                      style={{
                                                        height: "40px",
                                                        width: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></img>
                                                  ) : (
                                                    <span
                                                      className="avatar-title rounded-circle font-size-16 p-1 mr-1"
                                                      style={{
                                                        backgroundColor:
                                                          "#0a0b0e9e",
                                                        color:
                                                          "#ffffffa3!important",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                      }
                                                    >
                                                      {" "}
                                                      {discussion.author.name
                                                        .firstName[0] +
                                                        " " +
                                                        discussion.author.name
                                                          .lastName[0]}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: "0.5em",
                                                  }}
                                                >
                                                  <h5
                                                    className="m-0"
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                      color: "rgb(73, 80, 87)",
                                                    }}
                                                  >
                                                    {discussion.title}
                                                  </h5>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p className="mr-4 mb-0">
                                                      {" "}
                                                      <a
                                                        href="user profile"
                                                        style={{
                                                          color:
                                                            "rgb(73, 80, 87)",
                                                        }}
                                                      >
                                                        {discussion.author.name
                                                          .firstName +
                                                          " " +
                                                          discussion.author.name
                                                            .lastName}{" "}
                                                      </a>
                                                    </p>
                                                    <p className="mr-4 mb-0">
                                                      {formatDate(
                                                        discussion.createdAt
                                                      )}
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {discussion.likes}{" "}
                                                      <i className="bx bx-like"></i>
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {discussion.dislikes}{" "}
                                                      <i className="bx bx-dislike"></i>
                                                    </p>
                                                    <p className="mb-0">
                                                      {
                                                        discussion.comments
                                                          .length
                                                      }{" "}
                                                      <i className="bx bx-comment-dots"></i>{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        <div className="d-inline-flex mx-auto text-info">
                                          No discussions yet
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div
                                  className="tab-pane"
                                  id="teamdiscussion"
                                  role="tabpanel"
                                >
                                  {this.state.isFocusDiscussionSet ? (
                                    <div className="container">
                                      {/* discussion header */}
                                      <div className="row">
                                        <div>
                                          <h4
                                            className="m-0"
                                            style={{
                                              textTransform: "capitalize",
                                              color: "rgb(73, 80, 87)",
                                            }}
                                          >
                                            {this.state.focusDiscussion.title}
                                          </h4>
                                          <div style={{ display: "flex" }}>
                                            <p className="mr-4 mb-0">
                                              {" "}
                                              <a
                                                href="user profile"
                                                style={{
                                                  color: "rgb(73, 80, 87)",
                                                }}
                                              >
                                                {this.state.focusDiscussion
                                                  .author.name.firstName +
                                                  " " +
                                                  this.state.focusDiscussion
                                                    .author.name.lastName}{" "}
                                              </a>
                                            </p>
                                            <p className="mr-4 mb-0">
                                              {formatDate(
                                                this.state.focusDiscussion
                                                  .createdAt
                                              )}
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {this.state.focusDiscussion.likes}{" "}
                                              <i className="bx bx-like"></i>
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {
                                                this.state.focusDiscussion
                                                  .dislikes
                                              }{" "}
                                              <i className="bx bx-dislike"></i>
                                            </p>
                                            <p className="mr-3 mb-0">
                                              {
                                                this.state.focusDiscussion
                                                  .comments.length
                                              }{" "}
                                              <i className="bx bx-comment-dots"></i>
                                            </p>
                                          </div>
                                          <div className="mt-4">
                                            <MDEditor.Markdown
                                              source={
                                                this.state.focusDiscussion
                                                  .content
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* reply box */}
                                      <div className="row mt-5 mb-5">
                                        <div className="col-lg-8 mr-5 ml-5">
                                          <form>
                                            <div className="container">
                                              <MDEditor
                                                value={
                                                  this.state.discussionReply
                                                }
                                                hideToolbar={true}
                                                preview="edit"
                                                onChange={this.setReply}
                                              />
                                              <button
                                                className="mt-3 btn"
                                                style={{ float: "right" }}
                                              >
                                                Reply
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                      {/* Discussion responses */}
                                      {this.state
                                        .isLoadingDiscussionComments ? (
                                        <>loading comments ...</>
                                      ) : (
                                        <ul className="p-0">
                                          {this.state.focusDiscussion.comments.map(
                                            (comment) => (
                                              <li
                                                className="row mt-3 ml-5 mr-5 pb-3"
                                                style={{
                                                  borderBottom:
                                                    "solid 1px #b9b7b7 ",
                                                }}
                                              >
                                                <div className="avatar- msm-1">
                                                  {comment.author.githubData ? (
                                                    <img
                                                      className="rounded-circle avatar-sm mr-3"
                                                      src={
                                                        comment.author
                                                          .githubData.avatar_url
                                                      }
                                                      alt="profile picture"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title={
                                                        comment.author
                                                          .githubData
                                                          ? comment.author
                                                            .githubData
                                                            .public_repos +
                                                          " public projects on github"
                                                          : "0 public projects"
                                                      }
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                      style={{
                                                        height: "40px",
                                                        width: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></img>
                                                  ) : (
                                                    <span
                                                      className="avatar-title rounded-circle font-size-16 p-1 mr-3"
                                                      style={{
                                                        backgroundColor:
                                                          "#0a0b0e9e",
                                                        color:
                                                          "#ffffffa3!important",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                    >
                                                      {" "}
                                                      {comment.author.name
                                                        .firstName[0] +
                                                        " " +
                                                        comment.author.name
                                                          .lastName[0]}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: "0.5em",
                                                  }}
                                                >
                                                  <a href="">
                                                    <h5
                                                      className="m-0"
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                        color:
                                                          "rgb(73, 80, 87)",
                                                      }}
                                                    >
                                                      {comment.author.name
                                                        .firstName +
                                                        " " +
                                                        comment.author.name
                                                          .lastName}
                                                    </h5>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p className="mr-4 mb-0">
                                                      {formatDate(
                                                        comment.createdAt
                                                      )}
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.likes}{" "}
                                                      <i className="bx bx-like"></i>
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.dislikes}{" "}
                                                      <i className="bx bx-dislike"></i>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="mt-4">
                                                  <MDEditor.Markdown
                                                    source={comment.content}
                                                  />
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {this.state.discussions.length > 0 ? (
                                        <ul className="pl-4">
                                          {this.state.discussions.map(
                                            (discussion) => (
                                              <li
                                                className="row mb-4 pb-2"
                                                style={{
                                                  borderBottom:
                                                    "solid #b9b7b7 1px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.selectDiscussion(
                                                    discussion._id
                                                  )
                                                }
                                              >
                                                <div className="avatar-sm m-1">
                                                  {discussion.author
                                                    .githubData ? (
                                                    <img
                                                      className="rounded-circle avatar-sm mr-1"
                                                      src={
                                                        discussion.author
                                                          .githubData.avatar_url
                                                      }
                                                      alt="profile picture"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title={
                                                        discussion.author
                                                          .githubData
                                                          ? discussion.author
                                                            .githubData
                                                            .public_repos +
                                                          " public projects on github"
                                                          : "0 public projects"
                                                      }
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                      }
                                                      style={{
                                                        height: "40px",
                                                        width: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></img>
                                                  ) : (
                                                    <span
                                                      className="avatar-title rounded-circle font-size-16 p-1 mr-1"
                                                      style={{
                                                        backgroundColor:
                                                          "#0a0b0e9e",
                                                        color:
                                                          "#ffffffa3!important",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                      }
                                                    >
                                                      {" "}
                                                      {discussion.author.name
                                                        .firstName[0] +
                                                        " " +
                                                        discussion.author.name
                                                          .lastName[0]}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: "0.5em",
                                                  }}
                                                >
                                                  <h5
                                                    className="m-0"
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                      color: "rgb(73, 80, 87)",
                                                    }}
                                                  >
                                                    {discussion.title}
                                                  </h5>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p className="mr-4 mb-0">
                                                      {" "}
                                                      <a
                                                        href="user profile"
                                                        style={{
                                                          color:
                                                            "rgb(73, 80, 87)",
                                                        }}
                                                      >
                                                        {discussion.author.name
                                                          .firstName +
                                                          " " +
                                                          discussion.author.name
                                                            .lastName}{" "}
                                                      </a>
                                                    </p>
                                                    <p className="mr-4 mb-0">
                                                      {formatDate(
                                                        discussion.createdAt
                                                      )}
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {discussion.likes}{" "}
                                                      <i className="bx bx-like"></i>
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {discussion.dislikes}{" "}
                                                      <i className="bx bx-dislike"></i>
                                                    </p>
                                                    <p className="mb-0">
                                                      {
                                                        discussion.comments
                                                          .length
                                                      }{" "}
                                                      <i className="bx bx-comment-dots"></i>{" "}
                                                    </p>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        <div className="d-inline-flex mx-auto text-info">
                                          No discussions yet
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div
                                  className="tab-pane"
                                  id="judgesdiscussions"
                                  role="tabpanel"
                                >
                                  {this.state.isJudgesDiscussionSet ? (
                                    <div className="container">
                                      {/* discussion header */}
                                      <div className="row">
                                        <div>
                                          <h4
                                            className="m-0"
                                            style={{
                                              textTransform: "capitalize",
                                              color: "rgb(73, 80, 87)",
                                            }}
                                          >
                                            {this.state.judgesDiscussion.title}
                                          </h4>
                                          <div style={{ display: "flex" }}>
                                            <p className="mr-4 mb-0">
                                              {" "}
                                              <a
                                                href="user profile"
                                                style={{
                                                  color: "rgb(73, 80, 87)",
                                                }}
                                              >
                                                {this.state.judgesDiscussion
                                                  .author.name.firstName +
                                                  " " +
                                                  this.state.judgesDiscussion
                                                    .author.name.lastName}{" "}
                                              </a>
                                            </p>
                                            <p className="mr-4 mb-0">
                                              {formatDate(
                                                this.state.judgesDiscussion
                                                  .createdAt
                                              )}
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {
                                                this.state.judgesDiscussion
                                                  .likes
                                              }{" "}
                                              <i className="bx bx-like"></i>
                                            </p>
                                            <p className="mb-0 mr-3">
                                              {
                                                this.state.judgesDiscussion
                                                  .dislikes
                                              }{" "}
                                              <i className="bx bx-dislike"></i>
                                            </p>
                                            <p className="mr-3 mb-0">
                                              {
                                                this.state.judgesDiscussion
                                                  .comments.length
                                              }{" "}
                                              <i className="bx bx-comment-dots"></i>
                                            </p>
                                          </div>
                                          <div className="mt-4">
                                            <MDEditor.Markdown
                                              source={
                                                this.state.judgesDiscussion
                                                  .content
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* reply box */}
                                      <div className="row mt-5 mb-5">
                                        <div className="col-lg-8 mr-5 ml-5">
                                          <form>
                                            <div className="container">
                                              <MDEditor
                                                value={
                                                  this.state.discussionReply
                                                }
                                                hideToolbar={true}
                                                preview="edit"
                                                onChange={this.setReply}
                                              />
                                              <button
                                                className="mt-3 btn"
                                                style={{ float: "right" }}
                                              >
                                                Reply
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                      {/* Discussion responses */}
                                      {this.state
                                        .isLoadingDiscussionComments ? (
                                        <>loading comments ...</>
                                      ) : (
                                        <ul className="p-0">
                                          {this.state.judgesDiscussion.comments.map(
                                            (comment) => (
                                              <li
                                                className="row mt-3 ml-5 mr-5 pb-3"
                                                style={{
                                                  borderBottom:
                                                    "solid 1px #b9b7b7 ",
                                                }}
                                              >
                                                <div className="avatar-sm m-1">
                                                  {comment.author.githubData ? (
                                                    <img
                                                      className="rounded-circle avatar-sm mr-3"
                                                      src={
                                                        comment.author
                                                          .githubData.avatar_url
                                                      }
                                                      alt="profile picture"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title={
                                                        comment.author
                                                          .githubData
                                                          ? comment.author
                                                            .githubData
                                                            .public_repos +
                                                          " public projects on github"
                                                          : "0 public projects"
                                                      }
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                      style={{
                                                        height: "40px",
                                                        width: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></img>
                                                  ) : (
                                                    <span
                                                      className="avatar-title rounded-circle font-size-16 p-1 mr-3"
                                                      style={{
                                                        backgroundColor:
                                                          "#0a0b0e9e",
                                                        color:
                                                          "#ffffffa3!important",
                                                        objectFit: "cover",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                      }
                                                    >
                                                      {" "}
                                                      {comment.author.name
                                                        .firstName[0] +
                                                        " " +
                                                        comment.author.name
                                                          .lastName[0]}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    margin: "auto",
                                                    marginLeft: "0.5em",
                                                  }}
                                                >
                                                  <a href="">
                                                    <h5
                                                      className="m-0"
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                        color:
                                                          "rgb(73, 80, 87)",
                                                      }}
                                                    >
                                                      {comment.author.name
                                                        .firstName +
                                                        " " +
                                                        comment.author.name
                                                          .lastName}
                                                    </h5>
                                                  </a>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <p className="mr-4 mb-0">
                                                      {formatDate(
                                                        comment.createdAt
                                                      )}
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.likes}{" "}
                                                      <i className="bx bx-like"></i>
                                                    </p>
                                                    <p className="mb-0 mr-3">
                                                      {comment.dislikes}{" "}
                                                      <i className="bx bx-dislike"></i>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="mt-4">
                                                  <MDEditor.Markdown
                                                    source={comment.content}
                                                  />
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div
                                  className="tab-pane"
                                  id="eventanouncements"
                                  role="tabpanel"
                                ></div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.state.context ===
                            this.RequestContext.DESCRIPTION ? (
                            <>
                              <h4 className="card-title mb-4">
                                Event Description
                              </h4>
                              {ReactHtmlParser(
                                this.state.contest.longDescription
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context ===
                            this.RequestContext.SCHEDULE ? (
                            <>
                              <h4 className="card-title mb-4">
                                Event Schedule
                              </h4>
                              {ReactHtmlParser(
                                this.state.contest.longDescription
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.RequestContext.RULES ? (
                            <>
                              <h4 className="card-title mb-4">
                                Rules and Legal
                              </h4>
                              {ReactHtmlParser(this.state.contest.rules)}

                              <h4>Legal</h4>
                              {ReactHtmlParser(this.state.contest.legal)}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.RequestContext.DATA ? (
                            <>
                              <h4 className="card-title mb-4">
                                Supporting Files
                              </h4>
                              {this.contest.supportingFiles ? (
                                <>
                                  <p>
                                    {
                                      this.state.contest.supportingFiles
                                        .description
                                    }
                                  </p>
                                  {this.state.contest.supportingFiles.files
                                    .length > 0 ? (
                                    <ul>
                                      {this.state.contest.supportingFiles.files.map(
                                        (file) => (
                                          <li>
                                            <a href={file.url}>
                                              {file.filename}
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>{/* No files */}</>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context ===
                            this.RequestContext.CRITERIA ? (
                            <>
                              <h4 className="card-title mb-4">
                                Evaluation Criteria
                              </h4>
                              {this.state.contest.evaluationCriteria.length >
                                0 ? (
                                this.state.contest.evaluationCriteria.map(
                                  (criteria) => (
                                    <div id="accordion">
                                      <div className="card mb-1 shadow-none">
                                        <div
                                          className="card-header"
                                          id="headingOne"
                                        >
                                          <h6 className="m-0">
                                            <a
                                              href="#collapseOne"
                                              className="text-dark"
                                              data-toggle="collapse"
                                              aria-expanded="true"
                                              aria-controls="collapseOne"
                                            >
                                              Collapsible Group Item #1
                                            </a>
                                          </h6>
                                        </div>

                                        <div
                                          id="collapseOne"
                                          className="collapse show"
                                          aria-labelledby="headingOne"
                                          data-parent="#accordion"
                                        >
                                          <div className="card-body">
                                            Anim pariatur cliche reprehenderit,
                                            enim eiusmod high life accusamus
                                            terry richardson ad squid. 3 wolf
                                            moon officia aute, non cupidatat
                                            skateboard dolor brunch. Food truck
                                            quinoa nesciunt laborum eiusmod.
                                            Brunch 3 wolf moon tempor, sunt
                                            aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda
                                            shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes
                                            anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher
                                            vice lomo. Leggings occaecat craft
                                            beer farm-to-table, raw denim
                                            aesthetic synth nesciunt you
                                            probably haven't heard of them
                                            accusamus labore sustainable VHS.
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card mb-1 shadow-none">
                                        <div
                                          className="card-header"
                                          id="headingTwo"
                                        >
                                          <h6 className="m-0">
                                            <a
                                              href="#collapseTwo"
                                              className="text-dark collapsed"
                                              data-toggle="collapse"
                                              aria-expanded="false"
                                              aria-controls="collapseTwo"
                                            >
                                              Collapsible Group Item #2
                                            </a>
                                          </h6>
                                        </div>
                                        <div
                                          id="collapseTwo"
                                          className="collapse"
                                          aria-labelledby="headingTwo"
                                          data-parent="#accordion"
                                        >
                                          <div className="card-body">
                                            Anim pariatur cliche reprehenderit,
                                            enim eiusmod high life accusamus
                                            terry richardson ad squid. 3 wolf
                                            moon officia aute, non cupidatat
                                            skateboard dolor brunch. Food truck
                                            quinoa nesciunt laborum eiusmod.
                                            Brunch 3 wolf moon tempor, sunt
                                            aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda
                                            shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes
                                            anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher
                                            vice lomo. Leggings occaecat craft
                                            beer farm-to-table, raw denim
                                            aesthetic synth nesciunt you
                                            probably haven't heard of them
                                            accusamus labore sustainable VHS.
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card mb-0 shadow-none">
                                        <div
                                          className="card-header"
                                          id="headingThree"
                                        >
                                          <h6 className="m-0">
                                            <a
                                              href="#collapseThree"
                                              className="text-dark collapsed"
                                              data-toggle="collapse"
                                              aria-expanded="false"
                                              aria-controls="collapseThree"
                                            >
                                              Collapsible Group Item #3
                                            </a>
                                          </h6>
                                        </div>
                                        <div
                                          id="collapseThree"
                                          className="collapse"
                                          aria-labelledby="headingThree"
                                          data-parent="#accordion"
                                        >
                                          <div className="card-body">
                                            Anim pariatur cliche reprehenderit,
                                            enim eiusmod high life accusamus
                                            terry richardson ad squid. 3 wolf
                                            moon officia aute, non cupidatat
                                            skateboard dolor brunch. Food truck
                                            quinoa nesciunt laborum eiusmod.
                                            Brunch 3 wolf moon tempor, sunt
                                            aliqua put a bird on it squid
                                            single-origin coffee nulla assumenda
                                            shoreditch et. Nihil anim keffiyeh
                                            helvetica, craft beer labore wes
                                            anderson cred nesciunt sapiente ea
                                            proident. Ad vegan excepteur butcher
                                            vice lomo. Leggings occaecat craft
                                            beer farm-to-table, raw denim
                                            aesthetic synth nesciunt you
                                            probably haven't heard of them
                                            accusamus labore sustainable VHS.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.RequestContext.PRIZES ? (
                            <>
                              <h4 className="card-title mb-4">Prizes</h4>
                              {ReactHtmlParser(this.state.contest.prizes)}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context ===
                            this.RequestContext.LEADERBOARD ? (
                            <>
                              <h5 className="font-size-15 text-dark">
                                LEADERBOARD
                              </h5>
                              <div className="table-responsive">
                                {this.state.leaderboard.length > 0 ? (
                                  <table className="table table-centered table-nowrap table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        <th
                                          scope="col"
                                          style={{ width: "70px" }}
                                        >
                                          #
                                        </th>
                                        <th scope="col">Team</th>
                                        <th scope="col">Email</th>
                                        {/* <th scope="col">Team</th> */}
                                        <th scope="col">Points</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.leaderboard.map((team) => (
                                        <tr>
                                          <td>{team.rank}</td>
                                          <td>
                                            <h5 className="font-size-14 mb-1">
                                              <a href="#" className="text-dark">
                                                {team.name}
                                              </a>
                                            </h5>
                                          </td>
                                          <td>{team.members[0].email}</td>
                                          {/* <td>
                                                                                    skote
                                                                        </td> */}
                                          <td>{team.points}</td>
                                          <td>
                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                              <li className="list-inline-item px-2">
                                                <a
                                                  key={team._id}
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title="Message"
                                                >
                                                  <i className="bx bx-message-square-dots"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto",
                                      padding: "10rem",
                                    }}
                                  >
                                    <div>
                                      <i
                                        className="bx bx-cloud-snow"
                                        style={{
                                          fontSize: "4rem",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      ></i>
                                      <p
                                        className="mb-5"
                                        style={{
                                          fontSize: ".8rem",
                                          width: "100%",
                                          textAlign: "center",
                                          marginTop: ".8rem",
                                        }}
                                      >
                                        no data
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context ===
                            this.RequestContext.SUBMISSIONS ? (
                            <>
                              <h4
                                style={{ fontWeight: "bold" }}
                                className="card-title mb-4"
                              >
                                Submissions
                              </h4>

                              {this.state.teams.length > 0 ? (
                                this.state.teams.map((team) => (
                                  <>
                                    <p>{team.name}</p>
                                    <div className="table-responsive">
                                      <table className="table table-nowrap table-centered table-hover mb-0">
                                        <tbody>
                                          {team.submissions.length > 0 ? (
                                            team.submissions.map((file) => (
                                              <>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "45px",
                                                    }}
                                                  >
                                                    <div className="avatar-sm">
                                                      <span className=" rounded-circle text-secondary font-size-24">
                                                        <i className="bx bxs-file-blank"></i>
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <h5 className="font-size-14 mb-1">
                                                      <a
                                                        href="#"
                                                        className="text-dark"
                                                      >
                                                        {file.filename}
                                                      </a>
                                                    </h5>
                                                    <small>{file.size}</small>
                                                    <small
                                                      style={{
                                                        marginLeft: "2rem",
                                                      }}
                                                    >
                                                      Submission Time:{" "}
                                                      {file.submissionTime}
                                                    </small>
                                                  </td>
                                                  <td>
                                                    <div className="text-center">
                                                      <a
                                                        href={file.url}
                                                        className="text-dark"
                                                      >
                                                        <i className="bx bx-download h3 m-0"></i>
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            ))
                                          ) : (
                                            <>
                                              <tr>
                                                <td>
                                                  <div className="text-center">
                                                    No submissions yet
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </>
                                ))
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context === this.RequestContext.JUDGES ? (
                            <>
                              <h5 className="font-size-15 text-dark">
                                Judges/Moderators
                              </h5>
                              <div className="table-responsive">
                                {this.state.teams.length > 0 ? (
                                  <table className="table table-centered table-nowrap table-hover">
                                    <thead className="thead-light">
                                      <tr>
                                        {/* <th scope="col" style={{ width: "70px" }}>#</th> */}
                                        <th scope="col">NAME</th>
                                        <th scope="col">Email</th>
                                        {/* <th scope="col">Team</th> */}
                                        {/* <th scope="col">Points</th>
                                                                            <th scope="col">Action</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.contest.map((judge) => (
                                        <tr>
                                          {/* <td>
                                                                                    {team.rank}
                                                                                </td> */}
                                          <td>
                                            <h5 className="font-size-14 mb-1">
                                              <a href="#" className="text-dark">
                                                {judge.name.firstName +
                                                  " " +
                                                  judge.name.lastName}
                                              </a>
                                            </h5>
                                            {/* <p className="text-muted mb-0">{team.name}</p> */}
                                          </td>
                                          <td>{judge.email}</td>
                                          {/* <td>
                                                                                    skote
                                                                        </td> */}
                                          {/* <td>
                                                                                    {team.points}
                                                                                </td> */}
                                          <td>
                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                              <li className="list-inline-item px-2">
                                                <a
                                                  key={judge._id}
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title="Message"
                                                >
                                                  <i className="bx bx-message-square-dots"></i>
                                                </a>
                                              </li>
                                            </ul>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      margin: "auto",
                                      padding: "10rem",
                                    }}
                                  >
                                    <div>
                                      <i
                                        className="bx bx-cloud-snow"
                                        style={{
                                          fontSize: "4rem",
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      ></i>
                                      <p
                                        className="mb-5"
                                        style={{
                                          fontSize: ".8rem",
                                          width: "100%",
                                          textAlign: "center",
                                          marginTop: ".8rem",
                                        }}
                                      >
                                        no data
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.context ===
                            this.RequestContext.REPORTS ? (
                            <>
                              <h5 className="font-size-15 text-dark">
                                Event Reports
                              </h5>
                              <div className="table-responsive">
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "auto",
                                    padding: "10rem",
                                  }}
                                >
                                  <div>
                                    <i
                                      className="bx bx-cloud-snow"
                                      style={{
                                        fontSize: "4rem",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    ></i>
                                    <p
                                      className="mb-5"
                                      style={{
                                        fontSize: ".8rem",
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: ".8rem",
                                      }}
                                    >
                                      no data
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {/* end info container */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contest: state.contestData.contest,
    contestRequest: state.contestData.contestRequest,
    leaderboard: state.contestData.leaderboard,
    teams: state.teamsData.teams,
    eliminatedTeams: state.teamsData.eliminatedTeams,
    participants: state.contestData.participants,
    judges: state.judgeData.judges,
    judge: state.judgeData.judge,
    judgeEntries: state.contestData.judgeEntries,
    compalianceElements: state.complianceData.complianceList.map(element => {
      return {
        id: element._id,
        value: element._id,
        label: element.title
      }
    })
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContest: (cid) => dispatch(fetchContest(cid)),
    getContestRequest: (requestId) => dispatch(fetchContestRequest(requestId)),
    getLeaderBoard: (cid) => dispatch(getContestAdminleaderboard(cid)),
    getEventTeams: (cid) => dispatch(fetchAllEventTeams(cid)),
    getEventTeamsEliminated: (cid) => dispatch(fetchAllEliminatedEventTeams(cid)),
    updateEvent: (cid, body) => dispatch(updateContest(cid, body)),
    // updateContest: (id, data) => dispatch(publishContest(id, data)),
    updateSubmissionStatus: (event, status) => dispatch(updateContest(event, status)),
    changeTeamSuspensionStatus: (teamId, isSuspended) => dispatch(changeTeamSuspensionStatus({ teamId: teamId, status: isSuspended })),
    changeEliminationStatus: (teamId, isEliminated) => dispatch(updateEliminationStatus({ teamId: teamId, isEliminated: isEliminated })),
    pullParticipants: (cid) => dispatch(fetchAllParticipants(cid)),
    addJudge: (cid, judge) => dispatch(createJudge(cid, judge)),
    updateJudge: (cid, judge) => dispatch(updateJudge({ judgeSession: cid, ...judge })),
    sendEmail: (session) => dispatch(sendJudgeEmail(session)),
    removeJudge: (session) => dispatch(deleteJudge(session)),
    addRound: (body) => dispatch(addContestRound(body)),
    updateRound: (body) => dispatch(patchContestRound(body)),
    toogleEventRound: (body) => dispatch(patchContestRoundSubs(body)),
    fetchEventEntries: (event) => dispatch(getEventEntries(event)),
    changeComplianceStatus: (teamId, isCompliant) => dispatch(changeTeamComplianceStatus({ teamId: teamId, isCompliant: isCompliant })),
    getComplianceElemets: () => dispatch(fetchComplianceElements()),
    updateEventCompliance: (eventId, complianceList) => dispatch(updateEventComplianceList(eventId, complianceList)),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContest);

const ExpandedComponent = ({ data }) => (
  <pre>{JSON.stringify(data, null, 2)}</pre>
);

const customStyles = {
  cells: {
    style: {
      minWidth: "200%",
    },
  },
};
const TeamsExpandedComponent = ({ data }) => {
  let columns = [
    {
      name: "Role",
      selector: "role",
      sortable: true,
    },
    {
      name: "Name",
      selector: "names",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: "contactNumber",
      sortable: true,
    },

    {
      name: "Location",
      selector: "location",
      sortable: true,
    },
    {
      name: "DOB",
      selector: "dob",
      sortable: true,
    },
    {
      name: "Titles",
      selector: "titles",
      sortable: true,
    },
  ];

  let info = data.members.map((user) => ({
    ...user,
    names: user.name ? user.name.firstName + " " + user.name.lastName : "",
    location: user.location ? user.location : "",
    titles: user.titles.join(", "),
    role: data.owner == user._id ? "Team leader" : "Member",
  }));
  let data2 = { "data": info, "columns": columns }
  return (
    <DataTableExtensions
      {...data2}
    >
      <DataTable
        columns={columns}
        data={info}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
        exportHeaders={true}
        customStyles={customStyles}
      />
    </DataTableExtensions>
  );
};
