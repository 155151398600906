import React, { Component } from "react";

class EventsSkeleton extends Component {
  render() {
    return (
      <div className="main-content" style={{ width: "100%" }}>
        <div className="page-content" style={{ paddingTop: "10px" }}>
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {/* <h5
                    className="mb-0 font-size-14 font-weight-bolder text-uppercase"
                    style={{ color: "#000" }}
                  >
                    Assessments
                  </h5> */}
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-sm-6 col-md-3 col-xl-3 ">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-sm-6 col-md-3 col-xl-3">
                <div className="">
                  <div className="card-body">
                    <div className="media">

                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "10rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* <!-- end row --> */}
          </div>{" "}
          {/**<!-- container-fluid -->**/}
        </div>
        {/* <!-- End Page-content --> */}

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
              <div className="col-sm-6">
                <div className="text-sm-right d-none d-sm-block">
                  Skills Ranker
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default EventsSkeleton;
