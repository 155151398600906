import {
    client
} from "./client";

export function getTechnologies(techIDs) {
    return client.get("/technologies?technologies=" + techIDs);
}

export function adminGetTechnologies() {
    return client.get("/technologies", {
        // headers: {
        //     session: session
        // }
    });
}

// export function updateUserTechnologies(body) {
//     return client.put("/technologies", body, {
//         // headers: {
//         //     'content-type': 'multipart/form-data'
//         // }
//     });
// }

export function createTech(body) {
    return client.post("/technologies", body, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }); // error 400
}

export function deleteTech(id) {
    return client.delete("/technologies/" + id); //error 404
}

export function updateTech(body) {
    // return client.put("/admin_delete_technology/"+body.id, body); //error 404
    return client.put("/technologies/" + body.id, body.formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }); // error404
}