import React, { useState } from "react";
import Error from "../partials/Error";

import {isNotEmpty } from "../../utils/utils";
// import { Link } from "react-router-dom";
import { setupUserAccount } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Select from "react-select";
import Cookies from "js-cookie";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

function SetupAccount2(props) {
  // const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [gender, setGender] = useState("");
  const [titles, setTitles] = useState([]);
  const [dob, setDob] = useState([]);
  const [identification, setIdentification] = useState("");
  // const [technologies, setTechnologies] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("text");
  // const programmingTechnologies = [
  //   { value: "NodeJS", label: "NodeJS" },
  //   { value: "Bootstrap", label: "Bootstrap" },
  //   { value: "React", label: "React" },
  // ];
  const genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  // TODO :: pull list from backend
  const programmingLanguages = [
    {
      value: "Java",
      label: "Java",
    },
    {
      value: "Javascript",
      label: "Javascript",
    },
    {
      value: "C#",
      label: "C#",
    },
    {
      value: "C++",
      label: "C++",
    },
    {
      value: "CSS",
      label: "CSS",
    },
    {
      value: "HTML",
      label: "HTML",
    },
  ];

  const individualTitles = [
    {
      value: "Developer",
      label: "Developer",
    },
    {
      value: "UI/UX Designer",
      label: "UI/UX Designer",
    },
    {
      value: "Dev Ops",
      label: "Dev Ops",
    },
    {
      value: "Machine Learning",
      label: "Machine Learning",
    },
    {
      value: "Hacker",
      label: "Hacker",
    },
    {
      value: "Hipster",
      label: "Hipster",
    },
  ];

  function validateForm() {
    return (
      // isNotEmpty(bio) &&
      isNotEmpty(location) &&
      isNotEmpty(contactNumber) &&
      (!titles || (titles && titles.length > 0))
      //  &&
      // (!technologies || (technologies && technologies.length > 0)) &&
      // (!languages || (languages && languages.length > 0)
      // )
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      props
        .setupUserAccount({
          userId: JSON.parse(Cookies.get("user"))._id,
          gender: gender.value,
          location,
          dob,
          contactNumber,
          identification,
          titles: titles.map((i) => i.value),
          // technologies: technologies.map(t => t.value),
          // languages: languages.map(l => l.value),
          // titles: titles.map(t => t.value)
        })
        // .then((window.location = "/profile"))
        .catch((err) => {
          console.log(err);
          displayError(
            err.response
              ? err.response.data.message
              : "Failed to setup account. Please try again."
          );
        })
        .finally(setIsLoading(false));
    } else displayError("Please fill out all fields.");
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  function onDateChange(event) {
    var length = event.currentTarget.value.length;
    setDob(event.currentTarget.value)
    setInputType(length == 0 ? 'date' : 'date');
  }

  window.$(document).on("change", "#dob", function (event, ui) {
    var now = new Date();
    var selectedDate = new Date(window.$(this).val());

    if (selectedDate > now) {
      setDob("")
      window.$(this).val(null);
    }
    // else {
    //   dateControler.currentDate = window.$(this).val();
    // }
  });

  return (
    <div className="login-signin-container">
      <div className="login-wrapper col-lg-4 col-md-6 col-sm-10 mx-auto">
        <div
          className="col login-signup-form"
          style={{
            padding: "1.5rem 1.5rem",
            minHeight: "25rem"
          }}
        >
          <span>
            <img
              className=""
              src="./assets/images/skillsranker-grey.png"
              onClick={() => props.history.push("/")}
              alt="skills ranker logo"
              style={{
                cursor: "pointer",
                width: "30px",
                float: "left",
              }}
            />{" "}
          </span>{" "}
          <h2
          >
            Almost there
          </h2>{" "}
          <p> Please setup your account before you can proceed </p>{" "}
          {isLoading ? (
            "Setting up account..."
          ) : (
            <form className="form-2 text-left" onSubmit={handleSubmit}>
              <div> {error !== "" ? <Error error={error} /> : ""} </div>{" "}
              <div className="mt-4">
                <Select
                  style={{ padding: "0 !important" }}
                  options={genders}
                  onChange={setGender}
                  className="basic-multi-select b-0 p-0 form-control"
                  classNamePrefix="gender"
                  placeholder="Gender"
                />
              </div>{" "}
              <div className="mt-4">
                <input
                  type="text"
                  onChange={(event) => setIdentification(event.target.value)}
                  value={identification}
                  className="login-signin-input form-control"
                  id="identification"
                  alt="Please enter your identification"
                  required
                  placeholder="Omang/Passport"
                />
              </div>
              <div className="mt-4">
                <input
                  type={inputType}
                  onFocus={onDateChange}
                  onChange={onDateChange}
                  value={dob}
                  className="login-signin-input form-control"
                  id="omang"
                  alt="Please enter your date of birth"
                  required
                  placeholder="Date of Birth"
                />
              </div>
              <div className="mt-4">
                <Select
                  style={{ padding: "0 !important", zIndex: 10000 }}
                  options={individualTitles}
                  isMulti
                  onChange={setTitles}
                  className="basic-multi-select b-0 p-0 form-control"
                  classNamePrefix="select"
                  placeholder="Title"
                />
              </div>{" "}
              <div className="mt-4">
                <input
                  type="location"
                  onChange={(event) => setLocation(event.target.value)}
                  className="login-signin-input form-control"
                  id="location"
                  required
                  placeholder="City, Town or Village"
                />
              </div>
              <div className="mt-4">
                <IntlTelInput
                  preferredCountries={['bw']}
                  containerClassName="intl-tel-input"
                  inputClassName="login-signin-input form-control"
                  onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, extension) => setContactNumber(fullNumber)}
                  style={{ zIndex: 0 }}
                  type="number"
                  placeholder="Mobile number"
                />
              </div>
              <div className="mt-4 text-center">
                <button
                  className="btn login-btn btn-rounded waves-effect waves-light"
                  type="submit"
                >
                  Take Me To My Account{" "}
                </button>{" "}
              </div>{" "}
            </form>
          )}{" "}
        </div>{" "}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupUserAccount: (userDetails) => dispatch(setupUserAccount(userDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupAccount2);
