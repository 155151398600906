import { client } from "./client";

export function getBusinessProfileService(id) {
  return client.get(`/business?user=${id}`);
}

export function getBusinessProfileById(id) {
  return client.get(`/admin/business?profileId=${id}`);
}

export function getBusinessProfileList(id) {
  return client.get(`/businesses/user?userId=${id}`);
}

export function getAllBusinessProfiles() {
  return client.get(`/businesses`);
}

export function createBusinessProfile(body) {
  return client.post(`/business`, body);
}

export function updateBusinessProfile(body) {
  let profile = body
  delete profile.hackathons
  delete profile.events
  delete profile.jobs
  delete profile.createdBy
  delete profile.isCompliant
  delete profile.isVerified
  delete profile.members
  delete profile.complianceDocuments
  profile["profileId"] = profile._id

  return client.put(`/business`, profile);

}

export function uploadBusinessFile(body) {
  // return client.post(`/business`, body);

  //dummy data

  return Promise.resolve({
    data: [
      {
        businessName: "Business name",
        location: { description: "Business address" },
        createdBy: {},
        industry: "Technology services",
        businessDescription: "Some business description",
        members: [],
        complianceDocuments: [],
        isVerified: false,
        isCompliant: false,
        events: [],
        jobs: [],
        createdAt: "",
        updatedAt: ""
      }
    ]
  })
}

export function checkBusinessCompliance(body) {
  // return client.post(`/business`, body);

  //dummy data

  return Promise.resolve({
    data: [
      {
        businessName: "Business name",
        location: { description: "Business address" },
        createdBy: {},
        industry: "Technology services",
        businessDescription: "Some business description",
        members: [],
        complianceDocuments: [],
        isVerified: false,
        isCompliant: false,
        events: [],
        jobs: [],
        createdAt: "",
        updatedAt: ""
      }
    ]
  })
}

export function getBusinessComplianceTypes(body) {
  // return client.post(`/business`, body);

  //dummy data

  return Promise.resolve({
    data: [
      {
        businessName: "Business name",
        location: { description: "Business address" },
        createdBy: {},
        industry: "Technology services",
        businessDescription: "Some business description",
        members: [],
        complianceDocuments: [],
        isVerified: false,
        isCompliant: false,
        events: [],
        jobs: [],
        createdAt: "",
        updatedAt: ""
      }
    ]
  })
}

export function updateContactPerson(body) {
  return client.put(`/profile_update`, body);
}

export function suspendBusiness(body) {
  return client.put(`/business/status`, body);
}

export function reviveBusiness(body) {
  return client.put(`/business/status`, body);
}

export function sendBusinessInvite(body) {
  return client.post(`/business/invite`, body);
}

export function getBusinessInvite(id) {
  return client.gets(`/business/invites?profileId=${id}`);
}

export function checkBusinessInvite(id) {
  return client.get(`/business/invite?inviteId=${id}`)
}

export function checkBusinessEmail(email) {
  return client.get(`/profile/exists?email=${email}`)
}

export function acceptBusinessInvite(body) {
  return client.put(`/business/invite/respond`, body)
}

export function createJobPost(body) {
  return client.post(`/business/job`, body)
}

export function patchJobPost(body) {
  return client.put(`/business/job`, body)
}

export function getJobBusinessPosts(id) {
  return client.get(`/business/jobs?profileId=${id}`)
}

export function getJobSkills() {
  return client.get(`/skills`)
}

export function searchJobPosts(body) {
  return client.post(`/jobs/search`, body)
}

export function searchUserAppliedJobPosts(body) {
  return client.post(`/jobs/user/search`, body)
}

export function getUserApplicationById(applicant, job) {
  return client.get(`/jobs/application/user?applicant=${applicant}&job=${job}`,)
}

export function getJobPostById(id) {
  return client.get(`/jobs?job=${id}`)
}

export function pushJobApplication(body) {
  return client.post(`/jobs/application`, body)
}

export function applicationCheck(job, applicant) {
  return client.get(`/applicationCheck?job=${job}&applicant=${applicant}`)
}


export function getJobApplicants(job) {
  return client.get(`/business/job/applicants?job=${job}`)
}

export function checkBusiness(identifier) {
  return client.get(`/business/exists?identifier=${identifier}`)
}
