const initState = {
    courses:[],
    course:{},
    courseInfo:{}
};

const courseReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_COURSES":
            return {
                ...state,
                courses: action.course
            };

        case "COURSE_RESPONSE_INFO":
            return {
                ...state,
                courseInfo: {...action.course}
            };

        case "GET_COURSE":
            return {
                ...state,
                course: {...action.course}
            };
        default:
            break;
    }
    return state;
};

export default courseReducer;