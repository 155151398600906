import {
    client
} from "./client";

import store from "../index"


export function newContest(data) {
    return client.post("/events", data);
}

export function getAllContests(userId) {
    return client.get("/events" + (userId ? `?userId=${userId}` : ""), {
        // headers: {
        //     session: session
        // }
    });

    // return ([
    //     {
    //         id: "1",
    //         title: "Some very awsome event title",
    //         date: "Sat 3rd Nov 2020 - Sun 4th Nov 2020",
    //         type: "",
    //         sponsor: "",
    //         author: "",
    //         description: "",
    //         summary: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    //         supportingFiles: [{}],
    //         banner: "/assets/images/event2.jpeg",
    //         prizes: "",
    //         rules: "",
    //         expectedTurnOut: 0,
    //         tags: ["Web development", "Machine learning", "DevOps"],
    //         status: "Ended",
    //     },
    //     {
    //         id: "2",
    //         title: "Some very awsome event title",
    //         date: "Sat 3rd Nov 2020 - Sun 4th Nov 2020",
    //         type: "",
    //         sponsor: "",
    //         author: "",
    //         description: "",
    //         summary: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
    //         supportingFiles: [{}],
    //         banner: "/assets/images/isea2020.jpg",
    //         prizes: "",
    //         rules: "",
    //         expectedTurnOut: 0,
    //         tags: ["Web development", "Machine learning", "DevOps"],
    //         status: "New"
    //     }
    // ])
}

export function getContest(cid) {
    return client.get("/event/" + cid);
}

export function updateEvent(data) {
    return client.put("/events", data);
}
export function createContestRound(data){
    return client.post("/event/round", data)
}
export function updateContestRound(data){
    return client.put("/event/round", data)
}
export function removeContestRound(data){
    return client.delete("/event/round", data)
}

export function updateContestRoundSubmissions(data){
    return client.put("/event/round/submission", data)
}
export function removeContest(cid) {
    return client.delete("/events/" + cid);
}

export function isRegistered(body) { 
    if(body.isBusiness){
        return client.get(`/events/verify?eventId=${body.eventId}&businessProfileId=${body.userId}`);
    }else{
        return client.get(`/events/verify?eventId=${body.eventId}&userId=${body.userId}`);
    }
    
}

export function getContestParticipants(cid) {
    return client.get("/events/participants?eventId=" + cid);
}

export function getEventLeaderboard(cid) {
    return client.get("/events/teams/leaderboard?eventId=" + cid);
}

export function getEventAdminLeaderboard(cid) {
    return client.get("/events/teams/admin/leaderboard?eventId=" + cid);
}



export function newContestRequest(data) {
    return client.post("/events/requests", data);
}

export function getContestRequests(userId) {
    return client.get("/events/requests" + (userId ? `?userId=${userId}` : ""));
}

export function getEventRequest(requestId) {
    return client.get("/events/requests/" + requestId);
}

export function updateEventRequest() {
    return client.put("/events/requests");
}

export function approveContestRequest() {
    return client.put("/events/requests");
}

export function submitFile(body) {
    let options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            store.dispatch({
                type: "UPLOAD_PROGRESS_EVENT",
                data: { "percent": percent, "total": total, "uploaded": loaded }

            });
        }
    }
    return client.post("/events/submission", body, options);
}

export function fetchEventEntries(event) {
    return client.get(`/entries/event?eventId=${event}`);
}

export function fetchJudgeEventEntries(event, judge) {
    return client.get(`/entries/event/judge?eventId=${event}&&judge=${judge}`);
}

export function updateEventCompliance(event, complianceBody) {
    console.log(complianceBody)
    return client.put(`/event/compliance`,{eventId:event, complianceData:complianceBody});
}

