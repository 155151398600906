import React, { Component } from "react";
import { connect } from "react-redux";
// import {
//   fetchAllAssesments,
//   fetchCompletedAssessments,
// } from "../../store/actions/assesmentsActions";
// import BadgeHorizontal from "../../components/BadgeHorizontal";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Cookie from "js-cookie";
// import { getLanguageIcon, timeZoneHelper } from "../../utils/utils";
// import { getLanguages } from "../../api/languages";
// import { getTechnologies } from "../../api/technologies";
import globe from "../../../src/globe.svg";
import { findUserApplicationById } from "../../store/actions/businessProfileActions";
import moment from "moment";

class UserJobDetails extends Component {
  state = {
    isLoaded: false,
    jobApplication: {}
  };

  componentDidMount() {
    if (this.props.location.pathname.split("/").length > 0) {
      let jobId = this.props.location.pathname.split("/")[3]
      let user = JSON.parse(Cookie.get("user"))
      this.props.getJobApplication(user._id, jobId)
        .then(() => {
          this.setState({ jobApplication: this.props.jobApplication });
        })
        .catch(err => {
          this.setState({ isAvailable: false })
        })
        .finally(() => {
          this.setState({ isLoaded: true, user: user })
        })


    }


  }





  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div style={{ position: "fixed", width: "100%", height: "100%" }}>
            <div
              style={{
                backgroundImage: "url(" + globe + ")",
                transform: "translate(-50%, -50%) scale(1)",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transition: "transform .15s ease-out 0s",
                transform: "translate(-50%,-50%)",
                transformOrigin: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: " cover",
                willChange: "transform",
              }}
            ></div>
          </div>
          <div className="page-content pt-0">
            <div className="">
              {/* <!-- start page title --> */}
              <link rel="stylesheet" href="/assets/css/jobs.css" />
              <link rel="stylesheet" href="/assets/css/app.min2.css" />
              {/* header */}
              <div className="row mt-2 pt-5 pb-5" style={{ background: "#795548", color: "white" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <div className="p-1" style={{ width: "200px", height: "150px", backgroundColor: "white", borderRadius: "3%" }}>
                        <img
                          src="/assets/landing-page/images/bih_logo_colored.png"
                          alt="logo"
                          style={{ width: "100%", opacity: "100%", transform: "translateY(15%)" }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <p className="job-type mb-0 mt-3 jobs-h4-regular">{this.state.jobApplication.jobPost.jobType}</p>
                      <h4 className="mt-1 jobs-h4-semiBold" style={{ color: "white" }}>{this.state.jobApplication.jobPost.title}</h4>
                      <p className="mb-1 jobs-h4-regular">Posted {moment(this.state.jobApplication.jobPost.createdAt).format("D MMM YYYY")} by <span className="job-highlight " style={{ fontSize: "17px" }}>{this.state.jobApplication.business.businessName}</span></p>
                      <p className="jobs-h4-regular">Applied on {moment(this.state.jobApplication.createdAt).format("D MMM YYYY HH:mm")}</p>
                    </div>
                    <div className="col-sm-10 col-xs-10 col-xl-3 col-lg-3 col-md-3 ">
                      <button className="btn report-btn mt-4" data-toggle="modal" data-target="#report-modal">Report Issue <i className="bx bx-shield-quarter ml-2 font-size-16 align-middle mr-2"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              {/* body */}
              <div className="row">
                <div className="container-fluid pt-5">
                  <div className="row">
                    <div className="col-md-2">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <a
                          className="nav-link mb-2 active"
                          style={{
                            borderBottom: "solid 1px rgb(172 175 177)",
                            borderRadius: "0px",
                          }}
                          id="v-pills-details-tab"
                          data-toggle="pill"
                          href="#v-pills-details-content"
                          role="tab"
                          aria-controls="v-pills-details-content"
                          aria-selected="true"
                        >
                          Job Details
                        </a>
                        <a
                          className="nav-link mb-2"
                          style={{
                            borderBottom: "solid 1px rgb(172 175 177)",
                            borderRadius: "0px",
                          }}
                          id="v-pills-messages-tab"
                          data-toggle="pill"
                          href="#v-pills-messages-content"
                          role="tab"
                          aria-controls="v-pills-messages-content"
                          aria-selected="false"
                        >
                          Messages
                        </a>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div
                        className="tab-content text-muted  mt-md-0"
                        id="v-pills-tabContent"
                        style={{
                          borderLeft: "solid 1px rgb(172 175 177)",
                          padding: "0rem 1rem 1rem 1rem",
                          minWidth: "70vw",
                        }}
                      >
                        <div
                          className="tab-pane active show fade"
                          id="v-pills-details-content"
                          role="tabpanel"
                          aria-labelledby="v-pills-details-ta"
                        >
                          <div className="container pt-1">
                            <div className="row">
                              <div className="col-12">
                                <h2 className="mb-5">Job Description</h2>
                                <p style={{ fontSize: "10.5pt", lineHeight: "2em", textAlign: "justify" }}>
                                  {this.state.jobApplication.jobPost.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-messages-content"
                          role="tabpanel"
                          aria-labelledby="v-pills-messages-content"
                        >
                          <div className="container pt-1">
                            <div className="w-100 user-chat">
                              <div className="card">
                                <div className="p-4 border-bottom ">
                                  <div className="row">
                                    <div className="col-md-4 col-9">
                                      <h5 className="font-size-15 mb-1">Steven Franklin</h5>
                                      <p className="text-muted mb-0"><i className="mdi mdi-circle text-success align-middle me-1"></i> Active now</p>
                                    </div>
                                    <div className="col-md-8 col-3">

                                    </div>
                                  </div>
                                </div>


                                <div>
                                  <div className="chat-conversation p-3">
                                    <ul className="list-unstyled mb-0" data-simplebar="init" style={{ maxHeight: "486px" }}>
                                      <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                                        <div className="simplebar-height-auto-observer-wrapper">
                                          <div className="simplebar-height-auto-observer"></div>
                                        </div>
                                        <div className="simplebar-mask">
                                          <div className="simplebar-offset" style={{ right: "-17px", bottom: "0px" }}>
                                            <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden scroll" }}>
                                              <div className="simplebar-content" style={{ padding: "0px" }}>
                                                <li>
                                                  <div className="chat-day-title">
                                                    <span className="title">Today</span>
                                                  </div>
                                                </li>






                                                <li>
                                                  <div className="conversation-list">

                                                    <div className="ctext-wrap">
                                                      <div className="conversation-name">Steven Franklin</div>
                                                      <p className="text-muted">&amp; Next meeting tomorrow 10.00AM</p>
                                                      <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1" style={{ color: "#f1b44c" }}></i> 10:06</p>
                                                    </div>

                                                  </div>
                                                </li>

                                                <li className=" right">
                                                  <div className="conversation-list">

                                                    <div className="ctext-wrap">
                                                      <div className="conversation-name">Henry Wells</div>
                                                      <p className="text-muted">
                                                        Wow that's great
                                                      </p>

                                                      <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1" style={{ color: "#f1b44c" }}></i> 10:07</p>
                                                    </div>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="simplebar-placeholder" style={{ width: "auto", height: "645px" }}>
                                        </div>
                                      </div>
                                      <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                                        <div className="simplebar-scrollbar" style={{ transform: "translate3d(0px, 0px, 0px)", display: "none" }}>
                                        </div>
                                      </div>
                                      <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
                                        <div className="simplebar-scrollbar" style={{ height: "366px", transform: "translate3d(0px, 67px, 0px)", display: "block" }}>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                  <div className="p-3 chat-input-section">
                                    <div className="row">
                                      <div className="col">
                                        <div className="position-relative">
                                          <input type="text" className="form-control chat-input" placeholder="Enter Message..." />
                                          {/* <div className="chat-input-links" id="tooltip-container">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item"><a href="#" title="Emoji"><i className="mdi mdi-emoticon-happy-outline"></i></a></li>
                                                                <li className="list-inline-item"><a href="#" title="Images"><i className="mdi mdi-file-image-outline"></i></a></li>
                                                                <li className="list-inline-item"><a href="#" title="Add Files"><i className="mdi mdi-file-document-outline"></i></a></li>
                                                            </ul>
                                                        </div> */}
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <button type="submit" className="btn btn-warning btn-rounded chat-send w-md waves-effect waves-light"><span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>
            <div className="modal fade bs-example-modal-center" id="report-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ backgroundColor: "#0000007d" }}>
              <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "50vw" }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Issue with job post owner</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>We strive for fair practices on this platform, if you feel you have experienced unfair treatment between you
                      and the job post owner please feel free to express your grevences below. We will attend to the issue within 5 business days.</p>
                    <div>
                      <p>Upload (zipped) any supporting files (Optional)</p>
                      <input className="mb-3"
                        type="file"
                        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        id="customFile"
                        onChange={(event) =>
                          this.uploadFile(
                            event,
                            this
                          )
                        }
                      />
                      <p>Details</p>
                      <textarea id="textarea" className="form-control" maxlength="500" rows="3" placeholder="Limit of 500 characters."></textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="btn apply-btn" style={{ padding: "0.5em 2em" }}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    jobApplication: state.jobPostData.jobApplication
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobApplication: (applicant, job) => dispatch(findUserApplicationById(applicant, job)),
    // sendReport:(body) => dispatch(sendJobApplication(body)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserJobDetails);
