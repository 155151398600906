import React, { Component } from "react";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";

import moment from "moment";
import { fetchAllBusinessProfiles } from "../../store/actions/businessProfileActions";
import { formatDate, timeZoneHelper } from "../../utils/utils";

class AdminBusinesses extends Component {
  state = {
    profileList: [],
    isLoaded: true,
    showNotification: false,
    update: false,
    changeIcon: false,
  };

  componentDidMount() {
    this.props
      .getBusinesses()
      .then(() =>
        this.setState({ isLoaded: true, profileList: this.props.businesses })
      );
  }

  selectProfile = (profile) => {
    this.props.history.push(`/admin/businesses/${profile}`);
  };

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18"></h4>

                    {/* <div className="page-title-right">
                      <button
                        onClick={() => this.closeUpdate()}
                        type="button"
                        style={{
                          backgroundColor: "#74788d",
                          borderColor: "#74788d",
                          color: "white",
                        }}
                        className="btn waves-effect waves-light"
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        <i className="bx bxs-chip font-size-16 align-middle mr-2"></i>{" "}
                        Add Profile
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                <div className="col-xl-12">
                  <div className="row mb-4">
                    {this.props.businesses.map((profile) => (
                      // <BadgeHorizontal
                      //   icon={getLanguageIcon(item.title)}
                      //   data={item.title}
                      //   key={item._id}
                      // />
                      <div key={profile._id} className="col-xl-4 col-sm-6">
                        <div
                          className="card"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.selectProfile(profile._id)}
                        >
                          <div className="card-body">
                            <div className="media">
                              <div className="avatar-md mr-4">
                                <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                  <img
                                    src="assets/images/companies/img-1.png"
                                    alt=""
                                    height="30"
                                  />
                                </span>
                              </div>
                              <div className="media-body overflow-hidden">
                                <h5 className="text-truncate font-size-15">
                                  <a href="#" className="text-dark">
                                    {profile.businessName}
                                  </a>
                                </h5>
                                <p className="text-muted mb-4">
                                  {profile.industry}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-3 border-top">
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item mr-3">
                                <i className="bx bx-calendar mr-1"></i>{" "}
                                {formatDate(profile.createdAt)}
                              </li>
                              <li className="list-inline-item mr-3">
                                {profile.isCompliant ? (
                                  <span className="badge badge-primary">
                                    Compliant
                                  </span>
                                ) : (
                                  <span className="badge badge-danger"></span>
                                  //<span className="badge badge-danger">Not Compliant</span>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinesses: () => dispatch(fetchAllBusinessProfiles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBusinesses);
