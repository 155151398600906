import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class AssesmentResult extends Component {
  state={
    result:0,
    msg:'',
    
  }
  componentDidMount() {
    window.$(".knob").knob();
    this.displayResult()
  }

  pointsEarned() {
    return (
      (this.props.score.grade / this.props.score.totalQuestions) *
      1500
    ).toFixed(0);
  }

  displayResult() {
    // if(typeof this.props)
    var msg = ""
    var result =  (
      (Number(this.props.score.grade) / Number(this.props.score.totalQuestions)) *  100  ).toFixed(0);

    if(result > 90){
      msg = "Proficent"
    }
    else if(result > 75){
      msg = "Intermediate"
    }
    else if(result > 50){
      msg = "Beginner"
    }else{
      msg = "Training Wheels"
    }

    this.setState({result:result, msg:msg})
  }

  render() {
    return (
      <div className="row justify-content-md-center justify-content-sm-center">
        <div style={{ alignItems: "center" }} className="d-flex col-md-3 col-lg-3">
          <div className="text-center mx-auto" dir="ltr">
            <input
              style={{
                width: "86px !important",
                font: "bold 28px",
                marginTop: "44px",
              }}
              className="knob"
              data-width="150"
              data-height="150"
              data-linecap={"round"}
              data-fgcolor="#4757b3"
              value={this.state.result+' %'}
              data-skin="tron"
              data-angleoffset="-125"
              data-anglearc={250}
              data-readonly={true}
              data-thickness=".1"
              readOnly={true}
            />
            <p
              style={{
                height: "30px",
                marginTop: "-44px",
                fontSize: "14px",
                fontFamily: "monospace",
              }}
            >
              Average
            </p>
            {this.props.score.grade > 0 ? (
              <p
                className="mb-5 mt-5"
                style={{
                  height: "30px",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "20px",
                  marginTop: "-24px",
                }}
              >
                {this.state.msg}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div style={{ alignItems: "center" }} className="d-flex col-md-3 col-lg-3">
          <div
            className="text-center justify-content-md-center mx-auto"
            dir="ltr"
          >
            <h4
              className=" mb-2"
              style={{ fontFamily: "monospace", fontSize: "24px" }}
            >
              Assessment Completed
            </h4>
            <p style={{ fontFamily: "monospace", fontSize: "14px" }}>
              You have earned
              <span style={{ color: "#4757b3", font: "bold 28px" }}>
                &nbsp;+{this.pointsEarned()} points
              </span>
            </p>
            {/* <p className="mt-4">
              You scored {this.displayResult()}% better than your peers
            </p> */}
            <div className="mt-5">
              <NavLink
                to="/profile/assessments"
                style={{
                  backgroundColor: "#F2CB05",
                  borderColor: "#F2CB05",
                  color: "#495057",
                  fontWeight: "600",
                  fontFamily: "monospace",
                }}
                className="font-size-16 btn w-lg waves-effect waves-light"
              >
                Back to Assessments
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-lg-3">
          <img width="100%" alt=" " src="/assets/images/data_analysis_2_kit8-net.png" />
        </div>
      </div>
    );
  }
}

export default AssesmentResult;
