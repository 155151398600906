import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProject } from "../store/actions/projectsActions";
import BadgeHorizontal from "./BadgeHorizontal";

class ProjectTechnologiesList extends Component {
  
    componentWillMount(){
        this.props.getProject('123')
        // .then(() =>{
        //     this.setState({loaded:true})
        //   })
      } 
    render (){
        return (
        
            <div >
                {/* <!-- Technologies --> */}
                
                <div className="row mb-4" style={{padding: '2rem', backgroundColor:'#00000008'}}>
                    {this.props.technologies.map(item =>
                        <BadgeHorizontal data={item}/>
                    )}
                </div>
                
            </div>     
          
        );
    }
    
    
}

const mapStateToProps = (state) =>{
    return{
      technologies : state.projectsData.project.technologies,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      getProject:(pid) => dispatch(fetchProject(pid)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps) (ProjectTechnologiesList);
