import React, { Component } from "react";
import {
  fetchContest,
  checkParticipation,
} from "../../store/actions/contestActions";
import {
  publishTeam,
  fetchAllEventTeams,
  joinExistingTeam,
} from "../../store/actions/teamActions";
import { fetchBusinessProfileList } from "../../store/actions/businessProfileActions";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Cookie from "js-cookie";
import bih from "../../../src/bih.jpg";
import loader from "../../../src/Infinity.svg";
import moment from "moment";
import dateFormat from "dateformat";
import Cookies from "js-cookie";
import globe from "../../../src/globe.svg";
import { formatDate, timeZoneHelper } from "../../utils/utils";

class HackathonRegistration extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoaded: true,
    embedLoading: false,
    isRegistered: false,
    isLoggedIn: true,
    currentStage: "step1",
    event: {},
    errors: "",
    teamName: "",
    visualState: "loading",
    isTeamsLoading: true,
    isBusinessesLoading: true,
    businesses: [],
    teams: [],
    team: {
      name: "Innovators",
      _id: "1",
      members: [
        {
          name: {
            firstName: "Elias",
            lastName: "Bayona",
          },
          propic: null,
          point: "1234",
        },
        {
          name: {
            firstName: "Daniel",
            lastName: "Dube",
          },
          propic: "https://avatars3.githubusercontent.com/u/21962078?v=4",
          points: "1234",
        },
      ],
      submissions: [
        {
          filename: "Skote Landing.Zip",
          size: "3.25 MB",
          url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
          submissionTime: "12:00 pm",
        },
        {
          filename: "Skote Landing.Zip",
          size: "3.25 MB",
          url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
          submissionTime: "12:00 pm",
        },
      ],
    },
    user: null,
    hasConsented: false,
    registrationResult: "",
  };

  componentDidMount() {
    let user = Cookies.get("user") ? JSON.parse(Cookies.get("user"))._id : null;
    //   check if creating a new contest or updating an old one
    this.setState({
      isLoggedIn: user ? typeof Cookie.get("user") !== "undefined" : false,
      user: user ? JSON.parse(Cookie.get("user")) : null,
    });

    const { history } = this.props;
    let splitPath = history.location.pathname.split("/");
    let cid = splitPath[3];

    if (Cookie.get("user") && JSON.parse(Cookie.get("user")).accountType === "business") {
      this.props.getBusinessProfileList(user);
    }

    if (
      (Cookie.get("user") && JSON.parse(Cookie.get("user")).accountType !== "business") &&
      user &&
      cid !== "undefined"
    )
      this.props.checkEventParticipation(cid, user, false).then((res) => {
        if (res.event.isEnrolled)
          window.location.href = window.location.origin + "/event/" + cid;
      });

    cid === "undefined"
      ? this.props.history.push("/")
      : this.props
        .getContest(cid)
        .then(() =>
          this.setState({
            event: this.props.contest,
            isLoaded: true,
            caughtErr: false,
          })
        )
        .catch((err) => console.log(err));

    this.props
      .getEventTeams(cid)
      .then(() =>
        this.setState({
          teams: this.props.eventTeams,
          visualState: "displayTeams",
          isTeamsLoading: false,
        })
      )
      .catch((err) => console.log(err));
  }

  /**
   * update state with input change
   *
   * @param  {} doc key value pair with state variable to change as key and variable value as value
   */
  handleInputChange(doc) {
    this.setState(doc);
  }

  joinTeam(team) {
    //send request to join team
    this.setState({ visualState: "loading" });

    this.props
      .joinTeam(team, JSON.parse(Cookie.get("user"))._id, this.state.event._id)
      .then(() => this.setState({ visualState: "success" }))
      .catch((err) =>
        this.setState({ visualState: "failed", errors: err.response.data })
      );
    // open event details page
    // this.props.history.push("/profile/hackathon/" + this.state.event._id)
  }

  createTeam() {
    this.setState({ visualState: "loading" });
    this.props
      .createTeam({
        eventId: this.state.event._id,
        members: [JSON.parse(Cookie.get("user"))._id],
        type: "normal",
        teamName: this.state.teamName,
      })
      .then(() => {
        this.setState({ visualState: "success" });
      })
      .catch((err) => {
        this.setState({ visualState: "failed", errors: err.response.data });
      });
  }

  registerBusiness(business) {
    this.setState({ visualState: "registering" });
    this.props
      .createTeam({
        eventId: this.state.event._id,
        type: "business",
        // members: [JSON.parse(Cookie.get("user"))._id],
        businessProfile: business._id,
        teamName: business.businessName,
      })
      .then(() => {
        this.setState({
          registrationResult: "success",
          visualState: "success",
        });
      })
      .catch((err) => {
        this.setState({
          registrationResult: "failed",
          visualState: "failed",
          errors: err.response.data,
        });
      });
  }

  selectProfile = (business) => {
    this.setState({ selectedProfile: business });
    this.props
      .checkEventParticipation(this.state.event._id, business._id, true)
      .then((res) => {
        if (res.event.isEnrolled) {
          // show notification that a business is enrolled and prompt user to select another business
        } else {
          this.registerBusiness(business);
        }
      });
  };

  selectTeamType = (info) => {
    if (info.currentStage == "selectBusiness") {
      //get user businesses
      //then toggle is business loading off, populate businesses field
      let user = Cookies.get("user")
        ? JSON.parse(Cookies.get("user"))._id
        : null;
      this.props
        .getUserBusinesses(user)
        .then(() => {
          this.setState({
            ...info,
            businesses: this.props.businesses,
            isBusinessesLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ visualState: "failed", errors: err.response.data });
        });
      this.setState(info);
    } else {
      this.setState(info);
    }
  };

  toggleConsent(consent) {
    this.setState({ hasConsented: consent });
  }

  isFormComplete() {
    return this.state.hasConsented && this.state.teamName != "";
  }

  /**
   * check if all required data is loaded
   * render componet if true else render loading animations
   */
  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/animate.min.css"
          />
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/magnific-popup.css"
          />
          <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/dripicons.css"
          />
          <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/responsive.css"
          />

          <div
            className="page-content"
            style={{ padding: "0px", marginTop: "0px" }}
          >
            <div
              className="btn btn-secondary font-weight-bolder mx-auto"
              style={{
                position: "absolute",
                bottom: "100px",
                right: "30px",
                zIndex: "3",
              }}
              onClick={() =>
                this.props.history.push("/event/" + this.state.event._id)
              }
            >
              <i className="fa fa-arrow-left"></i>&nbsp;&nbsp;Go Back To Event
            </div>
            <div>
              <div className="row" style={{ marginTop: "-4rem" }}>
                <div className="col-xl-12">
                  <main>
                    {typeof this.state.event.eventBanner !== "undefined" ? (
                      <section
                        id="parallax"
                        className="parallax slider-area custom-bg second-slider3-bg d-flex align-items-center justify-content-left fix"
                        style={{
                          background:
                            "url(" +
                            this.state.event.eventBanner.data +
                            ") no-repeat",
                          backgroundPosition: "center center",
                          height: "65vh",
                        }}
                      >
                        <div className="slider-active">
                          <div className="single-slider">
                            <div
                              className="container"
                              style={{ maxWidth: "100vw", width: "100vw" }}
                            >
                              <div className="row">
                                <div className="col-lg-8">
                                  <div
                                    className="ml-5 slider-content second-slider-content"
                                    style={{
                                      paddingTop: "4rem",
                                      color: "white",
                                    }}
                                  >
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {/* <h3 data-animation="fadeInUp animated" data-delay=".4s" style={{ fontSize: "3vw", color: "white" }}>
                                                                        {this.state.event.title}
                                                                    </h3>
                                                                    <p style={{ fontSize: "12px" }} data-animation="fadeInUp animated" data-delay=".4s">
                                                                        {this.state.event.startDate ? dateFormat(new Date(this.state.event.startDate), ) : ""} - {this.state.event.endDate ? dateFormat(new Date(this.state.event.endDate), ) : ""}
                                                                    </p> */}

                                    <br />
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <section
                        id="parallax"
                        className="parallax slider-area slider3-bg second-slider3-bg d-flex align-items-center justify-content-left fix"
                        style={{
                          background: "url(" + bih + ")",
                          minHeight: "85vh",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      >
                        :
                        <div className="slider-active">
                          <div className="single-slider">
                            <div
                              className="container"
                              style={{ maxWidth: "100vw", width: "100vw" }}
                            >
                              <div className="row">
                                <div className="col-lg-8">
                                  <div
                                    className="ml-5 slider-content second-slider-content"
                                    style={{
                                      paddingTop: "4rem",
                                      color: "white",
                                    }}
                                  >
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {/* <h3 data-animation="fadeInUp animated" data-delay=".4s" style={{ fontSize: "3vw", color: "white" }}>
                                                                        {this.state.event.title}
                                                                    </h3>
                                                                    <p style={{ fontSize: "12px" }} data-animation="fadeInUp animated" data-delay=".4s">
                                                                        {this.state.event.startDate ? dateFormat(new Date(this.state.event.startDate), ) : ""} - {this.state.event.endDate ? dateFormat(new Date(this.state.event.endDate), ) : ""}
                                                                    </p>

                                                                    <div className="btn btn-secondary font-weight-bolder mb-1 mx-auto" onClick={() => this.props.history.push('/event/' + this.state.event._id)}><i className="fa fa-arrow-left"></i>&nbsp;&nbsp;Go Back To Event</div> */}

                                    <br />
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    <section
                      style={{
                        alignItems: "center",
                        padding: "1rem 3rem 3rem 0",
                        minHeight: "60vh",
                      }}
                      id="how-it-works"
                      className="about-p p-relative"
                    >
                      <div className="container text-center content-center">
                        {/* <h3 className="text-dark">Event Registration</h3> */}
                        {/* <div className="col-md-6 col-xl-6 mt-4">
                                                    <div className="card" style={{ cursor: "pointer", border: "1px gray dashed", backgroundColor: "transparent", minHeight: "120px" }} onClick={() => this.setState({ currentStage: "createTeam" })}>
                                                        <div className="card-body text-center">
                                                            <p style={{ fontSize: "1.5vw" }}>CREATE YOUR OWN TEAM</p>
                                                        </div>
                                                    </div>
                                                </div> */}

                        <div
                          style={{
                            position: "fixed",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              backgroundImage: "url(" + globe + ")",
                              transform: "translate(-50%, -50%) scale(1)",
                              position: "absolute",
                              top: "10%",
                              left: "40%",
                              width: "100%",
                              height: "100%",
                              transition: "transform .15s ease-out 0s",
                              transform: "translate(-50%,-50%)",
                              transformOrigin: "50% 50%",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: " cover",
                              willChange: "transform",
                            }}
                          ></div>
                        </div>
                        {!this.state.isLoggedIn ? (
                          <div
                            className="card bg-primary p-4 bg-light text-light "
                            style={{
                              padding: "2rem",
                              // border: "1px solid",
                              boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 15%)",
                              width: "50%",
                              margin: "auto",
                              marginTop: "2rem",
                            }}
                          >
                            <div className="mb-4">
                              {/* <span>
                                                                <img src="./assets/images/logo-light.png" alt="skills ranker logo" className="mb-5" style={{ width: "100px", cursor: "pointer" }} />
                                                            </span> */}
                              <h4 className="text-dark">
                                Register to participate in this event{" "}
                              </h4>
                            </div>
                            <div className="mb-3">
                              <p className="text-dark">
                                Create an account or login to participate in
                                this event
                              </p>
                            </div>
                            <div className="mt-3">
                              <button
                                onClick={() =>
                                  this.props.history.push(
                                    `/register?redirect=${escape(
                                      this.props.history.location.pathname
                                    )}`
                                  )
                                }
                                type="button"
                                className="btn btn-primary font-weight-bolder text-light btn-rounded mr-4"
                                style={{ padding: "0.67rem 2.75rem" }}
                              >
                                {" "}
                                Sign Up
                              </button>
                              <button
                                onClick={() =>
                                  this.props.history.push(
                                    `/login?redirect=${escape(
                                      this.props.history.location.pathname
                                    )}`
                                  )
                                }
                                type="button"
                                className="btn btn-warning font-weight-bolder text-dark btn-rounded"
                                style={{ padding: "0.67rem 2.75rem" }}
                              >
                                {" "}
                                Login
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div style={{ margin: "auto", width: "80%" }}>
                            {/* <div className=""> */}
                            {this.state.currentStage === "step1" ? (
                              this.state.user != null &&
                                this.state.user.accountType === "business" ? (
                                this.state.visualState === "success" ? (
                                  <div className="row">
                                    <div className="col-12">
                                      <div
                                        className="shadow mt-4"
                                        style={{
                                          cursor: "pointer",
                                          border: "dashed 1px gray",
                                        }}
                                      >
                                        <div className="card-body">
                                          <div className="media">
                                            <div className="media-body overflow-hidden">
                                              <p>
                                                Business Team Successfully
                                                Registered
                                              </p>
                                              <h4 className="mb-4 mt-4">
                                                {" "}
                                                {
                                                  this.state.selectedProfile
                                                    .businessName
                                                }
                                              </h4>
                                              <button
                                                onClick={() =>
                                                  this.props.history.push(
                                                    "/event/" +
                                                    this.state.event._id
                                                  )
                                                }
                                                className="btn btn-secondary"
                                              >
                                                Go to event
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : this.state.visualState === "registering" ? (
                                  <div className="row">
                                    <div className="col-12 mt-4">
                                      <div style={{ cursor: "pointer" }}>
                                        <div className="card-body">
                                          <div className="media">
                                            <div className="media-body overflow-hidden">
                                              <h4>
                                                Prepering Business Team ...
                                              </h4>
                                              <img src={loader} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : this.state.event.eventTier ===
                                  "individuals" ? (
                                  <div className="row">
                                    <div className="col-12 mt-4">
                                      <div style={{ cursor: "pointer" }}>
                                        <div className="card-body">
                                          <div className="media">
                                            <div className="media-body overflow-hidden">
                                              <h4>
                                                This event is open for
                                                individual developer accounts
                                                only, businesses are not allowed
                                                to participate.
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    {this.state.registrationResult ===
                                      "failed" ? (
                                      <div className="col-12 mt-4">
                                        <h5>
                                          Failed to register business team{" "}
                                          {
                                            this.state.selectedProfile
                                              .businessName
                                          }
                                        </h5>
                                        <p>
                                          Profile probably already has a team in
                                          the event. Try selecting a different
                                          profile
                                        </p>
                                        <p>
                                          Contact support if issue persists.
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="col-12 mt-4">
                                        <h4>Participate as a business</h4>
                                        <p>
                                          Please select a business profile you
                                          want to use
                                        </p>
                                      </div>
                                    )}
                                    <div className="col-12">
                                      <div className="row">
                                        {this.props.businesses.map(
                                          (profile) => (
                                            <div
                                              key={profile._id}
                                              className="col-xl-4 col-md-6 col-sm-6"
                                            >
                                              <div
                                                className="card bg-warning shadow"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.selectProfile(profile)
                                                }
                                              >
                                                <div className="card-body">
                                                  <div className="media">
                                                    <div className="media-body overflow-hidden">
                                                      <i
                                                        className="fas fa-sign-in-alt text-dark"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></i>
                                                      <h5 className="text-truncate font-size-15">
                                                        <a
                                                          href="#"
                                                          className="text-dark"
                                                        >
                                                          {profile.businessName}
                                                        </a>
                                                      </h5>
                                                      <p className="text-dark mb-4">
                                                        {profile.industry}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              ) : this.state.event.eventTier === "business" &&
                                this.state.user.accountType != "business" ? (
                                <div className="row">
                                  <div className="col-12 mt-4">
                                    <div style={{ cursor: "pointer" }}>
                                      <div className="card-body">
                                        <div className="media">
                                          <div className="media-body overflow-hidden">
                                            <h4>
                                              This event is for businesses only,
                                              contact your business account
                                              owner to register a team for this
                                              event.
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-6 col-xl-6 mt-4 ">
                                    <div
                                      className="card bg-primary shadow-lg"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        minHeight: "120px",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          currentStage: "createTeam",
                                          visualState: "displayTeams",
                                        })
                                      }
                                    >
                                      <div className="card-body text-center">
                                        <i
                                          className="fas fa-user-plus text-light"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                        <p
                                          className="text-light font-weight-bolder"
                                          style={{ fontSize: "16px" }}
                                        >
                                          Create Your Own Team
                                        </p>
                                        <p className="text-success ">
                                          Create a team and wait for your other
                                          team members to join your team.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xl-6 mt-4">
                                    <div
                                      className="card bg-warning shadow"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "transparent",
                                        minHeight: "120px",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          currentStage: "findTeam",
                                          visualState: "displayTeams",
                                        })
                                      }
                                    >
                                      <div className="card-body text-center">
                                        <i
                                          className="fas fa-sign-in-alt text-dark"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                        <p
                                          className="text-dark font-weight-bolder"
                                          style={{ fontSize: "16px" }}
                                        >
                                          Lets Help You Find a Team
                                        </p>
                                        <p className="text-dark">
                                          Join an existing team that your team
                                          leader has already created
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            ) : (
                              <></>
                            )}

                            {this.state.currentStage === "findTeam" ? (
                              <div className="col-md-12">
                                <div className="card bg-dark text-left">
                                  <div className="card-body">
                                    {/* <i className="bx bx-arrow-back mb-3" style={{ cursor: "pointer", fontSize: "30px" }} onClick={() => this.setState({ currentStage: "step1" })}></i> */}

                                    {this.state.isTeamsLoading ? (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          display: "block",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "white",
                                            margin: "0px",
                                          }}
                                        >
                                          Searching for teams ......
                                        </p>
                                        <img src={loader} />
                                      </div>
                                    ) : this.state.teams.length > 0 ? (
                                      this.state.visualState ===
                                        "displayTeams" ? (
                                        <>
                                          <div className="d-flex ml-2 mb-3">
                                            <i
                                              className="bx text-light bx-arrow-back font-weight-bolder"
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "25px",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  currentStage: "step1",
                                                })
                                              }
                                            ></i>
                                            <h4
                                              style={{ marginTop: "1.4px" }}
                                              className="mb-3 ml-3 text-light d-inline-flex font-weight-bolder justify-content-between"
                                            >
                                              Back
                                            </h4>
                                          </div>
                                          <div className="row" id="accordion">
                                            {this.state.teams.map((team) => (
                                              <div className="col-md-6">
                                                <div
                                                  key={team._id}
                                                  className="card border-primary mb-1 shadow-none"
                                                >
                                                  <div
                                                    className="card-header"
                                                    id={team._id}
                                                  >
                                                    <h6 className="m-0">
                                                      <a className="text-dark">
                                                        <h5 className="font-size-14 mb-1 text-dark font-weight-bolder">
                                                          {team.teamName}
                                                        </h5>
                                                        <small className="text-muted d-flex">
                                                          {formatDate(
                                                            team.createdAt
                                                          )}
                                                        </small>
                                                        <p className="mt-2 d-flex">
                                                          <i className="text-muted fa fa-users"></i>{" "}
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "1rem",
                                                            }}
                                                          >
                                                            &nbsp;&nbsp;
                                                            {
                                                              team.members
                                                                .length
                                                            }{" "}
                                                            member{" "}
                                                            {team.members
                                                              .length === 1
                                                              ? ""
                                                              : "s"}
                                                          </span>
                                                        </p>
                                                        {/* member{team.members.length > 1 ? "1" : ""} */}
                                                        {/* <small className="text-muted mb-0">Combined points: {team.combinedPoints}</small> */}
                                                      </a>
                                                    </h6>
                                                    <a
                                                      href={"#a" + team._id}
                                                      style={{ color: "black" }}
                                                      data-toggle="collapse"
                                                      aria-expanded="false"
                                                      aria-controls={team._id}
                                                    >
                                                      <i className="fas fa-chevron-down"></i>
                                                    </a>
                                                    <div
                                                      className="btn btn-primary btn-sm font-weight-bolder btn-rounded shadow"
                                                      style={{
                                                        float: "right",
                                                        cursor: "pointer",
                                                        background: "#f1b44c",
                                                        borderColor: "#f1b44c",
                                                      }}
                                                      onClick={() =>
                                                        this.joinTeam(team._id)
                                                      }
                                                    >
                                                      Join Team
                                                    </div>
                                                  </div>

                                                  <div
                                                    key={team._id}
                                                    id={"a" + team._id}
                                                    className="collapse"
                                                    aria-labelledby={team._id}
                                                    data-parent="#accordion"
                                                  >
                                                    <div className="card-body">
                                                      {team.members.map(
                                                        (member) => (
                                                          <div
                                                            key={member._id}
                                                            className="mb-3"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div className="mr-3">
                                                              {member.githubData ? (
                                                                <img
                                                                  className="rounded-circle avatar-sm"
                                                                  src={
                                                                    member
                                                                      .githubData
                                                                      .avatar_url
                                                                  }
                                                                  height="50px"
                                                                  width="50px"
                                                                  alt=""
                                                                ></img>
                                                              ) : (
                                                                <span
                                                                  className="avatar-title rounded-circle font-size-16"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#0a0b0e9e",
                                                                    color:
                                                                      "#ffffffa3!important",
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {member.name
                                                                    .firstName[0] +
                                                                    " " +
                                                                    member.name
                                                                      .lastName[0]}{" "}
                                                                </span>
                                                              )}
                                                            </div>
                                                            <div>
                                                              <h5
                                                                className="text-dark"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() =>
                                                                  this.props.history.push(
                                                                    "/profile/" +
                                                                    member._id
                                                                  )
                                                                }
                                                              >
                                                                {member.name
                                                                  .firstName +
                                                                  " " +
                                                                  member.name
                                                                    .lastName}
                                                              </h5>
                                                              <small>
                                                                <font
                                                                  className="font-weight-bolder"
                                                                  style={{
                                                                    color:
                                                                      "#f1b44c",
                                                                  }}
                                                                >
                                                                  POINTS:
                                                                </font>{" "}
                                                                {member.ranking
                                                                  ? member
                                                                    .ranking
                                                                    .points
                                                                  : ""}
                                                              </small>
                                                            </div>
                                                            {/* <div style={{ width: "50%" }}>
                                                                                                                        <span
                                                                                                                            style={{ float: "right" }}
                                                                                                                            href="#"
                                                                                                                            className="badge badge-primary font-size-11 m-1"
                                                                                                                        >
                                                                                                                            ruby
                                                                                                                    </span>
                                                                                                                        <span
                                                                                                                            style={{ float: "right" }}
                                                                                                                            href="#"
                                                                                                                            className="badge badge-primary font-size-11 m-1"
                                                                                                                        >
                                                                                                                            ruby
                                                                                                                    </span>
                                                                                                                        <span
                                                                                                                            style={{ float: "right" }}
                                                                                                                            href="#"
                                                                                                                            className="badge badge-primary font-size-11 m-1"
                                                                                                                        >
                                                                                                                            ruby
                                                                                                                    </span>
                                                                                                                    </div> */}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </>
                                      ) : this.state.visualState ===
                                        "loading" ? (
                                        <div style={{ textAlign: "center" }}>
                                          <p
                                            style={{
                                              color: "white",
                                              margin: "0px",
                                            }}
                                          >
                                            Validating and sending requests to
                                            team members ......
                                          </p>
                                          <img src={loader} />
                                        </div>
                                      ) : this.state.visualState ===
                                        "failed" ? (
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              textAlign: "center",
                                              marginBottom: "2rem",
                                            }}
                                          >
                                            <i
                                              className="bx text-light bx-arrow-back font-weight-bolder"
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "25px",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  currentStage: "step1",
                                                })
                                              }
                                            ></i>
                                            <h4
                                              className="text-light mb-0 font-weight-bolder"
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {/* Innovators... Assemble!!! */}
                                              Join Team
                                            </h4>
                                          </div>
                                          <div
                                            className="alert alert-danger bg-danger text-center p-2 mb-3"
                                            role="alert"
                                          >
                                            {this.state.errors}
                                          </div>
                                          <div className="d-flex">
                                            <div
                                              className="btn btn-secondary font-weight-bolder mb-1 mx-auto"
                                              onClick={() =>
                                                this.props.history.push(
                                                  "/event/" +
                                                  this.state.event._id
                                                )
                                              }
                                            >
                                              Go Back To Event
                                            </div>
                                          </div>
                                        </div>
                                      ) : this.state.visualState ===
                                        "success" ? (
                                        <div
                                          className="card"
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "transparent",
                                            minHeight: "120px",
                                          }}
                                        >
                                          <div className="card-body">
                                            <p
                                              className="text-center text-light"
                                              style={{
                                                fontSize: "14px",
                                                position: "absolute",
                                              }}
                                            >
                                              Request to join the team has been
                                              successful, wait for a member of
                                              the team to let you in.
                                            </p>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <div className="d-flex">
                                              <button
                                                onClick={() =>
                                                  this.props.history.push(
                                                    "/event/" +
                                                    this.state.event._id
                                                  )
                                                }
                                                type="button"
                                                className="btn btn-warning font-weight-bolder"
                                              >
                                                {" "}
                                                Event Page{" "}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <>
                                        <div
                                          className="card"
                                          style={{
                                            backgroundColor: "transparent",
                                            minHeight: "120px",
                                          }}
                                        >
                                          <div
                                            className="card-body"
                                            style={{ display: "grid" }}
                                          >
                                            <p
                                              className="text-center"
                                              style={{
                                                fontSize: "14px",
                                                color: "white",
                                              }}
                                            >
                                              No teams available yet. Create
                                              your own team
                                            </p>
                                            <button
                                              className="btn btn-warning font-weight-bolder mx-auto text-dark"
                                              onClick={() =>
                                                this.setState({
                                                  currentStage: "createTeam",
                                                  visualState: "displayTeams",
                                                })
                                              }
                                            >
                                              Create Team
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            {this.state.currentStage === "createTeam" ? (
                              <div className="card bg-dark col-md-8 mx-auto m-3">
                                <div className=" text-left">
                                  <div className="card-body">
                                    <div
                                      style={{
                                        display: "flex",
                                        textAlign: "center",
                                        marginBottom: "2rem",
                                      }}
                                    >
                                      <i
                                        className="bx text-light bx-arrow-back font-weight-bolder"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "25px",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            currentStage: "step1",
                                          })
                                        }
                                      ></i>
                                      <h4
                                        className="text-light mb-0 font-weight-bolder"
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "1.35px",
                                        }}
                                      >
                                        {/* Innovators... Assemble!!! */}
                                        Create Team
                                      </h4>
                                    </div>
                                    {this.state.visualState === "loading" ? (
                                      <div style={{ textAlign: "center" }}>
                                        <p
                                          style={{
                                            color: "white",
                                            margin: "0px",
                                          }}
                                        >
                                          Creating your team ......
                                        </p>
                                        <img src={loader} />
                                      </div>
                                    ) : (
                                      <>
                                        {this.state.visualState ===
                                          "displayTeams" ? (
                                          <div id="accordion">
                                            <p style={{ color: "white" }}>
                                              Provide your team's name to create
                                              it.
                                            </p>
                                            <form>
                                              <div className="mb-4 form-group">
                                                {/* <label>Team Name</label> */}
                                                <input
                                                  onChange={(e) =>
                                                    this.setState({
                                                      teamName: e.target.value,
                                                    })
                                                  }
                                                  className="form-control bg-dark text-light"
                                                  type="text"
                                                  placeholder="Team Name"
                                                />
                                              </div>
                                              <div
                                                className="mt-2 mb-2 small"
                                                style={{ textAlign: "left" }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="customCheck1"
                                                  onChange={() => {
                                                    this.toggleConsent(
                                                      !this.state.hasConsented
                                                    );
                                                  }}
                                                />
                                                <span
                                                  className="ml-2"
                                                  style={{ color: "white" }}
                                                >
                                                  By clicking the checkbox, I
                                                  agree that I have read and
                                                  agreed to the{" "}
                                                </span>
                                                <a
                                                  className="mt-0 pt-0 "
                                                  href={
                                                    "https://skillsranker.bih.co.bw/event/" +
                                                    this.state.event._id
                                                  }
                                                  target="_blank" rel="noreferrer"
                                                  alt="I have read and agreed to the Rules and Legal terms and conditions for participating in this event."
                                                  style={{
                                                    color: "rgb(255,196,12)",
                                                  }}
                                                >
                                                  Rules and Legal terms and
                                                  conditions for participating
                                                  in this event.{" "}
                                                </a>
                                              </div>{" "}
                                              <div className="mb-1 form-group">
                                                {this.isFormComplete() ? (
                                                  <button
                                                    onClick={() =>
                                                      this.createTeam()
                                                    }
                                                    type="button"
                                                    className="btn btn-warning text-dark font-weight-bolder"
                                                  >
                                                    Submit
                                                  </button>
                                                ) : (
                                                  <button
                                                    disabled
                                                    type="button"
                                                    className="btn btn-warning text-dark font-weight-bolder"
                                                  >
                                                    Submit
                                                  </button>
                                                )}
                                              </div>
                                            </form>
                                          </div>
                                        ) : (
                                          <></>
                                        )}

                                        {this.state.visualState ===
                                          "success" ? (
                                          <>
                                            <div
                                              className="card"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                                minHeight: "120px",
                                              }}
                                            >
                                              <div className="card-body text-center">
                                                <p
                                                  className="text-success"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  You have successfully been
                                                  registered and added to the
                                                  team.
                                                </p>
                                                <br />
                                                <br />
                                                <div className="d-flex">
                                                  <button
                                                    onClick={() =>
                                                      this.props.history.push(
                                                        "/event/" +
                                                        this.state.event._id
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn btn-warning font-weight-bolder"
                                                  >
                                                    {" "}
                                                    Event Page{" "}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        {this.state.visualState === "failed" ? (
                                          <>
                                            <div
                                              className="alert alert-danger bg-danger text-center p-2 mb-3"
                                              role="alert"
                                            >
                                              {this.state.errors}
                                            </div>
                                            <div className="d-flex">
                                              <div
                                                className="btn btn-secondary font-weight-bolder mb-1 mx-auto"
                                                onClick={() =>
                                                  this.props.history.push(
                                                    "/event/" +
                                                    this.state.event._id
                                                  )
                                                }
                                              >
                                                Go Back To Event
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                    </section>
                  </main>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contest: state.contestData.contest,
    eventTeams: state.teamsData.teams,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContest: (cid) => dispatch(fetchContest(cid)),
    createTeam: (data) => dispatch(publishTeam(data)),
    getEventTeams: (eventId) => dispatch(fetchAllEventTeams(eventId)),
    joinTeam: (teamId, user, eventId) =>
      dispatch(joinExistingTeam(teamId, user, eventId)),
    checkEventParticipation: (eventId, userId, isBusiness) =>
      dispatch(checkParticipation(eventId, userId, isBusiness)),
    getUserBusinesses: (user) => dispatch(fetchBusinessProfileList(user)),
    getBusinessProfileList: (user) => dispatch(fetchBusinessProfileList(user)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HackathonRegistration);
