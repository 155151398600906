const initState = {
    userRank: [],
    allRanks: [],
    totalUsers: {}
};

const rankReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_RANK":
            return {
                ...state,
                userRank: action.rankData
            };

        case "GET_ALL_RANK":
            let users = action.rankData.ranks.filter(user => user.owner !== null);
            var langs = action.rankData.languages;

            users.forEach(user => {
                var tempLangs2 = []
                if (user.owner !== null) {
                    user.owner.programmingLanguages.forEach(lang => {
                        var tempLangs = langs.filter(value => value._id !== lang)
                        tempLangs2 = tempLangs.map(item => { return item.title })
                    })
                    user.owner.programmingLanguages = tempLangs2
                }
            })
            return {
                ...state,
                allRanks: users,
                totalUsers: action.rankData.totalUsers
            };

        default:
            break;
    }
    return state;
};

export default rankReducer;