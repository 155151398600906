import Cookies from "js-cookie";

import { 
  getBusinessProfileService, 
  getBusinessProfileList, 
  getAllBusinessProfiles, 
  updateBusinessProfile, 
  updateContactPerson, 
  createBusinessProfile, 
  reviveBusiness, 
  suspendBusiness, 
  getBusinessProfileById , 
  sendBusinessInvite,
  checkBusinessInvite,
  checkBusinessEmail,
  acceptBusinessInvite,
  createJobPost,
  getJobBusinessPosts,
  getJobSkills,
  patchJobPost,
  searchJobPosts,
  getJobPostById,
  pushJobApplication,
  applicationCheck,
  getJobApplicants,
  searchUserAppliedJobPosts,
  getUserApplicationById,
  checkBusiness

} from "../../api/businessProfile";

export const getBusinessProfile = (id) => {
  return (dispatch) => {
    return getBusinessProfileService(id)
      .then((response) => {
        const businessProfile =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_BUSINESS_PROFILE",
          data: businessProfile,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchBusinessProfileById = (id) => {
  return (dispatch) => {
    return getBusinessProfileById(id)
      .then((response) => {
        const businessProfile =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_BUSINESS_PROFILE",
          data: businessProfile,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchBusinessProfileList = (id) => {
  return (dispatch) => {
    return getBusinessProfileList(id)
      .then((response) => {
        const businessProfileList =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_BUSINESS_PROFILE_LIST",
          data: businessProfileList,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchAllBusinessProfiles = (id) => {
  return (dispatch) => {
    return getAllBusinessProfiles()
      .then((response) => {
        const businessProfileList =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_BUSINESS_PROFILE_LIST",
          data: businessProfileList,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

//new field for busniess profile--
export const fetchAllBusinessProfiles1 = () => {
  return (dispatch) => {
    return getAllBusinessProfiles()
      .then((response) => {
        return dispatch({
          type: "GET_BUSINESS_PROFILE_LIST1",
          data: response.data,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushBusinessProfileUpdate = (body) => {
  return (dispatch) => {
    return updateBusinessProfile(body)
      .then((response) => {
        const businessProfile =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_BUSINESS_PROFILE",
          data: businessProfile,
        });
        return businessProfile
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushContactPersonUpdate = (body) => {
  return (dispatch) => {
    return updateContactPerson(body)
      .then((response) => {
        const contact =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_CONTACT",
          data: contact,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushNewProfile = (body) => {
  return (dispatch) => {
    return createBusinessProfile(body)
      .then((response) => {
        const contact =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_NEW_BUSINESS_PROFILE",
          data: contact,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushProfileToStore = (body) => {
  return async (dispatch) => {
      return await  dispatch({
          type: "GET_BUSINESS_PROFILE",
          data: body,
        });
      
  };
};

export const updateBusinessStatus = (body) => {
  return (dispatch) => {
    if(body.status){
      return reviveBusiness(body)
      .then((response) => {
        dispatch({
          type: "REVIVE_BUSINESS_PROFILE",
          data: response,
        });
      })
      .catch((err) => Promise.reject(err));
    }else{
      return suspendBusiness(body)
      .then((response) => {
        
        dispatch({
          type: "SUSPEND_BUSINESS_PROFILE",
          data: response,
        });
      })
      .catch((err) => Promise.reject(err));
    }
    
  };
};

export const pushInvite = (body) => {
  return (dispatch) => {
    return sendBusinessInvite(body)
      .then((response) => {
        const invite =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_BUSINESS_INVITES",
          data: invite,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushInviteAcceptence = (body) => {
  return (dispatch) => {
    return acceptBusinessInvite(body)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        return responseData
      })
      .catch((err) => Promise.reject(err));
  };
};

export const validateInvite = (id) => {
  return (dispatch) => {
    return checkBusinessInvite(id)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
       return responseData
      })
      .catch((err) => Promise.reject(err));
  };
};

export const validateBusinessEmail = (email) => {
  return (dispatch) => {
    return checkBusinessEmail(email)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        return responseData
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushNewJobPost = (body) => {
  return (dispatch) => {
    return createJobPost(body)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "CREATE_JOB_POST",
          data: responseData,
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchBusinessJobPosts = (id) => {
  return (dispatch) => {
    return getJobBusinessPosts(id)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_JOB_POSTS",
          data: responseData,
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};


export const fetchPlatformSkills = (id) => {
  return (dispatch) => {
    return getJobSkills(id)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_SKILLS",
          data: responseData,
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const updateJobPost = (body) => {
  return (dispatch) => {
    return patchJobPost(body.job)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_JOB_POST",
          data: {job:responseData,idx:body.idx}
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const updateJobPostData = (post_id, updateInfo) => {
  return (dispatch) => {
    return patchJobPost({_id:post_id, ...updateInfo })
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_JOB_POST_DATA",
          data: {job:responseData}
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};


export const findJobPosts = (body) => {
  return (dispatch) => {
    return searchJobPosts(body)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_JOB_POSTS",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};



export const findUserAppliedJobPosts = (body) => {
  return (dispatch) => {
    return searchUserAppliedJobPosts(body)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_JOB_POSTS",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const findUserApplicationById = (applicant, job) => {
  return (dispatch) => {
    return getUserApplicationById(applicant, job)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_JOB_APPLICATION",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const findJobPostById = (id) => {
  return (dispatch) => {
    return getJobPostById(id)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "FOCUS_JOB_POST",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};


export const sendJobApplication = (body) => {
  return (dispatch) => {
    return pushJobApplication(body)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "PUSH_APPLICATION",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};


export const hasApplied = (job, applicant) => {
  return (dispatch) => {
    return applicationCheck(job, applicant)
      .then((response) => {
        
        return response.data
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchJobApplications = (job) => {
  return (dispatch) => {
    return getJobApplicants(job)
      .then((response) => {
        const responseData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "GET_JOB_APPLICATIONS",
          data: responseData
        });
         
      })
      .catch((err) => Promise.reject(err));
  };
};

export const validateBusiness = (identifier) => {
    return checkBusiness(identifier)
      .then((response) => {
        return response.data;
        
      })
      .catch((err) => {return Promise.reject(err)});
  
};
