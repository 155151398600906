const initState = {
  projectsList:[],
  project:{}
};

const projectsReducer = (state = initState, action) => {
  switch (action.type) {
    case "PULL_PROJECTS":
      return {
        ...state,
        projectsList: action.data
      };

      case "PULL_PROJECT":
      return {
        ...state,
        project: {...action.data}
      };

    default:
      break;
  }
  return state;
};

export default projectsReducer;