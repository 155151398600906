const initState = {
    rating: {},
    judge: {},
    judges: []
};

const judgeReducer = (state = initState, action) => {
    switch (action.type) {
        case "SESSION_CHECK":
            return {
                ...state,
                judge: { ...action.session }
            };

        case "SUBMISSION_RATING":
            return {
                ...state,
                rating: action.rating
            };
        case "CREATE_JUDGE":
            return {
                ...state,
                judges: action.judges
            };
        case "REMOVE_JUDGE":
            return {
                ...state,
                judges: action.judges
            };
        case "UPDATE_JUDGE":
            return {
                ...state,
                judge: action.newJudge
            };


        default:
            break;
    }
    return state;
};

export default judgeReducer;