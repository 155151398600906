const initState = {
  technologiesList:[],
  allTechnologiesList:[]
};

const technologiesReducer = (state = initState, action) => {
  switch (action.type) {
    case "PULL_TECHNOLOGIES":
      return {
        ...state,
        technologiesList: action.data
      };

    case "PULL_ALL_TECHNOLOGIES":
      return {
        ...state,
        allTechnologiesList: action.data
      };

    case "CREATE_TECHNOLOGY":
      return {
        ...state,
        allTechnologiesList: state.allTechnologiesList.concat([action.data])
      };
    default:
      
      break;
  }
  return state;
};

export default technologiesReducer;