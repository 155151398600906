import React, { useState } from "react";
import Error from "../partials/Error";

import { isValidEmail, isNotEmpty } from "../../utils/utils";
import { signUpUser } from "../../store/actions/authActions";
import { connect } from "react-redux";
import queryString from "query-string";

function SignUp2(props) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [selectAccount, setSelectAccount] = useState("Select");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmConsent, setConsent] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function isFormValid() {
    return (
      isValidEmail(email) &&
      isNotEmpty(password) &&
      password.length >= 8 &&
      isNotEmpty(confirmPassword) &&
      confirmPassword.length >= 8 &&
      isNotEmpty(lastName) &&
      isNotEmpty(firstName) &&
      isNotEmpty(confirmPassword) &&
      selectAccount !== "Select" &&
      confirmConsent
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (isFormValid()) {
      setIsLoading(true);

      props
        .signUpUser({
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          accountType: selectAccount,
        })
        .then(() => {
          const redirect = queryString.parse(props.location.search).redirect;
          if (redirect) {
            window.location = unescape(redirect);
          } else {
            window.location = "/profile";
          }
        })
        .catch((err) => {
          resetForm();
          displayError(
            err && err.message
              ? err.message
              : "Failed to create account. Please try again."
          ); // TODO: replace with 404 component
          setIsLoading(false);
        });
    } else {
      displayError("Please enter a valid user details.");
      resetForm();
    }
  }

  function resetForm() {
    setEmail("");
    setFirstName("");
    setSelectAccount("Select");
    setLastName("");
    setPassword("");
    setConfirmPassword("");
    setConsent(false);
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  return (
    <div className="login-signin-container">
      <div className="login-wrapper">
        <div
          className="signup-message d-none d-md-block"
          style={{
            borderTopRightRadius: "0%",
            borderBottomRightRadius: "0%",
            borderTopLeftRadius: "2%",
            borderBottomLeftRadius: "2%",
          }}
        >
          <div className="signup-message-wrapper">
            <div className="mb-4">
              <span>
                <img
                  className=""
                  src="./assets/images/logo-light.png"
                  onClick={() => props.history.push("/")}
                  alt="skills ranker logo"
                  style={{
                    cursor: "pointer",
                    width: "120px",
                  }}
                />{" "}
              </span>{" "}
              <h1
                className="mt-4"
                style={{
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                Already have an account ?{" "}
              </h1>{" "}
            </div>{" "}
            {/* <div className="mb-4">
                          <p>Creat an account, rank your skills and get hired</p>
                        </div> */}{" "}
            <div className="mt-3">
              <button
                type="button"
                onClick={() => props.history.push("/login")}
                className="signup-1-btn btn btn-rounded waves-effect waves-light"
              >
                {" "}
                LOGIN{" "}
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div
          className="col login-signup-form"
          style={{
            padding: "1.5rem 1.5rem",
            minHeight: "25rem",
          }}
        >
          <h2
            style={{
              marginTop: "0rem",
            }}
          >
            {" "}
            Create Account{" "}
          </h2>{" "}
          {isLoading ? (
            "Creating account..."
          ) : (
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
              {error !== "" ? <Error error={error} /> : ""}{" "}
              <div className="mb-4">
                <input
                  id="validationDefault01"
                  name="email"
                  className=" login-signin-input form-control"
                  type="email"
                  style={{
                    border: isValidEmail(email) ? "black" : "red",
                  }}
                  required
                  placeholder="Enter email"
                  onBlur={(e) => {
                    //console.log(e.target.value);
                    !isValidEmail(e.target.value)
                      ? displayError("Please fill out email")
                      : setEmail(e.target.value);
                    //document.getElementById("validationDefault01").focus();
                    !isValidEmail(e.target.value)
                      ? document.getElementById("validationDefault01").focus()
                      : setEmail(e.target.value);
                  }}
                  onChange={(event) => {
                    !isValidEmail(event.target.value)
                      ? displayError("Please fill out email")
                      : setEmail(event.target.value);
                  }}
                />{" "}
                <div className="invalid-feedback">
                  Please input a valid email
                </div>
              </div>{" "}
              <div className="mb-4">
                <select
                  value={selectAccount}
                  onChange={(e) => setSelectAccount(e.target.value)}
                  className="login-signin-input form-control"
                >
                  <option value="Select" disabled>
                    Select Account Type
                  </option>
                  <option value="developer">Developer</option>
                  <option value="business">Business</option>
                  {/* <option value="sponsor">Event Sponsor</option> */}
                </select>
              </div>
              {selectAccount === "business" ? (
                <label className="mb-2 mt-0 pt-0 text-small text-uppercase d-flex">
                  Contact Person
                </label>
              ) : (
                ""
              )}
              <div className="mb-4">
                <input
                  id="firstName"
                  className="login-signin-input form-control"
                  type="text"
                  required
                  placeholder="Enter first name"
                  onChange={(event) => setFirstName(event.target.value)}
                />{" "}
              </div>{" "}
              <div className="mb-4">
                <input
                  id="lastName"
                  className="login-signin-input form-control"
                  type="text"
                  required
                  placeholder="Enter last name"
                  onChange={(event) => setLastName(event.target.value)}
                />{" "}
              </div>{" "}
              <div className="mb-4">
                <input
                  id="userpassword"
                  className="login-signin-input form-control"
                  type="password"
                  required
                  minLength="8"
                  maxLength="28"
                  placeholder="Enter password (>=8 characters)"
                  onChange={(event) => setPassword(event.target.value)}
                />{" "}
              </div>{" "}
              <div className="mb-1">
                <input
                  id="userpasswordConfirm"
                  className=" login-signin-input form-control"
                  type="password"
                  required
                  minLength="8"
                  maxLength="28"
                  placeholder="Confirm password (>=8 characters)"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />{" "}
              </div>{" "}
              <div className="mt-2 small" style={{ textAlign: "left" }}>
                <input
                  type="checkbox"
                  id="customCheck1"
                  value={confirmConsent}
                  defaultChecked={confirmConsent}
                  onChange={() => {
                    setConsent(!confirmConsent);
                  }}
                />
                <span className="ml-2">
                  By clicking the checkbox, I agree that I have read and agreed
                  to the&nbsp;{" "}
                </span>
                <a
                  className="mt-0 pt-0 "
                  href="/privacy-policy"
                  target="_blank" rel="noreferrer"
                  alt="I have read and agreed to the privacy policy"
                  style={{ color: "rgb(255,196,12)" }}
                >
                  privacy policy
                </a>
                {["sponsor", "business"].includes(selectAccount) ? (
                  <>
                    &nbsp;and&nbsp;{" "}
                    <a
                      className="mt-0 pt-0 "
                      href="/privacy-policy"
                      target="_blank" rel="noreferrer"
                      alt="I have read and agreed to the privacy policy"
                      style={{ color: "rgb(255,196,12)" }}
                    >
                      {" "}
                      terms and conditions
                    </a>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3">
                {isFormValid() ? (
                  <button
                    type="submit"
                    className="btn login-btn btn-rounded waves-effect waves-light"
                    style={{
                      padding: "0.47rem 2.75rem",
                    }}
                  >
                    {" "}
                    SIGN UP{" "}
                  </button>
                ) : (
                  <button
                    disabled
                    type="submit"
                    className="btn login-btn btn-rounded waves-effect waves-light"
                    style={{
                      padding: "0.47rem 2.75rem",
                    }}
                  >
                    {" "}
                    SIGN UP{" "}
                  </button>
                )}
              </div>{" "}
            </form>
          )}{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUpUser: (signUpDetails) => dispatch(signUpUser(signUpDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp2);
