import React from "react";

function BadgeHorizontal(props) {
  function goToAssessement(assessmentName) {
    window.location = "/profile/assessments?subject=" + assessmentName;
  }

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => goToAssessement(props.data)}
      className="col-lg-3 col-sm-6 pl-3 pr-3 mt-2 mb-2"
    >
      <div
        key={props.key}
        style={{
          height: "4rem",
          backgroundColor: "#f8f8fb",
          display: "flex",
          padding: "1rem",
          borderRadius: ".5rem",
          width: "100%",
        }}
      >
        <div className='d-inline-flex' style={{ width: "50px", height: "auto" }}>
          <img className="mx-auto"
            alt={props.data}
            src={props.icon}
            style={{ maxHeight: "100%", maxWidth: "100%", backgroundSize: "cover" }}
          />
        </div>
        {props.currentSubject && props.data === props.currentSubject ? (
          <i
            style={{ alignSelf: "center" }}
            className="text-success fa fa-lg fa-check-circle"
          ></i>
        ) : (
            ""
          )}
        <p
          className="ml-2 text-truncate"
          style={{
            margin: "auto",
            fontSize: "16px",
            lineHeight: 2,
            color: "#000",
          }}
        >
          {props.data}
        </p>
      </div>
    </div>
  );
}
// const mapStateToProps = (state) =>{
//   return{
//     profile : state.userProfile
//   }
// }

// const mapDispatchToProps =(dispatch) => {
//   return {
//     updateStoreProfile:(profile) => dispatch(updateStoreProfile(profile)),
//     auth:() => dispatch({type:'AUTHENTICATE', data:true})
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wrapper));
export default BadgeHorizontal;
