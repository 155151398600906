const initState = {
  notificationsList: [],
};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return {
        ...state,
        notificationsList: state.notificationsList.concat([action.data || action.data.err])
      };

    case "DELETE_NOTIFICATION":
      return {
        ...state,
        notificationsList: state.notificationsList.filter((notification) => notification.id !== action.data.id)
      };

    default:
      break;
  }
  return state;
};

export default notificationsReducer;