import React, { Component } from "react";
import {
  fetchContest,
  publishContest,
  deleteContest,
  addEvent,
  updateContest,
} from "../../store/actions/contestActions";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import $ from "jquery";
import Cookie from "js-cookie";
import Select from "react-select";
import ReactChipInput from "react-chip-input";
import bih from "../../../src/bih.jpg";
import { fireNotification } from "../../store/actions/notificationsActions";
const moment = require("moment");
// require('bootstrap');

class AdminContestNew extends Component {
  state = {
    id: "",
    name: "",
    title: "",
    startDate: "",
    registrationStartDate: "",
    registrationEndDate: "",
    endDate: "",
    info: "",
    rules: "",
    tags: [],
    type: "",
    summary: "",
    description: "",
    prizes: "",
    supportingFiles: "",
    rulesAndLegal: "",
    eventType: "",
    eventTier: "individuals",
    capacity: 1,
    judges: [],
    focusJudge: "",
    focusCriteria: {},
    evaluationCriteria: [],
    sponsors: "",
    teamsLimit: "",
    minTeamSize: 4,
    minTeamSize: 1,
    entryFee: "",
    location: "",
    submissionsRequired: true,
    eventBanner: {},
    isLoaded: true,
    caughtErr: false,
    isEditing: false,
    isJudgeEditing: false,
    judgeEditIndex: 0,
    criteriaEditIndex: 0,
    roundIndex: 0,
    isCriteriaEditing: false,
    rounds: [],
    focusRound: { evalCriterias: [] },
  };

  submissionStatus = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    // let wizardElement = window.$('#wizard');

    // });
  }

  componentDidMount() {
    // var myDropzone = new Dropzone("#eventBannerDropZone", {});
    // document.addEventListener("DOMContentLoaded", function () {
    //     Dropzone.options.eventBannerDropZone = {
    //         init: function () {
    //             this.on("addedfile", function (file) {
    //                 console.log(file);
    //             });
    //         }
    //     };
    // });

    //   check if creating a new contest or updating an old one
    const { history } = this.props;
    let splitPath = history.location.pathname.split("/");
    // let region = splitPath[2]
    let intent = splitPath[3];
    let cid = splitPath[3];

    if (intent === "edit") {
      cid = splitPath[4];
    }

    const ctx = this;
    document.addEventListener("DOMContentLoaded", () => {
      // $(() => {
      // });
      let wizard = new Wizard($("#wizard"));
      wizard.eventDetails = this.state;
      wizard.submitFunction = this.handleSubmit;
      let buttonNext = document.querySelector(".next");
      let buttonPrevious = document.querySelector(".previous");

      wizard.addControls(buttonPrevious, buttonNext);

      const observer = new MutationObserver((e) => {
        let stateValue = $(e[0].target.ownerDocument.activeElement).html();
        console.log(stateValue);
        // the id must be set to the name of the object in state object
        let stateKey = $(e[0].target.ownerDocument.activeElement.parentElement)
          .parent()
          .prev()
          .attr("id");
        this.setState({ [stateKey]: stateValue });
      });

      observer.observe($(".note-editable")[0], {
        characterData: true,
        childList: true,
        attributes: true,
        subtree: true,
      });

      observer.observe($(".note-editable")[1], {
        characterData: true,
        childList: true,
        attributes: true,
        subtree: true,
      });

      observer.observe($(".note-editable")[2], {
        characterData: true,
        childList: true,
        attributes: true,
        subtree: true,
      });

      observer.observe($(".note-editable")[3], {
        characterData: true,
        childList: true,
        attributes: true,
        subtree: true,
      });
      observer.observe($(".note-editable")[4], {
        characterData: true,
        childList: true,
        attributes: true,
        subtree: true,
      });
    });

    // window.jQuery = $;
    // window.$ = $
    // window.$('#summernote').summernote({height:100, tabsize:2,minHeight:null,maxHeight:null,focus:0});

    if (cid === "new") {
      // Creating new contest, toggle component editing state to false, isLoaded to true
      this.setState({ isEditing: false, isLoaded: true, caughtErr: false });
    } else {
      // Editing existing contest,
      // toggle component editing state to true
      // Get contest data to edit
      // update component state with contest data and toggle is loaded state to true

      this.setState({ isEditing: true });
      this.props.getContest(cid).then(() =>
        this.setState({
          id: this.props.contest._id,
          name: this.props.contest.title,
          description: this.props.contest.description,
          rulesAndLegal: this.props.contest.rulesAndLegal,
          prizes: this.props.contest.prizes,
          capacity: this.props.contest.teamsCapacity,
          tags: this.props.contest.tags,
          teamsLimit: this.props.contest.teamSize,
          judges: this.props.contest.judges,
          eventType: {
            value: this.props.contest.eventType,
            label: this.props.contest.eventType,
          },
          isLoaded: true,
          submissionsRequired: this.props.contest.submissionsRequired,
          summary: this.props.contest.shortDescription,
          startDate: this.props.contest.startDate,
          endDate: this.props.contest.endDate,
          minTeamSize: this.props.contest.minTeamSize,
          maxTeamSize: this.props.contest.maxTeamSize,
          evaluationCriteria: this.props.contest.evaluationCriteria,
          registrationStartDate: this.props.contest.registrationStartDate,
          registrationEndDate: this.props.contest.registrationEndDate,
          rounds: this.props.contest.rounds,
        })
      );
    }
  }

  /**
   * update state with input change
   *
   * @param  {} doc key value pair with state variable to change as key and variable value as value
   */
  handleInputChange(doc) {
    this.setState(doc);
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  uploadFile(event, ctx) {
    console.log(event);
    let file = event.target.files[0];

    ctx.getBase64(file, (result) => {
      ctx.setState({
        eventBanner: {
          data: result,
          contentType: result.split(";")[0].split("/")[1],
        },
      });
    });
  }

  uploadProfile(event, ctx) {
    console.log(event);
    let file = event.target.files[0];

    ctx.getBase64(file, (result) => {
      ctx.setState({
        focusJudge: {
          ...this.state.focusJudge,
          image: {
            data: result,
            contentType: result.split(";")[0].split("/")[1],
          },
        },
      });
    });
  }

  addJudge = (value) => {
    const judges = this.state.judges.slice();
    judges.push(value);
    this.setState({ judges });
  };

  addJudge2 = () => {
    const judges = this.state.judges;
    judges.push(this.state.focusJudge);
    this.setState({ judges });
    this.setState({
      focusJudge: {
        name: "Judge Full Name",
        title: "Judge Title/Company",
        email: "Judge Email",
        description: "Some information about the judge",
      },
    });
  };

  selectJudge = (judge, idx) => {
    this.setState({
      focusJudge: judge,
      judgeEditIndex: idx,
      isJudgeEditing: true,
    });
  };

  removeJudge = (index) => {
    const judges = this.state.judges.slice();
    judges.splice(index, 1);
    this.setState({ judges });
  };

  removeJudge2 = (index) => {
    const judges = this.state.judges.filter((judge, idx) => idx != index);
    // judges.splice(index, 1);
    this.setState({
      judges: judges,
      isJudgeEditing: false,
      focusJudge: {
        name: "Judge Full Name",
        title: "Judge Title/Company",
        email: "Judge Email",
        description: "Some information about the judge",
      },
    });
  };

  editJudge = () => {
    let judges = this.state.judges.filter(
      (judge, idx) => idx != this.state.judgeEditIndex
    );
    judges.push(this.state.focusJudge);
    this.setState({
      judges: judges,
      focusJudge: {
        name: "Judge Full Name",
        title: "Judge Title/Company",
        email: "Judge Email",
        description: "Some information about the judge",
      },
      isJudgeEditing: false,
    });
  };

  editCriteria = () => {
    var criteria = this.state.focusCriteria;

    let evalCriterias = this.state.focusRound.evalCriterias.filter(
      (criteria, idx) => idx != this.state.criteriaEditIndex
    );
    evalCriterias.push(criteria);

    let rounds = this.state.rounds;
    let round = rounds[this.state.roundIndex];
    round.evalCriterias = evalCriterias;
    rounds[this.state.roundIndex] = round;

    this.setState({
      evaluationCriteria: evalCriterias,
      focusCriteria: {
        title: "Criteria Title",
        maxScore: 100,
        description: "Some information about the evaluation criteria",
      },
      isCriteriaEditing: false,
      focusRound: { ...this.state.focusRound, evalCriterias: evalCriterias },
    });
  };

  selectCriteria(criteria, idx) {
    this.setState({
      focusCriteria: criteria,
      criteriaEditIndex: idx,
      isCriteriaEditing: true,
    });
  }
  removeCriteria(idx) {
    let rounds = this.state.rounds;
    let round = this.state.rounds[this.state.roundIndex];
    let criteriaList = round.evalCriterias.filter((ci, index) => index != idx);
    round.evalCriterias = criteriaList;
    rounds[this.state.roundIndex] = round;

    this.setState({
      rounds: rounds,
      isCriteriaEditing: false,
      focusRound: { ...this.state.focusRound, evalCriterias: criteriaList },
    });
  }

  // buggy
  addCriteria2 = (round, roundIdx) => {

    let criteria = this.state.focusCriteria;
    console.log("r index >>", roundIdx)

    let rounds = this.state.rounds;

    let target_round = this.state.focusRound

    target_round.evalCriterias.push(criteria);

    rounds[roundIdx] = target_round;
    console.log("t r >> ", target_round)
    console.log(rounds)

    this.setState({ rounds: rounds });
    this.setState({
      focusCriteria: {
        title: "Criteria Title",
        maxScore: 100,
        description: "Some information about the evaluation criteria",
      },
    });
  };

  cancelEdit = () => {
    this.setState({
      isJudgeEditing: false,
      focusJudge: {
        name: "Judge Full Name",
        title: "Judge Title/Company",
        email: "Judge Email",
        description: "Some information about the judge",
      },
    });
  };
  cancelEvalEdit = () => {
    this.setState({
      isCriteriaEditing: false,
      focusCriteria: {
        title: "Criteria Title",
        maxScore: 100,
        description: "Some information about the evaluation criteria",
      },
    });
  };

  selectEvaluationCriteria = (criteria, idx) => {
    this.setState({
      focusCriteria: criteria,
      criteriaEditIndex: idx,
      isCriteriaEditing: true,
    });
  };

  selectEvaluationRound = (round, idx) => {
    this.setState({
      focusRound: round,
      roundIndex: idx,
      isCriteriaEditing: false,
    });
  };

  eventTypes = [
    {
      value: "online",
      label: "Online",
    },
    {
      value: "physical",
      label: "Physical",
    },
  ];

  eventTiers = [
    {
      value: "business",
      label: "Business",
    },
    {
      value: "individuals",
      label: "Developer Only",
    },
    {
      value: "both",
      label: "Both",
    },
  ];

  submissionStatus = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  /**
   * delete current contest
   */
  removeContest() {
    this.props.removeContest(this.state.id);
  }

  /**
   * submit contest data to backend
   * prevent page from refreshing
   *
   * @param  {} e submit event
   */
  handleSubmit = () => {
    var userId = JSON.parse(Cookie.get("user"))._id;

    if (this.state.isEditing) {
      let data = {
        eventName: this.state.name,
        eventType: this.state.eventType.value,
        teamsCapacity: this.state.capacity,
        shortDescription: this.state.summary,
        description: this.state.description,
        startDate: new Date(this.state.startDate).toUTCString(),
        endDate: new Date(this.state.endDate).toUTCString(),
        rules: this.state.rulesAndLegal,
        prizes: this.state.prizes,
        evaluationCriteria: this.state.evaluationCriteria,
        legal: this.state.legal,
        sponsors: this.state.sponsors,
        teamSize: this.state.teamsLimit,
        tags: this.state.tags,
        location: this.state.location,
        entryFee: this.state.entryFee,
        eventBanner: this.state.eventBanner,
        submissionsRequired: this.state.submissionsRequired.value,
        userId: userId,
        judges: this.state.judges,
        faqs: this.state.faqs,
        minTeamSize: this.state.minTeamSize,
        maxTeamSize: this.state.maxTeamSize,
        registrationStartDate: new Date(this.state.registrationStartDate).toUTCString(),
        registrationEndDate: new Date(this.state.registrationEndDate).toUTCString(),
        eventTier: this.state.eventTier.value,
        rounds: this.state.rounds
      };
      this.props.updateContest(this.state.id, data);
    } else {
      let data = {
        eventName: this.state.name,
        eventType: this.state.eventType.value,
        teamsCapacity: this.state.capacity,
        shortDescription: this.state.summary,
        description: this.state.description,
        startDate: new Date(this.state.startDate).toUTCString(),
        endDate: new Date(this.state.endDate).toUTCString(),
        rules: this.state.rulesAndLegal,
        prizes: this.state.prizes,
        evaluationCriteria: this.state.evaluationCriteria,
        legal: this.state.legal,
        sponsors: this.state.sponsors,
        teamSize: this.state.teamsLimit,
        tags: this.state.tags,
        location: this.state.location,
        entryFee: this.state.entryFee,
        eventBanner: this.state.eventBanner,
        submissionsRequired: this.state.submissionsRequired.value,
        userId: userId,
        judges: this.state.judges,
        faqs: this.state.faqs,
        minTeamSize: this.state.minTeamSize,
        maxTeamSize: this.state.maxTeamSize,
        registrationStartDate: new Date(this.state.registrationStartDate).toUTCString(),
        registrationEndDate: new Date(this.state.registrationEndDate).toUTCString(),
        eventTier: this.state.eventTier.value,
        rounds: this.state.rounds
      };
      this.props.pushContest(data);
    }

  };

  onChange(content) {
    console.log("onChange", content);
  }

  submitRound() {
    let rounds = this.state.rounds;
    rounds.push(this.state.focusRound);
    this.setState({ rounds: rounds });
  }

  /**
   * check if all required data is loaded
   * render componet if true else render loading animations
   */
  render() {
    // let wizardElement = window.$('wizard');
    // console.log(wizardElement);
    // let wizard = new Wizard(wizardElement);
    // console.log(wizard)
    // let buttonNext = document.querySelector('.next');
    // let buttonPrevious = document.querySelector('.previous');

    // wizard.addControls(buttonPrevious, buttonNext);

    if (this.state.isLoaded) {
      return (
        <div className="main-content pb-5">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <div
                    id="addRoundModal"
                    className="modal fade bs-example-modal-sm"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    style={{ backgroundColor: "#0000007d" }}
                  >
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              window.$("#addRoundModal").modal("hide");
                              this.submitRound();
                            }}
                          >
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group mb-0">
                                  <label className="control-label">Title</label>
                                  <br />
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      if (e.target.checkValidity()) {
                                        this.handleInputChange({
                                          focusRound: {
                                            evalCriterias: [],
                                            ...this.state.focusRound,
                                            title: e.target.value,
                                          },
                                        });
                                      }
                                    }}
                                    className="form-control"
                                    id="judgename"
                                    value={this.state.focusRound.title}
                                    required
                                    placeholder="Round title"
                                  />
                                  <div className="invalid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <label className="control-label">
                                    Start time
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        focusRound: {
                                          evalCriterias: [],
                                          ...this.state.focusRound,
                                          start: e.target.value,
                                        },
                                      })
                                    }
                                    className="form-control"
                                    id="date"
                                    value={this.state.focusRound.start}
                                    required
                                    placeholder="Round start date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group mb-2">
                                  <label className="control-label">
                                    End time
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        focusRound: {
                                          evalCriterias: [],
                                          ...this.state.focusRound,
                                          end: e.target.value,
                                        },
                                      })
                                    }
                                    className="form-control"
                                    id="date"
                                    value={this.state.focusRound.end}
                                    required
                                    placeholder="Round end date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    Description
                                  </label>
                                  <br />
                                  <textarea
                                    type="text"
                                    onChange={(e) => {
                                      if (e.target.checkValidity()) {
                                        this.handleInputChange({
                                          focusRound: {
                                            evalCriterias: [],
                                            ...this.state.focusRound,
                                            description: e.target.value,
                                          },
                                        });
                                      }
                                    }}
                                    className="form-control"
                                    id="judgename"
                                    value={this.state.focusRound.description}
                                    required
                                    placeholder="Round Description"
                                  />
                                  <div className="invalid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                              </div>
                              <div className="col-12">
                                <button
                                  type="submit"
                                  className="btn btn-primary col-12"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div id="wizard" className="wizard" ref={this.myRef}>
                      <div className="wizard__content">
                        <header
                          className="wizard__header"
                          style={{ backgroundImage: "url(" + bih + ")" }}
                        >
                          <div className="wizard__header-overlay"></div>

                          <div className="wizard__header-content text-center">
                            <h1
                              className="wizard__title"
                              style={{
                                color: "white",
                                fontWeight: "bolder",
                                fontFamily: "Ubuntu !important",
                              }}
                            >
                              {!this.state.isEditing ? "Create New" : "Edit"}{" "}
                              Event&nbsp;&nbsp;
                              {this.state.isEditing ? (
                                <></>
                              ) : (
                                <i className="fa fa-rss"></i>
                              )}
                            </h1>
                            {/* <p style={{ fontFamily: "Ubuntu !important" }}>Share your event's details and await approval for your event to be shared to developers.</p> */}
                            {/* <p className="wizard__subheading">Start with <span>3</span> simple steps.</p> */}
                          </div>

                          <div className="wizard__steps">
                            <nav className="steps">
                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">1</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -start"></div>

                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">2</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">3</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">4</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">5</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">6</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">7</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="step">
                                <div className="step__content">
                                  <p className="step__number">8</p>
                                  <svg
                                    className="checkmark"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                  >
                                    <circle
                                      className="checkmark__circle"
                                      cx="26"
                                      cy="26"
                                      r="25"
                                      fill="none"
                                    />
                                    <path
                                      className="checkmark__check"
                                      fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                    />
                                  </svg>

                                  <div className="lines">
                                    <div className="line -background"></div>

                                    <div className="line -progress"></div>
                                  </div>
                                </div>
                              </div>
                            </nav>
                          </div>
                        </header>

                        <div className="panels">
                          <div className="panel">
                            <header className="panel__header">
                              {/* <h2 className="panel__title">Create an event</h2> */}
                            </header>

                            <div className="row">
                              <div className=" col-xl-6">
                                <div className="form-horizontal">
                                  <div className="form-group mt-2  mb-0">
                                    <label className="control-label">
                                      Event name
                                    </label>
                                    <br />
                                    <input
                                      type="text"
                                      onChange={(e) => {
                                        if (e.target.checkValidity()) {
                                          this.handleInputChange({
                                            name: e.target.value,
                                          });
                                        }
                                      }}
                                      className="form-control"
                                      id="name"
                                      value={this.state.name}
                                      required
                                      placeholder="Event name"
                                    />
                                    <div className="invalid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>

                                  <div className=" form-group mb-0">
                                    <label className="control-label">
                                      Event Type
                                    </label>
                                    <br />
                                    <Select
                                      options={this.eventTypes}
                                      onChange={(e) =>
                                        this.handleInputChange({ eventType: e })
                                      }
                                      value={this.state.eventType}
                                      required
                                      className="basic-select"
                                      id="type"
                                      classNamePrefix="select"
                                      style={{
                                        width: "-webkit-fill-available",
                                      }}
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>
                                  <div className="form-group mb-0">
                                    <label className="control-label">
                                      Event Capacity
                                    </label>
                                    <br />
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        this.handleInputChange({
                                          capacity: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="capacity"
                                      value={this.state.capacity}
                                      required
                                      placeholder="Number of teams"
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>

                                  <div className="form-group mb-0">
                                    <label className="control-label">
                                      Max team Size
                                    </label>
                                    <br />
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        this.handleInputChange({
                                          maxTeamSize: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="size"
                                      value={this.state.maxTeamSize}
                                      required
                                      placeholder="Max team size"
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>
                                  <div className="form-group mb-0">
                                    <label className="control-label">
                                      Min team Size
                                    </label>
                                    <br />
                                    <input
                                      type="number"
                                      onChange={(e) =>
                                        this.handleInputChange({
                                          minTeamSize: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      id="size"
                                      value={this.state.minTeamSize}
                                      required
                                      placeholder="Min team size"
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>

                                  <div className=" form-group mb-0">
                                    <label className="control-label">
                                      Submission Required
                                    </label>
                                    <br />
                                    <Select
                                      options={this.submissionStatus}
                                      required
                                      onChange={(e) =>
                                        this.handleInputChange({
                                          submissionsRequired: e,
                                        })
                                      }
                                      value={this.state.focus}
                                      id="submission"
                                      className="basic-select"
                                      classNamePrefix="select"
                                      style={{
                                        width: "-webkit-fill-available",
                                      }}
                                    />
                                    <div className="valid-feedback">
                                      Looks good!
                                    </div>
                                    <br />
                                  </div>

                                  <div className="form-group mb-0">
                                    <label className="control-label">
                                      Event banner (Recommended dimensions: 1200
                                      px by 600px)
                                    </label>
                                    <div className="fallback">
                                      <input
                                        name="file"
                                        id="banner"
                                        type="file"
                                        onChange={(event) =>
                                          this.uploadFile(event, this)
                                        }
                                      />
                                    </div>

                                    {/* <form action="#" id="eventBannerDropZone" className="dropzone">
                                                                            
                                                                            <div className="dz-message needsclick">
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                                </div>

                                                                                <h4>Drop image here or click to upload.</h4>
                                                                            </div>
                                                                        </form> */}
                                  </div>

                                  {/* <div className="form-group mt-3 mb-0">
                                                                        <label className="control-label">Event Judges</label>
                                                                        <div className="fallback">
                                                                            <ReactChipInput
                                                                                classes=""
                                                                                id="judges"
                                                                                chips={this.state.judges}
                                                                                onSubmit={value => this.addJudge(value)}
                                                                                onRemove={index => this.removeJudge(index)}
                                                                            />
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>

                              <div className="col-xl-6">
                                <div className=" form-group mb-0">
                                  <label className="control-label">
                                    Event Tier
                                  </label>
                                  <br />
                                  <Select
                                    options={this.eventTiers}
                                    onChange={(e) =>
                                      this.handleInputChange({ eventTier: e })
                                    }
                                    value={this.state.eventTier}
                                    required
                                    className="basic-select"
                                    id="tier"
                                    classNamePrefix="select"
                                    style={{
                                      width: "-webkit-fill-available",
                                    }}
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    Event Summary
                                  </label>
                                  <textarea
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        summary: e.target.value,
                                      })
                                    }
                                    className="form-control"
                                    id="summary"
                                    rows="4"
                                    maxLength={500}
                                    value={this.state.summary}
                                    required
                                    placeholder="Short Event Description"
                                  />
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">Event Schedule</h2>
                            </header>

                            <div className="row">
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h2 className="panel__title">Registration</h2>
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    Registration Start Date
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        registrationStartDate: e.target.value,
                                      })
                                    }
                                    className="form-control"
                                    id="date"
                                    value={this.state.registrationStartDate}
                                    required
                                    placeholder="Event registration start date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    Registration End Date
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        registrationEndDate: e.target.value,
                                      })
                                    }
                                    className="form-control"
                                    id="date"
                                    value={this.state.registrationEndDate}
                                    required
                                    placeholder="Event registration end date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h2 className="panel__title">Event Duration</h2>
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    Start Date
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        startDate: e.target.value,
                                      })
                                    }
                                    className="form-control"
                                    id="date"
                                    value={this.state.date}
                                    required
                                    placeholder="Event start date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                                <div className="form-group mb-0">
                                  <label className="control-label">
                                    End Date
                                  </label>
                                  <br />
                                  <input
                                    type="datetime-local"
                                    onChange={(e) =>
                                      this.handleInputChange({
                                        endDate: e.target.value,
                                      })
                                    }
                                    className="form-control"
                                    id="enddate"
                                    value={this.state.date}
                                    required
                                    placeholder="End Date"
                                  />
                                  <div className="valid-feedback">
                                    Looks good!
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h2 className="panel__title mb-0">Rounds</h2>
                                <div
                                  data-toggle="modal"
                                  data-target="#addRoundModal"
                                  style={{
                                    padding: "5px 10px",
                                    color: "black",
                                    cursor: "pointer",
                                    float: "right",
                                  }}
                                  href="#"
                                  className="badge bg-primary font-size-11 m-1 text-light"
                                >
                                  &nbsp;Add new round
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {this.state.rounds.map((round) => (
                                <div
                                  className="col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-0 mt-3 mr-1 p-2"
                                  style={{ border: "dashed 1px gray" }}
                                >
                                  <p>Title: {round.title}</p>
                                  <p>
                                    Start:{" "}
                                    {moment(round.start).format(
                                      "MMM DD YYYY HH:mm"
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    End:{" "}
                                    {moment(round.end).format(
                                      "MMM DD YYYY HH:mm"
                                    )}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              {/* <h2 className="panel__title">Create an event</h2> */}
                            </header>

                            <div className="row">
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-horizontal">
                                  {/* judge list  */}
                                  {this.state.judges.length > 0
                                    ? this.state.judges.map((judge, idx) => (
                                      <div
                                        onClick={() =>
                                          this.selectJudge(judge, idx)
                                        }
                                        style={{
                                          background: "#bdbdbd4d",
                                          color: "black",
                                          padding: ".5rem",
                                          marginBottom: ".2rem",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <div>
                                          <p
                                            className="mb-0"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {judge.name}
                                          </p>
                                          <p className="mb-0">
                                            {judge.title}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            marginTop: "-2.5rem",
                                            float: "right",
                                          }}
                                        >
                                          {/* <button className="mr-1 btn" style={{ color: "#2196F3", fontWeight: "bold" }}> Edit </button> */}
                                          <button
                                            className="btn"
                                            style={{
                                              color: "#2196F3",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              this.removeJudge2(idx)
                                            }
                                          >
                                            {" "}
                                            Remove{" "}
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                    : null}
                                  <div
                                    className="p-2"
                                    style={{ background: "#bdbdbd4d" }}
                                  >
                                    <div className="form-group mb-0 ">
                                      <label className="control-label">
                                        Full name
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          if (e.target.checkValidity()) {
                                            this.handleInputChange({
                                              focusJudge: {
                                                ...this.state.focusJudge,
                                                name: e.target.value,
                                              },
                                            });
                                          }
                                        }}
                                        className="form-control"
                                        id="judgename"
                                        value={this.state.focusJudge.name}
                                        required
                                        placeholder="Judge full name"
                                      />
                                      <div className="invalid-feedback">
                                        Looks good!
                                      </div>
                                      <br />
                                    </div>
                                    <div className="form-group mb-0">
                                      <label className="control-label">
                                        Title/Company
                                      </label>
                                      <br />
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          if (e.target.checkValidity()) {
                                            this.handleInputChange({
                                              focusJudge: {
                                                ...this.state.focusJudge,
                                                title: e.target.value,
                                              },
                                            });
                                          }
                                        }}
                                        className="form-control"
                                        id="judgeTitle"
                                        value={this.state.focusJudge.title}
                                        required
                                        placeholder="Judge Title"
                                      />
                                      <div className="invalid-feedback">
                                        Looks good!
                                      </div>
                                      <br />
                                    </div>
                                    <div className="form-group mb-0">
                                      <label>Email</label>
                                      <br />
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          if (e.target.checkValidity()) {
                                            this.handleInputChange({
                                              focusJudge: {
                                                ...this.state.focusJudge,
                                                email: e.target.value,
                                              },
                                            });
                                          }
                                        }}
                                        className="form-control"
                                        id="judgename"
                                        value={this.state.focusJudge.email}
                                        required
                                        placeholder="Judge email"
                                      />
                                      <div className="invalid-feedback">
                                        Looks good!
                                      </div>
                                      <br />
                                    </div>
                                    <div className="form-group mb-0">
                                      <label>Description</label>
                                      <br />
                                      <textarea
                                        onChange={(e) => {
                                          if (e.target.checkValidity()) {
                                            this.handleInputChange({
                                              focusJudge: {
                                                ...this.state.focusJudge,
                                                description: e.target.value,
                                              },
                                            });
                                          }
                                        }}
                                        className="form-control"
                                        id="description"
                                        value={
                                          this.state.focusJudge.description
                                        }
                                        required
                                        placeholder="Some information about the judge"
                                      />
                                      <div className="invalid-feedback">
                                        Looks good!
                                      </div>
                                      <br />
                                    </div>
                                    <div className="form-group mb-0">
                                      <label className="control-label">
                                        Photo
                                      </label>
                                      <br />
                                      <div className="fallback">
                                        <input
                                          name="file"
                                          id="banner"
                                          type="file"
                                          onChange={(event) =>
                                            this.uploadProfile(event, this)
                                          }
                                        />
                                      </div>
                                      <div className="invalid-feedback">
                                        Looks good!
                                      </div>
                                      <br />
                                    </div>
                                    <div className="form-group mb-0">
                                      {this.state.isJudgeEditing ? (
                                        <>
                                          <button
                                            className="btn btn-primary mr-2"
                                            style={{
                                              borderColor: "rgb(90, 25, 6)",
                                              background: "rgb(90, 25, 6)",
                                            }}
                                            onClick={() => this.editJudge()}
                                          >
                                            Edit Judge
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            style={{
                                              borderColor: "rgb(90, 25, 6)",
                                              background: "rgb(90, 25, 6)",
                                            }}
                                            onClick={() => this.cancelEdit()}
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <button
                                          className="btn btn-primary"
                                          style={{
                                            borderColor: "rgb(90, 25, 6)",
                                            background: "rgb(90, 25, 6)",
                                          }}
                                          onClick={() => this.addJudge2()}
                                        >
                                          Add Judge
                                        </button>
                                      )}
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div
                                  className="text-center"
                                  style={{ width: "70%", margin: "0 auto" }}
                                >
                                  {/* center profile image */}
                                  <img
                                    src={
                                      this.state.focusJudge.image
                                        ? this.state.focusJudge.image.data
                                        : "/assets/images/users/avatar.png"
                                    }
                                    alt=""
                                    className="rounded-circle avatar-lg mb-1"
                                    style={{
                                      background: "rgb(158 158 158 / 77%)",
                                      width: "8rem",
                                      height: "8rem",
                                      objectFit: "cover",
                                    }}
                                  ></img>
                                  {/* full name */}
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    {this.state.focusJudge.name}
                                  </p>
                                  {/* title */}
                                  <p>{this.state.focusJudge.title}</p>
                                  {/* email */}
                                  <p>{this.state.focusJudge.email}</p>
                                  <p>{this.state.focusJudge.description}</p>

                                  {/* add remove buttons */}
                                  {/* <div className="form-group mb-0">
                                                                        <button className="mr-1 btn" style={{ color: "#2196F3", fontWeight: "bold" }}> Edit </button>
                                                                        <button className="btn" style={{ color: "#2196F3", fontWeight: "bold" }}> Remove </button>
                                                                    </div> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Add Evaluation Criteria */}
                          <div className="panel">
                            <header className="panel__header">
                              {/* <h2 className="panel__title">Create an event</h2> */}
                            </header>

                            <div className="row">
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div id="accordion" className="form-horizontal">
                                  {/* criteria list  */}
                                  {this.state.rounds.length > 0
                                    ? this.state.rounds.map((round, idx) =>
                                      <div className="card" onClick={() => this.selectEvaluationRound(round, idx)}>
                                        <div className="card-header" id="headingOne">
                                          <h5 className="mb-0">
                                            <p style={{ fontWeight: "bold" }} className="btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                              ROUND: {round.title}
                                            </p>
                                          </h5>
                                        </div>

                                        <div id="collapseOne" className={idx == 0 ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                                          <div className="card-body">
                                            <div className="form-group mb-0 ">
                                              <label className="control-label">
                                                Title
                                              </label>
                                              <br />
                                              <input
                                                type="text"
                                                onChange={(e) => {
                                                  if (e.target.checkValidity()) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state.focusCriteria,
                                                        title: e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                // id="judgename"
                                                value={this.state.focusCriteria.title}
                                                required
                                                placeholder="Evaluation criteria title"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                            <div className="form-group mb-0">
                                              <label className="control-label">
                                                Max score
                                              </label>
                                              <br />
                                              <input
                                                type="number"
                                                onChange={(e) => {
                                                  if (e.target.checkValidity()) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state.focusCriteria,
                                                        maxScore: e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="judgeTitle"
                                                value={
                                                  this.state.focusCriteria.maxScore
                                                }
                                                required
                                                placeholder="Max allowable score"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>

                                            <div className="form-group mb-0">
                                              <label>Description</label>
                                              <br />
                                              <textarea
                                                onChange={(e) => {
                                                  if (e.target.checkValidity()) {
                                                    this.handleInputChange({
                                                      focusCriteria: {
                                                        ...this.state.focusCriteria,
                                                        description: e.target.value,
                                                      },
                                                    });
                                                  }
                                                }}
                                                className="form-control"
                                                id="description"
                                                value={
                                                  this.state.focusCriteria.description
                                                }
                                                required
                                                placeholder="Some information about the evaluation criteria"
                                              />
                                              <div className="invalid-feedback">
                                                Looks good!
                                              </div>
                                              <br />
                                            </div>
                                            <div className="form-group mb-0">
                                              {this.state.isCriteriaEditing ? (
                                                <>
                                                  <button
                                                    className="btn btn-primary mr-2"
                                                    style={{
                                                      borderColor: "rgb(90, 25, 6)",
                                                      background: "rgb(90, 25, 6)",
                                                    }}
                                                    onClick={() => this.editCriteria()}
                                                  >
                                                    Edit evaluation criteria
                                                  </button>
                                                  <button
                                                    className="btn btn-primary"
                                                    style={{
                                                      borderColor: "rgb(90, 25, 6)",
                                                      background: "rgb(90, 25, 6)",
                                                    }}
                                                    onClick={() =>
                                                      this.cancelEvalEdit()
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </>
                                              ) : (
                                                <button
                                                  className="btn btn-primary"
                                                  style={{
                                                    borderColor: "rgb(90, 25, 6)",
                                                    background: "rgb(90, 25, 6)",
                                                  }}
                                                  onClick={() => this.addCriteria2(round, idx)}
                                                >
                                                  Add evaluation criteria
                                                </button>
                                              )}
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                      </div>)

                                    : null}

                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {this.state.focusRound.evalCriterias.map(
                                  (criteria, idx) => (
                                    <div
                                      className="p-1"
                                      style={{
                                        margin: "0 auto",
                                        border: "dashed 1px gray",
                                      }}
                                    >
                                      {/* add remove buttons */}
                                      <div className="form-group mb-0">
                                        <button
                                          onClick={() =>
                                            this.removeCriteria(idx)
                                          }
                                          className="btn"
                                          style={{
                                            color: "#2196F3",
                                            fontWeight: "bold",
                                            float: "right",
                                          }}
                                        >
                                          {" "}
                                          Remove{" "}
                                        </button>
                                        <button
                                          onClick={() =>
                                            this.selectCriteria(criteria, idx)
                                          }
                                          className="mr-1 btn"
                                          style={{
                                            color: "#2196F3",
                                            fontWeight: "bold",
                                            float: "right",
                                          }}
                                        >
                                          {" "}
                                          Edit{" "}
                                        </button>
                                      </div>
                                      {/* title */}
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        Title: {criteria.title}
                                      </p>
                                      {/* max score */}
                                      <p>Max Score: {criteria.maxScore}</p>
                                      {/* description */}
                                      <p>Description: {criteria.description}</p>
                                      {/* round */}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">
                                Event Description
                              </h2>
                              <p className="panel__subheading">
                                Add and event description to detail your event
                              </p>
                            </header>

                            {/* <p className="panel__content">Add event description.</p> */}
                            <div id="description" className="summernote"></div>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">Prizes</h2>
                              <p className="panel__subheading">
                                If your event awards prizes now would be a good
                                time to say what they are.
                              </p>
                            </header>

                            <form>
                              {/* <textarea id="summernote" name="editordata" style={{ width: "100%", height: "200px" }}></textarea> */}
                              <div id="prizes" className="summernote"></div>
                            </form>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">Supporting files</h2>
                              <p className="panel__subheading">
                                Add any Additional files such as pdf documents,
                                maximum file size is 16 MB
                              </p>
                            </header>

                            <form>
                              <div
                                id="supportingFiles"
                                className="summernote"
                              ></div>
                            </form>
                            <form action="#" className="dropzone">
                              <div className="fallback">
                                <input
                                  name="file"
                                  type="file"
                                  id="supportings"
                                />
                              </div>
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>

                                <h4>Drop image here or click to upload.</h4>
                              </div>
                            </form>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">Rules and Legal.</h2>
                              <p className="panel__subheading">
                                Outline your event rules and any other legal
                                requirements.
                              </p>
                            </header>

                            <form>
                              {/* <textarea id="summernote" name="editordata" style={{ width: "100%", height: "200px" }}></textarea> */}
                              <div
                                id="rulesAndLegal"
                                className="summernote"
                              ></div>
                            </form>
                          </div>

                          <div className="panel">
                            <header className="panel__header">
                              <h2 className="panel__title">
                                FREQUENTLY ASKED QUESTIONS.
                              </h2>
                              <p className="panel__subheading">
                                Outline your events FAQs
                              </p>
                            </header>

                            <form>
                              {/* <textarea id="summernote" name="editordata" style={{ width: "100%", height: "200px" }}></textarea> */}
                              <div id="faqs" className="summernote"></div>
                            </form>
                          </div>
                        </div>

                        <div className="wizard__footer">
                          <button
                            className="button previous"
                            ref={this.previous}
                          >
                            Previous
                          </button>
                          <button className="button next" ref={this.next}>
                            Next
                          </button>
                        </div>
                      </div>

                      {
                        <h1 className="wizard__congrats-message">
                          Creating event for you... <br />
                        </h1>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contest: state.contestData.contest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEvent: (body) => dispatch(addEvent(body)),
    getContest: (cid) => dispatch(fetchContest(cid)),
    pushContest: (data) => dispatch(publishContest(data)),
    updateContest: (id, data) => dispatch(updateContest(id, data)),
    removeContest: (cid) => dispatch(deleteContest(cid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContestNew);

class Steps {
  constructor(wizard) {
    this.wizard = wizard;
    this.steps = this.getSteps();
    this.stepsQuantity = this.getStepsQuantity();
    this.currentStep = 0;
  }

  setCurrentStep(currentStep) {
    this.currentStep = currentStep;
  }

  getSteps() {
    return window.$(".step");
  }

  getStepsQuantity() {
    return this.getSteps().length;
  }

  handleConcludeStep(event) {
    event.preventDefault();

    this.steps[this.currentStep].classList.add("-completed");

    console.log(event.target);
    // props.
    //     addEvent({
    //         id,
    //         name,
    //         title,
    //         startDate,
    //         endDate,
    //         info,
    //         rules,
    //         prizes,
    //         tags,
    //         type,
    //         isEditing,
    //         shortDescription: summary,
    //         longDescription: description,
    //         prizes,
    //         supportingFiles,
    //         rulesAndLegal,
    //     })
    //     .the(() => {

    //     }).catch((e) => {

    //     });
  }

  // TODO
  validateSteps() {
    return true;
  }

  handleStepsClasses(movement) {
    if (movement > 0)
      this.steps[this.currentStep - 1].classList.add("-completed");
    else if (movement < 0)
      this.steps[this.currentStep].classList.remove("-completed");
  }
}

class Panels {
  constructor(wizard) {
    this.wizard = wizard;
    this.panelWidth = this.wizard.offsetWidth;
    this.panelsContainer = this.getPanelsContainer();
    this.panels = this.getPanels();
    this.currentStep = 0;

    this.updatePanelsPosition(this.currentStep);
    this.updatePanelsContainerHeight();
  }

  getCurrentPanelHeight() {
    return `${this.getPanels()[this.currentStep].offsetHeight}px`;
  }

  getPanelsContainer() {
    return window.$(".panels")[0];
  }

  getPanels() {
    return window.$(".panel");
  }

  updatePanelsContainerHeight() {
    this.panelsContainer.style.height = "800px"; //this.getCurrentPanelHeight();
    // console.log(this.getCurrentPanelHeight());
  }

  updatePanelsPosition(currentStep) {
    const panels = this.panels;

    for (let i = 0; i < panels.length; i++) {
      panels[i].classList.remove(
        "movingIn",
        "movingOutBackward",
        "movingOutFoward"
      );

      if (i !== currentStep) {
        if (i < currentStep) panels[i].classList.add("movingOutBackward");
        else if (i > currentStep) panels[i].classList.add("movingOutFoward");
      } else {
        panels[i].classList.add("movingIn");
      }
    }

    this.updatePanelsContainerHeight();
  }

  setCurrentStep(currentStep) {
    this.currentStep = currentStep;
    this.updatePanelsPosition(currentStep);
  }
}

class Wizard {
  constructor(obj, eventDetails, submitEvent) {
    this.eventDetails = eventDetails;
    this.submitFunction = submitEvent;
    this.wizard = obj;
    this.panels = new Panels(this.wizard);
    this.steps = new Steps(this.wizard);
    this.stepsQuantity = this.steps.getStepsQuantity();
    this.currentStep = this.steps.currentStep;
    this.concludeControlMoveStepMethod = this.steps.handleConcludeStep.bind(
      this.steps
    );
    this.wizardConclusionMethod = this.handleWizardConclusion.bind(this);
  }

  updateButtonsStatus() {
    if (this.currentStep === 0) this.previousControl.classList.add("disabled");
    else this.previousControl.classList.remove("disabled");
  }

  updateCurrentStep(movement) {
    this.currentStep += movement;
    this.steps.setCurrentStep(this.currentStep);
    this.panels.setCurrentStep(this.currentStep);

    this.handleNextStepButton();
    this.updateButtonsStatus();
  }

  handleNextStepButton() {
    if (this.currentStep === this.stepsQuantity - 1) {
      this.nextControl.innerHTML = "Submit";
      this.nextControl.removeEventListener(
        "click",
        this.nextControlMoveStepMethod
      );
      this.nextControl.addEventListener(
        "click",
        this.concludeControlMoveStepMethod
      );
      this.nextControl.addEventListener("click", this.wizardConclusionMethod);

      //this.handleSubmit()
    } else {
      this.nextControl.innerHTML = "Next";
      this.nextControl.classList.add("disabled");
      this.nextControl.addEventListener(
        "click",
        this.nextControlMoveStepMethod
      );
      this.nextControl.removeEventListener(
        "click",
        this.concludeControlMoveStepMethod
      );
      this.nextControl.removeEventListener(
        "click",
        this.wizardConclusionMethod
      );
    }
  }

  handleWizardConclusion() {
    window.$("#wizard").addClass("completed");
    console.log(this.eventDetails);
    this.submitFunction();
  }

  addControls(previousControl, nextControl) {
    this.previousControl = previousControl;
    this.nextControl = nextControl;
    this.previousControlMoveStepMethod = this.moveStep.bind(this, -1);
    this.nextControlMoveStepMethod = this.moveStep.bind(this, 1);

    previousControl.addEventListener(
      "click",
      this.previousControlMoveStepMethod
    );
    nextControl.addEventListener("click", this.nextControlMoveStepMethod);

    this.updateButtonsStatus();
  }

  moveStep(movement) {
    console.log(movement);
    var name = document.getElementById("name").value;
    // var type = document.getElementById("type").value
    var capacity = document.getElementById("capacity").value;
    var size = document.getElementById("size").value;
    // var submission = document.getElementById("submission").value
    var banner = document.getElementById("banner").value;
    // var judges = document.getElementById("judges").value
    var date = document.getElementById("date").value;
    var enddate = document.getElementById("enddate").value;
    // var summary = document.getElementById("summary").value
    var description = document.getElementById("description").value;
    var prizes = document.getElementById("prizes").value;
    // var supportingFiles = document.getElementById("supportings").value
    var rulesAndLegal = document.getElementById("rulesAndLegal").value;
    var faqs = document.getElementById("faqs").value;

    if (!name && movement === 1) {
      window.alert("Please enter event name");
      return;
      // } else if (!type) {
      //     window.alert("Please specify the type of event");
      //     return
    } else if (!capacity && movement === 1) {
      window.alert(
        "Please specify maximum number of people who can participate"
      );
      return;
    } else if (!size && movement === 1) {
      window.alert("Please specify the maximum size of each team");
      return;
      // } else if (!submission) {
      //     window.alert("Please specify if this is a submission based event.");
      //     return
    } else if (!banner && movement === 1) {
      window.alert("Please upload a banner for this event.");
      return;
      // } else if (!judges) {
      //     window.alert("Please enter the names of judges");
      //     return
    } else if (!date && movement === 2) {
      window.alert("Please specify when the event will start");
      return;
    } else if (!enddate && movement === 2) {
      window.alert("Please specify when the event will end");
      return;
      // } else if (!summary) {
      //     window.alert("Please enter the summary of the event");
      //     return
    } else if (!description && movement === 2) {
      window.alert("Please enter the full details of the event");
      return;
    } else if (!prizes && movement === 3) {
      window.alert("Please specify the prizes");
      return;
      // } else if (!supportingFiles) {
      //     window.alert("Please uploading any supporting files");
      //     return
    } else if (!rulesAndLegal && movement === 4) {
      window.alert(
        "Please enter the rules and legalities associated with the event."
      );
      return;
    } else {
      if (this.validateMovement(movement)) {
        this.updateCurrentStep(movement);
        this.steps.handleStepsClasses(movement);
      } else {
        throw "This was an invalid movement";
      }
    }
  }

  validateMovement(movement) {
    const fowardMov = movement > 0 && this.currentStep < this.stepsQuantity - 1;
    const backMov = movement < 0 && this.currentStep > 0;

    return fowardMov || backMov;
  }
}
