const initState = {
  businessProfile: {events:[]},
  businessProfileList:[]
};

const businessProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_BUSINESS_PROFILE":
      return {
        ...state,
        businessProfile: action.data,
      };
    case "UPDATE_BUSINESS_PROFILE":
      return {
        ...state,
        businessProfile: action.data,
      };
    case "GET_NEW_BUSINESS_PROFILE":
      return {
        ...state,
        businessProfile: action.data,
        businessProfileList: [...state.businessProfileList, action.data]
      };
    case "GET_BUSINESS_PROFILE_LIST":
      return {
        ...state,
        businessProfileList: action.data
      };

    //new business profile list
    case "GET_BUSINESS_PROFILE_LIST1":
      return {
        ...state,
        businessProfileList: action.data
      };

    case "UPDATE_CONTACT":
      return {
        ...state,
        businessProfile: {...state.businessProfile, createdBy:action.data}
      };
    case "REVIVE_BUSINESS_PROFILE":
      return {
        ...state,
        businessProfile: {...state.businessProfile, isActive : !state.businessProfile.isActive}
      };
    case "SUSPEND_BUSINESS_PROFILE":
      return {
        ...state,
        businessProfile: {...state.businessProfile, isActive:!state.businessProfile.isActive}
      };
    case "UPDATE_BUSINESS_INVITES":
      return {
        ...state,
        businessProfile: {...state.businessProfile, invitations:state.businessProfile.invitations.concat(action.data)}
      }
    case "UPLOAD_COMPLIANCE_DOC":
      let docs = state.businessProfile.complianceDocuments.concat([action.data])
      let profile = state.businessProfile
      profile.complianceDocuments = docs
      return {
        ...state,
        businessProfile: profile
      };

    default:
      break;
  }
  return state;
};

export default businessProfileReducer;
