import React, { Component } from "react";
import {fetchContest, publishContest, deleteContest } from "../../store/actions/contestActions";
import { connect } from "react-redux";
import Select from "react-select";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
// import {Wizard} from "./wizard"

class UserContest extends Component {

    constructor(props){
        super(props)
    }

    Context = {
        DESCRIPTION:1,
        RULES:2,
        DATA:3,
        PRIZES:4,
        LEADERBOARD:5,
        SUBMISSIONS:6,

    }
    state = {
        id:'',
        title:'',
        date:'',
        info:'',
        rules:'',
        prizes:'',
        tags:'',
        type:'',
        isEditing:'',
        isLoaded:true,
        caughtErr:false,
        context:this.Context.DESCRIPTION
    };


    

    componentDidMount(){
        //   check if creating a new contest or updating an old one
        const { history } = this.props;
        let cid = history.location.pathname.split("/")[3];

        // const script = document.createElement("script");
        // script.src = "./wizard.js";
        // script.async = true;
        // document.body.appendChild(script);

        // let wizardElement = window.$('#wizard');
        // console.log(wizardElement);
        // let wizard = new Wizard(wizardElement);
        // let buttonNext = window.$('.next');
        // let buttonPrevious = window.$('.previous');

        // wizard.addControls(buttonPrevious, buttonNext);

        if(cid === 'new'){

            // Creating new contest, toggle component editing state to false, isLoaded to true
            this.setState({isEditing:false, isLoaded:true, caughtErr:false})

        }else{
            
            // Editing exitsing contest,
            // toggle component editing state to true
            // Get contest data to edit
            // update component state with contest data and toggle is loaded state to true

            this.setState({isEditing:true});
            // this.props.getContest(cid)
            // .then( () => this.setState({
            //     id:this.props.contest.id,
            //     title:this.props.contest.title,
            //     date:this.props.contest.date,
            //     info:this.props.contest.info,
            //     rules:this.props.contest.rules,
            //     prizes:this.props.contest.prizes,
            //     tags:this.props.contest.tags,
            //     type:{value:this.props.contest.type, label:this.props.contest.type},
            //     isLoaded:true,
            // }))
        }
    }


    hackathonType = [
        {
            value:'Online',
            label:'Online'
        },
        {
            value:'Physical',
            label:'Physical'
        },
        {
            value:'Physical Mackathon',
            label:'Physical Mackathon'
        },

    ] 

    /**
     * update state with input change
     * 
     * @param  {} doc key value pair with state variable to change as key and variable value as value
     */
    handleInputChange(doc){
        this.setState(doc)
    }
  

        

    /**
     * submit contest data to backend
     * prevent page from refreshing
     * 
     * @param  {} e submit event
     */
    handleSubmit(e){
        {
            e.preventDefault()
            this.state.isEditing ?
                this.props.updateContest(this.state.id, this.state)
                : 
                this.props.updateContest(null, this.state)
        }
        
    }

    
  
  /**
   * check if all required data is loaded
   * render componet if true else render loading animations
   */
  render() {
    
    if(this.state.isLoaded){

        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Contests</h4>
                                </div>
                            </div>
                        </div>      */}
                        {/* <!-- end page title --> */}
                        {/* background:"rgb(42, 48, 66)" */}
                        <div className="row">
                            <div className="col-12" style={{minHeight:"30vh", background:"rgb(42, 48, 66)", backgroundSize:"cover", backgroundOrigin:"center", padding:"2rem"}}>
                                {/* <div style={{position:"absolute", width:"100%", height:"100%", background:"#0000008a", top:0, left:0}}></div> */}
                                <div className="row" style={{paddingBottom:"2rem"}}>
                                <span
                                        style={{position:"absolute",right:"20px" , padding:"5px 10px", background:"red"}}
                                        href="#"
                                        className="badge badge-primary font-size-11 m-1"
                                    >
                                        Apply 
                                    </span>
                                    <div className="col-6">
                                    <h3 style={{color:"white"}}>Event Name</h3>
                                    <p style={{color:"white", fontWeight:"lighter"}}>11 September—30 November</p>
                                    <p style={{color:"white", fontWeight:"lighter"}}>asfd adnflkajdf aksjlndflk adsfia alkdhfakd asdnflkansd ahdnskfansd kadnfkasd kasfdnak fakhfka;sdf; asdfuha ;fhakfkad</p>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"-4rem"}}>
                            <div className="col-xl-3">
                                <div className="card overflow-hidden">
                                    <div className="card-body">
                                        <div className="">
                                            <ul className="verti-timeline list-unstyled">
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        
                                                        <div className="media-body">
                                                            <div>
                                                                <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}} onClick={() => this.setState({context:this.Context.DESCRIPTION})}>Description</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                                <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.RULES})}>Rules and Legal</h5>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.DATA})}>Supporting Files</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.PRIZES})}>Prizes</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.LEADERBOARD})}>LeaderBoard</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.SUBMISSIONS})}>Submissions</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.SUBMISSIONS})}>Judges/Moderators</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="event-list">
                                                    <div className="event-timeline-dot">
                                                        <i className="bx bx-right-arrow-circle"></i>
                                                    </div>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <div>
                                                            <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.SUBMISSIONS})}>Event Reports</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-xl-9">
                                <div className="card">
                                    <div className="card-body" style={{minHeight:"100vh"}}>
                                        {/* info container */}
                                        <div>
                                            {/* lolerlkad sjdSNF sfLNSF Odfk DFsdbfKJBDF idf iGDF sfiGbufk  */}
                                            {this.state.context === this.Context.DESCRIPTION ? 
                                            <>
                                                <h4 className="card-title mb-4">Event Description</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
 
                                            </>
                                            :
                                            ""
                                            }
                                            {this.state.context === this.Context.RULES ? 
                                            <>
                                                <h4 className="card-title mb-4">Rules and Legal</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <h4>Legal</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                
                                            </>
                                            :
                                            ""
                                            }
                                            {this.state.context === this.Context.DATA ? 
                                            <>
                                                <h4 className="card-title mb-4">DATA</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                                <ul>
                                                    <li>
                                                        <a href="#">Data file 1</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Data file 2</a>
                                                    </li>
                                                </ul>
                                                
                                            </>
                                            :
                                            ""
                                            }
                                            {this.state.context === this.Context.PRIZES ? 
                                            <>
                                                <h4 className="card-title mb-4">PRIZES</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                                                <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                                            </>
                                            :
                                            ""
                                            }
                                            {this.state.context === this.Context.LEADERBOARD ?
                                            <>
                                                <h5 className="font-size-15 text-dark" style={{cursor:"pointer"}}  onClick={() => this.setState({context:this.Context.RULES})}>LEADERBOARD</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-centered table-nowrap table-hover">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col" style={{width: "70px"}}>#</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Team</th>
                                                                <th scope="col">Points</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    1
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">David McHenry</a></h5>
                                                                    <p className="text-muted mb-0">UI/UX Designer</p>
                                                                </td>
                                                                <td>david@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    125
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    2
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Frank Kirk</a></h5>
                                                                    <p className="text-muted mb-0">Frontend Developer</p>
                                                                </td>
                                                                <td>frank@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    132
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    3
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Rafael Morales</a></h5>
                                                                    <p className="text-muted mb-0">Backend Developer</p>
                                                                </td>
                                                                <td>Rafael@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    112
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                4
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Mark Ellison</a></h5>
                                                                    <p className="text-muted mb-0">Full Stack Developer</p>
                                                                </td>
                                                                <td>mark@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    121
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    5
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Minnie Walter</a></h5>
                                                                    <p className="text-muted mb-0">Frontend Developer</p>
                                                                </td>
                                                                <td>minnie@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    145
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    6
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Shirley Smith</a></h5>
                                                                    <p className="text-muted mb-0">UI/UX Designer</p>
                                                                </td>
                                                                <td>shirley@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    136
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    7
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">John Santiago</a></h5>
                                                                    <p className="text-muted mb-0">Full Stack Developer</p>
                                                                </td>
                                                                <td>john@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    125
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    8
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                    <p className="text-muted mb-0">Backend Developer</p>
                                                                </td>
                                                                <td>colin@skote.com</td>
                                                                <td>
                                                                    skote
                                                                </td>
                                                                <td>
                                                                    136
                                                                </td>
                                                                <td>
                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                        </li>
                                                                        <li className="list-inline-item px-2">
                                                                            <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                            :
                                            ""
                                            }
                                            {this.state.context === this.Context.SUBMISSIONS ?

                                                <>
                                                    <h4 className="card-title mb-4">Submissions</h4>
                                                    <p>Team name</p>
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap table-centered table-hover mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{width: "45px"}}>
                                                                        <div className="avatar-sm">
                                                                            <span className=" rounded-circle text-secondary font-size-24">
                                                                                <i className="bx bxs-file-blank"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Skote Landing.Zip</a></h5>
                                                                        <small>Size : 3.25 MB</small>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-center">
                                                                            <a href="#" className="text-dark"><i className="bx bx-download h3 m-0"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-sm">
                                                                            <span className=" rounded-circle text-secondary font-size-24">
                                                                                <i className="bx bxs-file-blank"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Skote Admin.Zip</a></h5>
                                                                        <small>Size : 3.15 MB</small>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-center">
                                                                            <a href="#" className="text-dark"><i className="bx bx-download h3 m-0"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-sm">
                                                                            <span className="rounded-circle text-secondary font-size-24">
                                                                                <i className="bx bxs-file-blank"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Skote Logo.Zip</a></h5>
                                                                        <small>Size : 2.02 MB</small>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-center">
                                                                            <a href="#" className="text-dark"><i className="bx bx-download h3 m-0"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="avatar-sm">
                                                                            <span className=" rounded-circle text-secondary font-size-24">
                                                                                <i className="bx bxs-file-blank"></i>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="font-size-14"><a href="#" className="text-dark">Veltrix admin.Zip</a></h5>
                                                                        <small>Size : 2.25 MB</small>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-center">
                                                                            <a href="#" className="text-dark"><i className="bx bx-download h3 m-0"></i></a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                             :
                                             ""
                                             }
                                            
                                        </div>
                                        {/* end info container */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end row --> */}

                        
                    </div> {/**<!-- container-fluid -->**/}
                </div>
                {/* <!-- End Page-content --> */}

                
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-sm-6">
                            {new Date().getFullYear()} © Botswana Innovation Hub
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-right d-none d-sm-block">
                            Skills Ranker
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
          );

    }else{
        return(
            <AssesmentsSkeleton/>
        );
    }
    
  }
}

const mapStateToProps = (state) => {
    return {
      contest: state.contestData.contest
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
      getContest: (cid) => dispatch(fetchContest(cid)),
      updateContest: (id, data) => dispatch(publishContest(id, data)),
      removeContest:(cid) => dispatch(deleteContest(cid)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserContest);



  

  
  