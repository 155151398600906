import React, { Component } from "react";

class ContentSkeleton extends Component {

  render() {

    return (
      <div className="row">
        <div className="col-md-2 mb-3">
          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3" style={{ display: "flex" }}>
          <div
            className="p-1 ml-1 _skeleton"
            style={{
              backgroundColor: "transparent!important",
              color: "transparent!important",
              width: "25px",
              height: "25px",
              borderRadius: "25px"
            }}
          >
          </div>
          <div className="_skeleton" style={{
            width: "75%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "1rem"
          }}>
          </div>
        </div>
        <div className="col-md-2 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3" style={{ display: "flex" }}>
          <div
            className="p-1 ml-1 _skeleton"
            style={{
              backgroundColor: "transparent!important",
              color: "transparent!important",
              width: "25px",
              height: "25px",
              borderRadius: "25px"
            }}
          >
          </div>
          <div className="_skeleton" style={{
            width: "75%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "1rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "4rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "4rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "75%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "4rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "75%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "1rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
            marginLeft: "-2rem"
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>
        <div className="col-md-3 mb-3">

          <div className="_skeleton" style={{
            width: "100%%",
            height: "25px",
            borderRadius: "25px",
          }}>
          </div>
        </div>

      </div>
    );
  }
}



export default ContentSkeleton;
