import {
    client
} from "./client";

export function getUserAssessments(body) {
    return client.get("/assessments/user/" + body.userId + (typeof body.subject !== "undefined" ? `?subject=${body.subject}&` : ''), {
        // headers: {
        //     session: session
        // }
    });
}

export function getAssesment(aid) {
    return client.get("/assessments/" + aid, {
        // headers: {
        //     session: session
        // }
    });
}

export function getAssessments() {
    return client.get("/assessments", {
        // headers: {
        //     session: session
        // }
    });
}

export function getAllAssesments(body) {
    return client.get("/assessments?" + (typeof body.subject !== "undefined" ? `subject=${body.subject}&` : '') + "userId=" + body.userId, {
        // headers: {
        //     session: session
        // }
    });
}

export function publishAssessment(data) {
    // console.log(data);

    return client.post("/assessments/grade", data);
}

export function newAssessment(data) {
    // console.log(data);

    return client.post("/assessments", data);
}

export function updateAssessment(aId, data) {
    // console.log(data);

    return client.put("/assessments/"+aId, data);
}

export function removeAssessment(aId) {
    // console.log(data);

    return client.delete("/assessments/"+aId);
}