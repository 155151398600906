import React, { Component } from "react";

class ProjectListSkeleton extends Component {
 
  render() {

    return (
      <div  style={{padding: "0rem" }}>
        <div className="row">
            <div
              className="  col-xl-12 mb-2 text-left"
              style={{
                backgroundColor: 'transparent'}}
               >
                <div className="card-body"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    padding: "1rem",
                    textAlign: "left",
                  }}
                >
                  <p className="_skeleton" style={{height:'1.5rem', width:'100%', borderRadius:'5%'}}></p>{" "}
                  <p className="mb-2 _skeleton" style={{height:'2.5rem', width:'100%', borderRadius:'5%'}}></p>


                  <div className="float-sm-left">
                    <div className="btn-group mr-2 mb-2 mb-sm-0 _skeleton" style={{height:'1.5rem', width:'6rem', borderRadius:'5%'}}>
                      

                    </div>
                  </div>

                  
              </div>
            </div>
          
        </div>

        
        
      </div>
    );
  }
}



export default ProjectListSkeleton;
