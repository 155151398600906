import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import JotformEmbed from 'react-jotform-embed';


var biz_number = 0,
dev_numbers = 0,
hack_numbers = 0,
skill_numbers = 0;


class LandingPage extends Component {
  state = {
    skills: [],
    users: 0,
    upcomingEvent: {},
    hasUpcoming: false,
    past: []
  };

  

  data = [];
  componentDidMount() {
    this.props
      .getPlatformSummary()
      .then(() => {
        this.setState({
          skills: this.props.platformSummary.skills,
          users: this.props.platformSummary.population, 
        });


        biz_number = 232;
   
        hack_numbers = 25;
        dev_numbers = this.state.users;
        skill_numbers = this.state.skills[2].value;

        
      })
      .catch((err) => this.props.dispatchNotification(err));

    this.props
      .getContests()
      .then(() => {
        this.setState({ past: this.props.past, isLoaded: true });
        if (this.props.live.length) {
          this.setState({
            upcomingEvent: this.props.live[0],
            hasUpcoming: true,
          });

          

        } else if (this.props.upcoming.length) {
          this.setState({
            upcomingEvent: this.props.upcoming[0],
            hasUpcoming: true,
          });
        }
     
      })
      .catch((err) => this.props.dispatchNotification(err));


    
  }


  
  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />

       
        <header id="home" className="header-area" style={{backgroundColor:"grey"}}>
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                    
                      <a href="/#">
                        <img
                          src="/assets/landing-page/images/logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div
                      className="responsive"
                      onClick={() => this.toggleMenu()}
                    >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                    <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub ml-sm-4">
                            <a href="/#">Home </a>
                          </li>
                          {/* <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="#about">About</a>
                          </li> */}
                          <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="/#how-it-works">Developer</a>
                          </li>
                          <li className=" ml-sm-4">
                            <a href="/#how-it-works-business">Business</a>
                          </li>
                         
                          <li className=" ml-sm-4">
                            <a href="/events">Hackathons</a>
                          </li>
                            {/* <li className=" ml-sm-4">
                            <a href="/awsRegistration"></a>
                           </li> */}


                          {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                          <ul className="dropdown">
                            <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                          </ul>
                          </li> */}

                          <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                           <ul className="dropdown">
                            <li className="ml-sm-4 has-sub"><a href="#">AWS-re/Start</a>
                              <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                              <li className="ml-sm-4"><a href="/awsRequirements">Requirements</a></li>
                              <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                              </ul>
                            </li>
                           </ul>
                        </li>

                          <li className=" ml-sm-4">
                            <a href="#footer">Contacts</a>
                          </li>

                          </ul>                        
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />{" "}
                        {this.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          

          <section style={{height:"120px"}}></section>

         
         <section>
          <div id="iframe-container">
          <JotformEmbed src="https://form.jotformeu.com/223241211683547"/>
          </div>
         </section>
     
   
        </main>

        <Footer />
       
        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));


  