import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
// import Footer from "../containers/partials/Footer";
import { connect } from "react-redux";
// import { fireNotification } from "../store/actions/notificationsActions";
// import {
//   fetchContest,
//   getContestleaderboard,
//   checkParticipation,
//   createSubmission,
// } from "../store/actions/contestActions";
// import {
//   fetchAllEventTeams,
//   getEventUserTeam,
//   updateMember,
//   updateConsent,
// } from "../store/actions/teamActions";
import { getBusinessProfile, validateBusiness } from "../store/actions/businessProfileActions";
// import store from "../index";
import Error from "../containers/partials/Error";

class CreateBusinessProfileForm extends Component {
  data = [];

  state = {
    user: null,
    hasProfiles: false,
    name: "",
    industry: "",
    description: "",
    address: "",
    number: "",
    registrationNumber: "",
    isCreatingProfile: false,
    isNameValid: false,
    nameMsg: "",
    error: ""

  };


  data = [];
  componentDidMount() {


  }

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let body = {
      businessName: this.state.name,
      location: { description: this.state.address },
      businessDescription: this.state.description,
      contactNumber: this.state.number,
      industry: this.state.industry,
      registrationNumber: this.state.registrationNumber,
      createdBy: JSON.parse(Cookies.get("user"))._id
    }
    this.setState({ isCreatingProfile: true })
    this.props.createProfile(body)
      .then(() => {
        // this.setState({ isCreatingProfile: false })

      }).catch(() => {
        this.setState({ isCreatingProfile: false })
      })
  }
  updateBusinessName = (businessName) => {
    // validateBusinessname
    this.setState({ name: businessName })
    this.props.checkBusiness(businessName)
      .then(info => {

        this.setState({ isNameValid: !info.exists, nameMsg: info.message })
        if (info.exists || businessName.length < 1) this.displayError("Please provide a unique business name");
      })
  }

  updateBusinessNumber = (number) => {
    // validateBusinessname
    this.setState({ number: number })
    this.props.checkBusiness(number)
      .then(info => {

        this.setState({ isNumberValid: !info.exists, nameMsg: info.message })
        if (info.exists || number.length < 1) this.displayError("Please provide a unique business number");
      })
  }

  updateBusinessRegistrationNumber = (number) => {
    // validateBusinessname
    this.setState({ registrationNumber: number })
    this.props.checkBusiness(number)
      .then(info => {

        this.setState({ isRegNumberValid: !info.exists, nameMsg: info.message })
        if (info.exists || number.length < 1) this.displayError("Please provide a unique business registration number");
      })
  }

  displayError = (_error) => {
    this.setState({ error: _error });
    setTimeout(() => {
      this.setState({ error: "" });
    }, 5000);
  }

  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div className="row">
        <div className="col-6 mx-auto">
          <div className="card-body" style={{ margin: "0 auto", border: "1px dashed gray" }}>
            {this.state.isCreatingProfile ?
              <div style={{
                position: "absolute",
                width: "100%",
                left: 0,
                top: 0,
                zIndex: 3,
                height: "100%",
                background: "#fffbfb3d",
                textAlign: "center",
                backdropFilter: "blur(3px)"
              }}>
                <p style={{ top: "50%", fontWeight: "bold" }}> Creating {this.state.businessName} profile ... </p>
              </div>
              :
              ""
            }
            <div className="text-center">
              <h4>Business Setup</h4>
              <p className="mb-4">Complete the form below with your businesses information</p>
            </div>
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              {this.state.error !== "" ? <Error error={this.state.error} /> : ""}{" "}
              <div className="row form-group" >
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Business Name</label>
                  <input style={{
                    border: this.state.isNameValid || this.state.name === "" ? "1px solid #ced4da" : "1px solid red",
                  }} onChange={(e) => this.updateBusinessName(e.target.value)} value={this.state.name} className="form-control" type="text" required />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Industry</label>
                  <input onChange={(e) => this.setState({ industry: e.target.value })} value={this.state.industry} className="form-control" type="text" required />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Company Registration Number</label>
                  <input onChange={(e) => this.updateBusinessRegistrationNumber(e.target.value)} value={this.state.registrationNumber} className="form-control" type="text" required />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Description</label>
                  <input onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} className="form-control" type="text" required />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Physical Address</label>
                  <input onChange={(e) => this.setState({ address: e.target.value })} value={this.state.address} className="form-control" type="text" required />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Phone Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">+267</span>
                    </div>
                    <input style={{
                    border: this.state.isNumberValid || this.state.number === "" ? "1px solid #ced4da" : "1px solid red",
                  }}  onChange={(e) => this.updateBusinessNumber(e.target.value)} value={this.state.number} className="form-control" type="tel" required />
                  </div>
                </div>
                {/* </div> */}
              </div>
              {/* <div className="row form-group">
                <div className="col-12">
                  <label className="text-uppercase 
                  small">Company Logo</label>
                  <input className="form-control" type="text" />
                </div>
              </div> */}
              <div className="row form-group">
                <div className="col-12">
                  {
                    this.state.isNameValid && this.state.name.length >= 1 ?
                      <button className="form-control btn btn-primary" style={{ backgroundColor: "#e2b537", color: "black", borderColor: "#e2b537", fontWeight: "bold" }} type="submit">Submit</button>
                      :
                      <button className="form-control btn btn-primary" style={{ backgroundColor: "#e2b537", cursor: "not-allowed", color: "black", borderColor: "#e2b537", fontWeight: "bold" }} disabled type="">Submit</button>
                  }

                </div>
              </div>
            </form>
          </div>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.businessProfileData.businessProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessProfile: (user) => dispatch(getBusinessProfile(user)),
    checkBusiness: (identifier) => validateBusiness(identifier),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateBusinessProfileForm));
