import React, { Component } from "react";
import { withRouter } from "react-router";
import ExcelExport from "./ExcelExport";
import PDFExport from "./PDFExport";

class DashboardUserRank extends Component {
    state = {
        targetData: [],
        users: [],
        isMale: false,
        isFemale: false,
        all: true,
        allUsers: [],
        title: '',
        male: [],
        female: [],
    }

    /**
     * get list of users
     * and populate component state
     */
    componentDidMount() {
        this.props.callback()
            .then(() => this.handleUsers(this.props.data))
            .catch((err) => console.log('caught some error ', err))

    }


    /**
     * Split users by gender
     * and update component state with top 5 in each category
     * 
     * @param  {} users list ranked users
     */
    handleUsers(users) {
        // users.map(user => console.log(user, user.owner))
        var femaleUsers = users.filter((user) => user.owner.gender === 'Female')
        femaleUsers = femaleUsers.splice(0, 4)
        var maleUsers = users.filter((user) => user.owner.gender === 'Male')
        maleUsers = maleUsers.splice(0, 4)
        var allUsers = users.splice(0, 4)
        this.setState({ targetData: allUsers, male: maleUsers, female: femaleUsers, users: allUsers, title: this.props.title })



    }

    formatExportData(arr) {
        return arr.map(user => {
            return {
                "Name": typeof user.owner.name !== "undefined" ? user.owner.name.firstName + " " + user.owner.name.lastName : '',
                "Points": user.points,
                "Mobile": user.mobile,
                "Contact Number": typeof user.owner !== "undefined" ? user.owner.contactNumber : '',
                "Email": typeof user.owner !== "undefined" ? user.owner.email : '',
                "Gender": typeof user.owner !== "undefined" ? user.owner.gender : '',
                "Skills": "",
                "Location": typeof user.owner !== "undefined" ? user.owner.location : '',
                "Date Of Birth": typeof user.owner !== "undefined" ? user.owner.dob : '',

            }
        })
    }

    render() {
        return (

            <div className="card">
                <div className="card-body" id="Top_5_users">
                    <h4 className="card-title mb-3 float-sm-left">{this.state.title}</h4>
                    <div className="float-sm-right">
                        <ul className="nav nav-pills">
                            <li className="nav-item" onClick={() => this.setState({ targetData: this.state.male, title: 'Top 5 Male Users' })}>
                                <a className="" style={{ display: 'block', padding: '0.2rem 0.5rem' }}>Male</a>
                            </li>
                            <li className="nav-item" onClick={() => this.setState({ targetData: this.state.female, title: 'Top 5 Female Users' })}>
                                <a className="" style={{ display: 'block', padding: '.2rem .5rem' }}>Female</a>
                            </li>
                            <li className="nav-item" onClick={() => this.setState({ targetData: this.state.users, title: 'Top 5 Users' })}>
                                <a className="" style={{ display: 'block', padding: '.2rem .5rem' }} >All</a>
                            </li>
                            <li className="nav-item ml-3">
                                <ExcelExport fileName="Top 5 Users" csvData={this.formatExportData(this.state.targetData)} />
                            </li>
                            <li className="nav-item ml-3">
                                <PDFExport title="Top_5_users" />
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                    {
                        this.state.targetData.length > 0 ?
                            <div className="row">
                                <div className="table-responsive mt-4">
                                    <table className="table table-centered table-nowrap">
                                        <tbody>
                                            {
                                                this.state.targetData.map(user =>
                                                    <tr key={user.owner._id}>
                                                        <td className="avatar-xs mx-auto mb-4">
                                                            {
                                                                user.owner.githubData ?
                                                                    <img className="rounded-circle avatar-xs" src={user.owner.githubData.avatar_url} alt=""></img>
                                                                    :
                                                                    typeof user.owner.name !== undefined ?
                                                                        <span className="avatar-title rounded-circle font-size-16" style={{ backgroundColor: '#0a0b0e9e', color: '#ffffffa3!important', height: "40px", width: "40px" }}> {user.owner.name.firstName[0] + " " + user.owner.name.lastName[0]} </span>
                                                                        :
                                                                        <span className="avatar-title rounded-circle font-size-16" style={{ backgroundColor: '#0a0b0e9e', color: '#ffffffa3!important', height: "40px", width: "40px" }}>  </span>

                                                            }
                                                        </td>
                                                        <td style={{ width: "60%" }}>
                                                            <p className="mb-0">{typeof user.owner.name !== undefined ? user.owner.name.firstName + " " + user.owner.name.lastName : ''}</p>
                                                        </td>
                                                        <td style={{ width: "25%" }}>
                                                            <h5 className="mb-0">{user.points}</h5>
                                                        </td>

                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            :
                            <>
                                <i className="bx bx-cloud-snow" style={{ fontSize: '4rem', width: '100%', textAlign: 'center' }}></i>
                                <p className="mb-5" style={{ fontSize: '.8rem', width: '100%', textAlign: 'center', marginTop: '.8rem' }}>no data</p>
                            </>
                    }



                </div>
            </div>


        );

    }
}

export default withRouter(DashboardUserRank);
