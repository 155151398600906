const initState = {

};

const userProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...action.user
      };
    case "SIGNUP_USER":
      return {
        ...action.data
      };
    case "SET_USER":
      return {
        ...action.user
      };
    case "RECOVER_PASSWORD":
      return {
        ...action.data
      };
    default:
      break;
  }
  return state;
};

export default userProfileReducer;