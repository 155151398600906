import React, { Component } from "react";
import { getUserRank } from "../store/actions/rankActions";
import { connect } from "react-redux";

class RankBadge extends Component {
  componentDidMount() {
    this.props.getRank(this.props.userId)
  }

  render() {
    return (
      <div className="row pt-3">
        <span
          style={{
            width: "256px",
            height: "125px",
            textAlign: "center",
            borderRadius: "10%",
          }}
        >
          <p className="pb-0"> National Rank </p>
          <h2 className="text-primary font-weight-bolder">
            {/* {this.props.rankData && this.props.rankData.nationalRank !== 1
              ? " "
              : "# "} */}
            {this.props.rankData ? this.props.rankData.nationalRank : " - "}
          </h2>
          <p className="pt-1">
            From &nbsp;
            <b
              className="font-weight-bolder "
              style={{ fontSize: "18px", color: "#000" }}
            >
              {this.props.rankData ? this.props.rankData.totalUsers : " - "}
            </b>
            &nbsp; users
          </p>
        </span>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rankData: state.rankData.userRank,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRank: (userId) => dispatch(getUserRank(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RankBadge);
// export default RankBadge;
