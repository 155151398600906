import React, { Component } from "react";

class AnswerBlade extends Component {
  state = {
    answered: true,
  };

  componentDidMount() {
    this.setState({
      answered: this.props.answered,
    });
  }
/**
 * call callback function
 * toggle component answered state to indicacted that an answer is selected
 * @param  {} props , properties inherited from parent component, in this case
 * a call back function to indicate which answer was selected
 */

  handleClick(props) {
    props.click();
    this.setState({ answered: !this.state.answered });
  }
  render() {
    return (
      <div
        onClick={() => this.handleClick(this.props)}
        className="btn btn-block d-flex mb-2 pl-2 pr-2 pt-0 pb-0"
        style={{
          border: "solid 1px rgb(236, 236, 236)",
          borderColor: this.state.answered ? "#63e680" : "rgb(236, 236, 236)",
          borderRadius: "4px",
          alignItems: "center",
        }}
      >
        <input type="radio" readOnly checked={this.state.answered} />
        <div
          key={this.props.answer._id}
          className={
            "text-left" +
            ("card", this.state.answered ? "ans_active" : "ans_inactive")
          }
          style={{
            fontWeight: "400",
            fontSize: "18px",
            fontSize: "15px",

            borderRadius: "4px",
            padding: "1rem",
          }}
        >
          {this.props.answer.text}
        </div>
      </div>
    );
  }
}

export default AnswerBlade;
