import React, { Component } from "react";
import { connect } from "react-redux";
import ProjectList from "../../components/ProjectList";
import ProjectTechnologiesList from "../../components/ProjectTechnologiesList";
import RankBadge from "../../components/RankBadge";
import { fetchProject } from "../../store/actions/projectsActions";
import Cookie from "js-cookie";

class Project extends Component {
  state = {


    rankdata: { nationalRank: "1", totalUsers: "2000" },
    bio:
      " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  };

  componentWillMount() {
    this.props.getProject('123')
    // .then(() =>{
    //     this.setState({loaded:true})
    //   })
  }

  render() {
    return (
      <div className="main-content">
        <div className="page-content" style={{ paddingTop: "50px" }}>
          <div className="container-fluid">
            {/* <!-- Top header --> */}
            <div className="row">
              <div className="col-xl-3" style={{ height: "90vh" }}>
                {/* avater */}
                <div className="card overflow-hidden">
                  <div className="bg-soft-primary">
                    <div className="row">
                      <div className="col-10">
                        <div className="text-primary p-3"></div>
                      </div>
                      {/* <!-- <div className="col-5 align-self-end">
                                          <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                                      </div> --> */}
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="avatar-lg profile-user-wid mb-4">
                          <img
                            src="/assets/images/users/avatar-1.jpg"
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {JSON.parse(Cookie.get("user")).name.firstName +
                            " " +
                            JSON.parse(Cookie.get("user")).name.lastName}
                        </h5>
                        <p className="text-muted mb-0 text-truncate" style={{ textTransform: 'capitalize' }}>
                          {JSON.parse(Cookie.get("user")).accountType}
                        </p>
                      </div>

                      <RankBadge data={this.state.rankdata} />
                    </div>
                  </div>
                </div>

                {/* PROJECTS */}
                {/* pass project data objects as a prop */}
                <ProjectList />
              </div>
              <div className="col-xl-9" style={{
                padding: "3rem"
              }}>

                <h4 className="mb-5" style={{ textTransform: 'capitalize' }}>{this, this.props.project.title}</h4>


                <h4>Summary</h4>
                <hr />
                <div >
                  <p>{this.props.project.summary}</p>
                </div>
                <h4>Technologies</h4>
                <hr />
                <ProjectTechnologiesList />

              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
              <div className="col-sm-6">
                <div className="text-sm-right d-none d-sm-block">
                  Skills Ranker
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projectsData.project,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProject: (pid) => dispatch(fetchProject(pid)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);

