import {
    client
} from "../../api/client";
import {
    getUser,
    createAdmin,
} from "../../api/user";
import {
    fireNotification
} from "./notificationsActions";


/**
 * @name GET
 * @description get all app users
 */
export const fetchAppUsers = () => {
    return dispatch => {
        return client.get("users/q", {})
            .then((response) => {
                return dispatch({
                    type: "PULL_APP_USERS",
                    data: response.data
                })
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
    };
};

/**
 * get all app users demographics
 */
export const fetchAppUsersDemographics = () => {
    return dispatch => {
        return client.get("users/demographics", {})
            .then((response) => {
                return dispatch({
                    type: "PULL_APP_USERS_DEMOGRAPHICS",
                    data: response.data
                })
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
    };
};
/**
 * @GET update user account
 */
export const updateUser = (userDetails) => {
    return dispatch => {
        return client.put("profile", userDetails)
            .then((response) => {
                return Promise.resolve(dispatch({
                    type: "UPDATE_PROFILE",
                    data: response.data
                }))
            })
            .catch(err => Promise.reject(dispatch(fireNotification(err)), Promise.reject(err)))
    };
};

/**
 * get platform summary
 */
export const fetchPlatformSummary = () => {
    return dispatch => {
        return client.get("users/summary", {})
            .then((response) => {
                return dispatch({
                    type: "PULL_PLATFORM_SUMMARY",
                    data: response.data
                })
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
    };
};


/**
 * create new admin user
 * 
 * @param  {Dict} body user details
 */
export const pushAdmin = (body) => {
    return dispatch => {
        return createAdmin(body)
            .then((response) => {
                dispatch(fireNotification(response))
                return dispatch({
                    type: "CREATE_ADMIN_USER",
                    data: response.data
                })
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
    };
};


/**
 * get all admin users
 */
export const fetchAdminUsers = () => {
    return dispatch => {
        return client.get("admin_users")
            .then((response) => {
                return dispatch({
                    type: "PULL_ADMIN_USERS",
                    data: response.data
                })
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
    };
};


/**
 * get user profile by id
 * 
 * @param  {String} userId user id
 */
export function getUserProfile(userId) {
    return dispatch => {
        return getUser(userId)
            .then(response => {
                let user;
                try {
                    user =
                        typeof response.data === "string" ?
                            JSON.parse(response.data) :
                            response.data;
                } catch (error) {
                    user = response.data;
                } finally {
                    dispatch({
                        type: "GET_USER_PROFILE",
                        data: user
                    })
                    return Promise.resolve(user)
                }
            })
            .catch(err => Promise.reject(err));
    };
}