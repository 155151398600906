import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { ResponsivePie } from '@nivo/pie'
import { fireNotification } from "../store/actions/notificationsActions";
import { fetchPlatformSummary } from "../store/actions/usersActions";
import { handleSkills } from "../utils/utils";
import ExcelExport from "./ExcelExport";
import PDFExport from "./PDFExport";

import { getLanguages } from "../api/languages";
import { getTechnologies } from "../api/technologies";

class SkillsBlade extends Component {

    state = {
        skills: [],
        on: false,
    }


    data = [];
    /**
     * get list of users 
     */
    componentDidMount() {

        this.props.getSkills()
            .then(() => {
                this.setState({ skills: this.props.skills.map(skill => { return { id: skill.label, ...skill } }) })
            })
            .catch(err => this.props.dispatchNotification(err))


    }



    render() {


        return (

            <div className="card">
                <div className="card-body" id="Skills_Distribution">
                    <h4 className="card-title mb-2">{this.props.title}</h4>
                    <div className="float-sm-right">
                        <ul className="nav nav-pills">
                            <li className="nav-item pl-2" >
                                <PDFExport title="Skills_Distribution" />
                            </li>
                            <li className="nav-item pl-2" >
                                <ExcelExport fileName="Skills Distribution" csvData={this.state.skills} />
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                    {
                        this.state.skills.length > 0 ?
                            <div style={{ height: '23rem' }}>

                                <ResponsivePie
                                    data={this.state.skills}
                                    margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
                                    startAngle={5}
                                    endAngle={314}
                                    innerRadius={0.45}
                                    padAngle={1}
                                    cornerRadius={3}
                                    colors={{ scheme: 'set1' }}
                                    borderWidth={1}
                                    borderColor={{ from: 'color', modifiers: [['darker', '0.2']] }}
                                    radialLabelsSkipAngle={10}
                                    radialLabelsTextXOffset={6}
                                    radialLabelsTextColor="#333333"
                                    radialLabelsLinkOffset={0}
                                    radialLabelsLinkDiagonalLength={16}
                                    radialLabelsLinkHorizontalLength={24}
                                    radialLabelsLinkStrokeWidth={1}
                                    radialLabelsLinkColor={{ from: 'color' }}
                                    slicesLabelsSkipAngle={10}
                                    slicesLabelsTextColor="#333333"
                                    animate={true}
                                    motionStiffness={10}
                                    motionDamping={15}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'CSS'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'React'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'HTML'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'C#'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'C'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'Go'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'Python'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'Bootstrap'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'C++'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'Javascript'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'bottom',
                                            direction: 'row',
                                            translateY: 56,
                                            itemWidth: 100,
                                            itemHeight: 18,
                                            itemTextColor: '#999',
                                            symbolSize: 18,
                                            symbolShape: 'circle',
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemTextColor: '#000'
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                />


                            </div>
                            :
                            <>
                                <i className="bx bx-cloud-snow" style={{ fontSize: '4rem', width: '100%', textAlign: 'center' }}></i>
                                <p className="mb-5" style={{ fontSize: '.8rem', width: '100%', textAlign: 'center', marginTop: '.8rem' }}>no data</p>
                            </>
                    }



                </div>
            </div>


        );

    }
}
const mapStateToProps = (state) => {
    return {
        skills: state.admin.platformSummary.skills
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchNotification: (msg) => dispatch(fireNotification(msg)),
        getSkills: () => dispatch(fetchPlatformSummary())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SkillsBlade));
