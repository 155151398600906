import {
    client
} from "./client";

/**
 * @param  {} body
 */
export function getData(body) {
    return client.post("/analytics", body);
}


