import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fecthAllRankData } from "../store/actions/rankActions";

class LeaderBoard extends Component {
  state = {
    isValid: true,
  };

  validate() {
    if (this.props.users.length < 1) {
      this.setState({
        isValid: false,
      });
    }
  }
  componentWillMount() {
    const self = this;
    this.props.getUsers().then((users) => {

      window.$(document).ready(function () {
        window.$("#datatable").DataTable({
          data: self.props.users.map((user, index) => ({
            ...user,
            name: user.owner !== null ? (index === 0 ? "🌟 " : "") +
              user.owner.name.firstName +
              " " +
              user.owner.name.lastName : "",
            rank: index + 1,

            points: Number(user.points).toFixed(0),
          })),
          sort: false,
          columns: [
            {
              data: "rank",
            },
            {
              data: "name",
              render: (_data, type, row) => {
                return row.owner === null ? "-" : `
                <a href="/profile/${row.owner._id}">  
                  <img class="mr-2" style="height: 30px; border-radius: 30px;"
                  src="${
                  row.owner.githubData
                    ? row.owner.githubData.avatar_url
                    : "/assets/images/users/avatar.png"
                  }" />
                  ${_data}
                </a>
            `;
              },
            },
            {
              data: "owner.programmingLanguages",
              visible: false,
              createdCell: (td, _data) => {
                return _data === null ? "-" : ReactDOM.render(
                  <span>
                    {typeof _data !== "undefined" &&
                      _data.toString().trim() !== "" &&
                      _data.length > 0
                      ? _data.toString().split(",").join(" , ")
                      : "-"}
                  </span>,
                  td
                );
              },
            },
            {
              data: "owner.technologies",
              visible: false,
              createdCell: (td, _data) => {
                // console.log(_data);

                return _data === null ? "-" : ReactDOM.render(
                  <span data-obj={JSON.stringify(_data)}>
                    {typeof _data !== "undefined" &&
                      _data.toString().trim() !== "" &&
                      _data.length > 0
                      ? _data.toString().split(",").join(" , ")
                      : "-"}
                  </span>,
                  td
                );
              },

            },
            {
              data: "level",
            },
            {
              data: "points",
              createdCell: (td, _data) => {
                return ReactDOM.render(
                  <span className="text-dark font-weight-bolder">{_data}</span>,
                  td
                );
              },
            },
          ],
          initComplete: function (settings, json) {
            var select;
            let arr = [];
            this.api()
              .columns()
              .every(function () {
                var column = this;

                if (column[0][0] === 2) {
                  window
                    .$("<label class='ml-2'>Rank By:&nbsp;&nbsp;</label>")
                    .appendTo(window.$("#datatable_filter"));
                  select = window
                    .$(
                      '<select class="d-inline-block col-md-3 form-control form-control-sm">\
                        <option value="">None</option>\
                        <option class="font-weight-bolder small" disabled>SKILLS</option>\
                      </select>'
                    )
                    .appendTo(window.$("#datatable_filter"))
                    .on("change", function () {
                      var val = window.$.fn.dataTable.util.escapeRegex(
                        window.$(this).val()
                      );
                      // ? new RegExp("/" + val + "/gmi") : "",
                      // true,
                      // false

                      column.search(val).draw();
                    });

                  column
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                      if (d !== null && d.toString().trim() !== "") {
                        d.toString()
                          .split(",")
                          .forEach((item) => {
                            if (arr.indexOf(item) === -1) {
                              arr.push(item);
                              select.append(
                                '<option value="' +
                                item +
                                '">' +
                                item +
                                "</option>"
                              );
                            }
                          });
                      }
                    });

                  // select.append(
                  //   '<option class="font-weight-bolder small" disabled>LANGUAGES</option>'
                  // );
                }

                if (column[(0)[0] === 3]) {
                  column
                    .data()
                    .unique()
                    .sort()
                    .each(function (d, j) {
                      if (d.toString().trim() !== "") {
                        d.toString()
                          .split(",")
                          .forEach((item) => {
                            if (arr.indexOf(item) === -1) {
                              arr.push(item);

                              if (typeof select !== "undefined")
                                select.append(
                                  '<option value="' +
                                  item
                                    .replace("#", "_sharp")
                                    .replace("++", "pp") +
                                  '">' +
                                  item +
                                  "</option>"
                                );
                            }
                          });
                      }
                    });
                }
              });

            self.validate();
            self.setState({
              isLoaded: true,
            });
          },
        });
      });
    });
  }

  render() {
    //todo add 404 image
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">Leaderboard</h4>
          <div className="table-responsive">
            <table
              id="datatable"
              className="table table-centered table-striped mb-0"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
              }}
            >
              <thead className="thead-light">
                <tr>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>Languages</th>
                  <th>Technologies</th>
                  <th>Level</th>
                  <th>Points</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.rankData.allRanks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(fecthAllRankData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeaderBoard));
