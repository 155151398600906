import React, { Component } from "react";

class AssesmentsSkeleton extends Component {
  render() {
    return (
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  {/* <h5
                    className="mb-0 font-size-14 font-weight-bolder text-uppercase"
                    style={{ color: "#000" }}
                  >
                    Assessments
                  </h5> */}
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="">
                  <div className="card-body">
                    <div className="media">
                      <div className="avatar-md mr-4">
                        <span className="avatar-title _skeleton rounded-circle bg-light text-danger font-size-16"></span>
                      </div>
                      <div className="media-body overflow-hidden">
                        <h5
                          className="_skeleton mt-2"
                          style={{ width: "100%", height: "1rem" }}
                        ></h5>

                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                        <p
                          className="_skeleton mb-1"
                          style={{ width: "100%", height: ".5rem" }}
                        >
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                      <li className="list-inline-item mr-3 mb-2">
                        <div
                          className=" _skeleton"
                          style={{ height: "1rem", width: "4rem" }}
                        ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end row --> */}
          </div>{" "}
          {/**<!-- container-fluid -->**/}
        </div>
        {/* <!-- End Page-content --> */}

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
              <div className="col-sm-6">
                <div className="text-sm-right d-none d-sm-block">
                  Skills Ranker
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default AssesmentsSkeleton;
