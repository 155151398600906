import {
    client
} from "./client";

export function login(body) {
    return client.post("/login", body);
}

export function signup(body) {
    return client.post("/signup", body);
}

export function forgotPassword(body) {
    return client.post("/forgot", body);
}

export function resetPassword(body) {
    return client.post("/reset", body);
}

export function setupAccount(body) {
    return client.post("/setup", body);
}

export function setupOrganisationAccount(body) {
    return client.post("/setup/organisation", body);
}

export function getGithubProfile(body) {
    const {
        user
    } = body;
    return client.get("/github/user/" + user,);
}