import React, { Component } from "react";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
// import { getLanguageIcon } from "../../utils/utils";
// import { IMAGE_BASE_URL } from "../../api/client";
// import MDEditor from '@uiw/react-md-editor';
import { fireNotification } from "../../store/actions/notificationsActions";
import { fetchComplianceElements, newComplianceElement, pushComplianceElementUpdate, removeComplianceElement, bringBackComplianceElement } from "../../store/actions/complianceActions";

class AdminComplianceList extends Component {
  state = {
    complianceList:[],
    isLoaded: true,
    showNotification: false,
    id: "",
    title: "",
    description:"",
    scope:"global",
    update:false,
    isActive:true
  };

  componentDidMount() {
    this.props.getComplianceList()
    .then((res) => {
      this.setState({ isLoaded: true, complianceList:this.props.complianceList })
      
    })
    // .catch(err => this.props.dispatchNotification(err));
  }

  handleDescription = (e) => {
    this.setState({description:e})
  }
  toggleElementScope(){
    let newScope = this.state.scope == "global" ? "unique" :"global"
    console.log(newScope)
    this.setState({scope:newScope })
  }
  handleTitle(info) {
    this.setState(info);
  }

  update(doc) {
    this.setState(doc);
    this.setState({ update: true });
  }

  closeUpdate() {
    this.setState({ update: false, title: "", description:"" });
  }

  reviveElement(id){
    this.props.reviveComplianceElement(id)
          .then((res) => {
            this.setState({complianceList:this.props.complianceList});
            this.props.dispatchNotification(res)
            window.$("#compliance-modal").modal("hide")
          })
  }


  handleSubmit(event) {
    event.preventDefault();
    if (this.state.update) {

      let body = {
        complianceCheckId:this.state._id,
        title:this.state.title,
        description:this.state.description,
        scope:this.state.scope
      }
      this.props.updateComplianceElement(body)
          .then((res) => {
            this.setState({complianceList:this.props.complianceList});
            this.props.dispatchNotification(res)
            window.$("#compliance-modal").modal("hide")
          })
          // .catch(err => this.props.dispatchNotification(err));
          
    } else {
      let body = {
        title:this.state.title,
        description:this.state.description,
        scope:this.state.scope
      }
      this.props.createComplianceElement(body)
          .then((res) => {
            this.setState({complianceList:this.props.complianceList})
            this.props.dispatchNotification(res)
            window.$("#compliance-modal").modal("hide")
          })
          // .catch(err => this.props.dispatchNotification(err));
    }

  }

  deleteElement(id) {
    this.props.deleteComplianceElement(id)
    .then((res) => 
        {
          this.setState({ isLoaded: true, complianceList:this.props.complianceList })
        }
    )
    .catch(err => {
      console.log(err);
      
    })
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18"></h4>

                    <div className="page-title-right">
                      <button
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                        onClick={() => this.closeUpdate()}
                        type="button"
                        style={{
                          backgroundColor: "#74788d",
                          borderColor: "#74788d",
                          color: "white",
                        }}
                        className="btn waves-effect waves-light"
                      >
                        <i className="bx bxs-chip font-size-16 align-middle mr-2"></i>{" "}
                        Add Compliance Element
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                <div className="col-xl-12">
                  <div className="card pl-4 pr-3">
                    {/* <!-- languages --> */}
                    <div className="card-body">
                      <div className="row mb-4">
                        {this.state.complianceList.map((item, index) => (
                          item.isActive ? 
                          <div
                            style={{ cursor: "pointer" }}
                            key={item._id}
                            className="col-lg-6 col-sm-6 pl-3 pr-3 mt-2 mb-2"
                          >
                            <div
                              data-toggle="modal"
                              data-target=".bs-example-modal-center"
                              key={item._id}
                              onClick={() =>
                                this.update({
                                  _id: item._id,
                                  title: item.title,
                                  description: item.description,
                                  scope:item.scope,
                                  isActive:item.isActive
                                })
                              }
                              style={{
                                height: "4rem",
                                backgroundColor: "#f8f8fb",
                                display: "flex",
                                padding: "1rem",
                                borderRadius: ".5rem",
                                width: "100%",
                              }}
                            >
                              <p
                                className="ml-2 text-truncate"
                                style={{
                                  margin: "auto",
                                  fontSize: "16px",
                                  lineHeight: 2,
                                  color: "#000",
                                }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                          :
                            <div
                              style={{ cursor: "pointer"}}
                              key={item._id}
                              className="col-lg-6 col-sm-6 pl-3 pr-3 mt-2 mb-2"
                            >
                              <div
                                data-toggle="modal"
                                data-target=".bs-example-modal-center"
                                key={item._id}
                                onClick={() =>
                                  this.update({
                                    _id: item._id,
                                    title: item.title,
                                    description: item.description,
                                    scope:item.scope,
                                    isActive:item.isActive
                                  })
                                }
                                style={{
                                  height: "4rem",
                                  backgroundColor: "#ff00001a",
                                  display: "flex",
                                  padding: "1rem",
                                  borderRadius: ".5rem",
                                  width: "100%",
                                }}
                              >
                                <p
                                  className="ml-2 text-truncate"
                                  style={{
                                    margin: "auto",
                                    fontSize: "16px",
                                    lineHeight: 2,
                                    color: "#000",
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                            </div>

                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}

              <div
                className="modal fade bs-example-modal-center"
                tabIndex="-1"
                id="compliance-modal"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <h5 className="modal-title mt-0">Add a technology</h5> */}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        onSubmit={(e) => this.handleSubmit(e)}
                        className=" needs-validation custom-validation"
                      >
                        {
                          this.state.isActive ?
                            <>
                              <div className="mb-4">
                                <label>Title: </label>
                                <input
                                  onChange={(e) =>
                                    this.handleTitle({
                                      id: this.state.id,
                                      title: e.target.value,
                                    })
                                  }
                                  value={this.state.title}
                                  required
                                  className="form-control"
                                  name="title"
                                  type="text"
                                  placeholder="Compliance element title"
                                />
                              </div>
                              <div className="mt-4 form-group">
                                  <label>Do you want this to be applied to all businesses?</label>
                                  <input type="checkbox" id="complianceswitch" switch="bool" value={this.state.scope} checked={this.state.scope === "global"} onChange={() => this.toggleElementScope()} />
                                
                                  <label className="mb-0" style={{display:"block"}} htmlFor="complianceswitch" data-on-label="Yes" data-off-label="No"></label>
                              </div>
                              <div className="mt-4">
                                <label>Description: </label>
                                <div>
                                <textarea placeholder="Compliance element Description" value={this.state.description}  onChange={(e) =>this.handleDescription(e.target.value)} 
                                style={{width: "-webkit-fill-available",
                                        height: "100px",
                                        borderColor:"darkgray",
                                        borderRadius: "5px"
                                    }}/>
                                </div>
                              </div>
                              
                              <div
                                className="modal-footer"
                                style={{ borderTop: "none" }}
                              >
                                <button type="submit" className="btn btn-primary">
                                  {this.state.update ? "Update" : "Save"}
                                </button>
                                {!this.state.update ? (
                                  ""
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.deleteElement(this.state._id)
                                    }
                                    className="btn btn-secondary"
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            </>
                          :
                            <>
                              <div className="mb-4">
                                <label>Are you  sure you want to revive this compliance item ?</label>
                                
                              </div>
                              <div className="mt-4">
                                <label>Description: </label>
                                <div>
                                    <p>{this.state.description}</p>
                                </div>
                              </div>
                              <div
                                className="modal-footer"
                                style={{ borderTop: "none" }}
                              >
                                
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.reviveElement(this.state._id)
                                    }
                                    className="btn btn-secondary"
                                  >
                                    Update
                                  </button>
                                
                              </div>
                            </>

                        }
                        
                      </form>
                    </div>
                  </div>
                  {/** <!-- /.modal-content --> **/}
                </div>
                {/** <!-- /.modal-dialog --> **/}
              </div>
              {/** <!-- /.modal --> **/}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    complianceList: state.complianceData.complianceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createComplianceElement: (body) => dispatch(newComplianceElement(body)),
    updateComplianceElement: (body) => dispatch(pushComplianceElementUpdate(body)),
    reviveComplianceElement: (id) => dispatch(bringBackComplianceElement(id)),
    getComplianceList: () => dispatch(fetchComplianceElements()),
    deleteComplianceElement: (id) => dispatch(removeComplianceElement(id)),
    dispatchNotification:(msg) => dispatch(fireNotification(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminComplianceList);
