import React, { useState } from "react";

function Policy(props) {

  return (
    <>
      <link href="assets/css/terms.css" rel="stylesheet"></link>
      <div className="jumbotron" style={{ overflow: "none", width: "100%", backgroundImage: "url('./assets/images/header-overla.png')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <h1 className="display-4" style={{ color: "white", paddingTop: "30px", paddingLeft: "30px", paddinRight: "30px" }}>PRIVACY POLICY AND DISCLAIMER NOTICE</h1>
        <hr className="my-4" />

        <div className="rowed" >
          <a className="btn btn-outline-dark" href="/" role="button" style={{ textTransform: "none" }} >Go Back</a>
        </div>
      </div>

      <div className="cover">

        <p className="updated">Updated : 20 June 2020</p>

        <p>This Privacy Policy and Disclaimer sets out how BIH uses and protects any information given to BIH when this website is used. BIH is committed to ensuring that your privacy is protected. BIH is committed to ensuring that your information is secure. In order to prevent un-authorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>

        <p>Users of this website do hereby certify and agree that:</p>

        <ol>
          <li> Hereby consent to share with BIH general information relating to their business, experience and/or qualification as may be relevant. </li>
          <br />
          <li> The information to be shared shall be general in nature and is meant to assist BIH to asses and rank the user as a developer and whether the user could be considered for BIH registration, collaboration and or membership. BIH reserves the right to verify any information provided, undertake re- assessments or adopt any suitable methods for assessments as may be deemed necessary.</li>
          <br />
          <li>The user acknowledge that such ranking and assessment is solely BIH’s discretion. BIH reserves the right to alter and/or revoke an assessment at any given time</li>
          <br />
          <li> No trade secrets, intellectual property or confidential information shall be shared and or exchanged by the user while using this website. In the event that there is a need for further contracting of any form, appropriate Non-Disclosure Agreements shall be signed.</li>
          <br />
          <li>The information being disclosed pursuant to this Agreement is done so with the express understanding that neither of the parties will be obligated to enter into any further agreement relating to such shared information. </li>
          <br />
          <li> I hereby indemnify, release, discharge and agree to hold harmless  BIH, its agents and officers from all claims, causes of action and liability of any kind  known or unknown based upon or arising out of or in anyway connected with any information exchanged pursuant to this agreement including, without limitation, claims of defamation, trademark infringement and copyright infringement. </li>
          <br />
          <li> I have carefully read fully understood the terms and conditions of this Privacy Policy and Disclaimer notice and by using this site I hereby grant consent to same. This shall be binding upon my heirs, successors, representatives and assigns. </li>
        </ol>
      </div>
    </>
  );
}

export default Policy
