import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllAssesments,
  fetchCompletedAssessments,
} from "../../store/actions/assesmentsActions";
import BadgeHorizontal from "../../components/BadgeHorizontal";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Cookies from "js-cookie";
import { formatDate, getLanguageIcon, timeZoneHelper } from "../../utils/utils";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";

class Assesments extends Component {
  state = {
    isLoaded: false,
    skills: [],
    // technologies: [],
    currentSubject: null,
  };

  componentDidMount() {
    if (this.props.location.search.split("=").length > 0) {
      this.setState({
        currentSubject: this.props.location.search.split("=")[1],
      });

      //get user skills

      getLanguages(JSON.parse(Cookies.get("user")).programmingLanguages).then(
        (response) =>
          response.data.map((lang) =>
            this.setState({ skills: this.state.skills.concat(lang) })
          )
      );

      getTechnologies(JSON.parse(Cookies.get("user")).technologies).then(
        (response) =>
          response.data.map((tech) =>
            this.setState({ skills: this.state.skills.concat(tech) })
          )
      );

      return this.props
        .getAssesments(this.props.location.search.split("=")[1])
        .then(
          this.props.getCompletedAssessments(
            this.props.location.search.split("=")[1]
          )
        )
        .then(() => this.setState({ isLoaded: true }));
    }

    return this.props
      .getAssesments()
      .then(
        this.props.getCompletedAssessments(
          this.props.location.search.split("=")[1]
        )
      )
      .then(() => this.setState({ isLoaded: true }));
  }

  getAssessments(subject) {
    window.location = "/profile/assessments?subject=" + subject.value;
  }

  //   format_time(time) {
  //     return moment(time).format("MM-DD-YYYY");
  //   }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                <h5
                  className="mb-4 font-size-14 font-weight-bolder text-uppercase"
                  style={{ color: "#000" }}
                >
                  <button
                    className="btn btn-outline-secondary mr-4"
                    data-toggle="collapse"
                    data-target="#collapseSubjects"
                    aria-expanded="false"
                    aria-controls="collapseSubjects"
                  >
                    <i className="fa fa-arrow-down"></i>
                  </button>
                  skills / technologies
                </h5>
              </div>
              <div className="collapse show card p-3" id="collapseSubjects">
                {/* <h3 className="mb-3">Languages</h3> */}

                <div className="row d-flex justify-content-between pl-4">
                  {this.state.skills.map((item) => (
                    //return full url of icon with assessment to avoid calling getIcon functions
                    <BadgeHorizontal
                      icon={getLanguageIcon(item.img)}
                      currentSubject={this.state.currentSubject}
                      data={item.title}
                      key={item._id}
                    />
                  ))}
                </div>
              </div>

              {typeof this.props.completedAssesments !== "undefined" &&
                this.props.completedAssesments.length > 0 ? (
                <div className="row mt-2">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h5
                        className="mb-0 font-size-14 font-weight-bolder"
                        style={{ color: "#000" }}
                      >
                        <button
                          className="btn btn-outline-secondary mr-4"
                          data-toggle="collapse"
                          data-target="#collapseAssessments"
                          aria-expanded="false"
                          aria-controls="collapseAssessments"
                        >
                          <i className="fa fa-arrow-down"></i>
                        </button>
                        COMPLETED ASSESSMENTS
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="collapse show" id="collapseAssessments">
                <div className="row">
                  {typeof this.props.completedAssesments != "undefined"
                    ? this.props.completedAssesments.map((item) => (
                      <div
                        key={item._id}
                        className="col-xl-3 col-md-4 col-sm-6"
                      >
                        <div
                          className="card"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.history.push(
                              "/profile/assessments/" + item._id
                            )
                          }
                        >
                          <div
                            className="card-body"
                            style={{ paddingBottom: ".5rem" }}
                          >
                            <div className="media">
                              <div className="avatar-md mr-4">
                                <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                  <img
                                    src={getLanguageIcon(item.focus)}
                                    alt=""
                                    height="30"
                                  />
                                </span>
                              </div>
                              <div className="media-body overflow-hidden">
                                <h5
                                  className="text-truncate font-size-18 font-weight-bolder"
                                  style={{
                                    fontFamily: "monospace",
                                    color: "#000",
                                  }}
                                >
                                  <a href="#" className="text-dark">
                                    {item.title}
                                  </a>
                                </h5>

                                <p
                                  style={{
                                    maxHeight: "3.5rem",
                                    overflow: "hidden",
                                  }}
                                  className="text-muted mb-2"
                                >
                                  {item.description}
                                </p>
                                <br />
                                <p
                                  className="mt-4 mb-4 mr-2 text-primary font-size-14"
                                  style={{
                                    float: "left",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <b>
                                    Retake Assessment
                                    <i
                                      className="bx bx-right-arrow-alt"
                                      style={{
                                        fontWeight: "bolder",
                                      }}
                                    ></i>
                                  </b>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-3 border-top">
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item mr-3">
                                <span
                                  className="badge badge-secondary"
                                  style={{ padding: ".5rem" }}
                                >
                                  {item.focus}
                                </span>
                              </li>
                              <li className="list-inline-item mr-3">
                                <span
                                  className="badge badge-primary"
                                  style={{ padding: ".5rem" }}
                                >
                                  {item.level}
                                </span>
                              </li>
                              <li className="list-inline-item mr-3">
                                <span
                                  className="badge badge-success"
                                  style={{ padding: ".5rem" }}
                                >
                                  Completed{/* {item.level} */}
                                </span>
                              </li>
                              {/* <li
                              className="list-inline-item mr-3"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Due Date"
                              style={{float:'right'}}
                            >
                              <b>Take assessment <i className="bx bx-right-arrow-alt"></i></b>
                            </li> */}
                              <div className="row ml-auto d-inline-flex mt-4">
                                <li
                                  className="list-inline-item mr-3"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Due Date"
                                >
                                  <i className="bx bx-calendar mr-1"></i>
                                  <b>Date taken: </b>
                                  {item.taken}
                                </li>
                                <li
                                  className="list-inline-item mr-3"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Due Date"
                                >
                                  <b>Duration:</b> {item.duration}
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                    : ""}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h5
                      className="mb-0 font-size-14 font-weight-bolder"
                      style={{ color: "#000" }}
                    >
                      ASSESSMENTS
                    </h5>
                  </div>
                </div>
              </div>
              <div>
                {/* <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                  <h5
                    className="mb-0 font-size-14 font-weight-bolder text-uppercase"
                    style={{ color: "#000" }}
                  >
                    Assessments
                  </h5>
                </div> */}
                <div className="row">
                  {this.props.assesments.map((item) => (
                    <div key={item._id} className="col-xl-4 col-sm-6">
                      <div
                        className="card"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.history.push(
                            "/profile/assessments/" + item._id
                          )
                        }
                      >
                        <div
                          className="card-body"
                          style={{ paddingBottom: ".5rem" }}
                        >
                          <div className="media">
                            <div className="avatar-md mr-4">
                              <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                                <img
                                  src={getLanguageIcon(item.focus)}
                                  alt=""
                                  height="30"
                                />
                              </span>
                            </div>
                            <div className="media-body overflow-hidden">
                              <h5
                                className="text-truncate font-size-18 font-weight-bolder"
                                style={{
                                  fontFamily: "monospace",
                                  color: "#000",
                                }}
                              >
                                <a href="#" className="text-dark">
                                  {item.title}
                                </a>
                              </h5>

                              <p
                                style={{
                                  maxHeight: "3.5rem",
                                  fontFamily: "monospace",
                                  overflow: "hidden",
                                }}
                                className="text-muted mb-2"
                              >
                                {item.description}
                              </p>
                              <br />
                              <p
                                className="mt-4 mb-3 mr-2 text-primary font-size-14"
                                style={{
                                  float: "left",
                                  marginBottom: "0px",
                                }}
                              >
                                <b>
                                  Take Assessment
                                  <i
                                    className="bx bx-right-arrow-alt"
                                    style={{
                                      fontWeight: "bolder",
                                    }}
                                  ></i>
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-3 border-top">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3 mb-1">
                              <span
                                className="badge badge-secondary"
                                style={{ padding: ".5rem" }}
                              >
                                {item.focus}
                              </span>
                            </li>
                            <li className="list-inline-item mr-3 mb-1">
                              <span
                                className="badge badge-primary"
                                style={{ padding: ".5rem" }}
                              >
                                {item.level}
                              </span>
                            </li>
                            {/* <li
                              className="list-inline-item mr-3"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Due Date"
                              style={{float:'right'}}
                            >
                              <b>Take assessment <i className="bx bx-right-arrow-alt"></i></b>
                            </li> */}
                            {/* <div className="row ml-auto d-inline-flex mt-4"> */}
                            <li
                              className="list-inline-item mr-3 mb-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Due Date"
                            >
                              <b>Duration:</b> {item.duration}
                            </li>
                            <li
                              className="list-inline-item mr-3 mb-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              data-original-title="Due Date"
                            >
                              <i className="bx bx-calendar mr-1"></i>
                              <b>Date created: </b>
                              {formatDate(item.createdAt)}
                            </li>
                            {/* </div> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    assesments: state.assesmentsData.globalAssesmentsList,
    completedAssesments: state.assesmentsData.completedAssesments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssesments: (subject) =>
      dispatch(
        fetchAllAssesments({
          subject,
          userId: JSON.parse(Cookies.get("user"))._id,
        })
      ),
    getCompletedAssessments: (subject) =>
      dispatch(
        fetchCompletedAssessments({
          subject,
          userId: JSON.parse(Cookies.get("user"))._id,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assesments);
