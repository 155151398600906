import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { fetchBusinessJobPosts, updateJobPost } from "../store/actions/businessProfileActions";
import { fireNotification } from "../store/actions/notificationsActions";
import { connect } from "react-redux";
import moment from "moment";

class BusinessJobPosts extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    jobPosts: []
  };

  componentDidMount() {
    // console.log(this.props.profile)
    this.props.getJobPosts(this.props.business._id)
      .then(() => {
        this.setState({ jobPosts: this.props.jobPosts })
      })
      .catch(err => {
        this.props.dispatchNotification(err)
      })
  }

  toggleStatus = (job, idx) => {

    this.props.toggleJobState({ job: { _id: job._id, isActive: !job.isActive }, idx: idx })
      .then(() => {
        this.setState({ jobPosts: this.props.jobPosts })
      })
      .catch(err => {
        this.props.dispatchNotification(err)
      })
  }


  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (

      <div className="card table-responsive">
        {this.state.jobPosts.length > 0 ?

          <table className="table mb-0" style={{ border: "solid 1px #cdcdcd" }}>
            <thead style={{ backgroundColor: "#d1d1d7e6", height: "85px" }}>
              <tr>
                <th className="p-3" style={{ verticalAlign: "middle" }}>Job Title</th>
                <th className="p-3" style={{ verticalAlign: "middle" }}>Applications</th>
                <th className="p-3" style={{ verticalAlign: "middle" }}>Status</th>
                <th className="p-3" style={{ verticalAlign: "middle" }}></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.jobPosts.map((job, idx) => (
                  <tr key={job._id}>
                    <td>
                      <div className="p-2">
                        <p className="mr-2">{job.jobType}</p>
                        <h4 className="text-muted">{job.title}</h4>
                        <div style={{ display: "flex" }}>
                          <p className="mr-2">Created:  {moment(job.createdAt).format("MMM D, YYYY")}</p>
                          <p>Expiry: {moment(job.dueDate).format("MMM D, YYYY")}</p>
                        </div>
                      </div>
                    </td>
                    {
                      job.applications.length > 0 ?
                        <td><div className="" style={{ transform: "translateY(100%)", cursor: "pointer" }} onClick={() => this.props.navigateToApplicants(job._id)}>{job.applications.length} Applications</div></td>
                        :
                        <td><div className="" style={{ transform: "translateY(100%)", cursor: "pointer" }}>{job.applications.length} Applications</div></td>
                    }

                    <td><div style={{ transform: "translateY(100%)" }}>{job.isActive ? "Open" : "Closed"}</div></td>
                    <td>
                      <div className="p-2" style={{ display: "flex" }}>
                        <button onClick={() => this.props.navigateToCreation("edit", job)} type="button" className="btn btn-info waves-effect mr-1"><i className="bx bx-pencil"></i></button>
                        {
                          job.isActive ?
                            <button type="button" className="btn btn-danger waves-effect" onClick={() => this.toggleStatus(job, idx)}><i className="bx bx-analyse"></i></button>
                            :
                            <button type="button" className="btn btn-warning waves-effect" onClick={() => this.toggleStatus(job, idx)}><i className="bx bx-analyse"></i></button>
                        }

                      </div>
                    </td>
                  </tr>
                ))

              }
            </tbody>
          </table>
          :
          <div style={{ width: "inherit", height: "500px", textAlign: "center" }}>
            <div className="mt-4">
              <p >Looks like you do not have any job posts yet. Lets help you create one</p>
              <button className="btn btn-warning text-dark font-weight-bold" onClick={() => { this.props.navigateToCreation("new") }}>Create new post</button>
            </div>
            <div style={{ width: "50%", height: "50%", margin: "0 auto", textAlign: "center", backgroundImage: "url('/assets/images/undraw_add_information_j2wg.svg')", backgroundRepeat: "no-repeat", backgroundSize: "contain", transform: "translateY(30%)" }}>


            </div>
          </div>
        }


      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobPosts: state.jobPostData.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getJobPosts: (business) => dispatch(fetchBusinessJobPosts(business)),
    toggleJobState: (body) => dispatch(updateJobPost(body))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessJobPosts));
