import React from "react";
import { Route, Redirect } from "react-router";

export default function AdminRoute({
  component: Component,
  authenticated,
  isAccountSetup,
  accountType,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        // return <Component {...props} />;
        return authenticated && (accountType === "sponsor") ? (
          <Component {...props} />) : (<Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location
              }
            }}
          />
          );
      }}
    />
  );
}
