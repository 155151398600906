import React, {Component} from "react";

class Data404 extends Component {
     

    render() {

            return(

                <div className="card" style={{backgroundColor:'transparent', position:'fixed',width:'100%', height:'100%', top:0, left:0, bottom:0, right:0,}}>
                    <div className="card-body" style={{width:'25rem', height:'25rem', position:'absolute', margin:'auto', top:0, right:0, bottom:0, left:0,  backgroundImage:'url("/assets/images/monkeybusiness.png")', backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>
                        
                    </div>
                </div>

            );
            
    }
}




export default Data404;

