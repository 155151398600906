const initState = {
    teams: [],
    eliminatedTeams:[],
    team: {},
};

const teamReducer = (state = initState, action) => {
    switch (action.type) {
        case "PUBLISH_TEAM":
            return {
                ...state,
                team: { ...action.team }
            };

        case "GET_ALL_EVENT_TEAMS":
            return {
                ...state,
                teams: action.teams
            };

        case "GET_TEAM":
            return {
                ...state,
                team: { ...action.team }
            };

        case "UPDATE_TEAM":
            return {
                ...state,
                team: { ...action.team }
            };

        case "UPDATE_TEAM_STATUS":
            return {
                ...state,
                team: { ...action.team }
            };
        case "EVENT_SUBMIT":
            return {
                ...state,
                team: { ...state.team, submissions: action.submissions }
            }
        case "GET_ALL_EVENT_ELIMINATED_TEAMS":
            return {
                ...state,
                eliminatedTeams: action.teams
            };
        default:
            break;
    }
    return state;
};

export default teamReducer;