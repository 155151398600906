import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import Cookie from "js-cookie";
import { withRouter } from "react-router-dom";
import AppUsers from "../containers/pages/AppUsers";
import AdminUsers from "../containers/pages/AdminUsers";
import AdminAssesments from "../containers/pages/AdminAssesments";
import IUser from "../containers/pages/IUser";
import AdminAssesmentNew from "../containers/pages/AdminAssesmentNew";
import AdminContest from "../containers/pages/AdminContest";
import AdminContestNew from "../containers/pages/AdminContestNew";
import AdminLearn from "../containers/pages/AdminLearn";
import Data404 from "../containers/pages/Data404";
import AdminContestList from "../containers/pages/AdminContestList";
import AdminLearnList from "../containers/pages/AdminLearnList";
import Dashboard from "../containers/pages/Dashboard";
import { deleteNotification } from "../store/actions/notificationsActions";
import Toast from "react-bootstrap/Toast";
import AdminTech from "../containers/pages/AdminTech";
import AdminLangs from "../containers/pages/AdminLangs";
import AdminEventRequestList from "../containers/pages/AdminEventRequestList";
import AdminBusinesses from "../containers/pages/AdminBusinesses";
import AdminBusinessesDetails from "../containers/pages/AdminBusinessesDetails";
import AdminComplianceList from "../containers/pages/AdminComplianceList";

class AdminWrapper extends Component {
  state = {};

  render() {
    const authenticated = typeof Cookie.get("user") !== "undefined";

    return (
      <div id="layout-wrapper">
        <header id="page-topbar" style={{ backgroundColor: "#2a3042" }}>
          <div className="navbar-header">
            <div className="d-flex">
              {/* <!-- LOGO --> */}
              <div className="navbar-brand-box">
                <NavLink
                  to="#"
                  className="logo logo-dark"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img src="/assets/images/logo.svg" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </NavLink>
                <NavLink
                  to="#"
                  className="logo logo-light"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img
                      src="/assets/images/logo-light.svg"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-light.png"
                      alt=""
                      height="19"
                    />
                  </span>
                </NavLink>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block">
                {/* <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => this.props.history.push('/profile')}>
                                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"/>
                                <span className="d-none d-xl-inline-block ml-1">Henry</span>
                                
                            </button> */}
                <NavLink
                  to="/profile"
                  className="btn header-item waves-effect"
                  style={{ verticalAlign: "middle", paddingTop: "16px" }}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={
                      JSON.parse(Cookie.get("user")).githubData
                        ? JSON.parse(Cookie.get("user")).githubData.avatar_url
                        : "/assets/images/users/avatar.png"
                    }
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ml-1">
                    {JSON.parse(Cookie.get("user")).name.firstName +
                      " " +
                      JSON.parse(Cookie.get("user")).name.lastName}
                  </span>
                </NavLink>
              </div>
              <div className="dropdown d-inline-block">
                <button
                  onClick={logout}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-power-off"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <div
                className="collapse navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <NavLink
                      to="/admin"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bxs-dashboard mr-2"></i>Dashboard{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      to="/admin/app-users"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-user mr-2"></i>Users
                      <div className="arrow-down"></div>
                    </NavLink>
                    <div
                      className="dropdown-menu dropdown-menu-right "
                      aria-labelledby="topnav-layout"
                    >
                      <NavLink to="/admin/app-users" className="dropdown-item">
                        {" "}
                        App Users{" "}
                      </NavLink>
                      <NavLink
                        to="/admin/admin-users"
                        className="dropdown-item"
                      >
                        {" "}
                        Admin Users{" "}
                      </NavLink>

                      {/* <!-- <a href="permissions.html" className="dropdown-item">Permissions</a> --> */}
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      to="/admin/businesses"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bxs-chip mr-2"></i>Businesses
                      <div className="arrow-down"></div>

                    </NavLink>
                    <div
                      className="dropdown-menu dropdown-menu-right "
                      aria-labelledby="topnav-layout"
                    >
                      <NavLink to="/admin/compliances" className="dropdown-item">
                        {" "}
                        Compliance List{" "}
                      </NavLink>

                    </div>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/admin/assessments"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-code-block mr-2"></i>Assessments{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/admin/technologies"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bxs-chip mr-2"></i>Technologies{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      to="/admin/languages"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bxs-chip mr-2"></i>Languages{" "}
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink
                      to="/admin/hackathon"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className=" bx bx-shekel mr-2"></i>Hackathons{" "}
                      <div className="arrow-down"></div>
                    </NavLink>
                    <div
                      className="dropdown-menu dropdown-menu-right "
                      aria-labelledby="topnav-layout"
                    >
                      <NavLink to="/admin/hackathon" className="dropdown-item">
                        {" "}
                        Hackathons{" "}
                      </NavLink>
                      <NavLink
                        to="/admin/hackathon/requests"
                        className="dropdown-item"
                      >
                        {" "}
                        Hackathon Requests{" "}
                      </NavLink>

                      {/* <!-- <a href="permissions.html" className="dropdown-item">Permissions</a> --> */}
                    </div>
                  </li>

                  















                 {/*   
                  <li className="nav-item dropdown">
                    <NavLink
                      to="/admin/hackathon"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-code-block mr-2"></i>Incidents{" "}
                      <div className="arrow-down"></div>
                    </NavLink>
                    <div
                      className="dropdown-menu dropdown-menu-right "
                      aria-labelledby="topnav-layout"
                    >
                      <NavLink to="/admin/hackathon" className="dropdown-item">
                        {" "}
                        Log an Incident{" "}
                      </NavLink>
                      <NavLink
                        to="/admin/hackathon/requests"
                        className="dropdown-item"
                      >
                        {" "}
                        Incident Logs{" "}
                      </NavLink>

                       <!-- <a href="permissions.html" className="dropdown-item">Permissions</a> --> */}
                    {/*</div>
                  </li>
                  */}




















                  {/* <li className="nav-item ">
                    <NavLink
                      to="/admin/hackathon"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className=" bx bx-shekel mr-2"></i>Hackathons{" "}
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item ">
                    <NavLink
                      to="/admin/course"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className=" bx bx-hive mr-2"></i>Courses{" "}
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        {/* Notifications */}
        <div
          className="notifications_wrapper"
          style={{ position: "fixed", zIndex: 2000, top: "140px", right: 0 }}
        >
          <div
            className="notification_container"
            style={{
              position: "relative",

              right: "1rem",
              width: "20rem",
            }}
          >
            {this.props.notifications.map((notification) => (
              <Toast
                key={notification.id}
                onClose={() => this.props.removeNotification(notification)}
                show={notification.isActive}
                autohide
                delay={notification.delay}
              >
                <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                  />
                  <strong id="title" className="mr-auto">
                    {notification.title}
                  </strong>
                  <small id="time">{notification.time}</small>
                </Toast.Header>
                <Toast.Body id="body">{notification.body}</Toast.Body>
              </Toast>
            ))}
          </div>
        </div>

        <Switch>
          {" "}
          <Route path={"/admin"} component={Dashboard} exact />
          <Route path={"/admin/app-users"} component={AppUsers} exact />
          <Route path={"/admin/user/:id"} component={IUser} />
          <Route path={"/admin/admin-users"} component={AdminUsers} exact />
          <Route path={"/admin/businesses"} component={AdminBusinesses} exact />
          <Route path={"/admin/businesses/:id"} component={AdminBusinessesDetails} exact />
          <Route
            path={"/admin/assessments"}
            component={AdminAssesments}
            exact
          />
          <Route
            path={"/admin/assessments/new"}
            component={AdminAssesmentNew}
            exact
          />
          <Route
            path={"/admin/assessments/:id"}
            component={AdminAssesmentNew}
            exact
          />
          <Route path={"/admin/hackathon/requests"} component={AdminEventRequestList} exact />
          <Route path={"/admin/hackathon"} component={AdminContestList} exact />
          <Route path={"/admin/hackathon/new"} component={AdminContestNew} exact />
          <Route path={"/admin/hackathon/edit/:id"} component={AdminContestNew} exact />
          <Route path={"/admin/hackathon/:id"} component={AdminContest} exact />
          <Route path={"/admin/hackathon/requests/:id"} component={AdminContest} exact />
          <Route path={"/admin/course"} component={AdminLearnList} exact />
          <Route path={"/admin/course/new"} component={AdminLearn} exact />
          <Route path={"/admin/course/:id"} component={AdminLearn} exact />
          <Route path={"/admin/languages"} component={AdminLangs} exact />
          <Route path={"/admin/compliances"} component={AdminComplianceList} exact />
          <Route path={"/admin/technologies"} component={AdminTech} exact />
          <Route component={Data404} />{" "}
        </Switch>
      </div>
    );
  }
}
/**
 * log user out and redirect to login screen
 */
function logout() {
  Cookies.remove("user");
  window.location = "/login";
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notificationsData.notificationsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotification: (nid) => dispatch(deleteNotification(nid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminWrapper));
