import {
    client
} from "./client";

export function getRank(uid) {
    return client.get("rank/" + uid, {
        // headers: {
        //     session: session
        // }
    });
}

export function getAllRank() {
    return client.get("/ranks", {
        // headers: {
        //     session: session
        // }
    });
}