import React, {Component} from "react";

class LeaderBoardSkeleton extends Component {
     

    render() {

            return (
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title mb-4">Leaderboard</h4>
                        <div className="_skeleton mb-3" style={{width:'10rem', height:'2.5rem'}}></div>
                        <div className="_skeleton mb-3" style={{width:'100%', height:'2.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                        <div className="_skeleton mb-2" style={{width:'100%', height:'1.5rem'}}></div>
                    </div>
                </div>
            );        
        }
}




export default LeaderBoardSkeleton;

