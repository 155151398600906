import React, { Component } from "react";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import {
  fetchAllTechnologies,
  pushTech,
  killTech,
  techUpdate,
} from "../../store/actions/technologiesActions";
// import { getTechnologyIcon } from "../../utils/utils";
import { IMAGE_BASE_URL } from "../../api/client";

class AdminTech extends Component {
  state = {
    technologies: [],
    isLoaded: false,
    update: false,
    showNotification: false,
    notifications: [],
    id: "",
    img: "",
    title: "",
    fileName: "Choose File",
    changeIcon:false,
  };

  handleImage(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        img: reader.result,
        fileName: file.name,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
      this.setState({
        img: reader.result,
        fileName: file.name,
      });
    } else {
      this.setState({
        img: "",
      });
    }
  }
  handleTitle(info) {
    this.setState(info);
  }

  componentDidMount() {
    this.props.getTechnologies().then((res) => this.setState({technologies:res.data, isLoaded: true }));
  }

  update(doc) {
    this.setState(doc);
    this.setState({ update: true });
  }

  closeUpdate() {
    this.setState({ update: false, title: "", fileName:'Choose File', changeIcon:false });
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    // console.log(dataURI)
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();

    if (this.state.update) {

          if (this.state.img !== null && this.state.img !== "" && this.state.img.includes('data:image') && this.state.changeIcon === true) {

            //update all fields
            formData.append("id", this.state.id);
            formData.append("img", this.dataURItoBlob(this.state.img));
            formData.append("title", this.state.title);
            this.props.updateTech({ id: this.state.id, formData }).then((res)=>{
              let technologies = this.state.technologies
              let oldTechnologies = technologies.filter((tech) => tech._id === res.data._id)[0]
              console.log(technologies);
              
              let tempT = this.state.technologies;
              
              tempT[this.state.technologies.indexOf(oldTechnologies)] = res.data;
              
              this.setState({technologies: tempT })
            }).catch(err => {console.log(err);
            });

          }else{

            //update title field 
            formData.append("id", this.state.id);
            formData.append("title", this.state.title);
            this.props.updateTech({ id: this.state.id, formData }).then((res)=>{
              let technologies = this.state.technologies
              let oldTechnologies = technologies.filter((tech) => tech._id === res.data._id)[0]
            
              let tempT = this.state.technologies;
              
              tempT[this.state.technologies.indexOf(oldTechnologies)] = res.data;
              
              this.setState({technologies: tempT })
            });
          }
     
    } else {

      if (this.state.img !== null || this.state.img !== "") {

        formData.append("img", this.dataURItoBlob(this.state.img));
        // formData.append("img", this.state.img)
        
        formData.append("title", this.state.title);
        this.props.createTech(formData);
      }

    }
   
  }

  deleteTech(id) {
    console.log(id);
    
    this.props.deleteTech(id)
    .then(() => ( this.setState({isLoaded:false}), this.props.getTechnologies()))
    .then(() => this.setState({ isLoaded: true }))
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18"></h4>

                    <div className="page-title-right">
                      <button
                        onClick={() => this.closeUpdate()}
                        type="button"
                        style={{
                          backgroundColor: "#74788d",
                          borderColor: "#74788d",
                          color: "white",
                        }}
                        className="btn waves-effect waves-light"
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        <i className="bx bxs-chip font-size-16 align-middle mr-2"></i>{" "}
                        Add Technology
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                <div className="col-xl-12">
                  <div className="card pl-4 pr-3">
                    {/* <!-- Technologies --> */}
                    <div className="card-body">
                      <div className="row mb-4">
                        {this.props.technologies.map((item, index) => (
                          <div
                            style={{ cursor: "pointer" }}
                            key={item._id}
                            className="col-lg-3 col-sm-6 pl-3 pr-3 mt-2 mb-2"
                          >
                            <div
                              data-toggle="modal"
                              data-target=".bs-example-modal-center"
                              key={item._id}
                              onClick={() =>
                                this.update({
                                  id: item._id,
                                  title: item.title,
                                  img: item.img,
                                  fileName: item.img,
                                })
                              }
                              style={{
                                height: "4rem",
                                backgroundColor: "#f8f8fb",
                                display: "flex",
                                padding: "1rem",
                                borderRadius: ".5rem",
                                width: "100%",
                              }}
                            >
                              <div style={{ width: "50px" }}>
                                <img
                                  alt="badge"
                                  src={IMAGE_BASE_URL + "uploads/" + item.img}
                                  style={{
                                    height: "100%",
                                    backgroundSize: "cover",
                                  }}
                                />
                              </div>

                              <p
                                className="ml-2 text-truncate"
                                style={{
                                  margin: "auto",
                                  fontSize: "16px",
                                  lineHeight: 2,
                                  color: "#000",
                                }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}

              <div
                className="modal fade bs-example-modal-center"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      {/* <h5 className="modal-title mt-0">Add a technology</h5> */}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        onSubmit={(e) => this.handleSubmit(e)}
                        className=" needs-validation custom-validation"
                        encType="multipart/form-data"
                      >
                        <div className="mb-4">
                          <input
                            onChange={(e) =>
                              this.handleTitle({
                                id: this.state.id,
                                title: e.target.value,
                              })
                            }
                            value={this.state.title}
                            required
                            className="form-control"
                            name="title"
                            type="text"
                            placeholder="title"
                          />
                        </div>
                        
                          <div className="ml-3 mt-2" >
                                  
                          { this.state.update ? 
                              
                              this.state.changeIcon ?
                                <label style={{cursor:'pointer'}} onClick={() => this.setState({changeIcon:false})}>{'>> I dont want to change the icon <<'}</label>
                              
                              : 
                                <label style={{cursor:'pointer'}} onClick={() => this.setState({changeIcon:true})}>{'>> Click to change icon <<'} </label>
                              
                          
                          :
                            ''
                          }
                          </div>
                          <div className="custom-file mb-4 mt-2">
                          {this.state.update ?
                              this.state.changeIcon ? 
                              <>
                                  <input
                                  onChange={(e) => this.handleImage(e)}
                                  required
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  // value = {this.state.fileName}
                                  style={{position:'relative'}}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  {this.state.fileName}
                                </label>
                              </>
                                :
                                ''
                          :
                              <>
                                  <input
                                  onChange={(e) => this.handleImage(e)}
                                  required
                                  type="file"
                                  className="custom-file-input"
                                  id="customFile"
                                  // value = {this.state.fileName}
                                  style={{position:'relative'}}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="customFile"
                                >
                                  {this.state.fileName}
                                </label>
                                </>
                          }
                            
                          </div>
                          

                      
                        

                        <div
                          className="modal-footer"
                          style={{ borderTop: "none" }}
                        >
                          <button type="submit" className="btn btn-primary">
                            {this.state.update ? "Update" : "Save"}
                          </button>
                          {!this.state.update ? (
                            ""
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                this.deleteTech(this.state.id)
                              }
                              className="btn btn-secondary"
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  {/** <!-- /.modal-content --> **/}
                </div>
                {/** <!-- /.modal-dialog --> **/}
              </div>
              {/** <!-- /.modal --> **/}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    technologies: state.technologiesData.allTechnologiesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTechnologies: () => dispatch(fetchAllTechnologies()),
    createTech: (data) => dispatch(pushTech(data)),
    deleteTech: (data) => dispatch(killTech(data)),
    updateTech: (data) => dispatch(techUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTech);
