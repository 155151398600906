import React, { Component } from "react";
import { fetchContest, getContestleaderboard } from "../../store/actions/contestActions";
import { fetchAllEventTeams } from "../../store/actions/teamActions";
import { connect } from "react-redux";
// import Select from "react-select";
import Cookies from "js-cookie";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
// import {Wizard} from "./wizard"
import ReactHtmlParser from 'react-html-parser';
import bih from "../../../src/bih.jpg"

class UserContest2 extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        event: {},
        teams: [],
        leaderboard: [],
        id: '',
        title: '',
        date: '',
        info: '',
        rules: '',
        prizes: '',
        tags: '',
        type: '',
        isEditing: '',
        isLoaded: false,
        caughtErr: false,
        // teams: [
        //     {
        //         name: "team 1",
        //         members: [
        //             {
        //                 name: {
        //                     firstName: "Elias",
        //                     lastName: "Bayona"
        //                 },
        //                 propic: null,
        //                 rank: "1234"
        //             },
        //             {
        //                 name: {
        //                     firstName: "Daniel",
        //                     lastName: "Dube"
        //                 },
        //                 propic: "https://avatars3.githubusercontent.com/u/21962078?v=4",
        //                 rank: "1234"
        //             },
        //         ]
        //     }
        // ],
        team: {
            name: "Innovators",
            _id: "1",
            members: [
                {
                    name: {
                        firstName: "Elias",
                        lastName: "Bayona"
                    },
                    propic: null,
                    rank: "1234"
                },
                {
                    name: {
                        firstName: "Daniel",
                        lastName: "Dube"
                    },
                    propic: "https://avatars3.githubusercontent.com/u/21962078?v=4",
                    rank: "1234"
                },
            ],
            submissions: [

                {
                    filename: "Skote Landing.Zip",
                    size: "3.25 MB",
                    url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
                    submissionTime: "12:00 pm",
                },
                {
                    filename: "Skote Landing.Zip",
                    size: "3.25 MB",
                    url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
                    submissionTime: "12:00 pm",
                }
            ]


        }
    };




    componentDidMount() {

        const { history } = this.props;
        let splitPath = history.location.pathname.split("/");
        let cid = splitPath[3]

        if (cid === undefined) this.props.history.push("/")

        this.props.getContest(cid)
            .then(() => this.setState({ event: this.props.contest, isLoaded: true, caughtErr: false }))
        // .catch(err => this.props.dispatchNotification(err))

        this.props.getEventTeams(cid)
            .then(() => this.setState({ teams: this.props.eventTeams }))
            .catch(err => console.log(err))

        this.props.getLeaderboard(cid)
            .then(() => this.setState({ leaderboard: this.props.leaderboard }))
            .catch(err => console.log(err))


    }

    user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

    /**
     * update state with input change
     * 
     * @param  {} doc key value pair with state variable to change as key and variable value as value
     */
    handleInputChange(doc) {
        this.setState(doc)
    }




    /**
     * submit contest data to backend
     * prevent page from refreshing
     * 
     * @param  {} e submit event
     */
    handleSubmit(e) {
        {
            e.preventDefault()
            this.state.isEditing ?
                this.props.updateContest(this.state.id, this.state)
                :
                this.props.updateContest(null, this.state)
        }

    }



    /**
     * check if all required data is loaded
     * render componet if true else render loading animations
     */
    render() {

        if (this.state.isLoaded) {

            return (
                <div className="main-content">
                    <div className="page-content" style={{ padding: "0px" }}>
                        <div>
                            <div className="row" style={{ marginTop: "-4rem" }}>
                                <div className="col-xl-12">
                                    <main>
                                        <section
                                            id="parallax"
                                            className="parallax slider-area slider3-bg second-slider3-bg d-flex align-items-center justify-content-left fix"
                                        >
                                            {typeof this.state.event.eventBanner !== "undefined" ?
                                                <div style={{ backgroundImage: "url(" + this.state.event.eventBanner.data.data + ")", position: "absolute", width: "100vw", height: "100%" }}></div>
                                                :
                                                <div style={{ backgroundImage: "url(" + bih + ")", position: "absolute", width: "100vw", height: "100%" }}></div>
                                            }
                                            <div style={{ background: "#00000063", position: "absolute", width: "100vw" }}></div>
                                            <div className="slider-active">
                                                <div className="single-slider">
                                                    <div className="container" style={{ maxWidth: "100vw", width: "100vw" }}>
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <div className="slider-content second-slider-content" style={{ padding: "2rem" }}>
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <br />
                                                                    <h3 data-animation="fadeInUp animated" data-delay=".4s" style={{ fontSize: "3vw", color: "white" }}>
                                                                        {this.state.event.title}
                                                                    </h3>
                                                                    <p data-animation="fadeInUp animated" data-delay=".4s" style={{ color: "white" }}>
                                                                        {this.state.event.startDate} - {this.state.event.endDate}
                                                                    </p>

                                                                    <br />
                                                                    <a
                                                                        id="loginButton"
                                                                        href={this.user ? "/profile" : "/login"}
                                                                        className="btn mb-3 d-none"
                                                                        style={{ float: "left" }}
                                                                    >
                                                                        <i className="fa fa-user-alt" />{" "}
                                                                        {this.user ? "Profile" : "Login"}
                                                                    </a>
                                                                    <br />
                                                                    <a className="btn" href={"/profile/hackathon/register/" + this.state.event._id}>
                                                                        {" "}
                                                                        Register For Event
                                                                    </a>
                                                                    <br />
                                                                    <br />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section
                                            style={{ alignItems: "center", minHeight: "100vh", padding: "2rem 0" }}
                                            id="how-it-works"
                                            className="about-area about-p p-relative"
                                        >
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="">
                                                        <div className="card-body">

                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                        <a className="nav-link mb-2 active" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-description-tab" data-toggle="pill" href="#v-pills-description" role="tab" aria-controls="v-pills-description" aria-selected="true">Description</a>
                                                                        <a className="nav-link mb-2" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-rules-tab" data-toggle="pill" href="#v-pills-rules" role="tab" aria-controls="v-pills-rules" aria-selected="false">Rules and legal</a>
                                                                        <a className="nav-link mb-2" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-teams-tab" data-toggle="pill" href="#v-pills-teams" role="tab" aria-controls="v-pills-teams" aria-selected="false">Teams</a>
                                                                        <a className="nav-link mb-2" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-prizes-tab" data-toggle="pill" href="#v-pills-prizes" role="tab" aria-controls="v-pills-prizes" aria-selected="false">Prizes</a>
                                                                        <a className="nav-link mb-2" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-leaderboard-tab" data-toggle="pill" href="#v-pills-leaderboard" role="tab" aria-controls="v-pills-leaderboard" aria-selected="false">Leader Board</a>
                                                                        <a className="nav-link mb-2" style={{ borderBottom: "solid 1px rgb(172 175 177)", borderRadius: "0px" }} id="v-pills-my-team-tab" data-toggle="pill" href="#v-pills-my-team" role="tab" aria-controls="v-pills-my-team" aria-selected="false">My Team</a>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-9">
                                                                    <div className="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent" style={{ borderLeft: "solid 1px rgb(172 175 177)", padding: "1rem", minWidth: "70vw" }}>
                                                                        <div className="tab-pane active show fade" id="v-pills-description" role="tabpanel" aria-labelledby="v-pills-description-tab">
                                                                            <div className="mb-4">
                                                                                <h2 style={{ fontSize: "29px", fontWeight: "lighter", color: "rgb(134 135 135)", textTransform: "uppercase" }} className="regular dark weight-600 caps inline-block float-left mr-3">
                                                                                    Event overview
                                                                                </h2>
                                                                                <div className="section-line" style={{ overflow: "hidden", height: "24px", maxWidth: "100%" }}>
                                                                                    <span style={{ backgroundColor: "#e5e7e8", width: "100%", height: "4px", display: "inline-block" }}>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {ReactHtmlParser(this.state.event.description)}
                                                                            <p>
                                                                                <a href=" https://join.slack.com/t/microsoftazur-hk06974/shared_invite/zt-hmsypi0n-o9_avPvXVFeqp9p5_dnNdQ ">
                                                                                    <img src="https://s3-ap-southeast-1.amazonaws.com/he-public-data/Hack1 Slack-01-01b9db9ac.png" />
                                                                                </a>
                                                                            </p>
                                                                        </div>
                                                                        <div className="tab-pane fade" id="v-pills-rules" role="tabpanel" aria-labelledby="v-pills-rules-tab">
                                                                            <div className=" mb-4">
                                                                                <h2 style={{ fontSize: "29px", fontWeight: "lighter", color: "rgb(134 135 135)", textTransform: "uppercase" }} className="regular dark weight-600 caps inline-block float-left mr-3">
                                                                                    Event rules
                                                                                </h2>
                                                                                <div className="section-line" style={{ overflow: "hidden", height: "24px", maxWidth: "100%" }}>
                                                                                    <span style={{ backgroundColor: "#e5e7e8", width: "100%", height: "4px", display: "inline-block" }}>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <ul>
                                                                                <li>
                                                                                    <p>This hackathon is only for working professionals.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>The solutions should be built on Azure.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>You should have an Azure account. If you do not have one, please create one here: <a href="https://azure.microsoft.com/en-us/free/?wt.mc_id=AID3018329_QSG_454858&amp;ocid=AID3018329_QSG_454858" rel="noreferrer" target="_blank">aka.ms/AzureLeagueAccount</a></p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>This is a team participation hackathon. You can have at most <strong>5</strong> members in your team.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>You can either invite your friends to form a team or you can request other teams to add you as a member.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>The hackathon will start at <strong>Sep 17, 2020 09:00 AM IST</strong> and end at <strong>Oct 18, 2020 11:55 PM IST</strong>, you work on your hack during the allotted time only.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>It's an online hackathon, you can participate from anywhere.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>There are <strong>3</strong> themes of the hackathon, you must submit a hack that is in one of these <a href="/challenges/hackathon/microsoft-azure-champions-league-hack-2/description/#themes">themes</a>.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Once the hackathon starts, you will get an option to submit your hack, you can submit as many times as you want, the last hack will be considered as the final submission.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>You are expected to come up with new and innovative ideas, any idea that has been copied from somewhere will be disqualified.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Your hack must be developed entirely during the Hackathon duration. You may use open source libraries and other freely available systems / services such as Google Maps, Facebook Connect, Twitter feeds etc.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>The intellectual property of your code belongs only to your team.</p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>By participating in the hackathon, you agree to the <a href="https://www.hackerearth.com/terms-of-service/">terms and conditions</a> of HackerEarth.</p>
                                                                                </li>
                                                                            </ul>
                                                                            <div className="mt-4 mb-4">
                                                                                <h2 style={{ fontSize: "29px", fontWeight: "lighter", color: "rgb(134 135 135)", textTransform: "uppercase" }} className="regular dark weight-600 caps inline-block float-left mr-3">
                                                                                    Legal
                                                                                </h2>
                                                                                <div className="section-line" style={{ overflow: "hidden", height: "24px", maxWidth: "100%" }}>
                                                                                    <span style={{ backgroundColor: "#e5e7e8", width: "100%", height: "4px", display: "inline-block" }}>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <p>
                                                                                Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                                                                                single-origin coffee squid. Exercitation +1 labore velit, blog
                                                                                sartorial PBR leggings next level wes anderson artisan four loko
                                                                                farm-to-table craft beer twee. Qui photo booth letterpress,
                                                                                commodo enim craft beer mlkshk.
                                                                            </p>
                                                                        </div>
                                                                        <div className="tab-pane fade" id="v-pills-teams" role="tabpanel" aria-labelledby="v-pills-teams-tab">
                                                                            <div className=" mb-4">

                                                                                <h2 style={{ fontSize: "29px", fontWeight: "lighter", color: "rgb(134 135 135)", textTransform: "uppercase" }} className="regular dark weight-600 caps inline-block float-left mr-3">
                                                                                    Teams
                                                                                </h2>
                                                                                <div className="section-line" style={{ overflow: "hidden", height: "24px", maxWidth: "100%" }}>
                                                                                    <span style={{ backgroundColor: "#e5e7e8", width: "100%", height: "4px", display: "inline-block" }}>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <p className="mb-4">This section shows all registered teams,
                                                                                join a team, get to know your teammates, share your interests, and have fun.
                                                                            </p>
                                                                            {
                                                                                this.state.teams.map(team => (
                                                                                    <div>
                                                                                        <p>{team.name}</p>
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-nowrap table-centered table-hover mb-0">
                                                                                                <tbody>
                                                                                                    {team.members.map(member =>
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td style={{ width: "45px" }}>
                                                                                                                    {member.propic ?
                                                                                                                        <img
                                                                                                                            className="rounded-circle avatar-sm"
                                                                                                                            src={member.propic}
                                                                                                                            alt=""
                                                                                                                        ></img>
                                                                                                                        :
                                                                                                                        <span
                                                                                                                            className="avatar-title rounded-circle font-size-16"
                                                                                                                            style={{
                                                                                                                                backgroundColor: "#0a0b0e9e",
                                                                                                                                color: "#ffffffa3!important",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            {" "}
                                                                                                                            {member.name.firstName[0] +
                                                                                                                                " " +
                                                                                                                                member.name.lastName[0]}{" "}
                                                                                                                        </span>
                                                                                                                    }

                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{member.name.firstName + " " + member.name.lastName}</a></h5>
                                                                                                                    <small>Rank{member.rank}</small>
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                        </>
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>

                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>


                                                                        <div className="tab-pane fade" id="v-pills-prizes" role="tabpanel" aria-labelledby="v-pills-prizes-tab">
                                                                            <div className=" mb-4">
                                                                                <h2 style={{ fontSize: "29px", fontWeight: "lighter", color: "rgb(134 135 135)", textTransform: "uppercase" }} className="regular dark weight-600 caps inline-block float-left mr-3">
                                                                                    Prizes
                                                                                </h2>
                                                                                <div className="section-line" style={{ overflow: "hidden", height: "24px", maxWidth: "100%" }}>
                                                                                    <span style={{ backgroundColor: "#e5e7e8", width: "100%", height: "4px", display: "inline-block" }}>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div>

                                                                                </div>


                                                                                <div className="dark large weight-400 medium-margin">Main Prizes</div>
                                                                                <div className="prize-container mt-3">
                                                                                    <div className="prize-image align-center inline-block v-align-top">
                                                                                        {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/c45fe55Code_Ed_MainPrizesIcon.jpg?Signature=yHL8b5dm02uaJPadIzLi6Z2frio%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}

                                                                                    </div>

                                                                                    {/* <div className="prize-desc medium-margin-left inline-block v-align-top">
                                                                                        <div className="large font-weight-bold dark">Winner </div>
                                                                                        <div className="regular dark">
                                                                                            <p>The winning team will be awarded a collective cash prize of <strong>100 USD</strong> and we'll also graciously throw in a <strong>Certificate of Achievement</strong> for each member.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="clear"></div> */}
                                                                                </div>
                                                                                <div className="prize-container mt-3">
                                                                                    <div className="prize-image align-center inline-block v-align-top">
                                                                                        {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/0d68d96Code_Ed_MainPrizesIcon.jpg?Signature=FqAX1gbvQ30o7SxIE43JyuOgvE4%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}

                                                                                    </div>
                                                                                    <div className="prize-desc medium-margin-left inline-block v-align-top">
                                                                                        <div className="large font-weight-bold dark">First runner-up </div>

                                                                                        <div className="regular dark">
                                                                                            <p>The winning team will be awarded a collective cash prize of <strong>75 USD</strong> and we'll also graciously throw in a <strong>Certificate of Achievement</strong> for each member.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                </div>
                                                                                <div className="prize-container mt-3">
                                                                                    <div className="prize-image align-center inline-block v-align-top">
                                                                                        {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/bafa1b4Code_Ed_MainPrizesIcon.jpg?Signature=tLfthjcXqSl1NhbZ1Tbl9TmBH34%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}

                                                                                    </div>

                                                                                    <div className="prize-desc medium-margin-left inline-block v-align-top">
                                                                                        <div className="large font-weight-bold dark">Second runner-up </div>

                                                                                        <div className="regular dark">
                                                                                            <p>The winning team will be awarded a collective cash prize of <strong>50 USD</strong> and we'll also graciously throw in a <strong>Certificate of Achievement</strong> for each member.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                </div>
                                                                                <div className="dark large weight-400 medium-margin">Special Prizes</div>
                                                                                <div className="prize-container mt-3">
                                                                                    <div className="prize-image align-center inline-block v-align-top">
                                                                                        {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/fdd5e13Code_Ed_SpecialPrizesIcon.jpg?Signature=YKVMhDgUt6BPNMOe7pn2d%2F9phIo%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}

                                                                                    </div>
                                                                                    <div className="prize-desc medium-margin-left inline-block v-align-top">
                                                                                        <div className="large font-weight-bold dark">Certificate of Appreciation </div>

                                                                                        <div className="regular dark">

                                                                                            <p>Each competing member of the top five ranking teams will, in fact, receive a bragworthy <strong>Certificate of Appreciation</strong> for commendable participation.</p>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="tab-pane fade" id="v-pills-leaderboard" role="tabpanel" aria-labelledby="v-pills-leaderboard-tab">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-centered table-nowrap table-hover">
                                                                                    <thead className="thead-light">
                                                                                        <tr>
                                                                                            <th scope="col" style={{ width: "70px" }}>#</th>
                                                                                            <th scope="col">Team</th>
                                                                                            <th scope="col">Points</th>
                                                                                            <th scope="col">Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                1
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                125
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                2
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                132
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                3
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                112
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                4
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                121
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                5
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                145
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                6
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                136
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                7
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                125
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                8
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">Colin Melton</a></h5>
                                                                                                <small className="text-muted mb-0">Combined points: 1234</small>
                                                                                            </td>
                                                                                            <td>
                                                                                                136
                                                                                            </td>
                                                                                            <td>
                                                                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Message"><i className="bx bx-message-square-dots"></i></a>
                                                                                                    </li>
                                                                                                    <li className="list-inline-item px-2">
                                                                                                        <a href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Profile"><i className="bx bx-user-circle"></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade" id="v-pills-my-team" role="tabpanel" aria-labelledby="v-pills-my-team-tab">
                                                                            <p className="mb-4">This section shows your teams information,
                                                                                all your teams submissions will be made here. if you do not
                                                                                have a team please join one in the teams section.
                                                                            </p>
                                                                            <h3>Team : {this.state.team.name}</h3>
                                                                            <br />
                                                                            <div className="table-responsive" style={{ width: "inherit" }}>
                                                                                <table className="table table-nowrap table-centered table-hover mb-0">
                                                                                    <tbody>
                                                                                        {this.state.team.members.map(member =>
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td style={{ width: "45px" }}>
                                                                                                        {member.propic ?
                                                                                                            <img
                                                                                                                className="rounded-circle avatar-sm"
                                                                                                                src={member.propic}
                                                                                                                alt=""
                                                                                                            ></img>
                                                                                                            :
                                                                                                            <span
                                                                                                                className="avatar-title rounded-circle font-size-16"
                                                                                                                style={{
                                                                                                                    backgroundColor: "#0a0b0e9e",
                                                                                                                    color: "#ffffffa3!important",
                                                                                                                }}
                                                                                                            >
                                                                                                                {" "}
                                                                                                                {member.name.firstName[0] +
                                                                                                                    " " +
                                                                                                                    member.name.lastName[0]}{" "}
                                                                                                            </span>
                                                                                                        }

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{member.name.firstName + " " + member.name.lastName}</a></h5>
                                                                                                        <small>Rank{member.rank}</small>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div>
                                                                                <h4 className="card-title mt-4 mb-4">Submissions</h4>

                                                                                <div className="table-responsive">
                                                                                    <table className="table table-nowrap table-centered table-hover mb-0">
                                                                                        <tbody>
                                                                                            {this.state.team.submissions.map(file =>
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td style={{ width: "45px" }}>
                                                                                                            <div className="avatar-sm">
                                                                                                                <span className=" rounded-circle text-secondary font-size-24">
                                                                                                                    <i className="bx bxs-file-blank"></i>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <h5 className="font-size-14 mb-1"><a href="#" className="text-dark">{file.filename}</a></h5>
                                                                                                            <small>{file.size}</small>
                                                                                                            <small style={{ marginLeft: "2rem" }}>Submission Time: {file.submissionTime}</small>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="text-center">
                                                                                                                <a href={file.url} className="text-dark"><i className="bx bx-download h3 m-0"></i></a>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>


                                                                            </div>


                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </section>


                                    </main>
                                </div>
                            </div>
                            {/* <!-- end row --> */}


                        </div> {/**<!-- container-fluid -->**/}
                    </div>
                    {/* <!-- End Page-content --> */}


                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    {new Date().getFullYear()} © Botswana Innovation Hub
                                </div>
                                <div className="col-sm-6">
                                    <div className="text-sm-right d-none d-sm-block">
                                        Skills Ranker
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            );

        } else {
            return (
                <AssesmentsSkeleton />
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        event: state.contestData.contest,
        eventTeams: state.teamsData.teams,
        leaderboard: state.contestData.leaderboard
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContest: (cid) => dispatch(fetchContest(cid)),
        getEventTeams: (eventId) => dispatch(fetchAllEventTeams(eventId)),
        getLeaderboard: (eventId) => dispatch(getContestleaderboard(eventId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContest2);






