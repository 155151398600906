import React, { Component } from "react";
import Countdown from "../../components/CountDown";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchAssesment } from "../../store/actions/assesmentsActions";
import { publishAssessment } from "../../api/assesments";
import Cookie from "js-cookie";
import AnswerBlade from "../../components/answerBlade";
import AssesmentResult from "../../components/AssesmentResult";
import AssesmentResultSkeleton from "../../components/skeletons/AssesmentResultSkeleton";
import ReactGA from 'react-ga';

class Assesment extends Component {

  state = {
    isVisible: false,
    isValid: false,
    beginAssessment: false,
    isFinished: false,
    answered: null,
    questionNumber: 0,
    resultsLoaded: false,
    targetAssessment: {},
    targetQuestions: [],
    currentSelectedAnswer: {},
    globalAnswerSheet: [],
    targetQ: {},
    grades: {},
    answersView: null,
  };



  /**
   * update state with selected answer
   * and toggle answered to true
   * 
   * @param  {dict} answer selected answer
   */
  selectAnswer(answer) {
    this.setState({
      currentSelectedAnswer: answer._id,
      answered: true,
    });
  }


  /**
   * update state with assessment grade results on completion of an assessment
   * 
   * @param  {dict} grade assessment results
   */
  grades(grade) {
    this.setState({
      grades: { ...grade.data },
    });
  }

  hasNext() {
    return this.state.questionNumber + 1 < this.state.targetQuestions.length
  }

  /**
   * Move to next available question in the assessment
   * if reached end of assessment submit assessment
   * 
   */
  nextQuestion() {
    if (this.hasNext()) {
      this.setState({
        questionNumber: this.state.questionNumber + 1,
        targetQ: {
          ...this.props.assessment.questions[this.state.questionNumber + 1],
        },
        answered: null,
      });
      window.$(".knob").knob();
    }
    else {

      //toggle is finished to true
      this.setState({
        isFinished: true,
      });

      //submit answersheet to server for grading
      publishAssessment({
        answers: this.state.globalAnswerSheet,
        assessment: this.state.targetAssessment._id,
        userId: JSON.parse(Cookie.get("user"))._id,
      })
        .then((grade) => this.grades(grade))
        .then(() => this.setState({ resultsLoaded: true }))
        .then(() => ReactGA.event({ category: 'Event fire', action: 'Completed ' + this.state.targetAssessment.title + ' assessment.' })); // signal google analytics of successfull completion of assessment
    }
  }


  /**
   *  force termination of assessment
   */
  forceFinish() {

    this.setState({
      isFinished: true,
    });
    //submit answersheet to server for grading
    publishAssessment({
      answers: this.state.globalAnswerSheet,
      assessment: this.state.targetAssessment._id,
      userId: JSON.parse(Cookie.get("user"))._id,
    })
      .then((grade) => this.grades(grade))
      .then(() => this.setState({ resultsLoaded: true }))
      .then(() => ReactGA.event({ category: 'Event fire', action: 'assessment timedout ' + this.state.targetAssessment.title + ' assessment.' })); // signal google analytics of unsuccessfull completion of assessment
  }



  /**
   * collect answers from each question
   * move to next question
   */
  collectResponse() {
    if (this.state.questionNumber < this.state.targetQuestions.length) {
      // const answers = this.state.globalAnswerSheet.concat({question:this.state.targetQ._id, answer:this.state.currentSelectedAnswer});
      // this.setState({
      //     globalAnswerSheet:answers
      // })
      this.updateAnswerSheet();
    }
    setTimeout(() => this.nextQuestion(), 1000);
  }


  /**
   * update state answersheet with answered questions and answers 
   */
  updateAnswerSheet() {
    const answers = this.state.globalAnswerSheet.concat({
      question: this.state.targetQ._id,
      answer: this.state.currentSelectedAnswer,
    });
    this.setState({
      globalAnswerSheet: answers,
    });
  }


  /**
   * populate answers to be rendered on screen
   * 
   * @param  {dict} targetQ target question to pull answeres from
   */
  populateAnswers(targetQ) {
    if (typeof targetQ.answers !== "undefined") {
      if (this.state.isFinished) {
        //do nothing
      } else {
        const answers = targetQ.answers.map((item) => (
          <AnswerBlade
            key={item._id}
            answer={item}
            answered={this.state.answered === item._id}
            click={() => this.selectAnswer(item)}
          />
        ));

        // this.setState({ answersView: answers });

        return answers;
      }
    }
  }


  /**
   * update state with assessment questions
   */
  populateQuestionState() {
    if (this.state.isValid) {
      this.setState({
        targetAssessment: this.props.assessment,
        targetQuestions: [...this.props.assessment.questions],
        targetQ: { ...this.props.assessment.questions[0] },
      });
    }
  }


  /**
   * validate incoming assessment data
   */
  validate() {
    if (
      typeof this.props.assessment.questions !== "undefined" &&
      this.props.assessment.questions.length > 0
    ) {
      this.setState({
        isValid: true,
      });
    }
  }


  componentDidUpdate() {
    window.$(".knob").knob();
  }

  componentWillUpdate() {
    window.$(".knob").knob();
  }
  componentWillReceiveProps() {
    window.$(".knob").knob();
  }
  componentDidMount() {
    const { history } = this.props;
    let aid = history.location.pathname.split("/")[3];

    this.props
      .getAssesment(aid)
      .then(() => this.validate())
      .then(() => this.populateQuestionState());

  }

  render() {
    if (this.state.beginAssessment) {
      if (this.state.isFinished) {
        return (
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row justify-content-md-center justify-content-sm-center">
                  <div className="col-lg-10">
                    <div className="card">
                      <div className="card-body">
                        {!this.state.isFinished ? (
                          <div className="media mb-4">
                            <img
                              src="/assets/images/companies/img-1.png"
                              alt=" "
                              className="avatar-sm mr-4"
                            />

                            <div className="media-body overflow-hidden">
                              <h5
                                className="text-truncate font-size-18"
                                style={{
                                  fontFamily: "monospace",
                                  fontSize: "14px",
                                }}
                              >
                                {this.state.targetAssessment.title}
                              </h5>
                              <p
                                className="text-muted"
                                style={{
                                  fontFamily: "monospace",
                                  fontSize: "14px",
                                }}
                              >
                                {this.state.targetAssessment.description}{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.resultsLoaded ? (
                          <AssesmentResult
                            loaded={this.state.resultsLoaded}
                            score={this.state.grades}
                          />
                        ) : (
                          <AssesmentResultSkeleton />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <!-- end col --> */}
                </div>
                {/* <!-- end row --> */}
              </div>{" "}
              {/*<!-- container-fluid -->*/}
            </div>
            {/* <!-- End Page-content --> */}

            <footer className="footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                  <div className="col-sm-6">
                    <div className="text-sm-right d-none d-sm-block">
                      Skills Ranker
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        );
      } else {
        return (
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="card">
                      <div className="card-body mt-4 mb-4">
                        <div className="media mb-4">
                          {/* {typeof this.state.targetAssessment.focus !==
                          "undefined" ? (
                            <img
                              src={getLanguageIcon(
                                this.state.targetAssessment.focus
                              )}
                              alt=""
                              className="avatar-sm mr-4 d-none"
                            />
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className="row">
                          <div className="col-md-1"></div>
                          <div className="col-lg-7  mb-5 pb-5">
                            {/* Assesment question */}
                            <div className="media-body overflow-hidden">
                              <div className="">
                                <div className="progress mb-3" style={{ height: "20px" }}>
                                  <div className="progress-bar bg-success" role="progressbar" style={{ width: (this.state.questionNumber + 1) / this.state.targetQuestions.length * 100 + "%" }} aria-valuenow={(this.state.questionNumber + 1) / this.state.targetQuestions.length * 100} aria-valuemin="0" aria-valuemax={this.state.targetQuestions.length}>{this.state.questionNumber + 1}</div>
                                </div>
                              </div>
                              <h5
                                className="text-truncate mb-3"
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "24px",
                                }}
                              >
                                {this.state.targetAssessment.title}
                              </h5>
                              {/* <p className="text-muted">
                                {this.state.targetAssessment.description}{" "}
                              </p> */}
                            </div>
                            <h5
                              className="mb-5"
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            >
                              {this.state.targetQ.text}
                            </h5>
                            {/* Assesment answers */}

                            {this.populateAnswers(this.state.targetQ)}

                            <div className="mt-5">


                              <div style={{ float: "right" }}>
                                {this.state.answered !== null ? (
                                  <button
                                    type="button"
                                    onClick={() => this.collectResponse()}
                                    className="btn btn-info w-lg waves-effect waves-light"
                                  >
                                    {this.hasNext() ? "Next" : "Finish"}
                                  </button>
                                ) : (
                                  <button
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    type="button"
                                    disabled
                                    onClick={() => this.collectResponse()}
                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                  >
                                    {this.hasNext() ? "Next" : "Finish"}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 mx-auto">
                            {/* question timer */}
                            {typeof this.state.targetAssessment.duration !==
                              "undefined" ? (
                              <Countdown
                                timeOut={() => this.forceFinish()}
                                duration={this.state.targetAssessment.duration}
                              // timeFormat="mm:ss"
                              />
                            ) : (
                              ""
                            )}
                            {/* <div className="text-center" dir="ltr"> 
                              <h5
                                  className="text-truncate mb-3"
                                  style={{
                                    color: "#000",
                                    fontFamily: "monospace",
                                    fontWeight: "bolder",
                                    fontSize: "24px",
                                    letterSpacing:"2px"
                                  }}
                                >
                                PROGRESS
                                </h5>
                              <input className="knob" 
                                    data-width="110" 
                                    data-height="110" 
                                    data-linecap={"round"}
                                    data-fgcolor="black" 
                                    data-min={0}
                                    data-max={this.state.targetQuestions.length}
                                    value={this.state.questionNumber+1}
                                    data-skin="tron" 
                                    data-angleoffset="0"
                                    data-readonly={true} 
                                    data-thickness=".1"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end col --> */}
                </div>
                {/* <!-- end row --> */}
              </div>{" "}
              {/*<!-- container-fluid -->*/}
            </div>
            {/* <!-- End Page-content --> */}

            <footer className="footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                  <div className="col-sm-6">
                    <div className="text-sm-right d-none d-sm-block">
                      Skills Ranker
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        );
      }
    } else {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 mx-auto">
                  <div className="card">
                    <div className="card-body">
                      <div className="media mb-3">
                        {/* <img
                          src="/assets/images/companies/img-1.png"
                          alt=""
                          className="avatar-sm mr-4"
                        /> */}

                        <div className="media-body overflow-hidden p-5">
                          <h3

                            className="text-truncate font-weight-bolder"
                          >
                            {this.state.targetAssessment.title}
                          </h3>
                          <p
                            className="text-muted"

                          >
                            {this.state.targetAssessment.description}{" "}
                          </p>
                          <hr />
                          <h4
                            className="font-weight-bolder"
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Rules and Info
                          </h4>
                          <code className="font-size-14 text-dark bg-light">
                            <li>
                              This is a multiple choice assessment designed to
                              test your skills
                            </li>
                            <li>
                              Some questions may have multiple correct answers.
                            </li>
                            <li>All invalid submission will be flaged wrong</li>
                            <li>
                              The assesment is timed. All unanswered questions
                              will be flaged wrong should you be timed out
                              before completing the assessment
                            </li>
                            <li>
                              Once a question is answered, there is no option to
                              make a correction
                            </li>
                          </code>
                          {/* <h3 className="mt-4 " style={{ textAlign: "center" }}>
                            GOOD LUCK, & HAVE FUN !!!!
                          </h3> */}
                        </div>
                      </div>

                      <div className="row mb-3 mr-3">
                        <div className="col-lg-12">
                          <div className="mt-5" style={{ float: "right" }}>
                            <NavLink
                              type="button"
                              to="/profile/assessments"
                              className="btn btn-secondary w-lg waves-effect waves-light"
                            >
                              Back to Assessments
                            </NavLink>
                            <button
                              type="button"
                              onClick={() => (ReactGA.event({ category: 'Button', action: 'started ' + this.state.targetAssessment.title + ' assessment.' }),
                                this.setState({ beginAssessment: true }))
                              }
                              className="ml-4 btn btn-info w-lg waves-effect waves-light"
                            >
                              Begin
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end col --> */}
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/*<!-- container-fluid -->*/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    assessment: state.assesmentsData.targetAssessment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssesment: (aid) => dispatch(fetchAssesment(aid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assesment);
