import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import { Overlay } from "react-bootstrap";
import JotformEmbed from 'react-jotform-embed';




class LandingPage extends Component {
  

  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />

   
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      {/* <a href="#">
                        <img
                          src="/assets/landing-page/images/bih_logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a> */}
                      <a href="/#">
                        <img
                          src="/assets/landing-page/images/logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div
                      className="responsive"
                      onClick={() => this.toggleMenu()}
                    >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                      
                      
                      
                    <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub ml-sm-4">
                            <a href="/#">Home </a>
                          </li>
                          {/* <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="#about">About</a>
                          </li> */}
                          <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="/#how-it-works">Developer</a>
                          </li>
                          <li className=" ml-sm-4">
                            <a href="/#how-it-works-business">Business</a>
                          </li>
                         
                          <li className=" ml-sm-4">
                            <a href="/events">Hackathons</a>
                          </li>
                            {/* <li className=" ml-sm-4">
                            <a href="/awsRegistration"></a>
                           </li> */}


                          {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                          <ul className="dropdown">
                            <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                          </ul>
                          </li> */}

                          <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                           <ul className="dropdown">
                            <li className="ml-sm-4 has-sub"><a href="#">AWS-re/Start</a>
                              <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                              <li className="ml-sm-4"><a href="/awsRequirements">Requirements</a></li>
                              <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                              </ul>
                            </li>
                           </ul>
                        </li>

                          <li className=" ml-sm-4">
                            <a href="#footer">Contacts</a>
                          </li>

                          </ul>                        
                      </nav>




                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />{" "}
                        {this.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* backgroundColor: "rgb(49,49,50)"  */}
        <main>


          <section
            id="parallax"
            className=""
            style={{
              height:"75%",
              background: "url(/assets/landing-page/images/25332.jpg)",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover"}}
          >
            <div className="slider-shape ss-one layer" data-depth="0.10">
              {/*  <img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
              {/*<img
                src="/assets/landing-page/images/shape/slider_shape02.png"
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
              {/*<img
                src="/assets/landing-page/images/shape/html.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
              {/*<img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-eight layer" data-depth="">
              {/*<img
                src="/assets/landing-page/images/man_header-1.png"
                alt="shape"
              />*/}





                  {/* <div className="header-btn second-header-btn" style={{marginTop:"100px", float:"right", right:"0" }}>
                      <a href="/awsRegistration" className="btn"><i className="fa fa-user-edit"/> Register Now</a>
                  </div>


 */}



            </div>
            <div className="slider-active"
            >
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 style={{marginTop:"100px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          <span>AWS re/Start</span>
                        </h2>

                        <h2 style={{marginTop:"-20px", marginBottom:"100px", fontSize:"45px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          requirements
                        </h2>
                        {/*<br />
                        <br />
                        <a
                          id="loginButton"
                          href={this.user ? "/profile" : "/login"}
                          className="btn mb-4"
                        >
                          <i className="fa fa-user-alt" />{" "}
                          {this.user ? "Profile" : "Login"}
                        </a>*/}
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          

         
<section>
 {/* <div id="iframe-container">
 <JotformEmbed src="https://form.jotform.com/230461666201044"/>
 </div> */}

<img src="/assets/landing-page/images/AWS_fFlyer.jpg"
width={"600"}
height={"973"} style={{marginTop:"10px", marginBottom:"10px", position:"relative", display:"block", width:"50%", left:"25%"}}>

</img>

{/* <embed src="/assets/landing-page/images/AWS_fFlyer.pdf" 
width={"550"} 
height={"790"} 
type={"application/pdf"}
style={{position:"relative", marginTop:"10px", marginBottom:"10px", display:"block", width:"50%", left:"25%"}}>

</embed> */}

</section>

        </main>

        <Footer />
        

        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
