const initState = {
    contests: [],
    contest: {},
    judgeEntries: [],
    live: [],
    upcoming: [],
    past: [],
    leaderboard: [],
    contestRequest: {},
    contestRequests: [],
    participants: [],
    isReg: false,
    uploadProgress: { percent: 0, total: 0, uploaded: 0 },
};

const splitEvents = (events) => {
    var date = new Date()
    var live = events.filter(event =>
        date > new Date(event.startDate) && date < new Date(event.endDate)
    )

    var past = events.filter(event =>
        date > new Date(event.endDate)
    )

    var upcoming = events.filter(event =>
        date < new Date(event.startDate)
    )

    return { live: live, past: past, upcoming: upcoming }
}

const contestReducer = (state = initState, action) => {
    switch (action.type) {
        case "PUBLISH_CONTEST":
            return {
                ...state,
                contest: { ...action.event }
            };

        case "GET_ALL_CONTESTS":
            return {
                ...state,
                ...splitEvents(action.events), contests: action.events
            };

        case "UPDATE_CONTEST":
            return {
                ...state,
                contest: { ...action.event }
            };

        case "GET_CONTEST_PARTICIPANTS":
            return {
                ...state,
                participants: action.participants.participants
            };

        case "GET_CONTEST":
            return {
                ...state,
                contest: { ...action.event }
            };

        case "PULL_CONTEST_LEADERBOARD":
            return {
                ...state,
                leaderboard: action.leaderboard
            };

        case "IS_REGISTERED":
            return {
                ...state,
                isReg: action.event.isEnrolled
            };

        case "REMOVE_CONTEST":
            return {
                ...state,
                contest: {}
            };

        case "PUBLISH_CONTEST_REQUEST":
            return {
                ...state,
                contestRequest: { ...action.contestRequest }
            };

        case "PULL_CONTEST_REQUESTS":
            return {
                ...state,
                contestRequests: action.contestRequests
            };

        case "PULL_CONTEST_REQUEST":
            return {
                ...state,
                contest_request: { ...action.contest_request }
            };

        case "UPDATE_CONTEST_REQUEST":
            return {
                ...state,
                contest_request: { ...action.contest_request }
            };
        case "ADD_CONTEST_ROUND":
            let contestRounds = state.contest.rounds
            contestRounds.push(action.round)
            return {
                ...state,
                contest: { ...state.contest, rounds: contestRounds }
            };
        case "UPDATE_CONTEST_ROUND":
            let roundsList = state.contest.rounds.map(CR => {
                if (CR._id == action.round._id) {
                    CR = action.round
                }
                return CR
            })

            return {
                ...state,
                contest: { ...state.contest, rounds: roundsList }
            };
        case "REMOVE_CONTEST_ROUND":
            let roundsList2 = state.contest.rounds.filter(CR => CR._id != action.round._id)
            return {
                ...state,
                contest: { ...state.contest, rounds: roundsList2 }
            };
        case "UPLOAD_PROGRESS_EVENT":
            return {
                ...state,
                uploadProgress: { ...action.data }
            };
        case "EVENT_ENTRIES":
            return {
                ...state,
                judgeEntries: action.entries
            };
        default:
            break;
    }
    return state;
};

export default contestReducer;