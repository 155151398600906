import React, { Component } from "react";
import { getUserRank } from "../store/actions/rankActions";
import { connect } from "react-redux";

class RankBadge2 extends Component {
  componentDidMount() {
    this.props.getRank(this.props.userId)
  }

  render() {
    return (
      <div className="row">
        <span
          style={{
            width: "256px",
            textAlign: "center",
          }}
        >
          <p className="pb-0"> Top </p>
          <h3 className="font-weight-bold" style={{color:"#ffffff9c"}}>
            {this.props.rankData ? this.props.rankData.nationalRank : " - "}
          </h3>
          <p className=" mb-0 font-weight-bolder">
            <span style={{ fontSize: "25px", fontWeight:"300" }}>
              {this.props.points}
            </span>
            &nbsp; XP
          </p>
        </span>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rankData: state.rankData.userRank,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRank: (userId) => dispatch(getUserRank(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RankBadge2);
// export default RankBadge;
