import React, { Component } from "react";
import { connect } from "react-redux";
// import {
//   fetchAllAssesments,
//   fetchCompletedAssessments,
// } from "../../store/actions/assesmentsActions";
// import BadgeHorizontal from "../../components/BadgeHorizontal";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
// import Cookies from "js-cookie";
// import { getLanguageIcon, timeZoneHelper } from "../../utils/utils";
// import { getLanguages } from "../../api/languages";
// import { getTechnologies } from "../../api/technologies";
import globe from "../../../src/globe.svg";
import { findJobPostById, sendJobApplication, hasApplied } from "../../store/actions/businessProfileActions";
import moment from "moment";
import Cookie from "js-cookie";

class JobDetails extends Component {
  state = {
    isLoaded: false,
    isAvailable: true,
    job: {},
    applicationFile: "",
    motivation: "",
    user: "",
    hasApplied: false,
  };

  componentDidMount() {
    if (this.props.location.pathname.split("/").length > 0) {
      let jobId = this.props.location.pathname.split("/")[3]
      let user = JSON.parse(Cookie.get("user"))
      this.props.getJobPost(jobId)
        .then(() => {
          this.setState({ job: this.props.post });
        })
        .catch(err => {
          this.setState({ isAvailable: false })
        })
        .finally(() => {
          this.setState({ isLoaded: true, user: user })
        })

      this.props.checkApplication(jobId, user._id)
        .then((res) => {
          this.setState({ hasApplied: res.hasApplied })
        })
        .catch(() => {

        })
    }


  }


  pushApplication = (e) => {
    e.preventDefault()
    // push application
    //get user
    let application =
    {
      applicant: this.state.user._id,
      jobPost: this.state.job._id,
      motivation: this.state.motivation,
      file: this.state.applicationFile
    }
    this.props.sendApplication(application)
      .then(() => {

      })
      .catch(err => {

      })
  }

  uploadFile(event, ctx) {
    let file = event.target.files[0];

    ctx.getBase64(file, (result) => {
      ctx.setState({
        applicationFile: {
          file: result,
          fileName: file.name,
        },
      });
    });
  }

  navToApplication = () => {
    this.props.history.push(`/profile/myjobs/${this.state.job._id}`)
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div style={{ position: "fixed", width: "100%", height: "100%" }}>
            <div
              style={{
                backgroundImage: "url(" + globe + ")",
                transform: "translate(-50%, -50%) scale(1)",
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transition: "transform .15s ease-out 0s",
                transform: "translate(-50%,-50%)",
                transformOrigin: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: " cover",
                willChange: "transform",
              }}
            ></div>
          </div>
          {this.state.isAvailable ?
            <div className="page-content pt-0">
              <div className="">
                {/* <!-- start page title --> */}
                <link rel="stylesheet" href="/assets/css/jobs.css" />
                {/* header */}
                <div className="row mt-2 pt-5 pb-5" style={{ background: "#795548", color: "white" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="p-1" style={{ width: "200px", height: "150px", backgroundColor: "white", borderRadius: "3%" }}>
                          <img
                            src="/assets/landing-page/images/bih_logo_colored.png"
                            alt="logo"
                            style={{ width: "100%", opacity: "100%", transform: "translateY(15%)" }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <p className="job-type mb-0 mt-3 jobs-h4-regular">{this.state.job.jobType}</p>
                        <h4 className="mt-1 jobs-h4-semiBold" style={{ color: "white" }}>{this.state.job.title}</h4>
                        <p className="mb-1 jobs-h4-regular">Posted {moment(this.state.job.createdAt).format("d MMMM YYYY")} by <span className="job-highlight " style={{ fontSize: "17px" }}>{this.state.job.businessProfileId.businessName}</span></p>
                        <p className="jobs-h4-regular">Deadline {moment(this.state.job.dueDate).format("d MMMM YYYY")}</p>
                      </div>
                      <div className="col-sm-10 col-xs-10 col-xl-3 col-lg-3 col-md-3 ">
                        {
                          this.state.hasApplied ?
                            <button className="btn apply-btn mt-4" onClick={() => this.navToApplication()}>My Application <i className="bx bx-right-arrow-alt ml-2 font-size-16 align-middle mr-2"></i></button>
                            :
                            <button className="btn apply-btn mt-4" data-toggle="modal" data-target="#apply-modal">Apply Now <i className="bx bx-right-arrow-alt ml-2 font-size-16 align-middle mr-2"></i></button>
                        }

                        <div className="mt-3 btn p-3 pl-4 pr-4">
                          <a
                            style={{ color: "#9e9e9e", fontSize: "medium" }}
                            href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fskillsranker.bih.co.bw/profile/${this.state.job._id}&text=Job%20oppotunity%20on%20Skills%20Ranker%20%23skills_ranker%20%F0%9D%99%A8%F0%9D%99%A0%F0%9D%99%9E%F0%9D%99%A1%F0%9D%99%A1%F0%9D%99%A8%F0%9D%99%A7%F0%9D%99%96%F0%9D%99%A3%F0%9D%99%A0%F0%9D%99%9A%F0%9D%99%A7%20%F0%9F%A4%93%20%F0%9F%92%BB`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-twitter"></i>
                          </a>
                          <a
                            style={{ color: "#9e9e9e", fontSize: "medium" }}
                            className="ml-4"
                            href={`https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fskillsranker.bih.co.bw/event/`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-facebook"></i>
                          </a>
                          <a
                            style={{ color: "#9e9e9e", fontSize: "medium" }}
                            className="ml-4"
                            href={`http://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fskillsranker.bih.co.bw/event/p&title=Check%20out%20this%20event%20on%20Skills%20Ranker%23skills_ranker`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* body */}
                <div className="row">
                  <div className="container pt-5">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="mb-5">Job Description</h2>
                        <p style={{ fontSize: "10.5pt", lineHeight: "2em", textAlign: "justify" }}>
                          {this.state.job.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/**<!-- container-fluid -->**/}
              <div className="modal fade bs-example-modal-center" id="apply-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" style={{ backgroundColor: "#0000007d" }}>
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "50vw" }}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Your application</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form onSubmit={(e) => this.pushApplication(e)}>
                      <div className="modal-body">
                        <p>We're glad your interested in this job post.</p>
                        <p>Please understand that in responding to this post the business or company behind this post will have access to your skillsranker profile and personal information
                          on this platform for review and communications purposes this encludes your registered skills(languages, technologies), github profile and public projects, names, email, gender and mobile number</p>
                        <div>
                          <p>Upload zipped files, CV cover letter, etc (Optional)</p>
                          <input className="mb-3"
                            type="file"
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            id="customFile"
                            onChange={(event) =>
                              this.uploadFile(
                                event,
                                this
                              )
                            }
                          />
                          <p>Motivational pharagraph</p>
                          <textarea required id="textarea" onChange={(e) => this.setState({ motivation: e.target.value })} className="form-control" maxlength="500" rows="3" placeholder="Limit of 500 characters."></textarea>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="submit" className="btn apply-btn" style={{ padding: "0.5em 2em" }}>Apply Now</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            :
            <AssesmentsSkeleton />
          }

          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    post: state.jobPostData.post
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    getJobPost: (id) => dispatch(findJobPostById(id)),
    sendApplication: (body) => dispatch(sendJobApplication(body)),
    checkApplication: (job, user) => dispatch(hasApplied(job, user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
