import {
    getRank,
    getAllRank
} from "../../api/rank"

/**
 * Get rank data
 * @param signUpDetails details
 */
export const getUserRank = (userId) => {
    return dispatch => {
        return getRank(userId)
            .then(response => {
                const rankData = response.data;
                dispatch({
                    type: "GET_RANK",
                    rankData
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};

export const fecthAllRankData = () => {
    return dispatch => {
        return getAllRank()
            .then(response => {
                const rankData = response.data;
                dispatch({
                    type: "GET_ALL_RANK",
                    rankData
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};