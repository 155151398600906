const initState = {
  appUsersList: [],
  adminUsersList: [],
  userProfile: null,
  platformSummary: {},
  userDemographics: {}
};

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case "PULL_APP_USERS":
      return {
        ...state,
        appUsersList: action.data.filter((user) => user.owner !== null)
      };

    case "GET_USER_PROFILE":
      return {
        ...state,
        userProfile: action.data
      };

    case "UPDATE_PROFILE":
      return {
        ...state,
        userProfile: action.data
      };

    case "PULL_ADMIN_USERS":
      return {
        ...state,
        adminUsersList: action.data
      };
    case "PULL_PLATFORM_SUMMARY":
      return {
        ...state,
        platformSummary: action.data
      };
    case "PULL_APP_USERS_DEMOGRAPHICS":
      return {
        ...state,
        userDemographics: action.data
      };
    case "TEST_USERS":
      return {
        ...state,
        appUsersList: [action.data]
      };

    default:
      break;
  }
  return state;
};

export default usersReducer;