import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";


var biz_number = 0,
dev_numbers = 0,
hack_numbers = 0,
skill_numbers = 0;


class LandingPage extends Component {
  state = {
    skills: [],
    users: 0,
    upcomingEvent: {},
    hasUpcoming: false,
    past: []
  };

  

  data = [];
  componentDidMount() {
    this.props
      .getPlatformSummary()
      .then(() => {
        this.setState({
          skills: this.props.platformSummary.skills,
          users: this.props.platformSummary.population, 
        });


        biz_number = 256;
        //biz_number = 197;
        //this.state.skills[1].value;
        hack_numbers = 37;
        dev_numbers = this.state.users;
        skill_numbers = this.state.skills[2].value;

        
      })
      .catch((err) => this.props.dispatchNotification(err));

    this.props
      .getContests()
      .then(() => {
        this.setState({ past: this.props.past, isLoaded: true });
        if (this.props.live.length) {
          this.setState({
            upcomingEvent: this.props.live[0],
            hasUpcoming: true,
          });

          

        } else if (this.props.upcoming.length) {
          this.setState({
            upcomingEvent: this.props.upcoming[0],
            hasUpcoming: true,
          });
        }
        // else if(this.props.past.length){
        //   this.setState({ upcomingEvent: this.props.past[0], hasUpcoming:true })
        // }
      })
      .catch((err) => this.props.dispatchNotification(err));
    //this.props.getbusinesslist().then(() => this.setState({ isLoaded: true, bizlist:this.props.businesses }));

    
  }


  
  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />

        {/* <Helmet>
                  <meta name="description" content=""/>
                  <meta name="viewport" content="width=device-width, initial-scale=1"/>
                  <link rel="shortcut icon" type="image/x-icon" href="img/favicon.ico"/>
                  <link href="https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap"
                        rel="stylesheet"/>
                  <link
                      href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"
                      rel="stylesheet"/>
                  <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/magnific-popup.css"/>
                  <link rel="stylesheet" href="assets/fontawesome/css/all.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/slick.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/default.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/style.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/responsive.css"/>
  
                  <script src="/assets/landing-page/js/vendor/modernizr-3.5.0.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/vendor/jquery-1.12.4.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/popper.min.js"></script>
                   <script src="/assets/landing-page//assets/landing-page/js/bootstrap.min.js"></script>
                  <script src="/assets/landing-page/js/one-page-nav-min.js"></script>
                  <script src="/assets/landing-page/js/slick.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/ajax-form.js"></script>
                  <script src="/assets/landing-page/js/paroller.js"></script>
                  <script src="/assets/landing-page/js/wow.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/parallax.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.waypoints.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.countdown.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.counterup.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.scrollUp.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.magnific-popup.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/element-in-view.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/isotope.pkgd.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/imagesloaded.pkgd.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/main.js" type="text/javascript"></script>
  
              </Helmet>*/}
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      {/* <a href="#">
                        <img
                          src="/assets/landing-page/images/bih_logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a> */}
                      <a href="#">
                        <img
                          src="/assets/landing-page/images/logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div
                      className="responsive"
                      onClick={() => this.toggleMenu()}
                    >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                      
                      
                      
                      <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub ml-sm-4">
                            <a href="/#">Home </a>
                          </li>
                          {/* <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="#about">About</a>
                          </li> */}
                          <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="/#how-it-works">Developer</a>
                          </li>
                          <li className=" ml-sm-4">
                            <a href="/#how-it-works-business">Business</a>
                          </li>
                         
                          <li className="ml-sm-4 has-sub">
                            <a href="/events">Hackathons</a>
                            {/* <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/plannedHackathons">Planned Hackathons</a></li>
                            </ul> */}
                          </li>
                            {/* <li className=" ml-sm-4">
                            <a href="/awsRegistration"></a>
                           </li> */}


                          {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                          <ul className="dropdown">
                            <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                          </ul>
                          </li> */}

                          <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                           <ul className="dropdown">
                            <li className="ml-sm-4 has-sub"><a href="#">AWS-re/Start</a>
                              <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                              <li className="ml-sm-4"><a href="/awsRequirements">Requirements</a></li>
                              <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                              </ul>
                            </li>
                           </ul>
                        </li>

                          <li className=" ml-sm-4">
                            <a href="#footer">Contacts</a>
                          </li>

                          </ul>                        
                      </nav>




                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />{" "}
                        {this.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* backgroundColor: "rgb(49,49,50)"  */}
        <main>
          <section
            id="parallax"
            className="parallax slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix"
            style={{
              background: "url(/assets/landing-page/images/header-overla.jpg)", height:"10%"
            }}
          >
            <div className="slider-shape ss-one layer" data-depth="0.10">
              {/*  <img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
              {/*<img
                src="/assets/landing-page/images/shape/slider_shape02.png"
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
              {/*<img
                src="/assets/landing-page/images/shape/html.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
              {/*<img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
              {/*<img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />*/}
            </div>
            <div className="slider-shape ss-eight layer" data-depth="0.50">
              {/*<img
                src="/assets/landing-page/images/man_header-1.png"
                alt="shape"
              />*/}
            </div>
            <div className="slider-active">
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 data-animation="fadeInUp animated" data-delay=".4s">
                          <span>Let the world</span> know you
                        </h2>
                        {/*<br />
                        <br />
                        <a
                          id="loginButton"
                          href={this.user ? "/profile" : "/login"}
                          className="btn mb-4"
                        >
                          <i className="fa fa-user-alt" />{" "}
                          {this.user ? "Profile" : "Login"}
                        </a>*/}
                        <br />
                        <a className="btn" href={"/register"}>
                          {" "}
                          + Join Skills Ranker
                        </a>
                        <br />
                        <br />
                        {/* <a id="glowingButton" type="button" className="btn" href="/plannedHackathons">Planned Hackathons</a> */}
                        
                        <ul
                          data-animation="fadeInUp animated"
                          data-delay="1s"
                          style={{ marginLeft: "-35px" }}
                        >
                          <li>Show off your work and skills to the industry</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{ alignItems: "center", minHeight: "80vh" }}
            id="how-it-works"
            className="about-area about-p p-relative d-flex"
          >
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      ></div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "rgb(140,142,94)" }}
                      >
                        How Skills Ranker Works
                      </span>
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Create your developer profile
                      </h2>
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                      >
                        See how you rank locally
                      </h5>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      <p>
                        Using our AI powered ranking algorithm, see how you rank
                        locally as a developer in Botswana. Get recommendations
                        on new skills to learn to improve your ranking
                      </p>
                      <NavLink to="/register" className="btn mt-20">
                        + &nbsp; Join Skills Ranker
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-lg-4">
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/note.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>1</span>
                    </div>

                    <h4>Register</h4>
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/man.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>2</span>
                    </div>
                    <h4>Build Your Profile</h4>
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/exam.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>3</span>
                    </div>
                    <h4>Take Tests</h4>
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/document.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>4</span>
                    </div>
                    <h4>Get Ranked</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {this.state.hasUpcoming ? (
            <main style={{ width: "100vw" }}>
              {typeof this.state.upcomingEvent.eventBanner !== "undefined" ? (
                //   <div
                //   className="modal show fade"
                //   data-backdrop="static" data-keyboard="false"
                //   id="startNewDiscussionModal"
                //   tabIndex="-1"
                //   aria-labelledby="startNewDiscussionLabel"
                //   aria-hidden="true"
                // >
                //   <div className="modal-dialog modal-xl modal-dialog-centered">
                //     <div className="modal-content">

                //       <div className="modal-body"
                //       style={{
                //         background:
                //           "url(" +
                //           this.state.upcomingEvent.eventBanner.data +
                //           ")",
                //         backgroundSize: "contain",
                //         backgroundRepeat: "no-repeat",
                //         backgroundPosition: "center center",
                //       }}
                //       >
                //         <button
                //           type="button"
                //           className="close"
                //           data-dismiss="modal"
                //           aria-label="Close"
                //         >
                //           <span aria-hidden="true">
                //             &times;
                //           </span>
                //         </button>

                //       </div>
                //     </div>
                //   </div>
                // </div>
                <div
                  className="modal fade show"
                  id="advertpopup"
                  tabIndex="-1"
                  aria-labelledby="advertpopup"
                  aria-modal="true"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="close"
                        aria-label="Close"
                        style={{
                          position: "absolute",
                          float: "right",
                          cursor: "pointer",
                          right: "10px",
                          fontSize: "2rem",
                          top: "4px",
                          zIndex: "2",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.$("#advertpopup").remove();
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          background:
                            "url(" +
                            this.state.upcomingEvent.eventBanner.data +
                            ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          height: "25vh",
                        }}
                      ></div>
                      <div className="modal-footer">
                        <div>
                          <p
                            className="m-0  text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <font className="">Event Start: </font>
                            <font className="text-dark">
                              {formatDate(this.state.upcomingEvent.startDate)}
                            </font>
                          </p>
                          <p
                            className="m-0  text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <font className="">Event End: </font>
                            <font className="text-dark">
                              {formatDate(this.state.upcomingEvent.endDate)}
                              {console.log("start date: " + this.state.upcomingEvent.startDate)}
                              {console.log("end Date: " + this.state.upcomingEvent.endDate)}
                            </font>
                          </p>
                        </div>
                        <span
                          type="button"
                          onClick={() =>
                            (window.location.href =
                              "/event/" + this.state.upcomingEvent._id)
                          }
                          className="btn-close btn ml-auto"
                          data-bs-dismiss="modal"
                          style={{ padding: "0.7em 1em" }}>


                          {/*Register for event.*/}
                          {formatDate(Date.now()) >= formatDate(this.state.upcomingEvent.registrationEndDate) || formatDate(Date.now()) <= formatDate(this.state.upcomingEvent.startDate) ? 
                          "Go to Event":"Register for Event"}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </main>
          ) : (
            <></>
            
          )}

          {/*style={{ backgroundColor: "rgb(49,49,50)", minHeight: "100vh" }} */}
          <section
            style={{ alignItems: "normal", backgroundColor: "rgb(49,49,50)", paddingTop:"50px"}}
            id="how-it-works-business"
            className="about-area about-p p-relative d-flex"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    {this.state.past.length > 0 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img
                              src="/assets/landing-page/images/HackathonsConducted.png"
                              style={{ width: "40px" }}
                            />
                             




                             
                            {this.state.past.length}
                           
                            




                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Hackathons Conducted</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img
                              src="/assets/landing-page/images/HackathonsConducted.png"
                              style={{ width: "40px" }}
                            />
                           
                           
                           
                           <div style={{display:"inline"}} id="dev_hack">0</div>





                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Hackathons Conducted</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="single-counter text-center">
                      <div className="counter p-relative">
                        <span className="count" style={{ fontSize: "55px" }}>
                          <img
                            src="/assets/landing-page/images/RegisteredDevelopers.png"
                            style={{ width: "40px" }}
                          />





                          <div style={{display:"inline"}} id="dev_numbers">0</div>




                         
                        </span>
                        <small style={{ fontSize: "55px" }}>+</small>
                      </div>
                      <p>Registered Developers</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    {this.state.skills.length > 1 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img
                              src="/assets/landing-page/images/RegisteredBusiness.png"
                              style={{ width: "40px" }}/>


                            
                           
                             <div style={{display:"inline"}} id="biz_counter">0</div>
                             
                             




                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Registered Businesses</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            0
                          </span>
                        </div>
                        <p>Registered Businesses</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    {this.state.skills.length > 2 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img
                              src="/assets/landing-page/images/Language.png"
                              style={{ width: "40px" }}
                            />






                            <div style={{display:"inline"}} id="skills_counter">0</div>





                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Java</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            0
                          </span>
                        </div>
                        <p>Java</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{ alignItems: "center", minHeight: "60vh" }}
            id="how-it-works-business"
            className="about-area about-p p-relative d-flex"
          >
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="col-lg-6 mt-lg-4">
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/note.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>1</span>
                    </div>

                    <h4>Register</h4>
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/man.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>2</span>
                    </div>
                    <h4>Build Your Profile</h4>
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/exam.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>3</span>
                    </div>
                    <h4>Join Hackathons</h4>
                  </div>
                  {/* <div
                    className="feature-box wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <div className="crl mb-30">
                      <img
                        src="/assets/landing-page/images/document.png"
                        style={{ width: "60px" }}
                        alt="icon"
                      />
                      <span>4</span>
                    </div>
                    <h4>Get Ranked</h4>
                  </div> */}
                </div>

                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      ></div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "rgb(140,142,94)" }}
                      >
                        How Skills Ranker Works
                      </span>
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Create your business profile
                      </h2>
                      {/* <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                      >

                        See how you rank locally
                      </h5> */}
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      {/* <p>
                        Using our AI powered ranking algorithm, see how you rank
                        locally as a developer in Botswana. Get recommendations on
                        new skills to learn to improve your ranking
                      </p> */}
                      <NavLink to="/register" className="btn mt-20">
                        + &nbsp; Join Skills Ranker
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
        {/*  <footer*/}
        {/*    id="footer"*/}
        {/*    className="footer-bg footer-p"*/}
        {/*    style={{ backgroundColor: "#0424D9" }}*/}
        {/*  >*/}
        {/*    <div className="footer-top">*/}
        {/*      <div className="container">*/}
        {/*        <div className="row justify-content-between">*/}

        {/*          <div className="wpb_text_column wpb_content_element  vc_custom_1455289331750">*/}
        {/*	<div>*/}

        {/*  /!* Contacts *!/*/}
        {/*<p className="contacts">*/}
        {/*  <i className="fa fa-envelope"></i> info@bih.co.bw</p>*/}
        {/*  <p className="contacts"><i className="fa fa-phone"></i> Tel +267 391 3328</p>*/}
        {/*  <p className="contacts"><i className="fa fa-fax"></i> Fax +267 391 3289</p>*/}
        {/*  <p className="contacts"><i className="fa fa-map-marker"></i> Plot 69184, Block 8, Botswana Innovation Hub  Science and Technology Park</p>*/}
        {/*<p className="sm-buttons fleft"></p>*/}

        {/*	</div>*/}
        {/*</div>*/}
        {/*          <div className="col-xl-12 col-lg-12 col-sm-12 text-center">*/}

        {/*            <div className="footer-widget pt-120 mb-30">*/}
        {/*              <div className="logo mb-35">*/}
        {/*                <a href="/home">*/}
        {/*                  <img*/}
        {/*                    src="/assets/landing-page/images/icon.png"*/}
        {/*                    alt="logo"*/}
        {/*                    style={{*/}
        {/*                      width: "7%",*/}
        {/*                      opacity: "100%",*/}
        {/*                      marginRight: "50px"*/}
        {/*                    }}*/}
        {/*                  />*/}
        {/*                </a>*/}
        {/*                <a href="http://www.bih.co.bw">*/}
        {/*                  <img*/}
        {/*                    src="/assets/landing-page/images/white_logo.png"*/}
        {/*                    alt="logo"*/}
        {/*                    style={{ width: "13%", opacity: "100%" }}*/}
        {/*                  />*/}
        {/*                </a>*/}

        {/*                */}
        {/*              </div>*/}

        {/*              /!* //-- Contact Details -->// *!/*/}

        {/*              <div className="footer-social">*/}
        {/*                <a href="https://facebook.com/yourBIH">*/}
        {/*                  <i className="fab fa-facebook-f"></i>*/}
        {/*                </a>*/}
        {/*                <a href="https://twitter.com/yourBIH">*/}
        {/*                  <i className="fab fa-twitter"></i>*/}
        {/*                </a>*/}
        {/*                <a href="https://instagram.com/yourBIH">*/}
        {/*                  <i className="fab fa-instagram"></i>*/}
        {/*                </a>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="copyright-wrap pb-120">*/}
        {/*      <div className="container">*/}
        {/*        <div className="row">*/}
        {/*          <div className="col-12">*/}
        {/*            <div className="copyright-text text-center">*/}
        {/*              <p>© 2020 Botswana Innovation Hub.</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </footer>*/}

        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));


let count = 0;

setInterval(() => {
  if(count < dev_numbers){
    var display = document.getElementById("dev_numbers");
    display.innerHTML =++ count;
    count++;
  }
}, 10);

setInterval(() => {
  if(count < skill_numbers){
    var display = document.getElementById("skills_counter");
    display.innerHTML =++ count;
  }
}, 10);

setInterval(() => {
  if(count < biz_number){
    var display = document.getElementById("biz_counter");
    display.innerHTML =++ count;
  }
}, 10);

  setInterval(() => {
    if(count < hack_numbers){
      var display = document.getElementById("dev_hack");
      display.innerHTML =++ count;
    }
  }, 10);

  



// setTimeout(function(){
// let counts = setInterval(update);
// let upto = 0;

// update();

// function update(){
//   var count = document.getElementById("dev_numbers");
//   count.innerHTML =++ upto;
//   if(upto === dev_numbers){
//     clearInterval(counts);
//   }
// }
// },10000);



// setTimeout(function(){
//   let counts = setInterval(update);
//   let upto = 0;

//   update();
  
//   function update(){
    
//     var count = document.getElementById("biz_counter");
//     count.innerHTML=++upto;
//       if(upto === 319){
//         clearInterval(counts);
//       }
//      }
// }, 10000);

// setTimeout(function(){
// let counts = setInterval(update);
// let upto = 0;

// update();

// function update(){
//   var count = document.getElementById("skills_counter");
//   count.innerHTML =++ upto;
//   if(upto === skill_numbers){
//     clearInterval(counts);
//   }
// }
// },10000)
  