import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dateFormat from "dateformat";
import moment from "moment";
import CreateBusinesProfileForm from "./CreateBusinesProfileForm";
import { formatDate, timeZoneHelper } from "../utils/utils";

class BusinessProfileList extends Component {
  data = [];

  state = {
    user: null,
    hasProfiles: false,
    isCreatingProfile: false,
  };

  data = [];
  componentDidMount() {
    window.$(".modal-backdrop").css("display", " !important");
  }

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }

  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div className="row">
        <h4 className="col-12 mb-3">
          My Business Profiles
          <div style={{ display: "flex", float: "right" }}>
            <div className="text-sm-right">
              <button
                onClick={() => this.props.triggerProfileCreation()}
                type="button"
                style={{
                  backgroundColor: "#e2b537",
                  color: "black",
                  borderColor: "#e2b537",
                  fontWeight: "bold",
                }}
                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
              >
                <i className="mdi mdi-plus mr-1"></i> New Profile
              </button>
            </div>
          </div>
        </h4>
        {this.props.profileList.map((profile) => (
          <div key={profile._id} className="col-xl-4 col-sm-6">
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.selectProfile(profile)}
            >
              <div className="card-body">
                <div className="media">
                  <div className="avatar-md mr-4">
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                      <img
                        src="assets/images/companies/img-1.png"
                        alt=""
                        height="30"
                      />
                    </span>
                  </div>
                  <div className="media-body overflow-hidden">
                    <h5 className="text-truncate font-size-15">
                      <a href="#" className="text-dark">
                        {profile.businessName}
                      </a>
                    </h5>
                    <p className="text-muted mb-4">{profile.industry}</p>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 border-top">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item mr-3">
                    <i className="bx bx-calendar mr-1"></i>{" "}
                    {formatDate(profile.createdAt)}
                  </li>
                  <li className="list-inline-item mr-3">
                    {profile.isCompliant ? (
                      <span className="badge badge-primary">Compliant</span>
                    ) : (
                      <span className="badge badge-danger"></span>
                      //<span className="badge badge-danger">Not Compliant</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(BusinessProfileList);
