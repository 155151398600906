import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

import Footer from "../partials/Footer";
import socketClusterClient from "socketcluster-client";
import { connect } from "react-redux";
import {
  fireNotification,
  deleteNotification,
} from "../../store/actions/notificationsActions";
// import TeamRequests from "../../components/TeamRequests"
import {
  getDiscussions,
  createDiscussion,
  createDiscussionComment,
  createTeamDiscussionComment,
  getDiscussionComments
} from "../../store/actions/discussionActions";
import {
  fetchContest,
  getContestleaderboard,
  checkParticipation,
  createSubmission,
} from "../../store/actions/contestActions";
import {
  reportContent as _reportContent
} from "../../store/actions/reportActions";
import {
  fetchAllEventTeams,
  getEventUserTeam,
  updateMember,
  updateConsent,
  pushEventInvite,
  leaveTeamWilingly,
  eliminateTeamMember,
  makeLeader,
} from "../../store/actions/teamActions";
import ReactHtmlParser from "react-html-parser";
import { BASE_URL } from "../../api/client";
// import Cookie from "js-cookie";
import bih from "../../../src/bih.jpg";
import globe from "../../../src/globe.svg";
import dateFormat from "dateformat";
import { ResponsiveRadar } from "@nivo/radar";
import moment from "moment";
// import parse from "html-react-parser";
// import banner from "./banner.png";
import ContentSkeleton from "../../components/skeletons/ContentSkeleton";
import TeamListSkeleton from "../../components/skeletons/TeamListSkeleton";
import store from "../../index";
import MDEditor from "@uiw/react-md-editor";
import Toast from "react-bootstrap/Toast";
import { formatDate, arrayPrepend } from "../../utils/utils";
import { getUser } from "../../api/user";


class EventDetailsPage extends Component {
  data = [];

  state = {
    inviteEmail: "",
    event: { isRegistrationClosed: true },
    team: { discussion: null },
    leaderboard: [],
    isRegistered: false,
    submissionFile: null,
    submissionType: null,
    submissionLink: null,
    teams: [],
    isLoaded: false,
    isUploading: false,
    isUploadFailed: false,
    isUploadComplete: false,
    teamRequestLoading: false,
    hasConsented: false,
    consentInfo: false,
    user: typeof Cookies.get("user") === "string" ? JSON.parse(Cookies.get("user")) : Cookies.get("user"),
    isTeamsLoaded: false,
    isLeaderBoardLoaded: false,
    isTeamLoaded: false,
    isSubmittingEventComment: false,
    isSubmittingTeamComment: false,
    isFocusDiscussionSet: false,
    isLoadingDiscussionComments: true,
    discussionReply: "",
    teamDiscussionReply: "",
    discussionTitle: "",
    discussionContent: "",
    teamComments: [],
    eventDiscussions: [],
    focusEventDiscussion: {},
    notifications: [],
    submissionRound: null,
    validRounds: [],
    lastNotified: null
  };
  socket = null;

  setReply = (e) => {
    this.setState({
      discussionReply: e
    });
  };
  setTeamReply = (e) => {
    this.setState({
      teamDiscussionReply: e
    });
  };
  selectDiscussion = (discussion) => {
    let currentDiscussion = this.props.eventDiscussions.filter(
      (x) => x._id === discussion
    )[0];
    this.setState({
      focusEventDiscussion: currentDiscussion,
      isFocusDiscussionSet: true,
    });
    this.setState({
      isLoadingDiscussionComments: false
    });
    this.props.getDiscussionComments(discussion, "event");
  };
  getEventDiscussions = (eventId) => {
    this.props.getEventDiscussions(eventId);
  };
  resetState = () => {
    this.setState({
      isLoadingDiscussionComments: true,
      isFocusDiscussionSet: false,
      focusEventDiscussion: {},
    });
  };

  initTeamInvite(e) {
    e.preventDefault();
    window.$("#member-invite-modal").modal("hide");
    this.props
      .sendTeamInvite({
        eventId: this.state.event._id,
        teamId: this.state.team._id,
        email: this.state.inviteEmail,
        userId: this.state.user._id,
      })
      .then((res) => {
        let notification = {
          id: new Date().valueOf(),
          isActive: true,
          delay: 10000,
          title: "Platform Notification",
          type: "success",
          time: moment().toString(),
          body: "Team invite sent!!",
        };
        let nots = this.state.notifications;
        nots = nots.concat([notification]);
        this.setState({ notifications: nots });
      })
      .catch((err) => {
        let notification = {
          id: new Date().valueOf(),
          isActive: true,
          delay: 10000,
          type: "danger",
          title: "Platform Notification",
          time: formatDate(moment()),
          body: err || "Failed to send team invite",
        };
        let nots = this.state.notifications;
        nots = nots.concat([notification]);
        this.setState({ notifications: nots });
        // this.props.dispatchNotification(err);
      })
      .finally(() => {
        this.setState({
          inviteEmail
            : ""
        })
      });
  }

  hasConsentedForEvent(hackathons, currentEvent) {
    let events = hackathons.filter(hackathon => {
      console.log(typeof hackathon.event, typeof currentEvent);
      return hackathon.event === currentEvent
    })

    return events.length > 0 ? events[events.length - 1].hasConsented : false
  }

  removeNotification(notificationId) {
    let nots = this.state.notifications;
    nots = nots.filter((notification) => notification.id !== notificationId);
    this.setState({ notifications: nots });
  }

  componentWillUnmount() {
    if (this.socket !== null && this.state.team)
      this.socket.unsubscribe(this.state.team._id, (x) => {
      });
    if (this.socket !== null && this.state.event)
      this.socket.unsubscribe(this.state.event._id, (x) => {
      });
  }

  report(body) {
    this.props
      .reportContent(body)
      .then((res) => {
        let notification = {
          id: new Date().valueOf(),
          isActive: true,
          delay: 10000,
          title: "Report Successful",
          time: formatDate(new Date()),
          type: "success",
          body: "Content has successfully been reported to our moderators.",
        };
        let nots = this.state.notifications;
        nots = nots.concat([notification]);
        this.setState({ notifications: nots });
      })
      .catch((e) => { console.log(e); });
  }

  makeSubmission() {
    if (this.state.submissionType === "s3") {
      this.setState({ isUploading: true });
      this.props
        .pushFile({
          teamId: this.state.team._id,
          file: this.state.submissionFile.file,
          uploadedBy: this.state.user._id,
          fileName: this.state.submissionFile.fileName,
          type: this.state.submissionType,
          round: this.state.submissionRound,
        })
        .then((submissions) => {
          this.setState({
            team: {
              ...this.props.team,
              submissions
            },
            isUploadFailed: false,
            isUploading: false,
            isUploadComplete: true,
          });

          window.$("#attachFileModal").modal("hide");
          let notification = {
            id: new Date().valueOf(),
            isActive: true,
            delay: 5000,
            title: "Submission Sent",
            time: formatDate(new Date()),
            body: "Submission has succefully been made.",
          };
          let nots = this.state.notifications;
          nots = nots.concat([notification]);
          this.setState({ notifications: nots });
          // this.props.dispatchNotification(err);
        })
        .catch((error) => {
          this.setState({
            isUploading: false,
            isUploadFailed: true,
            isUploadComplete: false,
          });
          let notification = {
            id: new Date().valueOf(),
            isActive: true,
            delay: 5000,
            title: "Submission Sent",
            time: formatDate(new Date()),
            body: "Submission upload failed.",
          };
          let nots = this.state.notifications;
          nots = nots.concat([notification]);
          this.setState({ notifications: nots });
          window.$("#attachFileModal").modal("hide");
        }).finally(() => {
          this.setState({
            submissionType: null,
            isUploading: false,
            isUploadFailed: false,
            isUploadComplete: false,
            submissionRound: null,
          });
        });;
    } else if (this.state.submissionType === "link") {
      let submissonInfo = {
        teamId: this.state.team._id,
        uploadedBy: this.state.user._id,
        type: this.state.submissionType,
        link: this.state.submissionLink,
        round: this.state.submissionRound,
      }

      this.props
        .pushFile(submissonInfo)
        .then((submissions) => {
          this.setState({
            team: {
              ...this.props.team,
              submissions
            }, isUploading: false
          });
          // window.$("#attachFileModal").modal("hide");
        })
        .catch((error) => {
          this.setState({ team: this.props.team, isUploading: false });

          let notification = {
            id: new Date().valueOf(),
            isActive: true,
            delay: 5000,
            title: "Submission Sent",
            time: formatDate(new Date()),
            body: "Submission upload failed.",
          };
          let nots = this.state.notifications;
          nots = nots.concat([notification]);
          this.setState({ notifications: nots });
        }
        ).finally(() => {
          this.setState({
            submissionType: null,
            isUploading: false,
            isUploadFailed: false,
            isUploadComplete: false,
            submissionRound: null,
          });
        });
      window.$("#attachFileModal").modal("hide");
    } else {
      window.$("#attachFileModal").modal("hide");
    }
  }

  closeModal() {
    this.setState({
      submissionType: null,
      isUploading: false,
      isUploadFailed: false,
      isUploadComplete: false,
      submissionRound: null,
    });
    store.dispatch({
      type: "UPLOAD_PROGRESS_EVENT",
      data: { percent: 0, total: 0, uploaded: 0 },
    });
  }

  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  handleSkills(users) {
    // console.log(users);
    var data_temp = [];
    users.forEach((user) => {
      data_temp.push(...user.programmingLanguages);
      data_temp.push(...user.technologies);
    });

    var data = [];

    data_temp.forEach((item) => {
      data.push(item.title);
    });

    // console.log(data);
    var compressed = [];
    // make a copy of the input array
    var copy = data.slice(0);

    // first loop goes over every element
    for (var i = 0; i < data.length; i++) {
      var myCount = 0;
      // loop over every element in the copy and see if it's the same
      for (var w = 0; w < copy.length; w++) {
        // console.log(data[w]);
        if (data[i] === copy[w]) {
          // increase amount of times duplicate is found
          myCount++;
          // sets item to undefined
          delete copy[w];
        }
      }

      if (myCount > 0) {
        var a = {};
        a.value = data[i];
        a.count = myCount;
        compressed.push({ id: data[i], label: data[i], value: myCount });
      }
    }
    return compressed;
  }

  validateRounds(rounds) {
    let validRounds = rounds.filter((round) => {
      return (
        !round.isClosed &&
        round.status &&
        moment() > moment(round.start) &&
        moment() < moment(round.end)
      );
    });

    this.setState({ validRounds: validRounds });
  }
  data = [];

  async getUserProfile() {
    const _user = await getUser(this.state.user._id);

    if (_user)
      this.setState({
        user: _user.data
      })
    return _user;
  }

  componentDidMount() {
    window.$(".modal-backdrop").css("display", " !important");
    this.socket = socketClusterClient.create({
      hostname: "https://skillsranker.bih.co.bw",
      port: "",
    }); (async () => {
      for await (let event of this.socket.listener("connect")) {
        console.log("Socket is connected");
      }
      for await (let event of this.socket.listener("error")) {
        console.log("Error");
      }
    })();

    const { history } = this.props;
    let splitPath = history.location.pathname.split("/");
    let cid = splitPath[2];

    if (cid === undefined) this.props.history.push("/");

    this.props
      .getContest(cid)
      .then(() => {
        this.setState({
          event: this.props.contest,
          isLoaded: true,
          caughtErr: false,
        });
        this.validateRounds(this.props.contest.rounds);
        this.registerEventSocketListener(cid);
      })
      .catch((err) => this.props.dispatchNotification(err));

    this.getEventDiscussions(cid);

    if (this.state.user && typeof cid !== "undefined") {

      var isBusiness = this.state.user.accountType === "business";

      this.getUserProfile().then(({ data }) => {
        let isConsented = this.hasConsentedForEvent(data.hackathons || [], cid)
        console.log(data);
        this.setState({ isBusiness, hasConsented: isConsented });

        this.props.checkEventParticipation(cid, data._id, isBusiness).then((res) => {

          this.setState({ isRegistered: res.event.isEnrolled });
          if (res.event.isEnrolled) {
            return this.getTeam(data._id, cid, isBusiness);
          }
        }).catch(console.error)
          .finally(() => {
            if (this.state.team.discussion !== null && typeof this.state.team.discussion._id !== "undefined")
              this.props.getDiscussionComments(this.state.team.discussion._id, "team")
                .then((res) => {
                });
          });
      })

    } else this.setState({ hasConsented: true });
    this.setState({ isLoaded: true });
    this.props
      .getEventTeams(cid)
      .then(() =>
        this.setState({ teams: this.props.eventTeams, isTeamsLoaded: true })
      )
      .catch((err) => console.log(err));

    this.props
      .getLeaderboard(cid)
      .then(() =>
        this.setState({
          leaderboard: this.props.leaderboard,
          isLeaderBoardLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }

  async registerTeamSocketListener(teamId,) {
    this.setState({ teamSocketId: teamId });
    let teamDiscussionChannel = this.socket.subscribe(this.state.teamSocketId);
    for await (let data of teamDiscussionChannel) {
      // console.log(this.state.teamSocketId, data.socketId);
      if (this.state.teamSocketId === data.socketId) {
        // notify once in 3 minutes
        if (
          this.state.lastNotified == null ||
          new Date().getTime() - this.state.lastNotified.getTime() > 60 * 60 * 3
        ) {

        }
        let a = arrayPrepend(data.payload, this.state.team.discussion.comments.ids);
        this.setState({
          team: {
            ...this.state.team,
            discussion: {
              ...this.state.team.discussion,
              comments: {
                ...this.state.team.comments,
                ids: a
              }
            }
          }
        });
        this.setState({
          lastNotified: new Date(),
        });
      }
    }
  }

  async registerEventSocketListener(eventId) {
    this.setState({ eventSocketId: eventId });
    let eventDiscussionChannel = this.socket.subscribe(this.state.eventSocketId);
    for await (let data of eventDiscussionChannel) {
      if (this.state.teamSocketId !== data.socketId) {
        // notify once in 3 minutes
        if (
          this.state.lastNotified == null ||
          new Date().getTime() - this.state.lastNotified.getTime() > 60 * 60 * 3
        ) {
        }
        let a = arrayPrepend(data.message, this.state.team.discussion.comments.ids);
        this.setState({
          team: {
            ...this.state.team,
            discussion: {
              ...this.state.team.discussion,
              comments: {
                ...this.state.team.comments,
                ids: a
              }
            }
          }
        });
        this.setState({
          lastNotified: new Date(),
        });
      }
    }
  }

  getTeam(user, cid, isBusiness) {
    this.props
      .getUserTeam(
        user,
        cid,
        isBusiness
      )
      .then(() => {
        this.registerTeamSocketListener(this.props.team._id);
        let members = [];
        let businessProfiles = [];
        let events = [];
        if (isBusiness && this.props.team.businessProfiles.length > 0) {
          businessProfiles = this.props.team.businessProfiles.filter(
            (element) => element._id == user
          );
          events = businessProfiles[0].events || [];
        } else {
          members = this.props.team.members.filter(
            (element) => element._id == user
          );
          events = members[members.length - 1].hackathons || [];
        }
        let event = events.filter((event) => event.event === cid);

        if (this.props.team)
          this.setState({
            team: {
              // discussion: {
              //   isOpen: true,
              //   ...this.props.teamDiscussions[0]
              // },
              ...this.props.team,
              discussion: {
                ...this.props.team.discussion,
                comments: {
                  ...this.props.team.comments,
                  ids: this.props.team.discussion ? this.props.team.discussion.comments.ids.reverse() : []
                }
              }
            },
            isLoadingDiscussionComments: false,
            // hasConsented: event.length > 0 ? event[0].hasConsented : false,
          });
      })
      .catch((err) => console.log(err));
  }

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }

  removeTeamMember(member) {
    let team = this.state.team._id;
    this.props
      .takeOutTeamMember({
        teamId: this.state.team._id,
        memberId: member,
        requestBy: this.state.user._id,
      })
      .then(() => {
        let members = this.state.team.members.filter(
          (tmember) => member._id != tmember
        );
        let team = this.state.team;
        team.members = members;
        this.setState({ team: team });
      })
      .catch((err) => { });
  }

  leaveTeam = (member) => {
    this.props
      .exitTeam({
        teamId: this.state.team._id,
        memberId: member,
        requestBy: this.state.user._id,
      })
      .then(() => {
        let members = this.state.team.members.filter(
          (tmember) => member !== tmember._id);
        let team = this.state.team;
        team.members = members;
        this.setState({ team: team });
        window.location.reload()
      })
      .catch((err) => { });

  };

  makeTeamLeader(member) {
    this.props
      .appointLeader({
        teamId: this.state.team._id,
        newLeader: member._id,
        requestBy: this.state.user._id,
      })
      .then(() => {
        let team = this.state.team;
        team.owner = member;
        this.setState({ team: team });
      })
      .catch((err) => { });
  }

  sendEventComment(e) {
    if (this.refs.teamEventCommentButton.hasAttribute("disabled") || this.state.discussionReply.trim() === "") return;
    this.setState({
      isSubmittingEventComment
        : true
    })
    this.refs.replyEventCommentButton.setAttribute("disabled", "disabled");
    this.refs.teamEventCommentButton.setAttribute("disabled", "disabled");

    this.props.createDiscussionComment({
      content: this.state.discussionReply,
      author: this.state.user._id,
      discussionId: this.state.focusEventDiscussion._id
    }).then(() => {
      this.setState({ discussionReply: "" })
    }).catch((err) => {
      this.setState({})
      let notification = {
        id: new Date().valueOf(),
        isActive: true,
        delay: 5000,
        title: "Failed to Send Comment",
        time: formatDate(new Date()),
        body: "Failed to send comment for discussion. Please check your internet connnection and try again.",
      };
      let nots = this.state.notifications;
      nots = nots.concat([notification]);
      this.setState({ notifications: nots, discussionReply: "" });
      this.props.dispatchNotification(err);
    }).finally(() => {
      this.refs.replyEventCommentButton.removeAttribute("disabled", "disabled");
      this.refs.teamEventCommentButton.removeAttribute("disabled", "disabled");
      this.setState({
        isSubmittingEventComment
          : false
      })
    });
  }

  sendTeamComment(e) {
    if (this.refs.teamReplyButton.hasAttribute("disabled") || this.state.teamDiscussionReply.trim() === "") return;
    this.setState({
      isSubmittingTeamComment
        : true
    })
    this.refs.teamReplyButton.setAttribute("disabled", "disabled");
    this.props.createTeamDiscussionComment({
      content: this.state.teamDiscussionReply,
      author: this.state.user._id,
      discussionId: this.state.team.discussion ? this.state.team.discussion._id : null,
      teamId: this.state.team._id
    }).then((res) => {
      this.setState({
        teamDiscussionReply: "",
        // teamComments: [...this.state.teamComments, { comment: th.s.comment }],
        // team: {
        //   ...this.state.team,
        //   // discussion: {
        //   //   ...res.teamDiscussions,
        //   //   comments: { ids: res.teamComment }
        //   // }
        // }
      });

      // if (typeof this.state.team.discussion.comments === "array")
      let a = arrayPrepend(res.teamComment, this.state.team.discussion.comments.ids);

      this.setState({
        team: {
          ...this.state.team,
          discussion: {
            ...this.state.team.discussion,
            comments: {
              ...this.state.team.comments,
              ids: a
            }
          }
        }
      });

    }).catch((err) => {
      this.setState({})
      let notification = {
        id: new Date().valueOf(),
        isActive: true,
        delay: 5000,
        title: "Failed to Send Comment",
        time: formatDate(new Date()),
        body: "Failed to send comment for discussion. Please check your internet connnection and try again.",
      };
      let nots = this.state.notifications;
      nots = nots.concat([notification]);
      this.setState({ notifications: nots, teamDiscussionReply: "" });
      this.props.dispatchNotification(err);
    }).finally(() => {
      this.refs.teamReplyButton.removeAttribute("disabled", "disabled"); this.setState({
        isSubmittingTeamComment
          : false
      })
    });
  }

  teamRequestRespond(user, response) {
    this.setState({ teamRequestLoading: true });
    let team = this.state.team._id;
    let requestingParty = user._id;
    this.props
      .updateTeamMembers(team, requestingParty, response)
      .then(() => {
        let team = this.state.team;
        if (response) {
          team.members.push(user);
          // this.state.teams.push(user)
        }

        team.requests = team.requests.filter(
          (element) => element._id != user._id
        );
        this.setState({ team: team });
      })
      .catch((error) => { })
      .finally(() => {
        this.setState({ teamRequestLoading: false });
      });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  getFileType(str) {
    return str.split(";")[0].split("/")[1];
  }

  uploadFile(event, ctx) {
    if (!ctx) return;
    let file = event.target.files[0];

    ctx.getBase64(file,
      (result) => {
        ctx.setState({
          submissionFile: {
            file: result,
            fileName: file.name,
          },
        });
      });
  }

  toggleConsent(consent) {
    this.setState({
      consentInfo: consent
    });
  }

  updateConsent() {
    this.props.updateConsent(
      this.state.user._id,
      this.state.event._id
    ).then(() => {
      this.setState({
        hasConsented: true
      });
    });
  }

  isFormComplete() {
    return this.state.consentInfo;
  }

  fillOutMembers(max) {
    var temps = [];
    for (let index = 0; index < max; index++) {
      temps.push(
        <tr key={index + new Date().getMilliseconds()}>
          <td
            style={{
              width: "45px",
              height: "45px",
              borderTop: "gray 1px dashed",
            }}
          >
            <span
              className="avatar-title rounded-circle font-size-13 p-1 ml-1"
              style={{
                backgroundColor: "transparent",
                width: "45px",
                height: "45px",
                border: "1px gray dashed",
              }}
            ></span>
          </td>
          <td style={{ borderTop: "gray 1px dashed" }}>
            <h5
              className="text-dark font-size-14 mb-1"
              style={{ border: "gray 1px dashed" }}
            >
              <a href="#" style={{ color: "transparent" }}>
                a
              </a>
            </h5>
            <small
              style={{
                border: "gray 1px dashed",
                color: "transparent",
                marginLeft: ".4rem",
              }}
            >
              a
            </small>
            <small
              style={{
                border: "gray 1px dashed",
                color: "transparent",
                marginLeft: ".4rem",
              }}
            >
              a
            </small>
            <small
              style={{
                border: "gray 1px dashed",
                color: "transparent",
                marginLeft: ".4rem",
              }}
            >
              a
            </small>
            <small
              style={{
                border: "gray 1px dashed",
                color: "transparent",
                marginLeft: ".4rem",
              }}
            >
              a
            </small>
            <small
              style={{
                border: "gray 1px dashed",
                color: "transparent",
                marginLeft: ".4rem",
              }}
            >
              a
            </small>
          </td>
        </tr>
      );
    }
    return temps;
  }

  submitEventDiscussion() {
    window.$("#startNewDiscussionModal").modal("hide");

    this.props.createDiscussion({
      type: "event",
      title: this.state.discussionTitle,
      content: this.state.discussionContent,
      metaId: this.state.event._id,
      state: "posted",
      author: this.state.user._id
    }).then(() => {
      // let notification = {
      //   id: new Date().valueOf(),
      //   isActive: true,
      //   delay: 5000,
      //   title: "Event Discussion Posted",
      //   time: timeZoneHelper(new Date()),
      //   body: "Event successfully posted to event discussion.",
      // };
      // let nots = this.state.notifications;
      // console.log({nots, notification});
      // nots = nots.concat([notification]);
      // this.setState({ notifications: nots });
      // this.props.dispatchNotification("Event successfully posted to event discussion.");
    }).catch((err) => {
      let notification = {
        id: new Date().valueOf(),
        isActive: true,
        delay: 5000,
        title: "Failed to Create Discussion",
        time: formatDate(new Date()),
        body: "Failed to send team invite. Please check your internet connnection and try again.",
      };
      let nots = this.state.notifications;
      nots = nots.concat([notification]);
      this.setState({ notifications: nots });
      this.props.dispatchNotification(err);
    }).finally(() => {
      this.setState({
        discussionTitle: "",
        discussionContent: ""
      });
      window.$(".modal-backdrop").css("display", " !important");
    });
  }

  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />

        <div style={{ position: "fixed", width: "100%", height: "100%" }}>
          <div
            style={{
              backgroundImage: "url(" + globe + ")",
              transform: "translate(-50%, -50%) scale(1)",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform",
            }}
          ></div>
        </div>
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      <a href="/#">
                        <img
                          src="/assets/landing-page/images/bih_logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                      <a href="/#">
                        <img
                          src="/assets/landing-page/images/logo_white.png"
                          alt="logo"
                          style={{
                            width: "40%",
                            opacity: "100%",
                            padding: "1rem",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div
                      className="responsive"
                      onClick={() => this.toggleMenu()}
                    >
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                    <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub ml-sm-4">
                            <a href="/#">Home </a>
                          </li>
                          {/* <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="#about">About</a>
                          </li> */}
                          <li className="has-sub font-weight-bolder ml-sm-4">
                            <a href="/#how-it-works">Developer</a>
                          </li>
                          <li className=" ml-sm-4">
                            <a href="/#how-it-works-business">Business</a>
                          </li>
                         
                          <li className="ml-sm-4 has-sub">
                            <a href="/events">Hackathons</a>
                            <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/plannedHackathons">Planned Hackathons</a></li>
                            </ul>
                          </li>

                          {/* <li className=" ml-sm-4">
                            <a href="/events">Hackathons</a>
                          </li> */}
                            {/* <li className=" ml-sm-4">
                            <a href="/awsRegistration"></a>
                           </li> */}


                          {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                          <ul className="dropdown">
                            <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                          </ul>
                          </li> */}

                          <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                           <ul className="dropdown">
                            <li className="ml-sm-4 has-sub"><a href="#">AWS-re/Start</a>
                              <ul className="dropdown">
                              <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                              <li className="ml-sm-4"><a href="/aboutAWS">Requirements</a></li>
                              <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                              </ul>
                            </li>
                           </ul>
                        </li>

                          <li className=" ml-sm-4">
                            <a href="#footer">Contacts</a>
                          </li>

                          </ul>                        
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a
                        href={this.state.user ? "/profile" : "/login"}
                        className="btn"
                      >
                        <i className="fa fa-user-alt" />
                        {this.state.user ? "Profile" : "Login"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
          {typeof this.state.event.eventBanner !== "undefined" ? (
            <section
              id="parallax"
              className="parallax slider-area slider3-bg second-slider3-bg d-flex align-items-center justify-content-left fix"
              style={{
                background: "url(" + this.state.event.eventBanner.data + ")",
                height: "86vh",
                backgroundSize: "contain",
                // backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            >
              {/* <div style={{ background: "#00000063", position: "absolute", width: "100vw", height: "100%" }}></div> */}
            </section>
          ) : (
            <section
              id="parallax"
              className="parallax slider-area slider3-bg second-slider3-bg d-flex align-items-center justify-content-left fix"
              style={{
                background: "url(" + bih + ")",
                minHeight: "87vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}
            >
              <div
                style={{
                  background: "#00000063",
                  position: "absolute",
                  width: "100vw",
                  height: "100%",
                }}
              ></div>
            </section>
          )}

          <section
            style={{ alignItems: "center", minHeight: "100vh" }}
            id="how-it-works"
            className=" p-relative"
          >
            <div
              className="d-flex"
              style={{ background: "#8080802e", minHeight: "16vh" }}
            >
              <div
                className="col-12"
                style={{ padding: "1rem 0rem", alignSelf: "center" }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-sm-10 col-lg-10"
                      style={{ display: "flex", paddingLeft: "3rem" }}
                    >
                      <h2 className="text-dark">
                        {this.state.event.title}
                      </h2> &nbsp;
                    </div>
                  </div>
                  <div className="row"
                    style={{ display: "flex", paddingLeft: "3rem" }}>

                    <h3 className="text-dark pb-0 mb-2 badge badge-warning font-weight-bold" style={{ alignSelf: "center" }}>{
                      this.state.event && this.state.event.eventTier
                        ? this.state.event.eventTier === "business" ?
                          "Businesses Only" :
                          this.state.event.eventTier === "individuals"
                            ? "Individuals Only" :
                            "Both" :
                        ""}</h3>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-9">
                      <div className="row" style={{ paddingLeft: "3rem" }}>
                        <div className="col-sm-12 col-md-3 col-lg-3 pl-0">
                          <p className="mb-0 text-muted">STARTS ON:</p>
                          <p style={{ fontWeight: "bold", lineHeight: "10px" }}>
                            {this.state.event.startDate
                              ? formatDate(
                                new Date(this.state.event.startDate),

                              )
                              : ""}
                          </p>
                          <div className="pt-1">
                            <a
                              style={{ color: "#2196f3", fontSize: "small" }}
                              href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fskillsranker.bih.co.bw/event/${this.state.event._id}&text=Check%20out%20this%20event%20on%20Skills%20Ranker%20%23skills_ranker%20%F0%9D%99%A8%F0%9D%99%A0%F0%9D%99%9E%F0%9D%99%A1%F0%9D%99%A1%F0%9D%99%A8%F0%9D%99%A7%F0%9D%99%96%F0%9D%99%A3%F0%9D%99%A0%F0%9D%99%9A%F0%9D%99%A7%20%F0%9F%A4%93%20%F0%9F%92%BB`}
                              target="_blank" rel="noreferrer"
                            >
                              <i className="fab fa-lg fa-twitter"></i>
                            </a>
                            <a
                              style={{ color: "#2196f3", fontSize: "small" }}
                              className="ml-4"
                              href={`https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fskillsranker.bih.co.bw/event/${this.state.event._id}`}
                              target="_blank" rel="noreferrer"
                            >
                              <i className="fab fa-lg fa-facebook"></i>
                            </a>
                            <a
                              style={{ color: "#2196f3", fontSize: "small" }}
                              className="ml-4"
                              href={`http://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fskillsranker.bih.co.bw/event/${this.state.event._id}/p&title=Check%20out%20this%20event%20on%20Skills%20Ranker%23skills_ranker`}
                              target="_blank" rel="noreferrer"
                            >
                              <i className="fab fa-lg fa-linkedin"></i>
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3 pl-0 ">
                          <p className="mb-0 text-muted">HACKATHON:</p>
                          <p style={{ fontWeight: "bold", lineHeight: "10px" }}>
                            {this.state.event.eventType}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3 pl-0">
                          <p className="mb-0 text-muted">ENDS ON:</p>
                          <p style={{ fontWeight: "bold", lineHeight: "10px" }}>
                            {this.state.event.endDate
                              ? formatDate(
                                new Date(this.state.event.endDate),

                              )
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 pl-0">
                          <p className="mb-0 text-muted">ALLOWED TEAM SIZE:</p>
                          <p style={{ fontWeight: "bold", lineHeight: "10px" }}>
                            {this.state.event.minTeamSize ? this.state.event.minTeamSize + " - " : ""}
                            {this.state.event.maxTeamSize}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-3 col-lg-3 order-md-9 order-lg-9 order-xl-9 text-right">
                      {this.state.isLoaded ? (
                        !this.state.isRegistered &&
                          !this.state.event.isRegistrationClosed &&
                          new Date() <=
                          new Date(this.state.event.registrationEndDate) &&
                          new Date(this.state.event.registrationStartDate) <=
                          new Date() ? (
                          <a
                            className="btn"
                            href={"/event/register/" + this.state.event._id}
                          >

                            Register For Event
                          </a>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="">
                  <div className="card-body">
                    {/* <h4 className="card-title">Vertical Nav Tabs</h4>
                    <p className="card-title-desc">Example of Vertical nav tabs</p> */}

                    {/* check if loading */}
                    {/* check if user consented to Ts & Cs  */}

                    <div className="row">
                      <div className="col-md-2">
                        <div
                          className="nav flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <a
                            className="nav-link mb-2 active"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-description-tab"
                            data-toggle="pill"
                            href="#v-pills-description"
                            role="tab"
                            aria-controls="v-pills-description"
                            aria-selected="true"
                          >
                            Description
                          </a>
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-rules-tab"
                            data-toggle="pill"
                            href="#v-pills-rules"
                            role="tab"
                            aria-controls="v-pills-rules"
                            aria-selected="false"
                          >
                            Rules and legal
                          </a>
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-judges-tab"
                            data-toggle="pill"
                            href="#v-pills-judges"
                            role="tab"
                            aria-controls="v-pills-judges"
                            aria-selected="false"
                          >
                            Judges and Moderators
                          </a>
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-teams-tab"
                            data-toggle="pill"
                            href="#v-pills-teams"
                            role="tab"
                            aria-controls="v-pills-teams"
                            aria-selected="false"
                          >
                            Teams
                          </a>
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-prizes-tab"
                            data-toggle="pill"
                            href="#v-pills-prizes"
                            role="tab"
                            aria-controls="v-pills-prizes"
                            aria-selected="false"
                          >
                            Prizes
                          </a>
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-faqs-tab"
                            data-toggle="pill"
                            href="#v-pills-faqs"
                            role="tab"
                            aria-controls="v-pills-faqs"
                            aria-selected="false"
                          >
                            FAQs
                          </a>
                          {this.state.isRegistered ? <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-discussions-tab"
                            data-toggle="pill"
                            href="#v-pills-discussions"
                            role="tab"
                            aria-controls="v-pills-discussions"
                            aria-selected="false"
                            onClick={() => this.resetState()}
                          >
                            Discussions
                          </a> : <></>}
                          <a
                            className="nav-link mb-2"
                            style={{
                              borderBottom: "solid 1px rgb(172 175 177)",
                              borderRadius: "0px",
                            }}
                            id="v-pills-leaderboard-tab"
                            data-toggle="pill"
                            href="#v-pills-leaderboard"
                            role="tab"
                            aria-controls="v-pills-settings"
                            aria-selected="false"
                          >
                            Leader Board
                          </a>
                          {this.state.isRegistered ? (
                            <>
                              <a
                                className="nav-link mb-2"
                                style={{
                                  borderBottom: "solid 1px rgb(172 175 177)",
                                  borderRadius: "0px",
                                }}
                                id="v-pills-myTeam-tab"
                                data-toggle="pill"
                                href="#v-pills-myTeam"
                                role="tab"
                                aria-controls="v-pills-myTeam"
                                aria-selected="false"
                              >
                                {this.props.team.type === "business"
                                  ? "My Business Team"
                                  : "My Team"}
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div
                          className="notifications_wrapper"
                          style={{
                            position: "fixed",
                            zIndex: 2000,
                            top: "140px",
                            right: 0,
                          }}
                        >
                          <div
                            className="notification_container"
                            style={{
                              position: "relative",
                              right: "1rem",
                              width: "20rem",
                            }}
                          >
                            {this.state.notifications.map((notification, index) => (
                              <Toast
                                key={new Date().valueOf()}
                                onClose={() =>
                                  this.removeNotification(notification.id)
                                }
                                bg={notification.type || "light"}
                                show={notification.isActive}
                                autohide
                                delay={notification.delay}
                              >
                                <Toast.Header>
                                  {/* <img
                                    src="holder.js/20x20?text=%20"
                                    className="rounded mr-2"
                                    alt=""
                                  /> */}
                                  <strong id="title" className="mr-auto text-dark">
                                    {notification.title}
                                  </strong>
                                  <small id="time">{notification.time}</small>
                                </Toast.Header>
                                <Toast.Body id="body" className="text-dark">
                                  {notification.body}
                                </Toast.Body>
                              </Toast>
                            ))}
                          </div>
                        </div>
                        <div
                          className="tab-content text-muted  mt-md-0"
                          id="v-pills-tabContent"
                          style={{
                            borderLeft: "solid 1px rgb(172 175 177)",
                            padding: "0rem 1rem 1rem 1rem",
                            minWidth: "70vw",
                          }}
                        >
                          <div
                            className="tab-pane active show fade"
                            id="v-pills-description"
                            role="tabpanel"
                            aria-labelledby="v-pills-description-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2

                                  style={{
                                    fontSize: "29px",
                                    fontWeight: "bolder",
                                    textTransform: "uppercase",
                                  }}
                                  className="regular text-dark weight-600 caps inline-block float-left mr-3"
                                >
                                  Event overview
                                </h2>
                              </div>

                              {this.state.isLoaded ? (
                                <div
                                  className="row"
                                  style={{
                                    display: "block",
                                    fontFamily:
                                      "Ubuntu,sans-serif !important",
                                  }}
                                >
                                  {ReactHtmlParser(
                                    this.state.event.description
                                  )}
                                </div>
                              ) : (
                                <ContentSkeleton />
                              )}
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-rules"
                            role="tabpanel"
                            aria-labelledby="v-pills-rules-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  Event rules and legal
                                </h2>
                              </div>
                              {this.state.isLoaded ? (
                                <div
                                  className="row"
                                  style={{
                                    display: "block",
                                    fontFamily:
                                      "Ubuntu,sans-serif !important",
                                  }}
                                >
                                  {ReactHtmlParser(this.state.event.rules)}
                                </div>
                              ) : (
                                <ContentSkeleton />
                              )}
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="v-pills-teams"
                            role="tabpanel"
                            aria-labelledby="v-pills-teams-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  Teams
                                </h2>
                              </div>
                              <p className="row mb-4">
                                This section shows all registered teams, join a
                                team, get to know your teammates, share your
                                interests, and have fun.
                              </p>
                              {this.state.isTeamsLoaded ? (
                                <div className="row">
                                  {this.state.teams.length > 0 ? (
                                    this.state.teams.map((team, index) => (
                                      <div
                                        className="col-md-6"
                                        key={
                                          index + new Date().getMilliseconds()
                                        }
                                      >
                                        <div className="card">
                                          <p className="text-dark font-weight-bolder my-2 ml-4 font-size-16">
                                            {team.type == "business" ? (
                                              <i className="bx bxs-briefcase-alt-2 text-primary"></i>
                                            ) : (
                                              <i className="fa fa-users text-primary"></i>
                                            )}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {team.teamName}
                                          </p>
                                          <div className="table-responsive">
                                            <table className="table table-nowrap table-centered table-hover mb-0">
                                              <tbody>
                                                {team.members.map(
                                                  (member, index) => (
                                                    <tr
                                                      key={
                                                        index +
                                                        new Date()
                                                          .getMilliseconds
                                                      }
                                                    >
                                                      <td
                                                        style={{
                                                          width: "45px",
                                                          height: "45px",
                                                        }}
                                                      >
                                                        {member.githubData ? (
                                                          <img
                                                            className="rounded-circle avatar-sm"
                                                            src={
                                                              member.githubData
                                                                .avatar_url
                                                            }
                                                            style={{
                                                              width: "45px",
                                                              height: "45px",
                                                            }}
                                                            alt=""
                                                          ></img>
                                                        ) : (
                                                          <span
                                                            className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                            style={{
                                                              backgroundColor:
                                                                "#0a0b0e9e",
                                                              color:
                                                                "#ffffffa3!important",
                                                              width: "45px",
                                                              height: "45px",
                                                            }}
                                                          >

                                                            {member.name
                                                              .firstName[0] +
                                                              " " +
                                                              member.name
                                                                .lastName[0]}
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <h5 className="text-dark font-size-14 mb-1">
                                                          <a
                                                            href={
                                                              "/profile/" +
                                                              member._id
                                                            }
                                                            className="text-dark"
                                                          >
                                                            {member.name
                                                              .firstName +
                                                              " " +
                                                              member.name
                                                                .lastName}
                                                          </a>
                                                        </h5>
                                                        <small>
                                                          {member.titles.join(
                                                            ", "
                                                          )}
                                                        </small>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                                {this.fillOutMembers(
                                                  this.state.event.maxTeamSize -
                                                  team.members.length
                                                ).map((event) => {
                                                  return event;
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      className="card text-center"
                                      style={{
                                        backgroundColor: "#cec9c985",
                                        width: "50%",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <span style={{ lineHeight: "5rem" }}>
                                        NO REGISTERED TEAMS
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <TeamListSkeleton />
                              )}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="v-pills-prizes"
                            role="tabpanel"
                            aria-labelledby="v-pills-prizes-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  Prizes
                                </h2>
                              </div>
                              {this.state.isLoaded ? (
                                <div
                                  className="row"
                                  style={{
                                    display: "block",
                                    fontFamily:
                                      "Ubuntu,sans-serif !important",
                                  }}
                                >
                                  {ReactHtmlParser(this.state.event.prizes)}
                                  {/* <div className="dark large weight-400 medium-margin">Main Prizes</div> */}
                                  <div className="prize-container mt-3">
                                    <div className="prize-image align-center inline-block v-align-top">
                                      {/* <img src="https://he-s3.s3.amazonaws.com/media/sprint/code-ed/c45fe55Code_Ed_MainPrizesIcon.jpg?Signature=yHL8b5dm02uaJPadIzLi6Z2frio%3D&amp;Expires=1603024400&amp;AWSAccessKeyId=AKIA6I2ISGOYH7WWS3G5" /> */}
                                    </div>

                                    <div className="prize-desc medium-margin-left inline-block v-align-top">
                                      {/* <div className="large font-weight-bold dark">Winner </div>
                                          <div className="regular dark">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>100 USD</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit <strong>Certificate of Achievement</strong> for each member.</p>
                                          </div> */}
                                    </div>
                                    <div className="clear"></div>
                                  </div>
                                </div>
                              ) : (
                                <ContentSkeleton />
                              )}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="v-pills-leaderboard"
                            role="tabpanel"
                            aria-labelledby="v-pills-leaderboard-tab"
                          >
                            <div className="pl-4">
                              <div className="row">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  leaderboard
                                </h2>
                              </div>
                              {this.state.isLeaderBoardLoaded ? (
                                this.state.leaderboard.length > 0 ? (
                                  <>
                                    {/* TOP 3 TEAMS */}
                                    <div className="row">
                                      {this.state.leaderboard.length === 1 ? (
                                        <div className="col-sm-12 col-md-3 col-lg-3 text-center offset-md-4 offset-lg-4">
                                          {this.state.leaderboard[0].team
                                            .members[0].githubData ? (
                                            <img
                                              src={
                                                this.state.leaderboard[0].team
                                                  .members[0].githubData
                                                  .avatar_url
                                              }
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          ) : (
                                            <img
                                              src="/assets/images/users/avatar.png"
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          )}

                                          <p className="mb-0">RANK #1</p>
                                          <p className="mb-0">
                                            {
                                              this.state.leaderboard[0].team
                                                .teamName
                                            }
                                          </p>
                                          <p>
                                            {
                                              this.state.leaderboard[0]
                                                .totalScore
                                            }
                                            points
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="col-sm-12 col-md-3 col-lg-3 text-center order-md-2 order-lg-2">
                                          {this.state.leaderboard[0].team
                                            .members[0].githubData ? (
                                            <img
                                              src={
                                                this.state.leaderboard[0].team
                                                  .members[0].githubData
                                                  .avatar_url
                                              }
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          ) : (
                                            <img
                                              src="/assets/images/users/avatar.png"
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          )}
                                          <p className="mb-0">RANK #1</p>
                                          <p className="mb-0">
                                            {
                                              this.state.leaderboard[0].team
                                                .teamName
                                            }
                                          </p>
                                          <p>
                                            {
                                              this.state.leaderboard[0]
                                                .totalScore
                                            }
                                            points
                                          </p>
                                        </div>
                                      )}

                                      {this.state.leaderboard.length > 1 ? (
                                        <div
                                          className="col-sm-12 col-md-3 col-lg-3 text-center offset-lg-1 order-md-1 order-lg-1"
                                          style={{ marginTop: "5rem" }}
                                        >
                                          {this.state.leaderboard[1].team
                                            .members[0].githubData ? (
                                            <img
                                              src={
                                                this.state.leaderboard[1].team
                                                  .members[0].githubData
                                                  .avatar_url
                                              }
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          ) : (
                                            <img
                                              src="/assets/images/users/avatar.png"
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          )}
                                          <p className="mb-0">RANK #2</p>
                                          <p className="mb-0">
                                            {
                                              this.state.leaderboard[1].team
                                                .teamName
                                            }
                                          </p>
                                          <p>
                                            {
                                              this.state.leaderboard[1]
                                                .totalScore
                                            }
                                            points
                                          </p>
                                        </div>
                                      ) : null}
                                      {this.state.leaderboard.length > 2 ? (
                                        <div
                                          className="col-sm-12 col-md-3 col-lg-3 text-center order-md-3 order-lg-3"
                                          style={{ marginTop: "5rem" }}
                                        >
                                          {this.state.leaderboard[2].team
                                            .members[0].githubData ? (
                                            <img
                                              src={
                                                this.state.leaderboard[2].team
                                                  .members[0].githubData
                                                  .avatar_url
                                              }
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          ) : (
                                            <img
                                              src="/assets/images/users/avatar.png"
                                              alt=""
                                              className="rounded-circle avatar-lg mb-1"
                                              style={{
                                                background:
                                                  "rgb(158 158 158 / 77%)",
                                                width: "10rem",
                                                height: "10rem",
                                              }}
                                            ></img>
                                          )}
                                          <p className="mb-0">RANK #3</p>
                                          <p className="mb-0">
                                            {
                                              this.state.leaderboard[2].team
                                                .teamName
                                            }
                                          </p>
                                          <p>
                                            {
                                              this.state.leaderboard[2]
                                                .totalScore
                                            }
                                            points
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                                        <div
                                          className="table-responsive text-center"
                                          style={{
                                            border: "dashed 1px #80808061",
                                          }}
                                        >
                                          <table className="table table-centered table-nowrap table-hover">
                                            <thead className="thead-light">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  style={{ width: "70px" }}
                                                >
                                                  #
                                                </th>
                                                <th scope="col">Team</th>
                                                <th scope="col">Points</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.leaderboard.map(
                                                (item, idx) => (
                                                  <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td>
                                                      <h5 className="font-size-14 mb-1">
                                                        <a
                                                          href="#"
                                                          className="text-dark"
                                                        >
                                                          {item.team
                                                            ? item.team.teamName
                                                            : ""}
                                                        </a>
                                                      </h5>
                                                      {/* <small className="text-muted mb-0">Combined Rank Points: 1234</small> */}
                                                    </td>
                                                    <td>{item.totalScore}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="card text-center"
                                    style={{
                                      backgroundColor: "#cec9c985",
                                      width: "50%",
                                      margin: "5rem auto",
                                    }}
                                  >
                                    <span style={{ lineHeight: "5rem" }}>
                                      NO REGISTERED QUALIFYING TEAMS
                                    </span>
                                  </div>
                                )
                              ) : (
                                <ContentSkeleton />
                              )}
                            </div>
                          </div>

                          {/* JUDGES */}
                          <div
                            className="tab-pane fade pl-4"
                            id="v-pills-judges"
                            role="tabpanel"
                            aria-labelledby="v-pills-judges-tab"
                          >
                            <div className="row mb-4">
                              <h2
                                style={{
                                  fontSize: "29px",
                                  textTransform: "uppercase",
                                }}
                                className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                              >
                                JUDGES &amp; MODERATORS
                              </h2>
                            </div>

                            <div className="row">
                              {this.state.isLoaded && this.state.event.judges
                                ? this.state.event.judges.map((judge) => (
                                  <div
                                    key={judge._id}
                                    className="col-sm-12 col-md-4 col-lg-4 col-xl-4"
                                  >
                                    <div
                                      className="text-center"
                                      style={{
                                        width: "70%",
                                        margin: "0 auto",
                                      }}
                                    >
                                      {/* center profile image */}
                                      <img
                                        src={
                                          judge.image
                                            ? judge.image.data
                                            : "/assets/images/users/avatar.png"
                                        }
                                        alt=""
                                        className="rounded-circle avatar-lg mb-1"
                                        style={{
                                          background:
                                            "rgb(158 158 158 / 77%)",
                                          width: "8rem",
                                          height: "8rem",
                                          objectFit: "cover",
                                        }}
                                      ></img>
                                      {/* full name */}
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "1rem",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {judge.name}
                                      </p>
                                      {/* title */}
                                      <p style={{ marginBottom: "0px" }}>
                                        {judge.title}
                                      </p>
                                      {/* email */}
                                      <p>{judge.email}</p>
                                      <p
                                        className="text-muted"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "lighter",
                                          WebkitLineClamp: 15,
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {judge.description}
                                      </p>
                                    </div>
                                  </div>
                                ))
                                : null}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="v-pills-faqs"
                            role="tabpanel"
                            aria-labelledby="v-pills-faqs-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  Event FAQs
                                </h2>
                              </div>
                              {this.state.isLoaded ? (
                                <div
                                  className="row"
                                  style={{
                                    display: "block",
                                    fontFamily:
                                      "Ubuntu,sans-serif !important",
                                  }}
                                >
                                  {ReactHtmlParser(this.state.event.faqs)}
                                  {/* {this.state.event.faqs === "" ? "hg" : "kk"} */}
                                </div>
                              ) : (
                                <ContentSkeleton />
                              )}
                            </div>
                          </div>

                          {this.state.isRegistered ? <div
                            className="tab-pane fade"
                            id="v-pills-discussions"
                            role="tabpanel"
                            aria-labelledby="v-pills-discussions-tab"
                          >
                            <div className="pl-4">
                              <div className="row mb-4">
                                <h2
                                  style={{
                                    fontSize: "29px",
                                    textTransform: "uppercase",
                                  }}
                                  className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                >
                                  EVENT DISCUSSION BOARD
                                </h2>
                                {this.state.user && this.state.isRegistered ? <button
                                  ref="replyEventCommentButton"
                                  className="btn text-dark ml-auto"
                                  data-toggle="modal"
                                  data-target="#startNewDiscussionModal"
                                  style={{
                                    float: "right",
                                    borderRadius: "5px",
                                    padding: "0.77rem 1rem",
                                  }}
                                  onClick={() => { window.$(".modal-backdrop").css("display", "block !important") }}
                                >
                                  <i className="fa fa-paper-plane fa-sm"></i> Start a New Discussion
                                </button> : ""}
                              </div>
                              {this.state.isFocusDiscussionSet ? (
                                <div className="col-md-8 mx-auto px-0 mt-3">
                                  <div className="row">
                                    <div style={{ width: "100%" }}>
                                      <div className="d-flex" style={{ alignItems: "center" }}> {this.state.focusEventDiscussion
                                        .author.githubData ? (
                                        <img
                                          className="rounded-circle"
                                          src={
                                            this.state.focusEventDiscussion
                                              .author.githubData
                                              .avatar_url
                                          }
                                          alt="profile picture"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            this.state.focusEventDiscussion
                                              .author.githubData
                                              ? this.state.focusEventDiscussion
                                                .author.githubData
                                                .public_repos +
                                              " public projects on github"
                                              : "0 public projects"
                                          }
                                          onClick={() =>
                                          (window.location.href = `https://skillsranker.bih.co.bw/profile/${this.state.focusEventDiscussion
                                            .author._id}`)
                                          }
                                          style={{
                                            height: "40px",
                                            width: "40px",
                                            cursor: "pointer",
                                          }}
                                        ></img>
                                      ) : (
                                        <span
                                          className="avatar-title rounded-circle font-size-16 p-1 mr-3"
                                          style={{
                                            backgroundColor: "#0a0b0e9e",
                                            color: "#ffffffa3!important",
                                            objectFit: "cover",
                                            cursor: "pointer",
                                            height: "40px",
                                            width: "40px",
                                          }}
                                          onClick={() =>
                                          (window.location.href = `https://skillsranker.bih.co.bw/profile/${this.state.focusEventDiscussion
                                            .author._id}`)
                                          }
                                        >

                                          {this.state.focusEventDiscussion
                                            .author.name
                                            .firstName[0] +
                                            " " +
                                            this.state.focusEventDiscussion
                                              .author.name
                                              .lastName[0]}
                                        </span>
                                      )}   <h5
                                        className="m-0 mr-4 mb-0"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                          {this.state.focusEventDiscussion
                                            .author.name.firstName +
                                            " " +
                                            this.state.focusEventDiscussion
                                              .author.name.lastName}
                                        </h5>
                                      </div>
                                      <h6
                                        className="m-0 mt-3 text-dark"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {this.state.focusEventDiscussion.title}
                                      </h6>


                                      <div style={{ display: "flex" }}>
                                        <p className="mr-4 mb-0 text-muted small
                                      d-block">
                                          {formatDate(
                                            this.state.focusEventDiscussion
                                              .createdAt
                                          )}
                                        </p>
                                        <div className="ml-auto d-flex">
                                          {/* <p className="mb-0 mr-3">
                                            {this.state.focusEventDiscussion.likes.length}
                                            <i className="bx bx-like"></i>
                                          </p> */}
                                          {/* <p className="mb-0 mr-3">
                                        {
                                          this.state.focusEventDiscussion
                                            .dislikes
                                        }
                                        <i className="bx bx-dislike"></i>
                                      </p> */}
                                          <p className="mr-3 mb-0">
                                            {
                                              this.props.comments.length
                                            }
                                            <i className="bx bx-comment-dots"></i>
                                          </p>

                                          <p className="mb-0 mr-4 text-info small text" onClick={() => this.report({ type: "discussion", id: this.state.focusEventDiscussion._id })}
                                            style={{ cursor: "pointer" }}>
                                            report
                                          </p>
                                        </div>
                                      </div>
                                      <div className="mt-2">
                                        <MDEditor.Markdown
                                          source={
                                            this.state.focusEventDiscussion
                                              .content
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row my-3 d-block">
                                    <div className=" px-0 mx-0">
                                      {
                                        this.state.isRegistered &&
                                          this.state.focusEventDiscussion.isOpen ? (

                                          <div>
                                            <MDEditor
                                              value={this.state.discussionReply}
                                              hideToolbar={true}
                                              preview="edit"
                                              onChange={this.setReply}
                                            />
                                            <button
                                              ref="teamEventCommentButton"
                                              onClick={() => this.sendEventComment()}
                                              className="mt-3 btn px-4 py-3"
                                              style={{ float: "right", }}
                                            >
                                              {
                                                this.state.isSubmittingEventComment ?
                                                  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                                  <></>
                                              }
                                              &nbsp; Reply
                                            </button>
                                          </div>

                                        ) : !this.state.event
                                          .isRegistrationClosed &&
                                          this.state.isRegistered &&
                                          this.state.focusEventDiscussion.isOpen ? (
                                          <button
                                            className="mt-3 btn px-4 py-3"
                                            style={{ float: "right" }}
                                          >
                                            Join Discussion
                                          </button>
                                        ) : (
                                          <h4 className="text-center">Conversation closed</h4>
                                        )}
                                    </div>
                                  </div>

                                  {this.state.isLoadingDiscussionComments ? (
                                    <><div className="text-center text-muted" style={{ fontFamily: "monospace" }}>loading comments ...</div></>
                                  ) : (
                                    <ul className="p-0 mt-5 pt-3">
                                      {this.props.comments.map(
                                        (comment) => (
                                          <li
                                            key={comment._id}
                                            className="row mt-3 pb-3"
                                            style={{
                                              borderBottom: "solid 1px #b9b7b7 ",
                                            }}
                                          >
                                            <div
                                              className="d-flex">
                                              <div className="avatar">
                                                {comment.author.githubData ? (
                                                  <img
                                                    className="rounded-circle"
                                                    src={
                                                      comment.author.githubData
                                                        .avatar_url
                                                    }
                                                    alt="profile picture"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      comment.author.githubData
                                                        ? comment.author.githubData
                                                          .public_repos +
                                                        " public projects on github"
                                                        : "0 public projects"
                                                    }
                                                    onClick={() =>
                                                      (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                    }
                                                    style={{
                                                      height: "40px",
                                                      width: "40px",
                                                      cursor: "pointer",
                                                    }}
                                                  ></img>
                                                ) : (
                                                  <span
                                                    className="avatar-title rounded-circle font-size-16 mr-3"
                                                    style={{
                                                      backgroundColor: "#0a0b0e9e",
                                                      color: "#ffffffa3!important",
                                                      objectFit: "cover",
                                                      cursor: "pointer",
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                    onClick={() =>
                                                      (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                    }
                                                  >

                                                    {comment.author.name
                                                      .firstName[0] +
                                                      " " +
                                                      comment.author.name
                                                        .lastName[0]}
                                                  </span>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  marginLeft: "0.5em",
                                                }}
                                              >
                                                <h5
                                                  className="m-0"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {comment.author.name.firstName +
                                                    " " +
                                                    comment.author.name.lastName}
                                                </h5>
                                                <div style={{ display: "flex" }}>
                                                  <p className="mr-4 mb-0 small text-muted">
                                                    {formatDate(
                                                      comment.createdAt
                                                    )}
                                                  </p>
                                                  {/* <p className="mb-0 mr-3">
                                                    {comment.likes ? comment.likes.count : 0}
                                                    <i className="bx bx-like"></i>
                                                  </p> */}
                                                  {/* <p className="mb-0 mr-3">
                                                {comment.dislikes}
                                                <i className="bx bx-dislike"></i>
                                              </p> */}

                                                  <p className="mb-0 mr-4 text-info small text" onClick={() => this.report({ type: "comment", id: comment._id })}
                                                    style={{ cursor: "pointer" }}>
                                                    report
                                                  </p>
                                                </div>
                                                <div>
                                                  <MDEditor.Markdown className="m-0 p-0"
                                                    source={comment.content}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </div>
                              ) : (
                                <>
                                  {this.state.isRegistered ? (
                                    <div className="row mb-3">
                                      <div className="col-12">
                                        <div
                                          className="modal fade"
                                          data-backdrop="static" data-keyboard="false"
                                          id="startNewDiscussionModal"
                                          tabIndex="-1"
                                          aria-labelledby="startNewDiscussionLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog modal-xl modal-dialog-centered">
                                            <div className="modal-content">

                                              <div className="modal-body">
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span aria-hidden="true">
                                                    &times;
                                                  </span>
                                                </button>
                                                <div className="p-5">
                                                  <h1 className="text-dark">Create Discussion</h1>
                                                  <p className="text-muted">Please complete the fields below to create a new discussion
                                                  </p>
                                                  <div className="row">
                                                    <div className="form-group col-md-6">
                                                      <div>Title</div>
                                                      <input className="form-control" onChange={(e) => this.state.discussionTitle = (e.target.value)} />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                      <div>Content</div>
                                                      <textarea className="form-control" rows="4" onChange={(e) => this.state.discussionContent = (e.target.value)}>

                                                      </textarea>
                                                      <div className="btn py-3 px-4 my-4" onClick={() => this.submitEventDiscussion()}>Post Discussion</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {this.props.eventDiscussions.length > 0 ? (
                                    <ul className="px-0">
                                      {this.props.eventDiscussions.map(
                                        (discussion) => (
                                          <li
                                            key={discussion._id}
                                            className="row mb-4 pb-2 px-0 mx-0"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            <div className="col-md-8 mx-auto card p-4 pb-2"
                                              onClick={() =>
                                                this.selectDiscussion(
                                                  discussion._id
                                                )
                                              }>
                                              <div className="row p-0 m-0 mb-3" style={{ alignItems: "center" }}>

                                                {discussion.author === null ? "" : discussion.author.githubData ? (
                                                  <img
                                                    className="rounded-circle avatar-sm mr-1 px-0 mx-0"
                                                    src={
                                                      discussion.author !== null ? discussion.author.githubData
                                                        .avatar_url : ""
                                                    }
                                                    alt="profile picture"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      discussion.author && discussion.author.githubData
                                                        ? discussion.author
                                                          .githubData
                                                          .public_repos +
                                                        " public projects on github"
                                                        : "0 public projects"
                                                    }
                                                    onClick={() =>
                                                      (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                    }
                                                    style={{
                                                      height: "50px",
                                                      width: "50px",
                                                      cursor: "pointer",
                                                    }}
                                                  ></img>
                                                ) : (
                                                  discussion.author ? <span
                                                    className="avatar-title rounded-circle mr-1 text-light"
                                                    style={{
                                                      backgroundColor: "#0a0b0e9e",
                                                      objectFit: "cover",
                                                      cursor: "pointer",
                                                      height: "50px",
                                                      width: "50px",
                                                    }}
                                                    onClick={() =>
                                                      (window.location.href = `https://skillsranker.bih.co.bw/profile/${discussion.author._id}`)
                                                    }
                                                  >

                                                    {discussion.author.name
                                                      .firstName[0] +
                                                      " " +
                                                      discussion.author.name
                                                        .lastName[0]}
                                                  </span> : ""
                                                )}
                                                <h5 className="ml-2 mb-0">

                                                  {discussion.author ? discussion.author.name
                                                    .firstName +
                                                    " " +
                                                    discussion.author.name
                                                      .lastName : ""}

                                                </h5>
                                              </div>
                                              <h5
                                                className="m-0 p-0 text-dark"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {discussion.title}
                                              </h5>
                                              <p
                                                className="m-0 p-0 text-dark"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {discussion.content}
                                              </p>
                                              <hr className="my-0 py-0 mb-2" />
                                              <div className=" p-0 m-0"
                                              >
                                                <div style={{ display: "flex" }}>

                                                  <p className="ml-0 mb-0 text-muted small">
                                                    {formatDate(
                                                      discussion.createdAt
                                                    )}
                                                  </p>
                                                  <div className="ml-auto d-flex">
                                                    {/* <p className="mb-0 mr-3" style={{ color: "red !important" }}>
                                                    {discussion.likes.length}
                                                    <i className="bx bx-like"></i>
                                                  </p> */}
                                                    {/* <p className="mb-0 mr-3">
                                                {discussion.dislikes}
                                                <i className="bx bx-dislike"></i>
                                              </p> */}
                                                    <button className="mb-0 btn p-2 m-0" style={{ color: "blue !important" }}>
                                                      {discussion.comments.count}
                                                      <i className="bx bx-comment-dots px-1 m-0"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <h5 className="p-5 mx-auto text-center text-info">no discussion yet</h5>
                                  )}
                                </>
                              )}
                            </div>
                          </div> : <></>}

                          <div
                            className="tab-pane fade pl-4"
                            id="v-pills-myTeam"
                            role="tabpanel"
                            aria-labelledby="v-pills-myTeam-tab"
                          >
                            {this.state.hasConsented ? (
                              <>
                                <div className="row mb-4">
                                  <h2
                                    style={{
                                      fontSize: "29px",
                                      textTransform: "uppercase",
                                    }}
                                    className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                  >
                                    {this.state.team.teamName}
                                  </h2>
                                </div>

                                <div
                                  className="card-body pl-0 pr-0"
                                  style={{ border: "1px dashed #7a7a7a" }}
                                >
                                  {/* <!-- Nav tabs --> */}
                                  <ul
                                    className="pl-0 nav nav-tabs nav-tabs-custom nav-justified"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#teammembers"
                                        role="tab"
                                      >
                                        <span className="d-block d-sm-none">
                                          <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                          Team Members
                                        </span>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#teamdiscussion"
                                        role="tab"
                                      >
                                        <span className="d-block d-sm-none">
                                          <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                          Team Discussion Board
                                        </span>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#teamsubmissions"
                                        role="tab"
                                      >
                                        <span className="d-block d-sm-none">
                                          <i className="far fa-envelope"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                          Submissions
                                        </span>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#teamrequests"
                                        role="tab"
                                      >
                                        <span className="d-block d-sm-none">
                                          <i className="fas fa-cog"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                          Team Requests
                                        </span>
                                      </a>
                                    </li>
                                    {/* <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#teamnotifications" role="tab">
                                                    <span className="d-block d-sm-none"><i className="fas fa-cog"></i></span>
                                                    <span className="d-none d-sm-block">Notifications</span>    
                                                </a>
                                            </li> */}
                                  </ul>

                                  {/* <!-- Tab panes --> */}
                                  <div className="tab-content p-3 text-muted">
                                    <div
                                      className="tab-pane active"
                                      id="teammembers"
                                      role="tabpanel"
                                    >
                                      <div className="row">
                                        {this.state.team.isCompliant ? (
                                          <></>
                                        ) : (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                cursor: "pointer",
                                                cursor: "pointer",
                                                backgroundColor: "#ff0000bd",
                                                minHeight: "60px",
                                                border: "dashed 1px",
                                                padding: "0 5px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  color: "white",
                                                  alignItems: "center",
                                                  height: "58px",
                                                  display: "flex",
                                                }}
                                                className="mb-0 pb-0"
                                              >
                                                <i
                                                  style={{
                                                    marginRight: "10px",
                                                    marginLeft: "10px",
                                                    fontSize: "20px"
                                                  }}
                                                  className="bx bx-info-circle"
                                                ></i>
                                                Team is currently not complaint.
                                                The compliance team will verify
                                                if you are compliant prior the
                                                commencement of hacking and
                                                advise. Make sure your team
                                                satisfy the eligibility criteria
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                        <div className="col-7 pl-0">
                                          {/* memebers table */}
                                          <div className="table-responsive">
                                            <table className="table table-centered table-nowrap table-hover">
                                              <thead className="thead-light">
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    style={{ width: "70px" }}
                                                  >
                                                    #
                                                  </th>
                                                  <th scope="col">Name</th>
                                                  <th scope="col">Email</th>
                                                  {/* <th scope="col">Points</th> */}
                                                  <th scope="col">Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.team.members ? (
                                                  this.state.team.members.map(
                                                    (member) =>
                                                      this.state.team.type ===
                                                        "business" ? (
                                                        <tr key={member._id}>
                                                          <td>
                                                            <div className="avatar-xs">
                                                              {member.githubData ? (
                                                                <img
                                                                  className="rounded-circle avatar-sm ml-1"
                                                                  src={
                                                                    member
                                                                      .githubData
                                                                      .avatar_url
                                                                  }
                                                                  alt="profile picture"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title={
                                                                    member.githubData
                                                                      ? member
                                                                        .githubData
                                                                        .public_repos +
                                                                      " public projects on github"
                                                                      : "0 public projects"
                                                                  }
                                                                  onClick={() =>
                                                                    (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                  }
                                                                  style={{
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                ></img>
                                                              ) : (
                                                                <span
                                                                  className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#0a0b0e9e",
                                                                    color:
                                                                      "#ffffffa3!important",
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                    objectFit:
                                                                      "cover",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title={
                                                                    member.githubData
                                                                      ? member
                                                                        .githubData
                                                                        .public_repos +
                                                                      " public projects on github"
                                                                      : "0 public projects"
                                                                  }
                                                                  onClick={() =>
                                                                    (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                  }
                                                                >

                                                                  {member.name
                                                                    .firstName[0] +
                                                                    " " +
                                                                    member.name
                                                                      .lastName[0]}
                                                                </span>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <h5 className="font-size-14 mb-1">
                                                              <a
                                                                href="#"
                                                                className="text-dark"
                                                              >
                                                                {member.name
                                                                  .firstName +
                                                                  " " +
                                                                  member.name
                                                                    .lastName}
                                                              </a>
                                                            </h5>
                                                            {/* <p className="text-muted mb-0">Full Stack Developer</p> */}
                                                          </td>
                                                          <td>
                                                            {member.email}
                                                          </td>

                                                          {/* <td>
                                                                        125
                                                                    </td> */}
                                                          <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0 pl-0">
                                                              {member._id ==
                                                                this.state
                                                                  .user ? (
                                                                <li
                                                                  className="list-inline-item px-2"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.leaveTeam(
                                                                      member._id
                                                                    )
                                                                  }
                                                                >
                                                                  <span
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Remove"
                                                                  >
                                                                    <i className="bx bx-user-minus"></i>
                                                                  </span>
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </ul>
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <tr>
                                                          <td>
                                                            <div className="avatar-xs">
                                                              {member.githubData ? (
                                                                <img
                                                                  className="rounded-circle avatar-sm ml-1"
                                                                  src={
                                                                    member
                                                                      .githubData
                                                                      .avatar_url
                                                                  }
                                                                  alt="profile picture"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title={
                                                                    member.githubData
                                                                      ? member
                                                                        .githubData
                                                                        .public_repos +
                                                                      " public projects on github"
                                                                      : "0 public projects"
                                                                  }
                                                                  onClick={() =>
                                                                    (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                  }
                                                                  style={{
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                ></img>
                                                              ) : (
                                                                <span
                                                                  className="avatar-title rounded-circle font-size-13 p-1 ml-1"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#0a0b0e9e",
                                                                    color:
                                                                      "#ffffffa3!important",
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                    objectFit:
                                                                      "cover",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title={
                                                                    member.githubData
                                                                      ? member
                                                                        .githubData
                                                                        .public_repos +
                                                                      " public projects on github"
                                                                      : "0 public projects"
                                                                  }
                                                                  onClick={() =>
                                                                    (window.location.href = `https://skillsranker.bih.co.bw/profile/${member._id}`)
                                                                  }
                                                                >

                                                                  {member.name
                                                                    .firstName[0] +
                                                                    " " +
                                                                    member.name
                                                                      .lastName[0]}
                                                                </span>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <h5 className="font-size-14 mb-1">
                                                              <a
                                                                href="#"
                                                                className="text-dark"
                                                              >
                                                                {member.name
                                                                  .firstName +
                                                                  " " +
                                                                  member.name
                                                                    .lastName}
                                                              </a>
                                                            </h5>
                                                            {/* <p className="text-muted mb-0">Full Stack Developer</p> */}
                                                          </td>
                                                          <td>
                                                            {member.email}
                                                          </td>

                                                          {/* <td>
                                                                        125
                                                                    </td> */}
                                                          <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0 pl-0">
                                                              {member._id ==
                                                                this.state.team
                                                                  .owner ? (
                                                                <>
                                                                  <li
                                                                    className="list-inline-item px-2"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.removeTeamMember(
                                                                        member._id
                                                                      )
                                                                    }
                                                                  >
                                                                    <span
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Remove"
                                                                    >
                                                                      <i className="bx bx-user-minus"></i>
                                                                    </span>
                                                                  </li>
                                                                  <li
                                                                    className="list-inline-item px-2"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      this.makeTeamLeader(
                                                                        member._id
                                                                      )
                                                                    }
                                                                  >
                                                                    <span
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Make team leader"
                                                                    >
                                                                      <i className="bx bx-rocket"></i>
                                                                    </span>
                                                                  </li>
                                                                </>
                                                              ) : member._id ==
                                                                this.state
                                                                  .user ? (
                                                                <li
                                                                  className="list-inline-item px-2"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    this.leaveTeam(
                                                                      member._id
                                                                    )
                                                                  }
                                                                >
                                                                  <span
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Remove"
                                                                  >
                                                                    <i className="bx bx-user-minus"></i>
                                                                  </span>
                                                                </li>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </ul>
                                                          </td>
                                                        </tr>
                                                      )
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </tbody>
                                            </table>

                                            {/* <TeamRequests /> */}

                                          </div>
                                        </div>
                                        <div className="col-5 pl-0 text-center">
                                          {this.state.event
                                            .isRegistrationClosed ? (
                                            <></>
                                          ) : (
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#member-invite-modal"
                                              className="btn mb-4"
                                              style={{ padding: "15px 25px" }}
                                            >
                                              Send Team Invite
                                            </button>
                                          )}
                                          <div
                                            className="modal fade bs-example-modal-center"
                                            id="member-invite-modal"
                                            style={{
                                              backgroundColor: "#00000080",
                                            }}
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="mySmallModalLabel"
                                            aria-hidden="true"
                                          >
                                            <div className="modal-dialog modal-dialog-centered">
                                              <div className="modal-content">
                                                <div className="modal-header text-left">
                                                  <h5 className="modal-title mt-0">
                                                    Invite Paticipant
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                  >
                                                    <span aria-hidden="true">
                                                      &times;
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <form
                                                    className="form-horizontal"
                                                    onSubmit={(e) =>
                                                      this.initTeamInvite(e)
                                                    }
                                                  >
                                                    <div className="row form-group">
                                                      <div className="col-12">
                                                        <label>Email</label>
                                                        <input
                                                          onChange={(e) =>
                                                            this.setState({
                                                              inviteEmail:
                                                                e.target.value,
                                                            })
                                                          }
                                                          value={
                                                            this.state
                                                              .inviteEmail
                                                          }
                                                          className="form-control"
                                                          type="email"
                                                          placeholder="Email"
                                                          required
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="row form-group">
                                                      <div className="col-12">
                                                        <button
                                                          className="btn btn-primary btn-block"
                                                          style={{
                                                            backgroundColor:
                                                              "#e2b537",
                                                            color: "black",
                                                            borderColor:
                                                              "#e2b537",
                                                            fontWeight: "bold",
                                                          }}
                                                          type="submit"
                                                        >
                                                          Send Invitation
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <h6>Combined Platform Points</h6>
                                          <p
                                            style={{
                                              fontSize: "4vw",
                                              letterSpacing: "5px",
                                              marginBottom: "3rem",
                                              marginTop: "3rem",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {this.state.team.totalRank}
                                          </p>
                                        </div>
                                      </div>
                                      {/* team stats */}
                                      <div className="row">
                                        <div className="col-12">
                                          <h6>Skills Distribution</h6>
                                          <div>
                                            {this.state.team.members !==
                                              undefined ? (
                                              <div
                                                style={{
                                                  width: "400px",
                                                  height: "400px",
                                                }}
                                              >
                                                {/* {console.log(this.handleSkills(this.state.team.members))}; */}
                                                <ResponsiveRadar
                                                  data={this.handleSkills(
                                                    this.state.team.members
                                                  )}
                                                  keys={["value"]}
                                                  indexBy="label"
                                                  maxValue={
                                                    this.state.team.members
                                                      .length
                                                  }
                                                  margin={{
                                                    top: 70,
                                                    right: 80,
                                                    bottom: 40,
                                                    left: 80,
                                                  }}
                                                  curve="linearClosed"
                                                  borderWidth={2}
                                                  borderColor={{
                                                    from: "color",
                                                  }}
                                                  gridLevels={5}
                                                  gridShape="circular"
                                                  gridLabelOffset={36}
                                                  enableDots={true}
                                                  dotSize={10}
                                                  dotColor={{
                                                    theme: "background",
                                                  }}
                                                  dotBorderWidth={2}
                                                  dotBorderColor={{
                                                    from: "color",
                                                  }}
                                                  enableDotLabel={true}
                                                  dotLabel="value"
                                                  dotLabelYOffset={-12}
                                                  colors={{ scheme: "nivo" }}
                                                  fillOpacity={0.25}
                                                  blendMode="multiply"
                                                  animate={true}
                                                  motionConfig="wobbly"
                                                  isInteractive={true}
                                                />
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      <div
                                        className="tab-pane"
                                        id="teamdiscussion"
                                        role="tabpanel"
                                      >
                                        <div className="container">

                                          <div className="row">
                                            <div>
                                              <h4
                                                className="m-0 text-dark"
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {
                                                  this.state.team === null ?
                                                    this.state.team.discussion
                                                      .title :
                                                    ""
                                                }
                                              </h4>
                                              {/* <div style={{ display: "flex" }}>
                                                <p className="mr-4 mb-0">
                                                  {
                                                    this.state.team.discussion
                                                      .createdAt
                                                  }
                                                </p>
                                                <p className="mr-3 mb-0">
                                                  {
                                                    this.state
                                                      .focusEventDiscussion
                                                      .commentsLength
                                                  }
                                                  <i className="bx bx-comment-dots"></i>
                                                </p>
                                              </div> */}
                                              <div className="mt-4">
                                                <MDEditor.Markdown
                                                  source={
                                                    this.state.team.discussion !== null &&
                                                      typeof this.state.team.discussion !== 'undefined' ?
                                                      this.state.team.discussion.content : ""
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          {/* TEAM DISCUSSIONS SECTION */}
                                          <div className="row my-5">
                                            <div className="col-lg-12">
                                              {
                                                // !this.state.team.discussion ? "" : this.state.team.discussion
                                                //   .isOpen ? (
                                                <div>
                                                  <MDEditor
                                                    value={
                                                      this.state
                                                        .teamDiscussionReply
                                                    }
                                                    hideToolbar={true}
                                                    preview="edit"
                                                    onChange={this.setTeamReply}
                                                  />
                                                  <button
                                                    ref="teamReplyButton"
                                                    onClick={() => this.sendTeamComment()}
                                                    className="mt-3 btn px-4 py-3"
                                                    style={{ float: "right" }}
                                                  >{
                                                      this.state.isSubmittingTeamComment ?
                                                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                                        <></>
                                                    }
                                                    &nbsp;
                                                    Reply
                                                  </button>
                                                </div>
                                                // ) : (
                                                //   <h4 className="text-center">Conversation closed</h4>
                                                // )
                                              }
                                            </div>
                                          </div>
                                          {<ul className="p-0">
                                            {typeof this.state.team.discussion === "undefined" ||
                                              this.state.team.discussion === null ? <></> :
                                              this.state.team.discussion.comments.ids.map(
                                                (comment) => {
                                                  return comment.author ? (
                                                    <li
                                                      key={comment._id}
                                                      className="d-flex mt-3 ml-5 mr-5 pb-3"
                                                      style={{
                                                        borderBottom:
                                                          "solid 1px #b9b7b7 ",
                                                      }}
                                                    >
                                                      <div className="avatar-sm m-1">
                                                        {comment.author && comment.author
                                                          .githubData ? (
                                                          <img
                                                            className="rounded-circle avatar-sm mr-3"
                                                            src={
                                                              comment.author
                                                                .githubData
                                                                .avatar_url
                                                            }
                                                            alt="profile picture"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title={
                                                              comment.author
                                                                .githubData
                                                                ? comment.author
                                                                  .githubData
                                                                  .public_repos +
                                                                " public projects on github"
                                                                : "0 public projects"
                                                            }
                                                            onClick={() =>
                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                            }
                                                            style={{
                                                              height: "40px",
                                                              width: "40px",
                                                              cursor: "pointer",
                                                            }}
                                                          ></img>
                                                        ) : (
                                                          <span
                                                            className="avatar-title rounded-circle font-size-16 p-1 mr-3"
                                                            style={{
                                                              backgroundColor:
                                                                "#0a0b0e9e",
                                                              color:
                                                                "#ffffffa3!important",
                                                              objectFit: "cover",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              (window.location.href = `https://skillsranker.bih.co.bw/profile/${comment.author._id}`)
                                                            }
                                                          >

                                                            {comment.author.name
                                                              .firstName[0] +
                                                              " " +
                                                              comment.author.name
                                                                .lastName[0]}
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          margin: "auto",
                                                          marginLeft: "0.5em",
                                                        }}
                                                      >
                                                        <a href="">
                                                          <h5
                                                            className="m-0  text-dark"
                                                            style={{
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                          >
                                                            {comment.author.name
                                                              .firstName +
                                                              " " +
                                                              comment.author.name
                                                                .lastName}
                                                          </h5>
                                                        </a>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <p className="mr-4 mb-0 small text-muted">
                                                            {formatDate(comment.createdAt)}
                                                          </p>
                                                          {/* <p className="mb-0 mr-3">
                                                              {comment.likes ? comment.likes.count : 0}
                                                              <i className="bx bx-like"></i>
                                                            </p> */}
                                                          {/* <p className="mb-0 mr-3">
                                                            {comment.dislikes}
                                                            <i className="bx bx-dislike"></i>
                                                          </p> */}

                                                          <p className="mb-0 mr-4 text-info small text" onClick={() => this.report({ type: "comment", id: comment._id })}
                                                            style={{ cursor: "pointer" }}>
                                                            report
                                                          </p>

                                                        </div>
                                                        <div className="col-md-12 mt-0 mb-0 px-0 pt-0">
                                                          <MDEditor.Markdown
                                                            source={comment.content}
                                                          />
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                    :
                                                    <>
                                                    </>
                                                }
                                              )}
                                          </ul>}
                                          {/* {!this.state.team.discussion || !this.state.team.discussion
                                            .isOpen ? "" : this.state
                                              .isLoadingDiscussionComments ?
                                            < ><div className="text-center text-muted" style={{ fontFamily: "monospace" }}>loading comments ...</div></>
                                            : (
                                              
                                            )} */}
                                        </div>
                                      </div>
                                    }
                                    <div
                                      className="tab-pane"
                                      id="teamsubmissions"
                                      role="tabpanel"
                                    >
                                      <div className="row">
                                        {this.state.team.isSuspended ? (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                backgroundColor: "transparent",
                                                minHeight: "90px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <h3 className="mt-3">
                                                SUSPENDED
                                              </h3>
                                              <p>
                                                Unfortunatly your team has been
                                                suspended from the event
                                              </p>
                                              <p>
                                                You or a member of your team
                                                have probably violated the
                                                event's rules, terms and
                                                condtions. Please review the
                                                event's rules, terms and
                                                conditons
                                              </p>
                                              <p className="text-muted">
                                                Feel free to contact support for
                                                help.
                                              </p>
                                            </div>
                                          </div>
                                        ) : this.state.team.isEliminated ? (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                backgroundColor: "transparent",
                                                minHeight: "90px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <h3 className="mt-3">
                                                ELIMINATED
                                              </h3>
                                              <p>
                                                Unfortunatly your team has been
                                                ELIMINATED from the event
                                              </p>
                                              <p>
                                                Keep innovating and better luck
                                                next time, hope you had fun.
                                              </p>
                                            </div>
                                          </div>
                                        ) : moment(this.state.event.startDate) <
                                          moment() &&
                                          moment(this.state.event.endDate) >
                                          moment() ? (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                cursor: "pointer",
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                                minHeight: "60px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  transform: "translateY(50%)",
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    marginRight: "20px",
                                                    fontSize: "20px",
                                                  }}
                                                  className="bx bx-info-circle"
                                                ></i>
                                                Before you upload your
                                                submission. Please zip your
                                                files into one file. Only .zip
                                                files with a maximum file size
                                                of 300MB are allowed.
                                              </p>
                                            </div>
                                            <div
                                              className="card text-center"
                                              data-toggle="modal"
                                              data-target="#attachFileModal"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "#e1dcc9",
                                                minHeight: "90px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  transform: "translateY(40%)",
                                                  alignItems: "center",
                                                  display: "grid",
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    marginRight: "20px",
                                                    fontSize: "20px",
                                                  }}
                                                  className="bx bx-upload"
                                                ></i>
                                                <span className="font-weight-bolder">
                                                  Upload New Submission</span>
                                              </p>
                                            </div>
                                            <div
                                              className="table-responsive"
                                              style={{
                                                border: "1px solid #80808087",
                                              }}
                                            >
                                              <table
                                                className="table card table-nowrap table-centered mb-0 box-shadow"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                }}
                                              >
                                                <thead
                                                  className="font-weight-bold"
                                                  style={{
                                                    backgroundColor:
                                                      "#cec9c985",
                                                  }}
                                                >
                                                  <tr>
                                                    <th
                                                      className="p-4 text-uppercase"
                                                      style={{
                                                        borderBottom: "0px",
                                                        width: "45px",
                                                      }}

                                                    >
                                                      Uploaded Files
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody >

                                                  {this.state.event.rounds.map(
                                                    (round) => (
                                                      <tr
                                                        key={round._id}
                                                        style={{
                                                          backgroundColor:
                                                            "transparent",
                                                          width: "100%",
                                                          display: "inline-table"
                                                        }}
                                                      >
                                                        <td className="col-12">
                                                          <div id="accordion">
                                                            <div className="card mb-1 shadow-none">
                                                              <div
                                                                className="card-header"
                                                                id="headingOne"
                                                              >
                                                                <a
                                                                  href={
                                                                    "#a" +
                                                                    round._id
                                                                  }
                                                                  className="text-dark"
                                                                  data-toggle="collapse"
                                                                  aria-expanded="true"
                                                                  aria-controls={
                                                                    "#" +
                                                                    round._id
                                                                  }
                                                                >
                                                                  <h6 className="m-0">
                                                                    <div className="d-inline-flex ">
                                                                      <span>
                                                                        Round:&nbsp;
                                                                        <span className="text-dark">{
                                                                          round.title
                                                                        }</span>
                                                                      </span>
                                                                    </div>
                                                                    <i
                                                                      className="bx bx-chevron-down"
                                                                      style={{
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                    ></i>
                                                                  </h6>
                                                                </a>
                                                              </div>
                                                              <div
                                                                id={
                                                                  "a" +
                                                                  round._id
                                                                }
                                                                className="collapse"
                                                                aria-labelledby="headingOne"
                                                                data-parent="#accordion"
                                                              >
                                                                <div className="card-body">
                                                                  <div className="table-responsive" style={{ overflowX: "hidden" }}>
                                                                    <table className="table table-nowrap table-centered table-hover mb-0">
                                                                      <tbody className="row">
                                                                        {this
                                                                          .state
                                                                          .team
                                                                          .submissions ? (
                                                                          this.state.team.submissions
                                                                            .reverse()
                                                                            .map(
                                                                              (
                                                                                sub,
                                                                                index
                                                                              ) =>
                                                                                (sub.type == "fcs" || sub.type == "file" || sub.type == "s3") && sub.round._id == round._id ?
                                                                                  (
                                                                                    <tr
                                                                                      key={
                                                                                        index
                                                                                      }
                                                                                      style={{
                                                                                        backgroundColor:
                                                                                          "transparent",
                                                                                      }}
                                                                                      className="col-12"
                                                                                    >
                                                                                      <td
                                                                                        style={{
                                                                                          width:
                                                                                            "100%",
                                                                                          height:
                                                                                            "fit-content",
                                                                                          "width": "10%"
                                                                                        }}
                                                                                      >
                                                                                        <h3>{index + 1}</h3> <i
                                                                                          style={{
                                                                                            verticalAlign:
                                                                                              "middle",
                                                                                            fontSize:
                                                                                              "20px",
                                                                                          }}
                                                                                          className="bx bxs-file-blank"
                                                                                        ></i>
                                                                                      </td>
                                                                                      <td
                                                                                        key={
                                                                                          index
                                                                                        }
                                                                                        style={{
                                                                                          cursor:
                                                                                            "pointer"
                                                                                        }}
                                                                                      >
                                                                                        {/* <small className="font-size-14 mb-1"  >{sub.file.fileName}</small> */}
                                                                                        <small>
                                                                                          {
                                                                                            sub
                                                                                              .file
                                                                                              .fileName
                                                                                          }
                                                                                          {ReactHtmlParser(
                                                                                            "&bullet;"
                                                                                          )}
                                                                                          {this.bytesToSize(
                                                                                            sub
                                                                                              .file
                                                                                              .fileSize
                                                                                          )}
                                                                                          <br />
                                                                                        </small>
                                                                                        <small>
                                                                                          <span className="font-weight-bolder text-muted"> Submission
                                                                                            Time:</span>&nbsp;
                                                                                          {formatDate(
                                                                                            new Date(
                                                                                              sub.createdAt
                                                                                            )
                                                                                          )}
                                                                                        </small>
                                                                                      </td>
                                                                                      <td style={{ width: "10%" }}>
                                                                                        <div className="text-center">
                                                                                          <a
                                                                                            href={`${BASE_URL}events/submission?fileId=${sub.file._id}`}
                                                                                            target="_blank" rel="noreferrer"
                                                                                          >

                                                                                            <i className="bx bx-download h3 m-0"></i>
                                                                                          </a>
                                                                                        </div>
                                                                                      </td>
                                                                                    </tr>
                                                                                  ) :
                                                                                  (sub.type == "link") && sub.round._id == round._id ? (
                                                                                    <tr
                                                                                      className="col-12"
                                                                                      style={{
                                                                                        backgroundColor:
                                                                                          "transparent",
                                                                                      }}
                                                                                    >
                                                                                      <td
                                                                                        style={{
                                                                                          width:
                                                                                            "45px",
                                                                                          height:
                                                                                            "fit-content",
                                                                                        }}
                                                                                      >
                                                                                        <i
                                                                                          style={{
                                                                                            verticalAlign:
                                                                                              "middle",
                                                                                            fontSize:
                                                                                              "20px",
                                                                                          }}
                                                                                          className="bx bxs-file-blank"
                                                                                        ></i>
                                                                                      </td>
                                                                                      <td
                                                                                        key={
                                                                                          index
                                                                                        }
                                                                                        style={{
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        }}
                                                                                      >
                                                                                        {/* <small className="font-size-14 mb-1"  >{sub.file.fileName}</small> */}
                                                                                        {/* <small>{sub.file.fileName} {ReactHtmlParser("&bullet;")} {this.bytesToSize(sub.file.fileSize)}<br /></small> */}
                                                                                        <small>
                                                                                          <span className="font-weight-bolder text-muted"> Submission
                                                                                            Time:</span>&nbsp;
                                                                                          {formatDate(
                                                                                            new Date(
                                                                                              sub.createdAt
                                                                                            )
                                                                                          )}
                                                                                        </small>
                                                                                      </td>
                                                                                      <td style={{ width: "10%" }}>
                                                                                        <div className="text-center">
                                                                                          <a
                                                                                            href={
                                                                                              sub.link
                                                                                            }
                                                                                            target="_blank" rel="noreferrer"
                                                                                          >

                                                                                            <i className="bx bx-link-alt h3 m-0"></i>
                                                                                          </a>
                                                                                        </div>
                                                                                      </td>
                                                                                    </tr>
                                                                                  )
                                                                                    :
                                                                                    <></>
                                                                            )
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                            <div
                                              id="attachFileModal"
                                              className="modal fade bs-example-modal-center"
                                              tabIndex="-1"
                                              role="dialog"
                                              aria-labelledby="mySmallModalLabel"
                                              aria-hidden="true"
                                              style={{
                                                backgroundColor: "#0000007d",
                                              }}
                                            >
                                              <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                  <div className="modal-header">
                                                    <h5 className="modal-title mt-0 font-weight-bolder">
                                                      Upload New Submission
                                                    </h5>
                                                    <button
                                                      type="button"
                                                      className="close"
                                                      data-dismiss="modal"
                                                      aria-label="Close"
                                                    >
                                                      <span
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          this.closeModal()
                                                        }
                                                      >
                                                        ×
                                                      </span>
                                                    </button>
                                                  </div>
                                                  <div className="modal-body">
                                                    {this.state
                                                      .submissionRound ===
                                                      null ? (
                                                      <>
                                                        Select the event round
                                                        you want to submit
                                                        against
                                                        <div className="row text-center mt-2">
                                                          {this.state.validRounds.map(
                                                            (round) => (
                                                              <div
                                                                className="col-sm-6 col-md-6 col-lg-6"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  border:
                                                                    "dashed 1px",
                                                                  textTransform:
                                                                    "Capitalize",
                                                                }}
                                                                onClick={() =>
                                                                  this.setState(
                                                                    {
                                                                      submissionRound:
                                                                        round._id,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                <span
                                                                  style={{
                                                                    display:
                                                                      "block",
                                                                    fontSize:
                                                                      "1vw",
                                                                    padding:
                                                                      "0.5rem",
                                                                  }}
                                                                >
                                                                  {round.title}
                                                                </span>
                                                              </div>
                                                            )
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : this.state
                                                      .submissionType ===
                                                      null ? (
                                                      <>
                                                        choose submission type
                                                        <div className="row text-center">
                                                          {/* file */}
                                                          <div
                                                            className="col-sm-6 col-md-6 col-lg-6"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.setState({
                                                                submissionType:
                                                                  "s3",
                                                              })
                                                            }
                                                          >
                                                            <i
                                                              className="bx bxs-file-blank"
                                                              style={{
                                                                padding: "2rem",
                                                                fontSize: "4vw",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "block",
                                                                  fontSize:
                                                                    "1vw",
                                                                  paddingTop:
                                                                    "1rem",
                                                                }}
                                                              >
                                                                FILE
                                                              </span>
                                                            </i>
                                                          </div>
                                                          {/* link */}
                                                          <div
                                                            className="col-sm-6 col-md-6 col-lg-6"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.setState({
                                                                submissionType:
                                                                  "link",
                                                              })
                                                            }
                                                          >
                                                            <i
                                                              className="bx bx-link-alt"
                                                              style={{
                                                                padding: "2rem",
                                                                fontSize: "4vw",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  display:
                                                                    "block",
                                                                  fontSize:
                                                                    "1vw",
                                                                  paddingTop:
                                                                    "1rem",
                                                                }}
                                                              >
                                                                LINK
                                                              </span>
                                                            </i>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : this.state
                                                      .submissionType ===
                                                      "link" ? (
                                                      <>
                                                        <div className="form-group">
                                                          <p className="text-muted">
                                                            Paste link to your
                                                            submission (github,
                                                            dropbox, onedrive,
                                                            google drive,
                                                            youtube... ) Please
                                                            make sure that links
                                                            are publicly
                                                            accessible
                                                          </p>
                                                          {/* <label>Link</label> */}
                                                          <input
                                                            className="form-control"
                                                            placeholder=" Link to submisson"
                                                            type="text"
                                                            id="link"
                                                            onChange={(event) =>
                                                              this.setState({
                                                                submissionLink:
                                                                  event.target
                                                                    .value,
                                                              })
                                                            }
                                                          />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                          <div className="d-flex">
                                                            <button
                                                              onClick={() => {
                                                                this.makeSubmission();
                                                              }}
                                                              type="submit"
                                                              style={{
                                                                padding:
                                                                  "11px 16px",
                                                              }}
                                                              className="btn btn-warning btn-rounded waves-effect waves-light"
                                                            >
                                                              Submit
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : this.state
                                                      .isUploading ? (
                                                      <div className="">
                                                        <p className="text-muted">
                                                          Uploading file
                                                        </p>
                                                        <p className="text-muted text-center mb-3">
                                                          {this.bytesToSize(
                                                            this.props
                                                              .uploadProgress
                                                              .uploaded
                                                          ) +
                                                            " of " +
                                                            this.bytesToSize(
                                                              this.props
                                                                .uploadProgress
                                                                .total
                                                            )}
                                                        </p>
                                                        <div
                                                          className="progress"
                                                          style={{
                                                            width: "90%",
                                                          }}
                                                        >
                                                          <div
                                                            className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                                            role="progressbar"
                                                            aria-valuenow={
                                                              this.props
                                                                .uploadProgress
                                                                .percent
                                                            }
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                              width:
                                                                this.props
                                                                  .uploadProgress
                                                                  .percent +
                                                                "%",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                right: "10px",
                                                              }}
                                                            >
                                                              {this.props
                                                                .uploadProgress
                                                                .percent + "%"}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : this.state
                                                      .isUploadComplete ? (
                                                      <div className="">
                                                        <div
                                                          style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            borderWidth: " 4px",
                                                            borderStyle:
                                                              "solid",
                                                            borderColor:
                                                              "rgb(76, 174, 76)",
                                                            borderRadius: "50%",
                                                            margin: "20px auto",
                                                            position:
                                                              "relative",
                                                            boxSizing:
                                                              "content-box",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              borderRadius:
                                                                "120px 0px 0px 120px",
                                                              position:
                                                                "absolute",
                                                              width: "60px",
                                                              height: "100px",
                                                              background:
                                                                "white",
                                                              transform:
                                                                "rotate(-45deg)",
                                                              top: "-7px",
                                                              left: "-33px",
                                                              transformOrigin:
                                                                "60px 60px",
                                                            }}
                                                          ></div>
                                                          <span
                                                            style={{
                                                              height: "5px",
                                                              backgroundColor:
                                                                "rgb(92, 184, 92)",
                                                              display: "block",
                                                              borderRadius:
                                                                "2px",
                                                              position:
                                                                "absolute",
                                                              zIndex: 2,
                                                              width: "25px",
                                                              left: "14px",
                                                              top: "46px",
                                                              transform:
                                                                "rotate(45deg)",
                                                              animation:
                                                                "0.75s ease 0s 1 normal none running animateSuccessTip",
                                                            }}
                                                          ></span>
                                                          <span
                                                            style={{
                                                              height: "5px",
                                                              backgroundColor:
                                                                "rgb(92, 184, 92)",
                                                              display: "block",
                                                              borderRadius:
                                                                "2px",
                                                              position:
                                                                "absolute",
                                                              zIndex: 2,
                                                              width: "47px",
                                                              right: "8px",
                                                              top: "38px",
                                                              transform:
                                                                "rotate(-45deg)",
                                                              animation:
                                                                "0.75s ease 0s 1 normal none running animateSuccessLong",
                                                            }}
                                                          ></span>
                                                          <div
                                                            style={{
                                                              width: "80px",
                                                              height: "80px",
                                                              border:
                                                                "4px solid rgba(92, 184, 92, 0.2)",
                                                              borderRadius:
                                                                "50%",
                                                              boxSizing:
                                                                "content-box",
                                                              position:
                                                                "absolute",
                                                              left: "-4px",
                                                              top: "-4px",
                                                              zIndex: 2,
                                                            }}
                                                          ></div>
                                                          <div
                                                            style={{
                                                              width: "5px",
                                                              height: "90px",
                                                              backgroundColor:
                                                                "rgb(255, 255, 255)",
                                                              position:
                                                                "absolute",
                                                              left: "28px",
                                                              top: "8px",
                                                              zIndex: 1,
                                                              transform:
                                                                "rotate(-45deg)",
                                                            }}
                                                          ></div>
                                                          <div
                                                            style={{
                                                              bordeRadius:
                                                                "0px 120px 120px 0px",
                                                              position:
                                                                "absolute",
                                                              width: "60px",
                                                              height: "120px",
                                                              background:
                                                                "white",
                                                              transform:
                                                                "rotate(-45deg)",
                                                              top: "-11px",
                                                              left: "30px",
                                                              transformOrigin:
                                                                "0px 60px",
                                                              animation:
                                                                "4.25s ease-in 0s 1 normal none running rotatePlaceholder",
                                                            }}
                                                          ></div>
                                                        </div>
                                                        <h4
                                                          style={{
                                                            position:
                                                              "relative",
                                                            color: "#495057",
                                                            maxWidth: "100%",
                                                            margin:
                                                              "0px 0px 0.4em",
                                                            padding: "0px",
                                                            fontWeight: "600",
                                                            textAlign: "center",
                                                            textTransform:
                                                              "none",
                                                            overflowWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          Upload completed
                                                        </h4>
                                                        <p className="text-muted text-center mb-3">
                                                          {this.bytesToSize(
                                                            this.props
                                                              .uploadProgress
                                                              .uploaded
                                                          ) +
                                                            " of " +
                                                            this.bytesToSize(
                                                              this.props
                                                                .uploadProgress
                                                                .total
                                                            )}
                                                        </p>
                                                        <div
                                                          className="progress"
                                                          style={{
                                                            width: "90%",
                                                          }}
                                                        >
                                                          <div
                                                            className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                                            role="progressbar"
                                                            aria-valuenow={
                                                              this.props
                                                                .uploadProgress
                                                                .percent
                                                            }
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                              width:
                                                                this.props
                                                                  .uploadProgress
                                                                  .percent +
                                                                "%",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                right: "10px",
                                                              }}
                                                            >
                                                              {this.props
                                                                .uploadProgress
                                                                .percent + "%"}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : this.state
                                                      .isUploadFailed ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            width: "80px",
                                                            height: "80px",
                                                            borderWidth: "4px",
                                                            borderStyle:
                                                              "solid",
                                                            borderColor:
                                                              "rgb(238, 162, 54)",
                                                            borderRadius: "50%",
                                                            margin: "20px auto",
                                                            position:
                                                              "relative",
                                                            boxSizing:
                                                              "content-box",
                                                            animation:
                                                              "0.75s ease 0s infinite alternate none running pulseWarning",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              width: "5px",
                                                              height: "47px",
                                                              left: "50%",
                                                              top: "10px",
                                                              borderRadius:
                                                                "2px",
                                                              marginLeft:
                                                                "-2px",
                                                              backgroundColor:
                                                                "rgb(240, 173, 78)",
                                                              animation:
                                                                "0.75s ease 0s infinite alternate none running pulseWarningIns",
                                                            }}
                                                          ></span>
                                                          <span
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              width: "7px",
                                                              height: "7px",
                                                              borderRadius:
                                                                "50%",
                                                              marginLeft:
                                                                "-3px",
                                                              left: "50%",
                                                              bottom: "10px",
                                                              backgroundColor:
                                                                "rgb(240, 173, 78)",
                                                              animation:
                                                                "0.75s ease 0s infinite alternate none running pulseWarningIns",
                                                            }}
                                                          ></span>
                                                        </div>
                                                        <h4
                                                          style={{
                                                            position:
                                                              "relative",
                                                            maxWidth: "100%",
                                                            textAlign: "center",
                                                            textTransform:
                                                              "none",
                                                            overflowWrap:
                                                              "break-word",
                                                          }}
                                                        >
                                                          File upload failed
                                                        </h4>
                                                        <p className="text-muted text-center mb-3">
                                                          {this.bytesToSize(
                                                            this.props
                                                              .uploadProgress
                                                              .uploaded
                                                          ) +
                                                            " of " +
                                                            this.bytesToSize(
                                                              this.props
                                                                .uploadProgress
                                                                .total
                                                            )}
                                                        </p>

                                                        <div
                                                          className="progress"
                                                          style={{
                                                            width: "90%",
                                                          }}
                                                        >
                                                          <div
                                                            className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                                            role="progressbar"
                                                            aria-valuenow={
                                                              this.props
                                                                .uploadProgress
                                                                .percent
                                                            }
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                              width:
                                                                this.props
                                                                  .uploadProgress
                                                                  .percent +
                                                                "%",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                position:
                                                                  "absolute",
                                                                right: "10px",
                                                              }}
                                                            >
                                                              {this.props
                                                                .uploadProgress
                                                                .percent + "%"}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="form-group">
                                                          <p className="text-muted">
                                                            Upload your
                                                            submission. Only
                                                            .zip files with a
                                                            maximum file size of
                                                            300MB are allowed.
                                                          </p>
                                                          {/* <label>File</label> */}
                                                          <input
                                                            type="file"
                                                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                                            id="customFile"
                                                            onChange={(event) =>
                                                              this.uploadFile(
                                                                event,
                                                                this
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="form-group mb-0">
                                                          <div className="d-flex">
                                                            <button
                                                              onClick={() => {
                                                                this.makeSubmission();
                                                              }}
                                                              type="submit"
                                                              style={{
                                                                padding:
                                                                  "11px 16px",
                                                              }}
                                                              className="btn btn-warning btn-rounded waves-effect waves-light"
                                                            >
                                                              Submit
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : moment(this.state.event.startDate) <
                                          moment() ? (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                                minHeight: "90px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  transform: "translateY(100%)",
                                                }}
                                              >
                                                Submissions closed
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-12">
                                            <div
                                              className="card text-center"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                                minHeight: "90px",
                                                border: "dashed 1px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  transform: "translateY(100%)",
                                                }}
                                              >
                                                Submissions open at&nbsp;
                                                {moment(
                                                  this.state.event.startDate
                                                ).format("DD-MM-YYYY HH:MM")}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="teamrequests"
                                      role="tabpanel"
                                    >
                                      <div className="pl-4">
                                        {this.state.teamRequestLoading ? (
                                          <div>Loading...</div>
                                        ) : (
                                          <div className="row">
                                            <>
                                              {/* iterate over list of users reandering a user card for each user */}
                                              {this.state.team.requests
                                                ? this.state.team.requests.map(
                                                  (user) => (
                                                    <div
                                                      key={user._id}
                                                      className="col-xl-3 col-lg-3 col-sm-6"
                                                    >
                                                      <div className="card text-center">
                                                        <div
                                                          className="card-body"
                                                          style={{
                                                            cursor: "pointer",
                                                            height: "13rem",
                                                          }}
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title={
                                                            user.githubData
                                                              ? user
                                                                .githubData
                                                                .public_repos +
                                                              " public projects on github"
                                                              : "0 public projects"
                                                          }
                                                          onClick={() =>
                                                            (window.location.href = `https://skillsranker.bih.co.bw/profile/${user._id}`)
                                                          }
                                                        >
                                                          <div className="avatar-lg mx-auto mb-4">
                                                            {/* check id user github data is available,
                                                                display user avatar if true else display users initials
                                                                if user name is not available display blank avatar */}

                                                            {user.githubData ? (
                                                              <img
                                                                className="rounded-circle avatar-lg"
                                                                src={
                                                                  user
                                                                    .githubData
                                                                    .avatar_url
                                                                }
                                                                alt=""
                                                              ></img>
                                                            ) : typeof user.name !==
                                                              undefined ? (
                                                              <span
                                                                className="avatar-title rounded-circle font-size-16"
                                                                style={{
                                                                  backgroundColor:
                                                                    "#0a0b0e9e",
                                                                  color:
                                                                    "#ffffffa3!important",
                                                                }}
                                                              >

                                                                {user.name
                                                                  .firstName[0] +
                                                                  " " +
                                                                  user.name
                                                                    .lastName[0]}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="avatar-title rounded-circle font-size-16"
                                                                style={{
                                                                  backgroundColor:
                                                                    "#0a0b0e9e",
                                                                  color:
                                                                    "#ffffffa3!important",
                                                                }}
                                                              >

                                                              </span>
                                                            )}
                                                          </div>
                                                          <h5 className="font-size-15 text-dark">
                                                            {typeof user.name !==
                                                              undefined
                                                              ? user.name
                                                                .firstName +
                                                              " " +
                                                              user.name
                                                                .lastName
                                                              : ""}
                                                          </h5>
                                                        </div>
                                                        <div className="card-footer bg-transparent border-top">
                                                          <div className="contact-links d-flex font-size-20">
                                                            <div className="flex-fill">
                                                              <button
                                                                onClick={() =>
                                                                  this.teamRequestRespond(
                                                                    user,
                                                                    true
                                                                  )
                                                                }
                                                                className="btn"
                                                                style={{
                                                                  padding:
                                                                    "0.6rem",
                                                                  fontSize:
                                                                    "15px",
                                                                  lineHeight:
                                                                    ".5",
                                                                }}
                                                              >

                                                                Accept
                                                              </button>
                                                            </div>
                                                            <div className="flex-fill">
                                                              <button
                                                                onClick={() =>
                                                                  this.teamRequestRespond(
                                                                    user,
                                                                    false
                                                                  )
                                                                }
                                                                className="btn"
                                                                style={{
                                                                  padding:
                                                                    "0.6rem",
                                                                  fontSize:
                                                                    "15px",
                                                                  lineHeight:
                                                                    ".5",
                                                                  backgroundColor:
                                                                    "red",
                                                                  color:
                                                                    "white",
                                                                }}
                                                              >

                                                                Reject
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )
                                                : null}
                                            </>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="tab-pane" id="teamnotifications" role="tabpanel">
                                              <div className="container">
                                                <div id="accordion">
                                                  <div className="card mb-1 shadow-none" style={{background:"transparent", border:"1px gray dashed"}}>
                                                      <div className="card-header" id="headingOne">
                                                          <h6 className="m-0">
                                                              <a href={'#A'+this.state.team.discussion._id} className="text-dark" data-toggle="collapse"
                                                                      aria-expanded="true"
                                                                      aria-controls="collapseOne">
                                                                  {this.state.team.discussion.title}
                                                              </a>
                                                              <p className="ml-4 mb-0" style={{display:"inline-flex", float:"right", fontSize:"12px"}}>{this.state.team.discussion.createdAt}</p>
                                                          </h6>
                                                      </div>
              
                                                      <div id={'A'+this.state.team.discussion._id} className="collapse"
                                                              aria-labelledby="headingOne" data-parent="#accordion">
                                                          <div className="card-body">
                                                            <MDEditor.Markdown source={this.state.team.discussion.content}/>
                                                          </div>
                                                      </div>
                                                  </div>
                                                </div>
                                              </div>
                                             </div> */}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="row">
                                <div className="col-12">
                                  <div
                                    className="card text-center"
                                    style={{
                                      cursor: "pointer",
                                      cursor: "pointer",
                                      backgroundColor: "#ff0000bd",
                                      minHeight: "60px",
                                      border: "dashed 1px",
                                      padding: "0 5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "white", padding: "20px 5px"

                                      }}
                                    >
                                      <i
                                        style={{
                                          marginRight: "20px",
                                          fontSize: "20px",
                                        }}
                                        className="bx bx-info-circle"
                                      ></i>
                                      <span style={{ verticalAlign: "text-bottom" }}>
                                        Rules and legal consent not found, please
                                        read through the rules and legal
                                        conditions below and accept to continue
                                        with the event</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h2
                                    style={{
                                      fontSize: "29px",
                                      textTransform: "uppercase",
                                    }}
                                    className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                                  >
                                    Event rules and legal
                                  </h2>
                                </div>

                                <div
                                  className="col-md-12 "
                                  style={{
                                    display: "block",
                                    fontFamily:
                                      "Ubuntu, sans-serif !important",
                                  }}
                                >
                                  {ReactHtmlParser(this.state.event.rules,)}
                                  <div
                                    className="mt-2 mb-2 small"
                                    style={{ textAlign: "left" }}
                                  >
                                    <input
                                      type="checkbox"
                                      id="customCheck1"
                                      onChange={() => {
                                        this.toggleConsent(
                                          !this.state.consentInfo
                                        );
                                      }}
                                    />
                                    <span className="ml-2 text-muted">
                                      By clicking the checkbox, I agree that I
                                      have read and agreed to the rules and
                                      Legal terms and conditions for
                                      participating in this event.
                                    </span>
                                  </div>
                                  <div className="mb-1 form-group">
                                    {this.isFormComplete() ? (
                                      <button
                                        onClick={() => this.updateConsent()}
                                        type="button"
                                        className="btn btn-warning text-dark font-weight-bolder"
                                      >
                                        Submit
                                      </button>
                                    ) : (
                                      <button
                                        disabled
                                        type="button"
                                        className="btn btn-warning text-dark font-weight-bolder"
                                      >
                                        Submit
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />

        {/* <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch> */}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contest: state.contestData.contest,
    eventTeams: state.teamsData.teams,
    team: state.teamsData.team,
    leaderboard: state.contestData.leaderboard,
    uploadProgress: state.contestData.uploadProgress,
    notifications: state.notificationsData.notificationsList,
    eventDiscussions: state.discussionData.eventDiscussions,
    comments: state.discussionData.comments,
    teamDiscussions: state.discussionData.teamDiscussions,
    report: state.reportData.report,
    // teamComments: state.discussionData.teamComments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContest: (cid) => dispatch(fetchContest(cid)),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getEventTeams: (eventId) => dispatch(fetchAllEventTeams(eventId)),
    getLeaderboard: (eventId) => dispatch(getContestleaderboard(eventId)),
    getUserTeam: (userId, eventId) =>
      dispatch(getEventUserTeam(userId, eventId)),
    checkEventParticipation: (eventId, userId, isBusiness) =>
      dispatch(checkParticipation(eventId, userId, isBusiness)),
    getEventDiscussions: (eventId, type = "event") =>
      dispatch(getDiscussions(eventId, type)),
    pushFile: (payload) => dispatch(createSubmission(payload)),
    updateTeamMembers: (team, requestingParty, isApproved) =>
      dispatch(updateMember(team, requestingParty, isApproved)),
    updateConsent: (user, event) => dispatch(updateConsent(user, event)),
    sendTeamInvite: (body) => dispatch(pushEventInvite(body)),
    leaveTeam: (body) => dispatch(leaveTeamWilingly(body)),
    createDiscussion: (body) => dispatch(createDiscussion(body)),
    takeOutTeamMember: (body) => dispatch(eliminateTeamMember(body)),
    createDiscussionComment: (body) => dispatch(createDiscussionComment(body)),
    createTeamDiscussionComment: (body) => dispatch(createTeamDiscussionComment(body)),
    getDiscussionComments: (discussionId, type) => dispatch(getDiscussionComments(discussionId, type)),
    appointLeader: (body) => dispatch(makeLeader(body)),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    removeNotification: (nid) => dispatch(deleteNotification(nid)),
    exitTeam: (body) => dispatch(leaveTeamWilingly(body)),
    reportContent: (body) => dispatch(_reportContent(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EventDetailsPage));
