import {
    client
} from "./client";


export function sessionCheck(session) {
    return client.get(`/events/judge/verify?session=${session}`);
}

export function gradeSubmission(data) {
    return client.put("/events/team/grade", data);
}

export function pushJudge(data) {
    return client.post("/events/judge/add", data);
}

export function updateJudges(data) {
    return client.put("/events/judge/update", data);
}

export function sendEmail(data) {
    return client.post("/events/judge/notify", data);
}

export function removeJudge(data) {
    return client.delete(`/events/judge/delete?judge=${data}`);
}


