const initState = {
    report: {}
};


const reportReducer = (state = initState, action) => {
    switch (action.type) {
        case "REPORT_CONTENT":
            return {
                ...state,
                report: action.report
            };

        default:
            break;
    }
    return state;
};


export default reportReducer;