const initState = {
  eventDiscussions: [],
  teamDiscussions: [],
  judgeDiscussions: [],
  comments: []
};

const discussionsReducer = (state = initState, action) => {

  switch (action.type) {
    case "GET_EVENT_DISCUSSIONS":
      return {
        ...state,
        eventDiscussions: action.eventDiscussions,
      };
    case "GET_TEAM_DISCUSSIONS":
      return {
        ...state,
        teamDiscussions: action.teamDiscussions,
      };
    case "GET_JUDGE_DISCUSSIONS":
      return {
        ...state,
        judgeDiscussions: action.judgeDiscussions,
      };
    case "POST_DISCUSSION_COMMENT":
      return {
        ...state,
        comments: [action.comment, ...state.comments],
      };
    case "POST_TEAM_DISCUSSION_COMMENT":
      return {
        ...state,
        teamComment: action.comment,
        teamDiscussions: action.teamDiscussions,
      };
    case "GET_DISCUSSION_COMMENTS":
      return {
        ...state,
        comments: action.comments,
      };
    case "GET_TEAM_DISCUSSIONS_COMMENTS":
      return {
        ...state,
        teamComments: action.comments,
      };
    case "GET_JUDGE_DISCUSSIONS_COMMENTS":
      return {
        ...state,
        judgeComments: action.comments,
      };
    case "POST_EVENT_DISCUSSION":
      return {
        ...state,
        eventDiscussions: [
          action.eventDiscussion,
          ...state.eventDiscussions,
        ],
      };
    default:
      break;
  }
  return state;
};

export default discussionsReducer;
