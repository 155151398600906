import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { ResponsivePie } from '@nivo/pie'
import { fireNotification } from "../store/actions/notificationsActions";
import { fetchPlatformSummary } from "../store/actions/usersActions";
import { handleSkills } from "../utils/utils";
import ExcelExport from "./ExcelExport";
import PDFExport from "./PDFExport";

import { getLanguages } from "../api/languages";
import { getTechnologies } from "../api/technologies";

class UserDeviceMapping extends Component {

   



    render() {


        return (

            <div className="card">
                <div className="card-body" id="userDeviceMapping">
                    {/* <h4 className="card-title mb-2">User Device Mapping</h4> */}
                    
                    <div className="clearfix"></div>
                    <iframe width="100%" height="1700" src="https://datastudio.google.com/embed/reporting/a24c2fad-f782-441b-b341-e7aed4d0680d/page/FB1xB" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                    


                </div>
            </div>


        );

    }
}

export default withRouter(UserDeviceMapping);
