import React, { Component } from "react";
import {publishCourse, fetchCourse, deleteCourse } from "../../store/actions/courseActions";

import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import { fireNotification } from "../../store/actions/notificationsActions";

class AdminLearn extends Component {
    state = {
        id:'',
        title:'',
        info:'',
        link:'',
        img:'',
        isEditing:false,
        isLoaded:false,
        caughtErr:false,
        showNotification:false,
        notifications:[]
      };
    
      componentDidMount(){
        //   check if creating a new contest or updating an old one
        const { history } = this.props;
        let cid = history.location.pathname.split("/")[3];

        if(cid === 'new' ){
            this.setState({isEditing:false, isLoaded:true, caughtErr:false})  
        }else{
            
            this.setState({isEditing:true})
            this.props.getCourse(cid)
            .then(() => this.setState({
                id:this.props.course.id,
                title:this.props.course.title,
                info:this.props.course.info,
                img:this.props.course.img,
                isLoaded:true,
            }))
            .catch(err => this.props.dispatchNotification(err));

        }
      }

 

    handleInputChange(doc){
        this.setState(doc)
    }


    removeCourse(){
        this.props.removeCourse(this.state.id)
        .then(res => this.props.dispatchNotification(res))
        .catch(err => this.props.dispatchNotification(err))
    }

  handleSubmit(e){
    {
        e.preventDefault()
        this.state.isEditing ? 
            this.props.updateCourse(this.state.id, this.state)
            .then((response_msg) => this.props.dispatchNotification(response_msg))
            .catch(err => this.props.dispatchNotification(err))
            : 
            this.props.updateCourse(null, this.state)
            .then((response_msg) => this.props.dispatchNotification(response_msg)) 
            .catch(err => this.props.dispatchNotification(err))
    }
  }
  render() {
      if(this.state.isLoaded){

        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Course</h4>

                                    
                                </div>
                            </div>
                        </div>     
                        {/* <!-- end page title --> */}

                        <div className="row">
                            <div className="col-xl-12 col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <label className="control-label">Course banner</label>
                                        <form action="#" className="dropzone">
                                            <div className="fallback">
                                                <input name="file" type="file"/>
                                            </div>
                                            <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                </div>
                                                
                                                <h4>Drop image here or click to upload.</h4>
                                            </div>
                                        </form>
                                        <form onSubmit={e => this.handleSubmit(e)} className="needs-validation mt-5" Validate>
                                            
                                            <div className="form-horizontal">
                                                <div className="form-group mb-0">
                                                    <label className="control-label">Title</label>
                                                    <br />
                                                        <input onChange={(e) => this.handleInputChange({title:e.target.value})} value={this.state.title} className="form-control"  required  name="title" type="text" placeholder="Title"/>
                                                        <div className="valid-feedback">
                                                            Looks good!
                                                        </div>
                                                    <br />
                                                </div>
                                            </div>
                                            
                                            <div className="form-horizontal">
                                                <div className="form-group mb-0">
                                                    <label className="control-label">Link to Course</label>
                                                    <br />
                                                        <input onChange={(e) => this.handleInputChange({link:e.target.value})} value={this.state.link} className="form-control"  required  name="link" type="text" placeholder="www.ciscoacad.com"/>
                                                        <div className="valid-feedback">
                                                            Looks good!
                                                        </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div className="form-horizontal">
                                                <div className="form-group mb-0">
                                                    <label className="control-label">Course Description</label>
                                                    <br />
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        rows="8"
                                                        required
                                                        maxLength="3000"
                                                        value={this.state.info}
                                                        onChange={(e) => this.handleInputChange({info:e.target.value})}
                                                        placeholder="Add course description/summary..."
                                                    ></textarea>
                                                    <div className="valid-feedback">
                                                        Looks good!
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div className="page-title-right">
                                                <button type="submit"  style={{backgroundColor:'#74788d', borderColor:'#74788d', color:'white'}} className="btn waves-effect waves-light">
                                                    <i className="bx bx-save font-size-16 align-middle mr-2" ></i> Save
                                                </button>
                                                {this.state.isEditing ? 
                                                    <button type="button" onClick={() => this.removeCourse()} style={{backgroundColor:'#74788d', borderColor:'#74788d', color:'white'}} className="ml-3 btn waves-effect waves-light">
                                                        <i className="bx bx-x font-size-16 align-middle mr-2" ></i> Delete
                                                    </button> 
                                                    : 
                                                    ''
                                                }
                                            </div>
                                        </form>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        {/* <!-- end row --> */}

                        
                    </div> {/**<!-- container-fluid -->**/}
                </div>
                {/* <!-- End Page-content --> */}

                
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-sm-6">
                            {new Date().getFullYear()} © Botswana Innovation Hub
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-right d-none d-sm-block">
                            Skills Ranker
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
          );

      }else{
          return(
              <AssesmentsSkeleton/>
          );
      }
    
  }
}

const mapStateToProps = (state) => {
    return {
        course: state.courseData.course,
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
      updateCourse: (cid, data) => dispatch(publishCourse(cid, data)),
      getCourse: (cid) => dispatch(fetchCourse(cid)),
      removeCourse: (cid) => dispatch(deleteCourse(cid)),
      dispatchNotification:(msg) => dispatch(fireNotification(msg)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdminLearn);
