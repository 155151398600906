import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";

import { validateBusinessEmail, pushInviteAcceptence, validateInvite} from "../../store/actions/businessProfileActions";
import globe from "../../../src/globe.svg";


class BusinessInvites extends Component {
  data = [];
  state = {
    hasAccount:false,
    exists:false,
    isSettingUpAccount:false,
    isInviteValid:false,
    isProcessingInvite:true,
    hasProcessingError:false
  };





  componentDidMount() {
    // get invite id from url
    // check if invite is valid
    // check if user has an account
    // 
    var inviteId = this.props.history.location.pathname.split('/')[3]
    this.props.checkInvite(inviteId)
    .then((res) =>{
      if(res.status == "pending"){
        this.setState({isInviteValid:true})

        this.props.checkEmailExists(res.email)
        .then((res) =>{
          if(res.isExist){
            this.setState({hasAccount:true})
            //accept invite
            this.props.acceptInvite({inviteId:inviteId, status:"accepted"})
              .then(() =>{
                this.setState({isProcessingInvite:false})
              }).catch(() =>{
                this.setState({hasProcessingError:true, isProcessingInvite:false})
              })
            
            // prompt user login
          }else{
            //create business user account
            this.setState({isProcessingInvite:false, hasAccount:false})
          }
        })
        .catch((err) =>{
          this.setState({hasProcessingError:true, isProcessingInvite:false})
        })
      }else{
        this.setState({isProcessingInvite:false, isInviteValid:false})
      }
    })
    .catch((err) =>{
      this.setState({isProcessingInvite:false, hasProcessingError:true})
    })
  }





  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />

        <div style={{ position: "fixed", width: "100%", height: "100%" }}>
          <div
            style={{
              backgroundImage: "url(" + globe + ")",
              transform: "translate(-50%, -50%) scale(1)",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform",
            }}
          ></div>
        </div>

        <main>
          <section
            style={{ alignItems: "center", minHeight: "100vh", backgroundColor: "rgb(49, 49, 50)" }}
            id="how-it-works"
            className=" p-relative slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix"
          >
            <div className="container-fluid" style={{zIndex:"10000", color:"white"}}>
              <div className="row">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5 text-center" >
                      <span>
                        <img
                          src="/assets/images/logo-light.png"
                          alt="skills ranker logo"
                          className="mb-3"
                          style={{
                            width: "200px",
                            cursor: "pointer",
                          }}
                        />{" "}
                        <h2
                          className="mt-4"
                          style={{
                            color: "white",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                         Business Invitations
                        </h2>
                      </span>
                      </div>
                      <div className="col-6 text-center" >
                      { this.state.isProcessingInvite ?
                          <h4 style={{color:"white"}}>
                            Proccesing invite
                          </h4>
                        :
                        this.state.isInviteValid ?
                          this.state.hasAccount ?
                          <div>
                            <div className="mt-3">
                              <h5 style={{color:"lime"}}>Invite Accepted, log into business account</h5>
                              <button
                                type="button"
                                onClick={() => this.props.history.push("/login")}
                                className="signup-1-btn btn btn-rounded waves-effect waves-light mt-3"
                              >
                                Take me to my account
                              </button>
                            </div>
                          </div>
                          :
                          <div className="mt-3">
                            <h5>Looks like you do not have a valid business account. To accept this invitation please setup your account first</h5>
                            <button
                              type="button"
                              onClick={() => this.props.history.push(`/register?redirect=${escape(this.props.history.location.pathname)}`)}
                              className="signup-1-btn btn btn-rounded waves-effect waves-light mt-3"
                            >
                              Setup Business account
                            </button>
                          </div>
                        :
                      
                          this.state.hasProcessingError ?
                          <div className="p-4">
                            <h4 style={{color:"white"}}>
                              We are having issues processing your invite
                            </h4>
                            <p style={{color:"white"}}> 
                              Please request a new invite or contact our support team
                            </p>
                          </div>
                          :
                          <div className="p-4">
                            <h4 style={{color:"white"}}>
                              Invite is invalid
                            </h4>
                            <p style={{color:"white"}}> 
                              We checked your invite, this invite has either been revoked or has expired please request another invite
                            </p>
                          </div>
                      }
                      </div>
                    </div>
                    
                  </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkInvite : (id) => dispatch(validateInvite(id)),
    checkEmailExists:(email) => dispatch(validateBusinessEmail(email)),
    acceptInvite:(body) => dispatch(pushInviteAcceptence(body))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessInvites));
