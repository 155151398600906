import {
  // updateUserTechnologies,
  createTech,
  deleteTech,
  adminGetTechnologies,
  updateTech,
  getTechnologies,
} from "../../api/technologies";
import Cookies from "js-cookie";
import {
  fireNotification
} from "./notificationsActions";

export const fetchUserTechnologies = () => {
  return dispatch => {
    const technologies = JSON.parse(Cookies.get("user")).technologies;

    var newTechnologies = '';
    newTechnologies = technologies.map(i => (
      newTechnologies + ',' + i
    ))

    return getTechnologies(technologies)
      .then(response => {
        return dispatch({
          type: "PULL_TECHNOLOGIES",
          data: response.data
        })
      });
  };
};


export const fetchAllTechnologies = () => {
  return dispatch => {
    return adminGetTechnologies()
      .then(response => {
        return dispatch({
          type: "PULL_ALL_TECHNOLOGIES",
          data: response.data,
        })
      })
      .catch(err => (dispatch(fireNotification(err)), console.log(err),
        Promise.reject(err)))
  };
};

export const updateTechnologies = (body) => {
  return dispatch => {
    return updateTech(body)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "UPDATE_TECHNOLOGIES",
          data: response.data
        });

      })
      .catch(err => Promise.reject(err));
  };
};

export const pushTech = (body) => {
  return dispatch => {
    return createTech(body)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "CREATE_TECHNOLOGY",
          data: response.data
        });

      })
      .catch(err => dispatch(fireNotification(err)))
  };
};

export const killTech = (id) => {
  return dispatch => {
    return deleteTech(id)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "DELETE_TECHNOLOGY",
          data: response.data
        });

      })
      .catch(err => dispatch(fireNotification(err)))
  };
};

export const techUpdate = (body) => {
  return dispatch => {
    return updateTech(body)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "UPDATE_TECHNOLOGY",
          data: response.data
        });

      })
      .catch(err => dispatch(fireNotification(err)))
  };
};